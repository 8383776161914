import type { Maybe } from '@adornis/base/utilTypes';
import { Entity, Field } from '@adornis/baseql/decorators';
import { Base } from '@adornis/buildify/db/Base';
import { validate } from '@adornis/validation/decorators';
import { nonOptional } from '@adornis/validation/functions/nonOptional';

@Entity()
export class DubniumBaseLeadSignupFormular extends Base {
  static override _class = 'DubniumBaseLeadSignupFormular';

  @Field(type => Boolean) isWebinar?: Maybe<boolean>;
  @Field(type => Boolean) isNewsletter?: Maybe<boolean>;

  @validate(nonOptional())
  @Field(type => [String], { default: v => v ?? [] })
  communicationCampaigns?: Maybe<string[]>;
}
