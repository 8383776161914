import { registerQuery } from '@adornis/baseql/metadata/register';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { Product } from '@adornis/digitale-helden-shared/db/product/Product';
import { getAllProducts } from './getAllProducts';

//* Resolver
const getAllActiveProductsResolver = () => {
  return async (gqlFields: BaseQLSelectionSet<Product>) => {
    const filterProduct = (product: Product): boolean => {
      return product.isActive();
    };
    const allProducts = await getAllProducts()(gqlFields);
    const filteredProducts = allProducts.filter(product => filterProduct(product));
    return filteredProducts;
  };
};

//* Query
export const getAllActiveProducts = registerQuery({
  type: () => [Product],
  operationName: 'getAllActiveProducts',
  resolve: getAllActiveProductsResolver,
});
