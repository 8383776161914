import { registerMutation } from '@adornis/baseql/metadata/register';
import { context } from '@adornis/baseql/server/context';
import { Contact } from '@adornis/digitale-helden-shared/db/Contact';
import { ContactEmailStatus } from '@adornis/digitale-helden-shared/db/enums';
import { UserRoles } from '@adornis/digitale-helden-shared/db/permissions';
import { Order } from '../../../db/Order';
import { ContactCompanyRelation } from '../../../db/Relations/ContactCompanyRelation';
import { GroupContactRelation } from '../../../db/Relations/GroupContactRelation';
import { checkRole } from '../../../db/helpers';
import { getAllContactCompanyRelationsByContactCOQL } from '../../contact-company-relation/queries/getAllContactCompanyRelationsByContactCOQL';
import { getAllGroupContactRelationsByContactIDCOQL } from '../../group-contact-relations/queries/getAllGroupContactRelationsByContactIDCOQL';
import { deleteContactByID } from '../queries/deleteContactByID';
import { getContactByIDCOQL } from '../queries/getContactByIDCOQL';

export const removeContactByIDSafe = registerMutation({
  type: () => String,
  operationName: 'removeContactByIDSafe',
  resolve: (id: string) => {
    return async () => {
      await checkRole({ context, role: UserRoles.SUPER_ADMIN });
      if (!id) return null;

      const contact = await getContactByIDCOQL(id)(Contact.allFields);
      if (!contact) return;

      if (
        contact.isNewsletter ||
        contact.isWebinar ||
        (contact.communicationCampaigns ?? []).length > 0 ||
        (contact.emailStatus !== ContactEmailStatus.OPT_IN_PENDING && contact.emailStatus !== ContactEmailStatus.SOI)
      )
        return;

      const countOrdersOfContact = await Order.countOrderByContactID(id)();
      if ((countOrdersOfContact ?? 0) > 0) return;

      const companyRelations = await getAllContactCompanyRelationsByContactCOQL(id)(ContactCompanyRelation.allFields);
      if (companyRelations.length > 0) return;

      const groupRelations = await getAllGroupContactRelationsByContactIDCOQL(id)(GroupContactRelation.allFields);
      if (groupRelations.length > 0) return;

      await deleteContactByID(id)();
    };
  },
  params: [{ name: 'id', type: () => String }],
});
