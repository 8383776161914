import { setGlobalStyle } from '@adornis/chemistry/elements/theming/global-style';

setGlobalStyle(() => ({
  '@keyframes slide-in-blurred-bottom': {
    '0%': {
      transform: 'translateY(1000px) scaleY(2.5) scaleX(0.2)',
      transformOrigin: '50% 100%',
      filter: 'blur(40px)',
      opacity: '0',
    },
    '100%': {
      transform: 'translateY(0) scaleY(1) scaleX(1)',
      transformOrigin: '50% 50%',
      filter: 'blur(0)',
      opacity: '1',
    },
  },
  '.slide-in': {
    animation: 'slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both',
  },
  '.slide-out': {
    animation: 'slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both',
    animationDirection: 'reverse',
  },
}));
