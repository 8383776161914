import { logger } from '@adornis/base/logging';
import { type ValueEvent } from '@adornis/base/utilTypes';
import { ChemistryLitElement } from '@adornis/chemistry/chemistry-lit-element';
import { XSnackbar } from '@adornis/chemistry/elements/components/x-snackbar.js';
import { upsertLeadOrContactAndCheckDOI } from '@adornis/digitale-helden-shared/api/Lead/upsertLeadOrContactAndCheckDOI';
import '@adornis/digitale-helden-shared/client/theme/d-checkbox';
import { DubniumDialog } from '@adornis/digitale-helden-shared/client/theme/d-dialog';
import '@adornis/digitale-helden-shared/client/theme/d-dropdown-selection';
import '@adornis/digitale-helden-shared/client/theme/d-highlight';
import '@adornis/digitale-helden-shared/client/theme/d-input';
import '@adornis/digitale-helden-shared/client/theme/d-link';
import '@adornis/digitale-helden-shared/client/theme/d-tooltip';
import { Lead } from '@adornis/digitale-helden-shared/db/Lead';
import { UpsertContactOptions } from '@adornis/digitale-helden-shared/db/UpsertContactOptions';
import { ContactAcademyRole, Salutation } from '@adornis/digitale-helden-shared/db/enums';
import { FormController } from '@adornis/forms/x-form-controller';
import '@adornis/internationalization/client/x-tel-input';
import { TranslationController } from '@adornis/translation-core/client/translation-controller';
import { html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { triggerSuccessIcon } from '../../../client/theme/animations/success-animation';
import { DefaultWindowSizeController, _contentBySize, _defaultColumnsBySize } from '../helpers';

@customElement('las-form-newsletter')
export class LASFormNewsletter extends ChemistryLitElement {
  @state() private readonly _translation = new TranslationController<any>(this);
  @state() private readonly formController = this._initFormController();
  @state() private repeatEmail: string | undefined = undefined;
  @state() private _acceptedPrivacy = false;

  @state() private _isFinished = false;

  private readonly _size = DefaultWindowSizeController(this);

  override render() {
    if (this._isFinished) return html`<las-success show-email-note></las-success>`;

    const doc = this.formController.document;
    if (!doc) return nothing;
    return html`
      <d-flex space="md">
        <d-grid space="md" columns=${_defaultColumnsBySize(this._size)}>
          <d-dropdown-selection
            placeholder-mode="static-floating"
            placeholder="Anrede"
            .selectables=${Object.values(Salutation)}
            .renderString=${val => this._translation.translate(val)}
            ${this.formController.field('salutation')}
          >
          </d-dropdown-selection>
          ${_contentBySize({ size: this._size, mobile: undefined, desktop: html`<div></div>` })}
          <d-input
            placeholder-mode="static-floating"
            placeholder=${this._translation.translate('first_name')}
            flex
            ${this.formController.field('firstName')}
          ></d-input>
          <d-input
            placeholder-mode="static-floating"
            placeholder=${this._translation.translate('last_name')}
            flex
            ${this.formController.field('lastName')}
          ></d-input>
          <d-input
            type="email"
            placeholder=${this._translation.translate('e_mail')}
            placeholder-mode="static-floating"
            ${this.formController.field('email')}
            @value-picked=${() => this.requestUpdate()}
          ></d-input>
          <d-input
            type="email"
            placeholder=${this._translation.translate('e_mail_repeat')}
            placeholder-mode="static-floating"
            onpaste="return false"
            ondrop="return false"
            .value=${this.repeatEmail}
            @value-picked=${(e: ValueEvent<string>) => (this.repeatEmail = e.detail.value)}
            error=${ifDefined(
              this.formController.document?.email === this.repeatEmail || this.repeatEmail === undefined
                ? undefined
                : this._translation.translate('email_repeat_error'),
            )}
          ></d-input>
          <!-- rolle -->
          <d-dropdown-selection
            ${this.formController.field('academyRoles')}
            placeholder=${this._translation.translate('academy_role')}
            placeholder-mode="static-floating"
            .selectables=${Object.values(ContactAcademyRole)}
            .renderString=${val => this._translation.translate(val)}
            @value-picked=${(e: ValueEvent<string>) => {
              doc.academyRoles = [e.detail.value];
              // @ts-expect-error fehler.
              doc.otherAcademyRole = null;
              this.requestUpdate();
            }}
          >
          </d-dropdown-selection>
          ${!doc.academyRoles?.includes(ContactAcademyRole.SONSTIGES)
            ? nothing
            : html`
                <d-input
                  placeholder=${this._translation.translate('academy_role_other')}
                  placeholder-mode="static-floating"
                  .value=${doc.otherAcademyRole}
                  @value-picked=${(e: ValueEvent<string>) => {
                    if (!e.detail.value || e.detail.value === '') {
                      doc.academyRoles = [ContactAcademyRole.SONSTIGES];
                      return;
                    }
                    doc.otherAcademyRole = e.detail.value;
                    this.requestUpdate();
                  }}
                ></d-input>
              `}
        </d-grid>

        <d-flex horizontal crossaxis-center space="xs">
          <d-checkbox
            .value=${this._acceptedPrivacy}
            @value-picked=${(e: ValueEvent<boolean>) => {
              this._acceptedPrivacy = e.detail.value;
            }}
          ></d-checkbox>
          <d-text> ${this._translation.translate('accepts_data_privacy')} </d-text>
        </d-flex>
        <d-flex horizontal end>
          <d-button
            ?disabled=${!this._acceptedPrivacy}
            @click=${async () => {
              if (!this.repeatEmail) this.repeatEmail = '';
              await this.formController.validate();
            }}
          >
            ${this._translation.translate('send')}
          </d-button>
        </d-flex>
      </d-flex>
    `;
  }

  private _initFormController(): FormController<Lead> {
    this.repeatEmail = undefined;
    this._acceptedPrivacy = false;
    return new FormController(
      this,
      new Lead({
        isNewsletter: true,
      }),
      {
        success: async (data: Lead) => {
          if (!this.repeatEmail || this.repeatEmail !== data.email) {
            return XSnackbar.show(this._translation.translate('email_repeat_error'));
          }

          return DubniumDialog.waitFor(
            upsertLeadOrContactAndCheckDOI(
              data,
              false,
              new UpsertContactOptions({ checkAcademyRole: true, checkOtherAcademyRole: true }),
            )(),
            this._translation.translate('loading', 'subscribing_newsletter'),
            {
              closeOnNavigate: false,
            },
          ).then(() => {
            this._isFinished = true;
            return triggerSuccessIcon();
          });
        },
        error: err => {
          logger.error(err);
          return XSnackbar.show(this._translation.translate('enter_all_non_optional'));
        },
        translateError: key => this._translation.translate(key),
      },
    );
  }
}
