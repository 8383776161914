import { H3Extension } from '@adornis/buildify/client/extensions/H3Extension';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import { html } from 'lit';
import { h3Extensions } from '../../../../../client/tiptap-editor/visual-text-extensions';
import './campus-h3-extension';

export const BuildifyCampusH3Extension: BuildifyExtension = {
  class: H3Extension.class,
  toolbar: { icon: 'heading', text: { default: 'H3' } },
  render(instance, { defaultValues, isSubelement }) {
    return html`
      <campus-h3-extension
        .content=${instance}
        .defaultValues=${defaultValues}
        ?sub=${isSubelement}
      ></campus-h3-extension>
    `;
  },
  editor({ contentController, controllerBaseKeyPath }) {
    return html`
      <d-tiptap-buildify
        hide-typo
        ${contentController.field(...controllerBaseKeyPath, 'text')}
        .extensions=${h3Extensions}
      ></d-tiptap-buildify>
    `;
  },
  group: 'Schriften',
};
