import type { Styles } from '@adornis/ass/style';
import type { Maybe } from '@adornis/base/utilTypes';
import { XContainerExtension } from '@adornis/buildify/client/extensions/ContainerExtension';
import { Size } from '@adornis/buildify/client/globals/enums';
import { __renderBase } from '@adornis/buildify/client/globals/methods';
import type { Base } from '@adornis/buildify/db/Base';
import { BaseContainer } from '@adornis/buildify/db/extensions/BaseContainer';
import { css } from '@adornis/chemistry/directives/css.js';
import type { Renderable } from '@adornis/chemistry/renderable';
import { goTo } from '@adornis/router/client/open-href';
import { html, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import type { DubniumBaseContainer } from '../../../../db/extensions/DubniumBaseContainer';

@customElement('campus-container-extension')
export class CampusContainerExtension extends XContainerExtension {
  override connectedCallback(): void {
    super.connectedCallback();

    this.addEventListener('click', () => {
      const content = this.content.value as Maybe<DubniumBaseContainer>;
      if (!content) return;
      if (!content.link.resolvedLink) return;
      if (this.isEditMode) return;
      goTo(content.link.resolvedLink, { target: content.link.target ? content.link.target : '_self' });
    });
  }

  protected override _content(content: DubniumBaseContainer): any {
    if (this._contentFilteredSorted?.length === 0) {
      return this.__contentWrapper(
        html` <x-buildify-dropzone hide-text .index=${0} .parentID=${content._id}></x-buildify-dropzone> `,
      );
    }

    const contents = repeat(this._contentFilteredSorted ?? [], (content: Base, index) =>
      this.__item(content, content.index, index),
    );

    return this.__contentWrapper(html` ${contents} `);
  }

  protected override __item(content: Base, contentIndex: number, index: number) {
    const isContainer = content instanceof BaseContainer;
    const defaultValues = isContainer ? { margin: { global: '0' } } : {};

    return html`
      <!-- dropzone -->
      ${this.__dropzoneBetweenContent(contentIndex)}
      <!-- content  -->
      ${__renderBase(this._consumedExtensions.value, content, { defaultValues })}
      <!-- dropzone -->
      ${index === (this._contentFilteredSorted ?? []).length - 1
        ? this.__dropzoneBetweenContent(contentIndex + 1)
        : nothing}
    `;
  }

  protected __contentWrapper(render: Renderable) {
    return html` ${render} ${this.__leftLine()} `;
  }

  protected __leftLine() {
    if (!this.getValue('hasLineLeft')) return nothing;
    return html`
      <d-flex
        ${css({
          background: this.defaultValue('lineLeftColor', { global: this.colors.accent }),
          position: 'absolute',
          top: '1px',
          left: '0',
          height: 'calc(100% - 2px)',
          width: this.defaultValue('lineLeftWidth', { global: '3px' }),
          borderRadius: this.sizes.borderRadius,
          zIndex: '20',
        })}
      ></d-flex>
    `;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          position: 'relative',
          borderRadius: this.defaultValue('borderRadius', { global: '5px' }),
          backgroundColor: this.defaultValue('backgroundColor', { global: 'transparent' }),
          margin: this.defaultValue('margin', { global: '0 0 48px 0', [Size.MOBILE]: '0 0 32px 0' }),
          padding: this.defaultValue('padding', { global: this.isViewMode ? '0' : '10px' }),

          '&:hover': {
            ...(!!this.getValue('link')?.link
              ? {
                  cursor: 'pointer',
                  boxShadow: `2px 2px 11px 0 ${this.colors.tone.secondaryText}`,
                }
              : {}),
          },
        },
      },
    ] as Styles[];
  }
}
