import { ChemistryLitElement } from '@adornis/chemistry/chemistry-lit-element';
import { customElement, property } from 'lit/decorators.js';

@customElement('las-shimmer')
export class LASShimmer extends ChemistryLitElement {
  @property({ type: String }) height = '60px';
  @property({ type: String }) width = '100%';

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          display: 'block',
          height: this.height,
          width: this.width,
          background: 'linear-gradient(-45deg, #eee 40%, #e5e5e5 50%, #eee 60%)',
          backgroundSize: '300%',
          backgroundPositionX: '-300%',
          animation: 'shimmer 3s infinite linear',
        },
        '@keyframes shimmer': {
          to: {
            backgroundPositionX: '0%',
          },
        },
      },
    ];
  }
}

@customElement('las-shimmer-letter')
export class LASShimmerLetter extends LASShimmer {
  @property({ type: String }) override height = '16px';
  @property({ type: String }) override width = '6px';
}
