import { ExtensionBoilerplate } from '@adornis/buildify/client/extensions/ContentExtensionBoilerplate';
import { goTo } from '@adornis/router/client/open-href';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import type { DubniumBaseButton } from '../../../../../db/buildify/DubniumBaseButton';
import '../../../d-button';

@customElement('campus-button-extension')
export class CampusButtonExtension extends ExtensionBoilerplate<DubniumBaseButton> {
  protected override _content(content: DubniumBaseButton) {
    return html`
      <d-button
        mode=${content.invert ? 'outline' : 'action'}
        tone=${content.isDigitalEmergency ? 'emergency' : ''}
        ?invert=${content.invert}
        @click=${() => {
          if (!content.link.resolvedLink) return;
          goTo(content.link.resolvedLink, { target: content.link.target ?? '_blank' });
        }}
      >
        ${content.text}
      </d-button>
    `;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          ...(this.getValue('alignHorizontal') === 'center' ? { left: '50%', transform: 'translateX(-50%)' } : {}),
          ...(this.getValue('alignVertical') === 'center' ? { top: '50%', transform: 'translateY(-50%)' } : {}),
          ...(this.getValue('alignVertical') === 'center' && this.getValue('alignHorizontal') === 'center'
            ? { transform: 'translate(-50%, -50%)' }
            : {}),
        },
      },
    ];
  }
}
