import { Arg, Entity, Field, Query } from '@adornis/baseql/decorators';
import { type ContactAcademyRole } from '@adornis/digitale-helden-shared/db/enums';
import { BiMap } from '@adornis/digitale-helden-shared/db/zoho/BiMap';
import { ZohoModule } from '@adornis/digitale-helden-shared/db/zoho/zoho';
import { ZohoEntity } from '@adornis/digitale-helden-shared/db/zoho/zoho-entity';
import { genericSerializeZoho } from '@adornis/digitale-helden-shared/server/zoho/interface-zoho-adornis';
import { AdornisFile } from '@adornis/file-utils/db/files';
import { getCertificate } from '../server/certificates/get-certificate';
import { Company } from './Company';
import { Order } from './Order';
import { GroupContactRelation } from './Relations/GroupContactRelation';
import { GroupContactRole } from './enums';

export enum CERTIFICATE_ZOHO_FIELDS {
  ID = 'id',
  AKADEMIE_ROLLE = 'Akademie_Rolle',
  GROUP_PERMISSION = 'Gruppen_Permission',
  BUNDESLAND = 'Bundesland',
  PRODUKT = 'Produkt',
  ZERTIFIKAT_URL = 'Zertifikat_URL',
  DATEINAME = 'Dateiname',
}

export const CERTIFICATE_BIMAP = new BiMap<string, CERTIFICATE_ZOHO_FIELDS>([
  ['id', CERTIFICATE_ZOHO_FIELDS.ID],
  ['academyRole', CERTIFICATE_ZOHO_FIELDS.AKADEMIE_ROLLE],
  ['productID', CERTIFICATE_ZOHO_FIELDS.PRODUKT],
  ['certificateURL', CERTIFICATE_ZOHO_FIELDS.ZERTIFIKAT_URL],
  ['bundesland', CERTIFICATE_ZOHO_FIELDS.BUNDESLAND],
  ['groupContactRole', CERTIFICATE_ZOHO_FIELDS.GROUP_PERMISSION],
  ['filename', CERTIFICATE_ZOHO_FIELDS.DATEINAME],
]);

/**
 * @entity CertificateFile
 */
@Entity()
export class CertificateFile extends AdornisFile {
  static override _class = 'CertificateFile';
}

const CERTIFICATE_FOREIGN_KEYS: CERTIFICATE_ZOHO_FIELDS[] = [CERTIFICATE_ZOHO_FIELDS.PRODUKT];
/**
 * @entity Certificate
 */
@Entity()
export class Certificate extends ZohoEntity {
  static override _class = 'Certificate';
  static override ZOHO_MODULE = ZohoModule.CERTIFICATE;
  static override ZOHO_FIELDS = Array.from(CERTIFICATE_BIMAP.values).join(',');

  @Field(type => String) id?: string;
  @Field(type => [String]) academyRole?: ContactAcademyRole[];
  @Field(type => String) groupContactRole?: GroupContactRole;
  @Field(type => [String]) bundesland?: string[];
  @Field(type => String) productID?: string;
  @Field(type => String) certificateURL?: string;
  @Field(type => String) filename?: string;

  override serializeZoho = (isNew: boolean = false) => {
    return genericSerializeZoho({
      bimap: CERTIFICATE_BIMAP,
      instance: this,
      foreignKeys: CERTIFICATE_FOREIGN_KEYS,
    });
  };

  static override deserializeZoho(rawData: object) {
    const fields = {};
    const keys = Array.from(CERTIFICATE_BIMAP.reverseKeys);
    keys.forEach(key => {
      const keyLAS = CERTIFICATE_BIMAP.reverseGet(key);
      const value = rawData[key];
      if (keyLAS) {
        if (CERTIFICATE_FOREIGN_KEYS.includes(key)) {
          fields[keyLAS] = value?._id ?? null;
        } else {
          fields[keyLAS] = rawData[key] ?? null;
        }
      }
    });

    return new Certificate({
      ...fields,
    });
  }

  @Query(type => Boolean)
  static hasValidCertificate(@Arg('relation', type => GroupContactRelation) relation: GroupContactRelation) {
    return async (gqlFields: void): Promise<boolean> => {
      const orders = await Order.getOrdersByGroups([relation.groupId])(Order.allFields);
      const order = orders?.[0];
      if (!order) {
        throw new Error(`Order for group '${relation.groupId}' coulnd't be found.`);
      }

      const company = await Company.getCompanyById(order.buyerCompanyId)({ name: 1, state: 1 });
      if (!company?.name) {
        throw new Error(`Couldn't find company or company has no name.`);
      }

      const cert = await getCertificate(
        order.productId,
        relation.academyRole,
        relation.groupContactRole,
        company.state,
      );
      return !!cert && !!cert.certificateURL;
    };
  }

  @Query(type => Boolean)
  static checkRelationsForValidCertificate(
    @Arg('relations', type => [GroupContactRelation]) relations: GroupContactRelation[],
  ) {
    return async (gqlFields: void) => {
      for (const relation of relations) {
        const isValid = await Certificate.hasValidCertificate(relation)();
        if (isValid) return true;
      }
      return false;
    };
  }
}
