import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import { html } from 'lit';
import { DubniumBaseSupportFormular } from '../../../../../../db/buildify/_formulars/DubniumBaseSupportFormular';
import './campus-support-formular-extension';

export const BuildifyCampusSupportFormularExtension: BuildifyExtension<DubniumBaseSupportFormular> = {
  class: DubniumBaseSupportFormular,
  toolbar: { icon: 'headset', text: { default: 'Supportformular' } },
  render(instance, { defaultValues, isSubelement, dropzoneBefore, dropzoneAfter }) {
    return html`
      <campus-support-formular-extension
        .content=${instance}
        .defaultValues=${defaultValues}
        ?sub=${isSubelement}
        .hasDropzoneBefore=${dropzoneBefore}
        .hasDropzoneAfter=${dropzoneAfter}
      ></campus-support-formular-extension>
    `;
  },
  editor({}) {
    return html` <d-text> Hier kann nichts zusätzlich verändert werden. </d-text> `;
  },
  group: 'Formulare',
};
