import { ExtensionBoilerplate } from '@adornis/buildify/client/extensions/ContentExtensionBoilerplate';
import type { DubniumBaseSupportFormular } from 'db/buildify/_formulars/DubniumBaseSupportFormular';
import 'formulars/_support-form/client/las-form-support';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('campus-support-formular-extension')
export class CampusSupportFormularExtension extends ExtensionBoilerplate<DubniumBaseSupportFormular> {
  protected override _content(content: DubniumBaseSupportFormular) {
    return html` <las-form-support></las-form-support> `;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          width: '100%',
        },
      },
    ];
  }
}
