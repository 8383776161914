import '@adornis/buildify/client/components/x-buildify-permission-selection';
import { TextExtension } from '@adornis/buildify/client/extensions/TextExtension';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import { BaseH1 } from '@adornis/buildify/db/extensions/text/BaseH1';
import type { AdornisTiptapExtension } from '@adornis/tiptap/client/extensions/ExtensionBase';

export const BuildifyCampusH1Extension = (tiptapExtensions: AdornisTiptapExtension[][]): BuildifyExtension<BaseH1> => ({
  class: BaseH1,
  toolbar: { icon: 'h1', text: { default: 'H1' } },
  group: 'Schriften',
  render: TextExtension(tiptapExtensions).render,
  editor: TextExtension(tiptapExtensions).editor,
});
