import { ChemistryLitElement } from '@adornis/chemistry/chemistry-lit-element';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('d-sup')
export class DSuperscript extends ChemistryLitElement {
  override render() {
    return html`<sup><slot></slot></sup>`;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          fontSize: 'inherit',
          lineHeight: 'inherit',
          fontFamily: 'inherit',
        },
        sup: {
          position: 'relative',
          top: '-5px',
          verticalAlign: 'unset',
        },
      },
    ];
  }
}
