/* eslint-disable complexity */
import { ExtensionBoilerplate } from '@adornis/buildify/client/extensions/ContentExtensionBoilerplate';
import { SizeBreakpointConsumer } from '@adornis/buildify/client/globals/consumer';
import { Size } from '@adornis/buildify/client/globals/enums';
import { __renderBase } from '@adornis/buildify/client/globals/methods';
import { css } from '@adornis/chemistry/directives/css';
import { DubniumBaseIcon } from '@adornis/digitale-helden-shared/db/buildify/DubniumBaseIcon';
import { goTo } from '@adornis/router/client/open-href';
import { html, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import { LASFile } from '../../../../../db/files/LASFile';
import type { DubniumBaseLinkList, DubniumBaseLinkListItem } from '../../../../db/extensions/DubniumBaseLinkList';

@customElement('campus-link-list-extension')
export class CampusLinkListExtension extends ExtensionBoilerplate<DubniumBaseLinkList> {
  private readonly _consumedBreakpoints = SizeBreakpointConsumer(this);

  protected override _content(content: DubniumBaseLinkList) {
    return html`
      <d-flex space="md">
        <!-- items -->
        <d-flex space="sm">
          ${content.items.map((item, index) => {
            return html`
              <d-flex horizontal crossaxis-center space="lg">
                <!-- first icon -->
                ${this._renderFirstIcon(item)}

                <!-- text -->
                ${this._renderText(item)}

                <!-- second icon -->
                ${this._renderSecondIcon(item)}
              </d-flex>
              ${this._renderDivider(content, index)}
            `;
          })}
        </d-flex>
      </d-flex>
    `;
  }

  private _renderDivider(content: DubniumBaseLinkList, index: number) {
    if (index + 1 >= content.items.length) return nothing;
    return html`
      <d-flex ${css({ background: this.colors.tone.secondaryText, height: '1px', width: '100%' })}></d-flex>
    `;
  }

  private _renderFirstIcon(item: DubniumBaseLinkListItem) {
    if (item.hideIcon) return nothing;

    return html`
      ${__renderBase(
        this._consumedExtensions.value,
        item.icon ?? new DubniumBaseIcon({ isBold: true, icon: item.firstIcon }),
        { isSubelement: true },
      )}
    `;
  }

  private _renderSecondIcon(item: DubniumBaseLinkListItem) {
    return html`
      <las-icon-button
        medium
        background=${item.isDigitalEmergency ? 'emergency' : 'accent'}
        color="white"
        icon=${item.secondIcon}
        secondary
        hover
        @click=${() => {
          if (item.link.resolvedLink) return goTo(item.link.resolvedLink, { target: item.link.target ?? '_self' });
          if (item.fileID) return goTo(LASFile.getDownloadLink(item.fileID, {}), { target: '_blank' });
        }}
      ></las-icon-button>
    `;
  }

  private _renderText(item: DubniumBaseLinkListItem) {
    if ((this._componentSize.width ?? 0) > (this._consumedBreakpoints.value?.[Size.TABLET] ?? 0)) {
      console.log(item.isDigitalEmergency);

      return html`
        <d-flex flex horizontal crossaxis-center space="sm">
          <d-flex flex horizontal crossaxis-center>
            <d-text> ${this.__titleLink(item)} </d-text>
            <d-text>
              ${item.source
                ? html`<d-text ${css({ display: 'inline-block', textDecoration: 'none' })}
                    >,&nbsp;${item.source}
                  </d-text>`
                : nothing}
            </d-text>
          </d-flex>
          ${item.info ? html`<d-text grey> ${item.info} </d-text>` : nothing}
        </d-flex>
      `;
    }

    return html`
      <d-flex space="sm" flex>
        <d-text> ${this.__titleLink(item)} </d-text>
        ${item.info ? html`<d-text grey> ${item.info} </d-text>` : nothing}
      </d-flex>
    `;
  }

  private __titleLink(item: DubniumBaseLinkListItem) {
    return html`
      <d-link
        href=${item.link.resolvedLink
          ? item.link.resolvedLink
          : item.fileID
          ? LASFile.getDownloadLink(item.fileID, {})
          : ''}
        target=${item.link.target ?? '_self'}
        isdigitalemergency=${String(item.isDigitalEmergency)}
      >
        ${item.title}
      </d-link>
    `;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          width: '100%',
        },
      },
    ];
  }
}
