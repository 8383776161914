import type { AdornisTiptapExtension } from '@adornis/tiptap/client/extensions/ExtensionBase';
import { Mark, mergeAttributes } from '@tiptap/core';
import '../theme/d-icon';

const ICON_MARK_NAME = 'IconMark';
export const IconNodeExtension: AdornisTiptapExtension = {
  buttons: ({ editor }) => {
    const iconMarkAttributes = editor.getAttributes(ICON_MARK_NAME);
    const existingIcon = Object.entries(iconMarkAttributes).length;
    const result = [
      {
        name: 'toggleIconNode',
        icon: existingIcon ? 'star-shooting' : 'star',
        action: () => {
          // @ts-expect-error
          if (existingIcon) editor.chain().extendMarkRange(ICON_MARK_NAME).toggleIconNode().run();
          // @ts-expect-error
          else editor.chain().focus().toggleIconNode().run();
        },
      },
      ...(existingIcon
        ? [
            {
              name: 'toggleBold',
              icon: iconMarkAttributes['solid'] ? 'circle' : 'circle-dot',
              action: () => {
                editor
                  .chain()
                  .extendMarkRange(ICON_MARK_NAME, iconMarkAttributes)
                  .updateAttributes(ICON_MARK_NAME, { solid: iconMarkAttributes['solid'] ? undefined : 'true' })
                  .run();
              },
            },
          ]
        : []),
    ];
    return result;
  },
  extensions: [
    Mark.create({
      name: ICON_MARK_NAME,
      renderHTML({ HTMLAttributes }) {
        return ['d-icon', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
      },

      parseHTML: () => [
        {
          tag: 'd-icon',
        },
        {
          tag: 'x-icon',
        },
      ],
      addAttributes() {
        return {
          'im-a-icon': {
            default: null,
          },
          solid: {
            default: null,
          },
        };
      },
      // @ts-expect-error
      addCommands() {
        return {
          toggleIconNode: () => {
            return ({ commands }) => {
              return commands.toggleMark(ICON_MARK_NAME);
            };
          },
        };
      },
    }),
  ],
};
