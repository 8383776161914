import type { ContentExtension } from '@adornis/buildify/client/extensions/ContentExtension';
import { Droppable } from '@adornis/buildify/client/globals/enums';
import { ThrowEventDraggingChanged } from '@adornis/buildify/client/globals/events';
import { XBuildifyToolbar } from '@adornis/buildify/client/x-buildify-toolbar';
import { css } from '@adornis/chemistry/directives/css';
import type { XDragDrop } from '@adornis/dragdrop/x-drag-drop';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';

@customElement('d-buildify-bar')
export class DBuildifyBar extends XBuildifyToolbar {
  protected override __items() {
    return html`
      <x-drag-drop
        allow-from="x-flex.bar-item"
        child-selector="x-flex.bar-item"
        @dragging-changed=${e => ThrowEventDraggingChanged(this, (e.target as XDragDrop).dragging)}
      >
        <d-grid space="xs" auto-fill-columns="120px" ${css({ width: '100%' })}>
          <!-- clipboard -->
          ${this.__clipboard()}

          <!-- extensions -->
          ${repeat(this._filteredExtensions ?? [], extension => this.__item(extension as typeof ContentExtension))}

          <!-- custom content -->
          ${this.__customContent()}
        </d-grid>
      </x-drag-drop>
    `;
  }

  protected override __item(extension: typeof ContentExtension) {
    const settings = extension.BarSettings;

    return html`
      <x-flex
        class="bar-item"
        identifier=${this._consumedIdentifier.value}
        .type=${Droppable.EXTENSION}
        .value=${extension}
      >
        <d-icon> ${settings.icon} </d-icon>
        <x-text>
          ${settings.text.translationKey
            ? this._translation.translate(settings.text.translationKey)
            : settings.text.default}
        </x-text>
      </x-flex>
    `;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        '.bar-item': {
          userSelect: 'none',
          padding: this.spacing.sm,
          color: '#fff',
          background: 'rgb(63,63,63)',
          touchAction: 'unset',
          gap: this.spacing.sm,
          alignItems: 'center',
        },
      },
    ];
  }
}
