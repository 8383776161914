import type { Maybe, ValueEvent } from '@adornis/base/utilTypes';
import { ChemistryLitElement } from '@adornis/chemistry/chemistry-lit-element';
import { RXController } from '@adornis/chemistry/controllers/RXController';
import { css } from '@adornis/chemistry/directives/css.js';
import { XSnackbar } from '@adornis/chemistry/elements/components/x-snackbar';
import { FormController } from '@adornis/forms/x-form-controller';
import { TranslationController } from '@adornis/translation-core/client/translation-controller';
import { html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { firstValueFrom, timer } from 'rxjs';
import { getAllActiveProducts } from '../../../_api/product/queries/getAllActiveProducts';
import '../../../client/components/las-shimmer';
import { CurrentUserController, mobile } from '../../../client/globals';
import { triggerSuccessIcon } from '../../../client/theme/animations/success-animation';
import { DubniumDialog } from '../../../client/theme/components/d-dialog';
import '../../../client/theme/components/d-textarea';
import { Product } from '../../../db/Product';
import { Salutation } from '../../../db/enums';
import { TranslationRootItem, type LASTranslationDictionary } from '../../../db/translation';
import { DefaultComponentSizeController } from '../../client/helpers';
import { FormularSupport } from '../db/FormularSupport';
import { SupportTopic, SupportTopicWithProductNeed } from '../db/enums';
import './las-form-support-loading';

@customElement('las-form-support')
export class LASFormSupport extends ChemistryLitElement {
  @state() private readonly _currentUser = CurrentUserController(this);
  @state() private readonly _translation = new TranslationController<LASTranslationDictionary>(this);
  @state() private readonly _formController = new FormController<FormularSupport>(this, undefined, {
    success: data => {
      if (!data) return;
      return DubniumDialog.waitFor(Promise.all([FormularSupport.sendSupportEmail(data)(), firstValueFrom(timer(1500))]))
        .then(res => {
          void triggerSuccessIcon();
          this._setNewFormularSupportInstance();
          this._statusMessage = {
            message: 'Ihre Anfrage wurde erfolgreich versandt.',
            isError: false,
          };
        })
        .catch(err => {
          return XSnackbar.show(`Fehler: ${err.message}`);
        });
    },
    error: err => {
      console.log('im error', err);
      const errorJSON = err.toJSON();

      this._statusMessage = {
        message: `Bitte füllen Sie alle Pflichtfelder aus.`,
        hoverInformations:
          errorJSON._class === 'MultipleValidationError'
            ? errorJSON.extensions.validationErrors?.map(error =>
                this._translation.translate(
                  TranslationRootItem.SUPPORT_FORMULAR,
                  'fieldValidationMessage',
                  error.extensions.key,
                ),
              )
            : [
                this._translation.translate(
                  TranslationRootItem.SUPPORT_FORMULAR,
                  'fieldValidationMessage',
                  errorJSON.extensions.key,
                ),
              ],
        isError: true,
      };
    },
  });
  @state() private readonly _size = DefaultComponentSizeController(this);
  @state() private readonly _products = new RXController(
    this,
    Promise.all([getAllActiveProducts()(Product.allFields), firstValueFrom(timer(500))]).then(([products]) => products),
  );
  @state() private _statusMessage: { message?: string; hoverInformations?: string[]; isError?: boolean } = {};

  override connectedCallback(): void {
    super.connectedCallback();

    this._currentUser.observable.subscribe(user => this._setNewFormularSupportInstance());
  }

  override render() {
    if (this._currentUser.isLoading || !this._formController.document || this._products.isLoading) {
      return html` <las-form-support-loading></las-form-support-loading> `;
    }

    const isMobile = (this._size.width ?? 0) <= mobile;

    return html`
      <d-flex space="md" ${css({ position: 'relative' })}>
        <!-- topic -->
        ${this.__topic()}

        <!-- product -->
        ${this.__product()}

        <!-- contact info -->
        ${this.__contactInformation(isMobile)}

        <!-- message -->
        ${this.__message()}

        <!-- privacy -->
        ${this.__privacy()}

        <d-flex horizontal crossaxis-end space-between>
          <d-text
            bold
            ${css({
              color: this._statusMessage.isError ? this.colors.tone.darkGreen : this.colors.accent,
              userSelect: 'none',
            })}
          >
            ${this._statusMessage.message}
            ${(this._statusMessage.hoverInformations ?? []).length > 0
              ? html`
                  <d-icon secondary pointer>
                    circle-info
                    <d-tooltip>
                      <d-flex space="xs" padding="xs">
                        ${this._statusMessage.hoverInformations!.map(info => html` <d-text> ${info} </d-text> `)}
                      </d-flex>
                    </d-tooltip>
                  </d-icon>
                `
              : nothing}
          </d-text>
          <d-button @click=${() => this._formController.validate()}> ${this._translation.translate('send')} </d-button>
        </d-flex>
      </d-flex>
    `;
  }

  private __message() {
    return html`
      <!-- Message -->
      <d-textarea
        ${this._formController.field('message')}
        placeholder=${this._translation.translate('contact_form', 'message')}
        @value-changed=${(e: ValueEvent<Maybe<string>>) => {
          if (!this._formController.document || (!e.detail.value && e.detail.value !== '')) return;
          this._formController.document.message = e.detail.value;
          this.requestUpdate();
        }}
        rows="4"
        no-auto-resize
      ></d-textarea>
    `;
  }

  private __privacy() {
    if (this._currentUser.value) return nothing;

    return html`
      <!-- privacy -->
      <d-flex horizontal crossaxis-center space="xs">
        <d-checkbox ${this._formController.field('hasAcceptedPrivacy')}></d-checkbox>
        <d-text> ${this._translation.translate('accepts_data_privacy')} </d-text>
      </d-flex>
    `;
  }

  private __contactInformation(isMobile: boolean) {
    if (this._currentUser.value) return nothing;

    return html`
      <d-grid space="md" columns=${isMobile ? '1' : '2'}>
        <!-- Anrede -->
        <d-dropdown-selection
          placeholder=${this._translation.translate('salutation')}
          clearable
          .selectables=${Object.values(Salutation)}
          .renderString=${val => this._translation.translate(val)}
          ${this._formController.field('salutation')}
        >
        </d-dropdown-selection>

        ${isMobile ? nothing : html` <div></div> `}

        <!-- Vorname -->
        <d-input
          placeholder=${this._translation.translate('first_name')}
          placeholder-mode="static-floating"
          ${this._formController.field('firstName')}
        ></d-input>
        <!-- Nachname -->
        <d-input
          placeholder=${this._translation.translate('last_name')}
          ${this._formController.field('lastName')}
        ></d-input>
        <!-- Email -->
        <d-input
          type="email"
          placeholder=${this._translation.translate('e_mail')}
          ${this._formController.field('email')}
          @value-picked=${() => this.requestUpdate()}
        ></d-input>
        <!-- Email Wiederholung -->
        <d-input
          placeholder=${this._translation.translate('e_mail_repeat')}
          type="email"
          onpaste="return false"
          ondrop="return false"
          ${this._formController.field('emailRepeat')}
          @value-picked=${() => this.requestUpdate()}
        ></d-input>
      </d-grid>
    `;
  }

  private __topic() {
    const topics = Object.keys(SupportTopic);

    return html`
      <d-dropdown-selection
        clearable
        placeholder=${'Thema'}
        .selectables=${topics}
        .renderString=${topicKey =>
          this._translation.translate(TranslationRootItem.SUPPORT_FORMULAR, 'topic', topicKey)}
        ${this._formController.field('topic')}
        @value-picked=${() => this.requestUpdate()}
      ></d-dropdown-selection>
    `;
  }

  private __product() {
    const doc = this._formController.document;
    const products = this._products.value;
    if (!doc || !products || products.length === 0) return nothing;
    if (!SupportTopicWithProductNeed.includes(doc.topic)) return nothing;

    return html`
      <d-dropdown-selection
        clearable
        placeholder=${doc.topic === SupportTopic.INTEREST_IN_PRODUCT
          ? 'Um welches Produkt handelt es sich?'
          : 'Hat dieses Problem mit einem dieser Produkte zu tun?'}
        .selectables=${products.map(p => p.id)}
        .renderString=${id => products.find(p => p.id === id)!.name}
        ${this._formController.field('productID')}
      ></d-dropdown-selection>
    `;
  }

  private _setNewFormularSupportInstance() {
    const user = this._currentUser.value;
    if (this._currentUser.isLoading) return;

    let instance = new FormularSupport({});
    if (user?.contact) {
      instance = new FormularSupport({
        salutation: user.contact.salutation,
        firstName: user.contact.firstName,
        lastName: user.contact.lastName,
        email: user.contact.email,
        emailRepeat: user.contact.email,
        hasAcceptedPrivacy: true,
      });
    }

    this._formController.document = instance;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          display: 'block',
        },
      },
    ];
  }
}
