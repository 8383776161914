import { mergeAttributes } from '@tiptap/core';
import { Bold as ExtensionBold } from '@tiptap/extension-bold';
import { Color as ExtensionColor } from '@tiptap/extension-color';
import { Document as ExtensionDocument } from '@tiptap/extension-document';
import { Dropcursor as ExtensionDropcursor } from '@tiptap/extension-dropcursor';
import { HardBreak as ExtensionHardBreak } from '@tiptap/extension-hard-break';
import { Heading as ExtensionHeading } from '@tiptap/extension-heading';
import { Highlight as ExtensionHighlight } from '@tiptap/extension-highlight';
import { History as ExtensionHistory } from '@tiptap/extension-history';
import { Link as ExtensionLink } from '@tiptap/extension-link';
import { Paragraph as ExtensionParagraph } from '@tiptap/extension-paragraph';
import { Superscript as ExtensionSuperscript } from '@tiptap/extension-superscript';
import { Text as ExtensionText } from '@tiptap/extension-text';
import type { ParseRule } from 'prosemirror-model';
import '../theme/components/d-sup';
import { InsertHTMLExtension } from './InsertHTMLExtension';

const CustomSuperscript = ExtensionSuperscript.extend({
  renderHTML({ HTMLAttributes }) {
    return ['d-sup', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
  addAttributes() {
    return {};
  },
});

const CustomLink = ExtensionLink.extend({
  renderHTML({ HTMLAttributes }) {
    return ['d-link', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
  addAttributes() {
    return {
      href: {
        default: null,
      },
      target: {
        default: this.options.HTMLAttributes.target,
      },
      isDigitalEmergency: {
        default: false,
      },
    };
  },
});

const CustomHighlight = ExtensionHighlight.extend({
  addOptions() {
    return {
      multicolor: true,
      HTMLAttributes: {},
    };
  },

  parseHTML() {
    return [
      {
        tag: 'd-highlight',
      },
      {
        tag: 'span',
        getAttrs: node => {
          const nodeElement = node as HTMLElement;
          if (['rgb(29, 29, 27)', '#333', 'rgb(0, 0, 0)', '#000'].includes(nodeElement.style.color)) return false;
          return (node as HTMLElement).style.color !== null && (node as HTMLElement).innerText !== ' ' && null;
        },
      },
    ];
  },

  renderHTML({ mark, HTMLAttributes }) {
    return [
      'd-highlight',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, { style: `color: ${mark.attrs.color}` }),
      0,
    ];
  },

  addAttributes() {
    if (!this.options.multicolor) {
      return {};
    }

    return {
      color: {
        default: null,
        parseHTML: element => element.getAttribute('data-color') || element.style.backgroundColor,
        renderHTML: attributes => {
          if (!attributes.color) {
            return {};
          }

          return {
            'data-color': attributes.color.string,
            style: `color: ${attributes.color.string}`,
          };
        },
      },
    };
  },
});

const CustomParagraph = ExtensionParagraph.extend({
  renderHTML({ HTMLAttributes }) {
    return ['d-text', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
  addAttributes() {
    return {
      paragraph: {
        default: '',
      },
    };
  },
});

const CustomBold = ExtensionBold.extend({
  renderHTML({ HTMLAttributes }) {
    return ['d-bold', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
  parseHTML() {
    return [
      {
        tag: 'd-bold',
        getAttrs: node => {
          const nodeElement = node as HTMLElement;
          if (['rgb(29, 29, 27)', '#333', 'rgb(0, 0, 0)', '#000'].includes(nodeElement.style.color)) return false;
          return (node as HTMLElement).style.color !== null && (node as HTMLElement).innerText !== ' ' && null;
        },
      },
      {
        tag: 'strong',
      },
      {
        tag: 'b',
        getAttrs: node => (node as HTMLElement).style.fontWeight !== 'normal' && null,
      },
      {
        style: 'font-weight',
        getAttrs: value => /^(bold(er)?|[5-9]\d{2,})$/.test(value as string) && null,
      },
    ];
  },

  addAttributes() {
    return {
      style: {
        default: `display: contents`,
      },
    };
  },
});

const CustomHeading = ExtensionHeading.configure({ levels: [2, 3, 4] }).extend({
  renderHTML({ node, HTMLAttributes }) {
    const hasLevel = this.options.levels.includes(node.attrs.level);
    const level = hasLevel ? node.attrs.level : 2;
    return [`d-h${level}`, {}, 0];
  },

  parseHTML() {
    const cases: ParseRule[] = [];

    for (const level of this.options.levels) {
      cases.push({ tag: `h${level}`, attrs: { level } });
      cases.push({ tag: `d-h${level}`, attrs: { level } });
    }

    return cases;
  },
});

export enum Extension {
  DOCUMENT = 'document',
  TEXT = 'text',
  HARD_BREAK = 'hard-break',
  HISTORY = 'history',
  DROP_CURSOR = 'drop-cursor',
  COLOR = 'color',
  PARAGRAPH = 'paragraph',
  BOLD = 'bold',
  HEADING = 'heading',
  HIGHLIGHT = 'highlight',
  LINK = 'link',
  CUSTOM_INSERT_HTML = 'custom-insert-html',
  SUPERSCRIPT = 'superscript',
}

export const defaultExtensionMap = new Map<string, any>([
  [Extension.DOCUMENT, ExtensionDocument],
  [Extension.TEXT, ExtensionText],
  [Extension.HARD_BREAK, ExtensionHardBreak],
  [Extension.HISTORY, ExtensionHistory],
  [Extension.DROP_CURSOR, ExtensionDropcursor],
  [Extension.COLOR, ExtensionColor],
  [Extension.PARAGRAPH, CustomParagraph],
  [Extension.BOLD, CustomBold],
  [Extension.HEADING, CustomHeading],
  [Extension.HIGHLIGHT, CustomHighlight],
  [Extension.LINK, CustomLink],
  [Extension.SUPERSCRIPT, CustomSuperscript],
  [Extension.CUSTOM_INSERT_HTML, InsertHTMLExtension],
]);

export const defaultExtensions = Array.from(defaultExtensionMap.values());
