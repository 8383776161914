import { GeschaeftsvorfallBuchung } from '@adornis/accounting/api/geschaeftsvorfall-buchung';
import { SKR04 } from '@adornis/accounting/api/kontenrahmen';
import type { Maybe } from '@adornis/base/utilTypes';
import { Arg, Entity, Field, Mutation, Query } from '@adornis/baseql/decorators';
import { DataLoadedField } from '@adornis/baseql/decorators/data-loaded-field';
import type { PartialEntityData } from '@adornis/baseql/entities/adornisEntity';
import { getRawCollection } from '@adornis/baseql/server/collections';
import { context } from '@adornis/baseql/server/context';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { LASUser } from '@adornis/digitale-helden-shared/db/las-user';
import { AccountingPermission } from '@adornis/digitale-helden-shared/db/permissions';
import { CurrentUserInfo } from '@adornis/users/db/currentUserInfo';
import { AdornisRoleToContextsWrapper } from '@adornis/users/db/roleToContextsWrapper';
import { validate } from '@adornis/validation/decorators';
import { nonOptional } from '@adornis/validation/functions/nonOptional';
import { DATEV_ACCOUNTS, LASAccountingTransaction } from './LASAccountingTransaction';

// zahlung
@Entity()
export class LASAccountingDeposit extends GeschaeftsvorfallBuchung<typeof SKR04> {
  static override _class = 'LASAccountingDeposit';

  constructor(doc: PartialEntityData<LASAccountingDeposit>) {
    super(doc);
  }

  @validate(nonOptional())
  @Field(type => String)
  accountingTransactionID: Maybe<string>;

  @DataLoadedField(type => LASAccountingTransaction, deposit => deposit.accountingTransactionID)
  public accountingTransaction: Maybe<LASAccountingTransaction>;

  @Field(type => String, {
    resolve(this: LASAccountingDeposit) {
      return async () => {
        if (this.accountingTransaction) return this.accountingTransaction.buchungsText;
        return '';
      };
    },
  })
  override buchungsText!: string;

  @Query(type => [LASAccountingDeposit])
  static getAllDepositsByTransactions(@Arg('transactionIDs', type => [String]) transactionIDs: string[]) {
    return async (gqlFields: BaseQLSelectionSet<LASAccountingDeposit>) => {
      const rawDepositCollection = await getRawCollection<LASAccountingDeposit>(LASAccountingDeposit._collectionName);
      const deposits = await rawDepositCollection
        .find<LASAccountingDeposit>({ accountingTransactionID: { $in: transactionIDs } })
        .toArray();
      return deposits;
    };
  }

  @Mutation(type => String)
  static createDepositForTransaction(@Arg('transactionID', type => String) transactionID: string) {
    return async () => {
      if (!context.serverContext) {
        const user = await CurrentUserInfo.getMyself<LASUser>()({
          _id: 1,
          roles: AdornisRoleToContextsWrapper.allFields,
        });
        if (!user) throw new Error('no user on context found');
        if (!user.hasPermission(AccountingPermission.EDIT))
          throw new Error('no permission to edit accounting entities');
      }

      const selectionSet = LASAccountingTransaction.allFields;
      delete selectionSet['invoice'];
      delete selectionSet['settledAmount'];
      delete selectionSet['buchungsText'];
      delete selectionSet['order'];

      const transaction = await LASAccountingTransaction.getByID<LASAccountingTransaction>(transactionID)(selectionSet);
      if (!transaction) throw new Error('transaction for given id not found');

      const deposit = LASAccountingDeposit.bucheZahlung<LASAccountingDeposit>(
        transaction as unknown as LASAccountingDeposit,
        {
          betrag: transaction.betrag,
          kostenstellen: transaction.kostenstellen,
          committed: false,
          accountingTransactionID: transaction._id,
        },
      );

      // @ts-expect-error We use the GELDTRANSIT account for the deposit
      deposit.sollKonto = DATEV_ACCOUNTS.GELDTRANSIT;

      await deposit.create();
    };
  }
}
