/* eslint-disable max-statements */
import { ID, Int } from '@adornis/baseql/baseqlTypes';
import { Arg, Entity, Field, Subscription } from '@adornis/baseql/decorators';
import { getCollection, getCollectionHandle } from '@adornis/baseql/server/collections';
import { publishFilter } from '@adornis/baseql/server/context';
import { type BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { Contact } from '@adornis/digitale-helden-shared/db/Contact.js';
import { UserRoles } from '@adornis/digitale-helden-shared/db/permissions';
import { AdornisFilter } from '@adornis/filter/AdornisFilter';
import { AdornisUser } from '@adornis/users/db/a-user';
import { from, switchMap } from 'rxjs';
import { getContactByID } from '../../campus/src/_api/contact/queries/getContactByID';
import { ensureDigitaleHeldenRoles } from '../../campus/src/server/ensure/ensureDigitaleHeldenRoles';

/**
 * @entity LASUser
 */
@Entity()
export class LASUser extends AdornisUser<any> {
  static override _class = 'LASUser';

  // de facto the zoho contact ID
  @Field(type => ID)
  zohoID!: string;

  @Field(type => Contact, {
    resolve(this: LASUser) {
      return async (gqlFields: BaseQLSelectionSet<Contact>) => {
        if (!this.zohoID) return null;
        try {
          const contact = await getContactByID(this.zohoID)(gqlFields);
          return contact;
        } catch {
          return null;
        }
      };
    },
  })
  contact?: Contact;

  hasRole(role: UserRoles) {
    return !!this.roles.find(r => r.name === role);
  }

  serializeZoho = () => {
    const info = JSON.stringify({
      data: [
        {
          Email: this.email,
          Last_Name: this.username,
          Double_Opt_In: this.emailConfirmed,
        },
      ],
    });
    return info;
  };

  @Subscription(type => [LASUser])
  static subscribeLASUsers(
    @Arg('skip', type => Int) skip: number,
    @Arg('limit', type => Int) limit: number,
    @Arg('filter', type => AdornisFilter) filter: AdornisFilter,
  ) {
    return (gqlFields: BaseQLSelectionSet<LASUser>) => {
      const filterObject = filter.toObject();
      if (filterObject.search) filterObject.search = filterObject.search.replace(/([()[{*+.$^\\|?])/g, '\\$1');

      const query = filterObject.search
        ? {
            $or: [
              { username: { $regex: filterObject.search, $options: 'i' } },
              { email: { $regex: filterObject.search, $options: 'i' } },
            ],
          }
        : {};

      const sorting = {};

      return getCollectionHandle<LASUser>(LASUser._collectionName)
        .watchQuery({})
        .pipe(
          switchMap(async e => {
            const pubFilter = await publishFilter(this._class);
            const filtering = pubFilter ? { $and: [pubFilter, query] } : query;

            const collection = await getCollection<LASUser>(LASUser._class);
            const result = await collection
              .find(filtering, {
                sort: sorting,
                skip,
                limit,
              })
              .toArray();
            return result;
          }),
        );
    };
  }

  @Subscription(type => [LASUser])
  static subscribeCurrentLASUsers(@Arg('userIDs', type => [ID]) userIDs: string[]) {
    return (gqlFields: BaseQLSelectionSet<LASUser>) => {
      return from(Promise.all(userIDs.map(userID => ensureDigitaleHeldenRoles(userID)))).pipe(
        switchMap(() => getCollectionHandle<LASUser>(this._collectionName).watchQuery({ _id: { $in: userIDs } })),
      );
    };
  }
}
