import type { Styles } from '@adornis/ass/style';
import { Size } from '@adornis/buildify/client/globals/enums';
import { customElement } from 'lit/decorators.js';
import { CampusContainerExtension } from '../campus-container-extension';

@customElement('campus-container-flex-extension')
export class CampusContainerFlexExtension extends CampusContainerExtension {
  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          display: 'flex',
          flexDirection: this.defaultValue('flexDirection', { global: 'column' }),
          justifyContent: this.getValue('justifyContent'),
          alignItems: this.getValue('alignItems'),
          rowGap: this.defaultValue('rowGap', { global: '20px', [Size.MOBILE]: '16px' }),
          columnGap: this.defaultValue('columnGap', { global: '24px', [Size.MOBILE]: '20px' }),
        },
      },
    ] as Styles[];
  }
}
