import type { Maybe, ValueEvent } from '@adornis/base/utilTypes';
import { ChemistryLitElement } from '@adornis/chemistry/chemistry-lit-element';
import { RXController } from '@adornis/chemistry/controllers/RXController';
import { goTo } from '@adornis/router/client/open-href';
import { permissionGuard } from '@adornis/user-forms/permission-guard';
import { html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { filter, from, map } from 'rxjs';
import { CampusBuildifyRoute, PathToCampusBuildifyRoute } from '../../../_routing/db/enums';
import '../../../client/components/las-live-animation';
import { DubniumDialog } from '../../../client/theme/components/d-dialog';
import { DubniumPage } from '../../db/DubniumPage';
import { DubniumPageDraft } from '../../db/DubniumPageDraft';
import { DubniumPagePublished } from '../../db/DubniumPagePublished';

@customElement('las-page-explorer')
export class LASPageExplorer extends ChemistryLitElement {
  @state() private readonly _allPages = new RXController(
    this,
    from(DubniumPage.subscribeAll<DubniumPage>()({ name: 1, parentPageID: 1, productIDs: 1, path: 1, version: 1 })),
  );

  @state() private readonly _pageArrays = new RXController(
    this,
    this._allPages.observable.pipe(
      filter(Boolean),
      map(pages => {
        const map = new Set<Array<DubniumPage>>();

        const addEntriesToReference = (array: Array<DubniumPage>, _id: string) => {
          for (const page of pages) {
            if (page.parentPageID !== _id) continue;

            array.push(page);
            addEntriesToReference(array, page._id);
          }
        };

        // 1. alle auf root ebene
        for (const page of pages) {
          if (page.parentPageID) continue;
          const entry = [page];
          map.add(entry);
          addEntriesToReference(entry, page._id);
        }

        // ab hier, sollten alle bereits sortiert sein
        const arrays = Array.from(map.values());

        console.log(arrays.map(array => array.map(p => p.productIDs)));

        return arrays;
      }),
    ),
  );

  @state() private readonly _productArrays = new RXController(
    this,
    this._pageArrays.observable.pipe(
      filter(Boolean),
      map(arrays => {
        const result: Array<Array<DubniumPage>> = [];
        for (const array of arrays) {
          if (
            array.every(page => {
              if (page.parentPageID) return true;
              if (page.productIDs.length > 0) return true;
              return false;
            })
          )
            result.push(array);
        }
        return result;
      }),
    ),
  );

  @state() private readonly _standaloneArrays = new RXController(
    this,
    this._pageArrays.observable.pipe(
      filter(Boolean),
      map(arrays => {
        const result: Array<Array<DubniumPage>> = [];
        for (const array of arrays) {
          if (
            array.every(page => {
              if (page.parentPageID) return true;
              if (!page.productIDs) return true;
              if (page.productIDs.length === 0) return true;
              return false;
            })
          )
            result.push(array);
        }
        return result;
      }),
    ),
  );

  @property({ attribute: 'selectable', type: Boolean }) selectable = false;
  @property({ type: String }) selectedPageID: Maybe<string>;

  @state() private readonly _ownDrafts = new RXController(
    this,
    DubniumPageDraft.subscribeMyDrafts()({ createdAt: 1, draftName: 1, pageID: 1 }),
  );

  @state() private readonly _publishments = new RXController(
    this,
    DubniumPagePublished.subscribeAll<DubniumPagePublished>()(DubniumPagePublished.allFields),
  );

  override render(): any {
    return permissionGuard(
      'Designer.View',
      () => html`
        <d-flex space="xl">
          <!-- products -->
          ${this._renderProductPages()}

          <!-- standalone -->
          ${this._renderStandalonePages()}
        </d-flex>
      `,
      {
        forbiddenTemplate: 'Sie haben hierfür keine Berechtigung',
      },
    );
  }

  private _renderStandalonePages() {
    const data = this._standaloneArrays.value ?? [];
    return html`
      <d-flex space="sm">
        <d-text tiny> Sonstige Seiten </d-text>
        ${data.length === 0
          ? html` <d-text> Keine sonstigen Seiten angelegt </d-text> `
          : data.map(pageArray => this._renderNode(pageArray))}
      </d-flex>
    `;
  }

  private _renderProductPages() {
    const data = this._productArrays.value ?? [];
    return html`
      <d-flex space="sm">
        <d-text tiny> Produktseiten </d-text>
        ${data.length === 0
          ? html` <d-text> Keine Produktseiten angelegt </d-text> `
          : data.map(pageArray => this._renderNode(pageArray))}
      </d-flex>
    `;
  }

  private _renderNode(pageArray: Array<DubniumPage>) {
    const buildTree = (nodes: Array<DubniumPage>) => {
      const nodeMap = {};

      // Erstelle eine Zuordnung von ID zu Node für einfachen Zugriff
      nodes.forEach(nodeData => {
        nodeData['children'] = [];
        nodeMap[nodeData._id] = nodeData;
      });

      // Baue die Baumstruktur, indem du jedem Node seine Kinder zuweist
      nodes.forEach(nodeData => {
        const node = nodeMap[nodeData._id];

        if (nodeData.parentPageID) {
          const parent = nodeMap[nodeData.parentPageID];
          if (parent) {
            parent.children.push(node);
          } else {
            console.error(`Parent node with ID ${nodeData.parentPageID} not found for node ${nodeData._id}`);
          }
        }
      });

      return nodeMap;
    };

    buildTree(pageArray);

    const root = pageArray.filter(e => !e.parentPageID).sort((a, b) => a.name.localeCompare(b.name));

    return html`
      ${repeat(
        root,
        root => html`
          <las-buildify-page-node
            .node=${root}
            .drafts=${this._ownDrafts.value}
            .publishments=${this._publishments.value}
            .selectedPageID=${this.selectedPageID}
            ?selectable=${this.selectable}
            @page-changed=${(e: ValueEvent<Maybe<string>>) => {
              this.selectedPageID = e.detail.value;
            }}
            @edit-page=${(e: ValueEvent<string>) =>
              goTo(`${PathToCampusBuildifyRoute(CampusBuildifyRoute.PAGES)}/${e.detail.value}`)}
            @delete-page=${async (e: ValueEvent<string>) => {
              if (!(await DubniumDialog.confirm('Wollen Sie diese Seite wirklich löschen?'))) return;
              await DubniumPage.deletePageByID(e.detail.value)();
            }}
          ></las-buildify-page-node>
        `,
      )}
    `;
  }
}
