import { context } from '@adornis/baseql/server/context';
/* eslint-disable complexity */
import type { Maybe } from '@adornis/base/utilTypes';
import { Int } from '@adornis/baseql/baseqlTypes';
import { Arg, Entity, Field, Mutation, Query } from '@adornis/baseql/decorators';
import { type BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { DateTime } from 'luxon';
import { getAllGroupContactRelationsByContactIDCOQL } from '../_api/group-contact-relations/queries/getAllGroupContactRelationsByContactIDCOQL';
import {
  getZohoRecordByID,
  insertZohoRecord,
  makeZohoAPIRequest,
  makeZohoCOQLRequest,
  upsertZohoRecord,
} from '../server/zoho/api';
import { type ZohoRecord } from '../server/zoho/types';
import { BiMap } from './BiMap';
import { GroupContactRelation } from './Relations/GroupContactRelation';
import { ZohoModule } from './enumns/zoho';
import { checkRole } from './helpers';
import { UserRoles } from './las-user';
import { ZohoEntity } from './zoho-entity';

export enum GROUP_ZOHO_FIELDS {
  ID = 'id',
  CURRENT_PARTICIPANTS = 'Current_Participants',
  END_PARTICIPANTS = 'End_Participants',
  HIGHEST_PARTICIPANTS = 'Highest_Participants',
  START_PARTICIPANTS = 'Start_Participants',
  NAME = 'Name',
  FIRST_PARTICIPANT_AT = 'First_Participant_At',
}

export const GROUP_BIMAP = new BiMap<string, string>([
  ['id', GROUP_ZOHO_FIELDS.ID],
  ['name', GROUP_ZOHO_FIELDS.NAME],
  ['beginningParticipants', GROUP_ZOHO_FIELDS.START_PARTICIPANTS],
  ['endParticipants', GROUP_ZOHO_FIELDS.END_PARTICIPANTS],
  ['currentParticipants', GROUP_ZOHO_FIELDS.CURRENT_PARTICIPANTS],
  ['highestParticipants', GROUP_ZOHO_FIELDS.HIGHEST_PARTICIPANTS],
  ['firstParticipantAt', GROUP_ZOHO_FIELDS.FIRST_PARTICIPANT_AT],
]);

@Entity()
export class Group extends ZohoEntity {
  static override _class = 'Group';
  static override ZOHO_MODULE = ZohoModule.GROUPS;
  static override ZOHO_FIELDS = Array.from(GROUP_BIMAP.values).join(',');

  @Field(type => String) id!: string;
  @Field(type => String) name!: string;
  @Field(type => Int) beginningParticipants!: number;
  @Field(type => Int) endParticipants!: number;
  @Field(type => Int) currentParticipants!: number;
  @Field(type => Int) highestParticipants!: number;
  @Field(type => Date) firstParticipantAt: Maybe<Date>;

  static get MAX_ADMINS() {
    return 3;
  }

  static get MAX_PARTICIPANTS() {
    return 30;
  }

  get toFilteredJSON() {
    const fields = {};
    const keys = Array.from(GROUP_BIMAP.keys);
    keys.forEach(key => {
      if (key && this.toJSON()[key]) {
        fields[key] = this.toJSON()[key];
      }
    });
    return fields;
  }

  override serializeZoho = (isNew: boolean = false) => {
    const fields = {};
    const keys = Array.from(GROUP_BIMAP.keys);
    keys.forEach(key => {
      const keyZoho = GROUP_BIMAP.get(key);
      if (keyZoho && (this[key] || typeof this[key] === 'boolean')) {
        if (this[key] instanceof Date) {
          fields[keyZoho] = DateTime.fromJSDate(this[key]).toFormat('yyyy-MM-dd');
        } else {
          fields[keyZoho] = this[key];
        }
      }
    });
    const data: ZohoRecord<any> = {
      data: [fields],
      trigger: ['workflow'],
    };
    return JSON.stringify(data).replace(/"id":[ ]?"([0-9]+)"/g, '"id":$1');
  };

  static override deserializeZoho = (rawData: any) => {
    const fields = {};
    const keys = Array.from(GROUP_BIMAP.reverseKeys);
    keys.forEach(key => {
      const keyLAS = GROUP_BIMAP.reverseGet(key);
      if (keyLAS) {
        fields[keyLAS] = rawData[key] ?? null;
      }
    });

    return new Group({
      ...fields,
    });
  };

  @Query(type => String)
  static deleteGroupByID(@Arg('id', type => String) id: string) {
    return async () => {
      await checkRole({ context, role: UserRoles.SUPER_ADMIN });
      const endpoint = `${this.ZOHO_MODULE}/${id}`;
      await makeZohoAPIRequest({ method: 'delete', endpoint, zohoModule: this.ZOHO_MODULE });
    };
  }

  @Mutation(type => String)
  static removeGroupByIDSafe(@Arg('id', type => String) id: string) {
    return async () => {
      await checkRole({ context, role: UserRoles.SUPER_ADMIN });
      if (!id) return null;

      const group = await this.getGroupById(id)(Group.allFields);
      if (!group) return;

      const groupContactRelations = await GroupContactRelation.getGroupContactRelationsByGroupID(id)({ id: 1 });
      const relationIDs = groupContactRelations.map(relation => relation.id);
      await GroupContactRelation.deleteGroupContactRelationsByIDs(relationIDs)();

      await this.deleteGroupByID(id)();
    };
  }

  @Query(type => Group)
  static getGroupByIDCOQL(@Arg('id', type => String) id: string) {
    return async (gqlFields: BaseQLSelectionSet<Group>) => {
      if (!id) return null;

      const filter = `${GROUP_ZOHO_FIELDS.ID} = '${id}'`;
      const result = await makeZohoCOQLRequest({
        filter,
        gqlFields,
        moduleBiMap: GROUP_BIMAP,
        moduleName: this.ZOHO_MODULE,
      });

      if (!result?.data[0]) return null;
      const data = result.data[0];
      const deserialized = this.deserializeZoho(data);
      return deserialized;
    };
  }

  @Mutation(type => Group)
  public static upsertGroup(@Arg('entity', type => Group) entity: Group) {
    return async (gqlFields: BaseQLSelectionSet<Group>) => {
      const id = await upsertZohoRecord(this.ZOHO_MODULE, new Group({ ...entity.toFilteredJSON }));
      entity.id = id;
      return entity;
    };
  }

  @Query(type => Group)
  public static getGroupById(@Arg('zohoID', type => String) zohoID: string) {
    return async (gqlFields: BaseQLSelectionSet<Group>) => {
      const groupJson = await getZohoRecordByID(this.ZOHO_MODULE, zohoID);
      const group = this.deserializeZoho(groupJson) as Maybe<Group>;
      if (!group) return null;
      return group;
    };
  }

  @Mutation(type => Group)
  public static insertGroup(@Arg('group', type => Group) group: Group) {
    return async (gqlFields: BaseQLSelectionSet<Group>) => {
      const groupId = await insertZohoRecord(this.ZOHO_MODULE, group);
      group.id = groupId;
      return group;
    };
  }

  @Query(type => [Group])
  public static getGroupByIDs(@Arg('zohoIDs', type => [String]) zohoIDs: string[]) {
    return async (gqlFields: BaseQLSelectionSet<Group>) => {
      zohoIDs = zohoIDs.filter(id => !!id);

      if (!zohoIDs || zohoIDs.length === 0) return [];
      if (zohoIDs.length === 1 && zohoIDs[0]) {
        const group = await this.getGroupById(zohoIDs[0])(gqlFields);
        if (!group) return [];
        return [group];
      }

      const filter = `${GROUP_BIMAP.get('id')} in (${zohoIDs.join(',')})`;
      const result = await makeZohoCOQLRequest({
        filter,
        gqlFields,
        moduleBiMap: GROUP_BIMAP,
        moduleName: this.ZOHO_MODULE,
      });

      const groups = result.data?.map(groupZohoJson => this.deserializeZoho(groupZohoJson));
      return groups;
    };
  }

  @Query(type => [Group])
  static getAllGroupsForUser(@Arg('contactID', type => String) contactID: string) {
    return async (gqlFields: BaseQLSelectionSet<Group>) => {
      const allRelations = await getAllGroupContactRelationsByContactIDCOQL(contactID)({
        groupId: 1,
      });
      const allGroupIds = allRelations.map(relation => relation.groupId);
      const allGroups = await this.getGroupByIDs(allGroupIds)(gqlFields);
      return allGroups;
    };
  }
}
