import type { Maybe } from '@adornis/base/utilTypes';
import { BaseText } from '@adornis/buildify/db/extensions/text/BaseText';
import { RXController } from '@adornis/chemistry/controllers/RXController';
import { css } from '@adornis/chemistry/directives/css.js';
import '@adornis/chemistry/elements/components/x-flex';
import { html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import '../../../client/theme/components/d-button';
import '../../../client/theme/components/d-checkbox';
import { DubniumDialog } from '../../../client/theme/components/d-dialog';
import '../../../client/theme/components/d-dropdown-selection';
import { DTiptapBuildify } from '../../../client/tiptap-editor/d-tiptap-buildify';
import { extensionsForContentType } from '../../../client/tiptap-editor/visual-text-extensions';
import type { DubniumBaseLinkListItem } from '../../db/extensions/DubniumBaseLinkList';

type Items = BaseText[] | DubniumBaseLinkListItem[];
/**
 * @element las-prompt-swap-index-list
 */
@customElement('las-prompt-swap-index-list')
export class LASPromptSwapIndexList extends DubniumDialog<Maybe<BaseText[] | DubniumBaseLinkListItem[]>> {
  static override get element_name(): string {
    return 'las-prompt-swap-index-list';
  }

  @property({ attribute: false }) items = new RXController<Maybe<BaseText[] | DubniumBaseLinkListItem[]>>(
    this,
    undefined,
  );

  public static async swapIndexes(
    items: BaseText[] | DubniumBaseLinkListItem[],
  ): Promise<Maybe<BaseText[] | DubniumBaseLinkListItem[]>> {
    return await this.showPopup({ props: { items } });
  }

  override content(): any {
    if (!this.items.value) return nothing;

    const items = this.items.value;

    return html`
      <d-flex space="lg">
        <d-h3 bold ${css({ textAlign: 'center' })}> Reihenfolge verändern </d-h3>

        <x-drag-drop
          drag-mode="column"
          allow-from="d-flex[item]"
          child-selector="d-flex[item]"
          @resorted=${e => {
            const newOrder: Items = [];
            for (const key of e.detail.order) {
              const item = items.find(item => item._id === key);
              if (!item) continue;
              newOrder.push(item);
            }

            this.items.next(newOrder);
            this.requestUpdate();
          }}
        >
          <d-flex space="sm">
            ${repeat(
              items,
              item => item._id,
              item => html`
                <d-flex key=${item._id} item horizontal crossaxis-center space="md">
                  <las-icon-button icon="bars"></las-icon-button>
                  ${item instanceof BaseText ? this._parseToText(item) : html` <d-text> ${item.title} </d-text> `}
                </d-flex>
              `,
            )}
          </d-flex>
        </x-drag-drop>

        <d-flex horizontal space="md" space-between>
          <d-button
            @click=${async () => {
              this.close(this.items.value);
            }}
          >
            Fertig
          </d-button>
        </d-flex>
      </d-flex>
    `;
  }

  private _parseToText(text: BaseText) {
    if (!text?.text) return html` <d-text> Kein Inhalt </d-text> `;

    const parsedContent = unsafeHTML(DTiptapBuildify.stringToHTMLConverter(text.text, extensionsForContentType(text)));

    return html` <d-text> ${parsedContent} </d-text> `;
  }
}
