import { registerQuery } from '@adornis/baseql/metadata/register';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import {
  CONTACT_PRODUCT_RELATION_BIMAP,
  CONTACT_PRODUCT_RELATION_ZOHO_FIELDS,
  ContactProductRelation,
} from '../../../db/Relations/ContactProductRelation';
import { makeZohoAPIRequest } from '../../../server/zoho/api';

function getAllContactProductRelationsByProductIDCOQLResolver(productID: string) {
  return async (gqlFields: BaseQLSelectionSet<ContactProductRelation>) => {
    const fields = ContactProductRelation.gqlFieldsZoho(gqlFields, CONTACT_PRODUCT_RELATION_BIMAP).join(',');
    const query = `SELECT ${fields} FROM ${ContactProductRelation.ZOHO_MODULE} WHERE ${CONTACT_PRODUCT_RELATION_ZOHO_FIELDS.PRODUCT} = '${productID}'`;

    const result = await makeZohoAPIRequest({
      method: 'post',
      endpoint: 'coql',
      data: { select_query: query },
      zohoModule: ContactProductRelation.ZOHO_MODULE,
      isRawRequest: true,
    });

    if (!result?.data?.[0]) return [];
    const deserializedRelation = result.data.map(data =>
      ContactProductRelation.deserializeZoho(data),
    ) as ContactProductRelation[];
    return deserializedRelation;
  };
}

/** Query */
export const getAllContactProductRelationsByProductIDCOQL = registerQuery({
  type: () => [ContactProductRelation],
  operationName: 'getAllContactProductRelationsByProductIDCOQL',
  resolve: getAllContactProductRelationsByProductIDCOQLResolver,
  params: [{ type: () => String, name: 'productID' }],
});
