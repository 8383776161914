import { registerQuery } from '@adornis/baseql/metadata/register';
import { Product } from '@adornis/digitale-helden-shared/db/product/Product';
import { getProductByID } from '@adornis/digitale-helden-shared/db/product/queries/getProductByID';
import { Order } from '../../../db/Order';

//* Resolver
const getURLLasLinkResolver = (productID: string) => {
  return async () => {
    const product = await getProductByID(productID)(Product.allFields);
    if (!product) throw new Error('Product not found');
    const isAdmin = await Order.isAdminOfOrder(product.name)();
    return isAdmin ? product.urlLasAdministration ?? product.urlLas : product.urlLas;
  };
};

//* Query
export const getURLLasLink = registerQuery({
  type: () => String,
  operationName: 'getURLLasLink',
  resolve: getURLLasLinkResolver,
  params: [{ type: () => String, name: 'productID' }],
});
