import { Arg, Entity, Field, Mutation } from '@adornis/baseql/decorators';
import { AdornisEntity, constructValue } from '@adornis/baseql/entities/adornisEntity';
import { getRawCollection } from '@adornis/baseql/server/collections';
import { context } from '@adornis/baseql/server/context';
import { runInServerContext } from '@adornis/baseql/server/server';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { LASUser } from '@adornis/digitale-helden-shared/db/las-user';
import { ChosenProduct } from '@adornis/payments/db/chosenProduct';
import { ProcessorInfo } from '@adornis/payments/db/processorInfo';
import { UserReference } from '@adornis/payments/db/userReference';
import { stripe } from '@adornis/payments/server/stripe-client';
import { CurrentUserInfo } from '@adornis/users/db/currentUserInfo';
import { validate } from '@adornis/validation/decorators';
import { nonOptional } from '@adornis/validation/functions/nonOptional';
import { LASProduct } from '../../_accounting/db/LASProduct';
import { LASPaymentCard } from '../../_accounting/db/Payments/DirectPay/LASPaymentCard';
import { LASPaymentKlarna } from '../../_accounting/db/Payments/DirectPay/LASPaymentKlarna';
import { LASPaymentPaypal } from '../../_accounting/db/Payments/DirectPay/LASPaymentPaypal';
import { LASPayment } from '../../_accounting/db/Payments/LASPayment';
import { LASPaymentFactory } from '../../_accounting/db/Payments/LASPaymentFactory';
import { LASPaymentRecipient } from '../../_accounting/db/Payments/LASPaymentRecipient';
import { PaymentMethod } from '../../_forms/_new-mentoring-form/db/SignupNewMentoringForm';
import { SignupCompanyData } from '../../_forms/db/SignupCompanyData';
import { getContactByID } from '../contact/queries/getContactByID';

@Entity()
export class IntentInfo extends AdornisEntity {
  static override _class = 'IntentInfo';

  @validate(nonOptional())
  @Field(type => String)
  clientSecret!: string;

  @validate(nonOptional())
  @Field(type => String)
  paymentID!: string;
}

@Entity()
export class Intent extends AdornisEntity {
  static override _class = 'Intent';

  @Mutation(type => Boolean)
  static validatedIntent(@Arg('paymentIntentID', type => String) paymentIntentID: string) {
    return async () => {
      console.log('=============================================================');
      console.log('validate intent', paymentIntentID);
      return await runInServerContext(async () => {
        const intent = await stripe.paymentIntents.retrieve(paymentIntentID);
        if (!intent || intent.status !== 'succeeded') return false;
        const rawPaymentCollection = await getRawCollection<LASPayment>(LASPayment._collectionName);
        let payment = await rawPaymentCollection.findOne<LASPayment>({
          'processorInfo.stripePaymentIntent': paymentIntentID,
        });
        if (!payment) return false;
        payment = constructValue(payment) as LASPayment;
        if (!payment) return false;
        payment.status = 'paid';
        try {
          await payment.save();
          return true;
        } catch {
          return false;
        }
      });
    };
  }

  @Mutation(type => IntentInfo)
  static createPaymentIntent(
    @Arg('paymentMethod', type => String) paymentMethod: PaymentMethod,
    @Arg('productID', type => String) productID: string,
    @Arg('schoolData', type => SignupCompanyData) schoolData: SignupCompanyData,
  ) {
    return async (gqlFields: BaseQLSelectionSet<IntentInfo>) => {
      if (context.serverContext) throw new Error('not available for server context');
      const user = await CurrentUserInfo.getMyself<LASUser>()({ _id: 1, zohoID: 1 });
      if (!user) throw new Error('no user on context was found');

      if (!paymentMethod) return null;
      if (paymentMethod === PaymentMethod.ON_ACCOUNT) {
        return null;
      }

      const syncedProduct = await LASProduct.getLASProductByZohoID(productID)(LASProduct.allFields);
      if (!syncedProduct) throw new Error('synced product was not found');

      const price = syncedProduct.prices.at(0);
      if (!price) throw new Error('synced product has no price');

      // Create a PaymentIntent with the order amount and currency
      const paymentIntent = await stripe.paymentIntents.create({
        amount: price.unitAmount,
        currency: 'eur',
        payment_method_types: [paymentMethod],
      });

      const PaymentClass = {
        [PaymentMethod.PAYPAL]: LASPaymentPaypal,
        [PaymentMethod.KLARNA]: LASPaymentKlarna,
        [PaymentMethod.CARD]: LASPaymentCard,
      }[paymentMethod];

      const contact = await getContactByID(user.zohoID)({
        id: 1,
        email: 1,
        salutation: 1,
        firstName: 1,
        lastName: 1,
        street: 1,
        zip: 1,
        city: 1,
        state: 1,
        country: 1,
      });
      if (!contact) throw new Error('contact for user on context was not found');
      if (!contact.email || !contact.salutation || !contact.firstName || !contact.lastName) {
        throw new Error('mandatory recipient field not given. Please add your infos in profile');
      }
      const processorInfo = new ProcessorInfo({
        stripePaymentIntent: paymentIntent.id,
      });

      const payment = await LASPaymentFactory.createAndPersist({
        recipient: new LASPaymentRecipient({
          email: contact.email,
          salutation: contact.salutation,
          firstName: contact.firstName,
          lastName: contact.lastName,
          street: schoolData.street,
          zip: schoolData.zip,
          city: schoolData.city,
          state: schoolData.state,
          country: schoolData.country,
          companyName: schoolData.name,
        }),
        amount: price.unitAmount,
        paymentType: paymentMethod,
        products: [
          new ChosenProduct({
            amount: 1,
            product: syncedProduct,
            priceID: price._id,
          }),
        ],
        paymentClass: PaymentClass,
        processorInfo,
        userReference: new UserReference({
          userID: user._id,
        }),
      });

      return new IntentInfo({
        clientSecret: paymentIntent.client_secret,
        paymentID: payment._id,
      });
    };
  }
}
