import { baseqlMetaData } from '@adornis/baseql/metadata/metaDataStore';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { impersonatingUser, isImpersonating, updateCurrentUser } from '@adornis/users/client/currentUser';
import type { AdornisUser } from '@adornis/users/db/a-user';
import { CurrentUserInfo } from '@adornis/users/db/currentUserInfo';
import type { Subscription } from 'rxjs';
import { LASUser } from '../db/las-user';

let userSubscription: Subscription | undefined;
let lastUserSelectionSet: (user: typeof AdornisUser) => BaseQLSelectionSet<any> = user => user.allFields;

export const subscribeCurrentUser = async ({
  userID,
  userClass,
  userSelectionSet,
}: {
  userID?: string;
  userClass?: string;
  userSelectionSet?: (userEntity: typeof AdornisUser) => BaseQLSelectionSet<any>;
} = {}) => {
  userSubscription?.unsubscribe();
  if (userSelectionSet) {
    lastUserSelectionSet = userSelectionSet;
  } else {
    userSelectionSet = lastUserSelectionSet;
  }
  let impersonatingUserID: string | null = null;
  if (!userID) {
    ({ userID, userClass, impersonatingUserID } = await CurrentUserInfo.getCurrentUserInfo()(
      CurrentUserInfo.allFields,
    ));
  }
  if (!userID || !userClass) {
    impersonatingUser().next(null);
    updateCurrentUser(null, true);
    return;
  }
  const userNode = baseqlMetaData.entities.resolveNode(userClass);
  const userEntity = userNode.type() as typeof AdornisUser;
  userSubscription = (
    userClass === LASUser._class
      ? LASUser.subscribeCurrentLASUsers(impersonatingUserID ? [userID, impersonatingUserID] : [userID])(
          userSelectionSet(userEntity),
        )
      : userEntity.subscribeByID<AdornisUser>(impersonatingUserID ? [userID, impersonatingUserID] : [userID])(
          userSelectionSet(userEntity),
        )
  ).subscribe({
    next: users => {
      const user = users.find(u => u._id === userID);
      const impersonating = users.find(u => u._id === impersonatingUserID);
      impersonatingUser().next(impersonating);
      isImpersonating.next(!!impersonatingUserID);
      updateCurrentUser(user, true);
    },
    complete: () => {
      updateCurrentUser(null, true);
    },
  });
};
