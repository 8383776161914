import { css } from '@adornis/chemistry/directives/css';
import { injectAttributesOf } from '@adornis/chemistry/directives/inject-attributes';
import { xComponents } from '@adornis/chemistry/elements/x-components';
import { XDropdownSelection } from '@adornis/popover/x-dropdown-selection';
import { html, nothing, type TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import './d-dropdown';
import './d-formfield-wrapper';
import './d-input';

/**
 * @element d-dropdown-selection
 */
@customElement('d-dropdown-selection')
export class DubniumDropdownSelection extends XDropdownSelection<string> {
  constructor() {
    super();
    this['placeholder-mode'] = 'static-floating';
  }

  protected override _icon() {
    return 'caret-down';
  }

  protected override _search(): TemplateResult<1> | symbol {
    return this.search
      ? html`
          <d-input
            ${css({
              padding: this.spacing.sm,
              position: 'sticky',
              background: '#fff',
              top: '0',
            })}
            placeholder="Suchen..."
            placeholder-mode="hide"
            @value-changed=${e => this._searchString.next(e.detail.value)}
            disable-blur-submit
            @mousedown=${() => (this._ignoreNextBlur = true)}
          ></d-input>
        `
      : nothing;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          '--placeholder-color': '#807f80',
          minWidth: '100px',
        },
        ':host #trigger': {
          paddingTop: '1.1rem !important',
          paddingBottom: '1.1rem !important',
          paddingLeft: '.625rem !important',
          paddingRight: '.625rem !important',
          background: this.colors.tone.whiteInput,
          border: '1px solid rgba(128,127,128,.3) !important',
          boxShadow: 'inset 0 0 3px rgb(128 127 128 / 30%) !important',
          borderRadius: `${this.sizes.borderRadiusSecondary} !important`,
          // color: '#807f80',
        },
        ':host d-text': {
          fontSize: '1rem',
        },
      },
    ];
  }

  protected override _items(): TemplateResult<1> {
    if (this._loading) return this._loader();
    const selectables = this._searchResultSelectables.value;
    if (!selectables?.length) return this._empty();
    return html`
      ${repeat(
        selectables,
        s => this.resolveID(s),
        (selectable, index) =>
          xComponents(html`
            <div
              ?pre-selected=${this._preSelectedItem === index}
              ?active=${selectable === this.value.value}
              @mousedown=${e => {
                // ignore blur until the mouseup event fires, so the onBlur handler does not close the dropdown before the click event if fired.
                this._ignoreBlur = true;
              }}
              @mouseup=${() => {
                // reset this in mouseup so the click event can be prevented by custom renderItem functions.
                this._ignoreBlur = false;
              }}
              @click=${() => {
                this._choose(selectable);
                this.dispatchEvent(new CustomEvent('value-picked', { detail: { value: this.value.value } }));
              }}
            >
              ${this.renderItem(selectable, index)}
            </div>
          `),
      )}
    `;
  }

  protected override _trigger(): TemplateResult<1> {
    if (this.placeholder || !this['use-slot'])
      return html`
        <d-formfield-wrapper
          ${injectAttributesOf(this, ['tabindex'])}
          icon=${this._icon()}
          ?show-label=${this.showLabel}
          @cleared=${() => this._clear()}
          @icon-click=${() => this.dispatchEvent(new CustomEvent('icon-click'))}
          one-line
        >
          <d-text flex tone=${this.hasValue ? '' : 'placeholder'}>
            ${this.hasValue
              ? this.renderSelected(this.value.value)
              : this['placeholder-mode'] === 'static-floating' || !this.error
              ? ''
              : this.placeholder}
          </d-text>
        </d-formfield-wrapper>
      `;
    return html` <slot></slot> `;
  }
}
