import type { Maybe } from '@adornis/base/utilTypes';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import { BaseText } from '@adornis/buildify/db/extensions/text/BaseText';
import { RXController } from '@adornis/chemistry/controllers/RXController';
import { css } from '@adornis/chemistry/directives/css.js';
import '@adornis/chemistry/elements/components/x-flex';
import { XNativeDialog } from '@adornis/dialog/x-native-dialog';
import '@adornis/digitale-helden-shared/client/theme/d-button';
import '@adornis/digitale-helden-shared/client/theme/d-checkbox';
import '@adornis/digitale-helden-shared/client/theme/d-dropdown-selection';
import '@adornis/digitale-helden-shared/client/theme/fonts';
import { html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';

/**
 * @element las-prompt-swap-index-list
 */
@customElement('las-prompt-swap-index-list')
export class LASPromptSwapIndexList extends XNativeDialog<Maybe<any[]>> {
  static override get element_name(): string {
    return 'las-prompt-swap-index-list';
  }

  @property({ attribute: false }) textExtension?: BuildifyExtension<BaseText>;
  @property({ attribute: false }) items = new RXController<Maybe<any[]>>(this, undefined);

  public static async swapIndexes(items: any[]): Promise<Maybe<any[]>> {
    return await this.showPopup({ props: { items } });
  }

  override content(): any {
    if (!this.items.value) return nothing;

    const items = this.items.value;

    return html`
      <d-flex space="lg" padding="lg">
        <d-h3 bold ${css({ textAlign: 'center' })}> Reihenfolge verändern </d-h3>

        <x-drag-drop
          drag-mode="column"
          allow-from="d-flex[item]"
          child-selector="d-flex[item]"
          @resorted=${e => {
            const newOrder: any[] = [];
            for (const key of e.detail.order) {
              const item = items.find(item => item._id === key);
              if (!item) continue;
              newOrder.push(item);
            }

            this.items.next(newOrder);
            this.requestUpdate();
          }}
        >
          <d-flex space="sm">
            ${repeat(
              items,
              item => item._id,
              (item, index) => html`
                <d-flex key=${item._id} item horizontal crossaxis-center space="md">
                  <component-icon-button icon="bars"></component-icon-button>
                  <d-text> ${index + 1}. </d-text>
                </d-flex>
              `,
            )}
          </d-flex>
        </x-drag-drop>

        <d-flex horizontal space="md" space-between>
          <d-button
            @click=${async () => {
              this.close(this.items.value);
            }}
          >
            Fertig
          </d-button>
        </d-flex>
      </d-flex>
    `;
  }
}
