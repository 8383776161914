import { Entity, Field } from '@adornis/baseql/decorators';
import type { ContactEmailStatus } from '@adornis/digitale-helden-shared/db/enums';
import { BiMap } from '@adornis/digitale-helden-shared/db/zoho/BiMap';
import { ZohoType } from '@adornis/digitale-helden-shared/db/zoho/enums';
import { ZohoModule } from '@adornis/digitale-helden-shared/db/zoho/zoho';
import { ZohoEntity } from '@adornis/digitale-helden-shared/db/zoho/zoho-entity';
import { genericSerializeZoho } from '@adornis/digitale-helden-shared/server/zoho/interface-zoho-adornis';
import { emailRegexCheck } from '@adornis/users/db/validators';
import { validate } from '@adornis/validation/decorators';
import { ValidationError } from '@adornis/validation/errors/ValidationError';
import { nonOptional } from '@adornis/validation/functions/nonOptional';

export enum LEAD_ZOHO_FIELDS {
  ID = 'id',
  BIRTHDAY = 'Date_of_Birth',
  EMAIL = 'Email',
  SECOND_EMAIL = 'Secondary_Email',
  FIRST_NAME = 'First_Name',
  LAST_NAME = 'Last_Name',
  STATUS = 'Lead_Status',
  LEAD_SOURCE = 'Lead_Source',
  NEWSLETTER = 'Newsletter',
  SALUTATION = 'Salutation',
  TITEL = 'Titel',
  FAX = 'Fax',
  PHONE = 'Phone',
  MOBILE = 'Mobile',
  WEBSITE = 'Website',
  TWITTER = 'Twitter',
  STREET = 'Street',
  ZIP_CODE = 'Zip_Code',
  CITY = 'City',
  STATE = 'State',
  COUNTRY = 'Country',
  WEBINARE = 'Webinare',
  AKADEMIE_ROLLEN = 'Akademie_Rollen',
  SONSTIGE_AKADEMIE_ROLLE = 'Sonstige_Akademie_Rolle',
  AUSTRAGUNGSDATUM = 'Austragungsdatum',
  EINTRAGUNGSDATUM = 'Eintragungsdatum',
  BESTAETIGUNGSDATUM = 'Bestaetigungsdatum',
  KOMMUNIKATIONS_KAMPAGNEN = 'Kommunikations_Kampagnen',
  EMAIL_STATUS = 'E_Mail_Status',
  KONTAKTFORMULAR = 'Kontaktformular',
  EMAIL_OPT_OUT = 'Email_Opt_Out',
  KLICKTIPP_ERSTEINTRAGUNG = 'KlickTipp_Ersteintragung',
}

export const LEAD_BIMAP = new BiMap<string, string>([
  ['id', LEAD_ZOHO_FIELDS.ID],
  ['birthday', LEAD_ZOHO_FIELDS.BIRTHDAY],
  ['email', LEAD_ZOHO_FIELDS.EMAIL],
  ['secondEmail', LEAD_ZOHO_FIELDS.SECOND_EMAIL],
  ['firstName', LEAD_ZOHO_FIELDS.FIRST_NAME],
  ['lastName', LEAD_ZOHO_FIELDS.LAST_NAME],
  ['status', LEAD_ZOHO_FIELDS.STATUS],
  ['leadSource', LEAD_ZOHO_FIELDS.LEAD_SOURCE],
  ['isNewsletter', LEAD_ZOHO_FIELDS.NEWSLETTER],
  ['salutation', LEAD_ZOHO_FIELDS.SALUTATION],
  ['title', LEAD_ZOHO_FIELDS.TITEL],
  ['fax', LEAD_ZOHO_FIELDS.FAX],
  ['phone', LEAD_ZOHO_FIELDS.PHONE],
  ['mobile', LEAD_ZOHO_FIELDS.MOBILE],
  ['website', LEAD_ZOHO_FIELDS.WEBSITE],
  ['twitter', LEAD_ZOHO_FIELDS.TWITTER],
  ['street', LEAD_ZOHO_FIELDS.STREET],
  ['zip', LEAD_ZOHO_FIELDS.ZIP_CODE],
  ['city', LEAD_ZOHO_FIELDS.CITY],
  ['state', LEAD_ZOHO_FIELDS.STATE],
  ['country', LEAD_ZOHO_FIELDS.COUNTRY],
  ['isWebinar', LEAD_ZOHO_FIELDS.WEBINARE],
  ['academyRoles', LEAD_ZOHO_FIELDS.AKADEMIE_ROLLEN],
  ['otherAcademyRole', LEAD_ZOHO_FIELDS.SONSTIGE_AKADEMIE_ROLLE],
  ['signOutDate', LEAD_ZOHO_FIELDS.AUSTRAGUNGSDATUM],
  ['signInDate', LEAD_ZOHO_FIELDS.EINTRAGUNGSDATUM],
  ['confirmDate', LEAD_ZOHO_FIELDS.BESTAETIGUNGSDATUM],
  ['communicationCampaigns', LEAD_ZOHO_FIELDS.KOMMUNIKATIONS_KAMPAGNEN],
  ['emailStatus', LEAD_ZOHO_FIELDS.EMAIL_STATUS],
  ['isContactform', LEAD_ZOHO_FIELDS.KONTAKTFORMULAR],
  ['isEmailCancellation', LEAD_ZOHO_FIELDS.EMAIL_OPT_OUT],
  ['klicktippSignIn', LEAD_ZOHO_FIELDS.KLICKTIPP_ERSTEINTRAGUNG],
]);

@Entity()
export class Lead extends ZohoEntity {
  static override _class = 'Lead';
  static override ZOHO_MODULE = ZohoModule.LEADS;
  static override ZOHO_FIELDS = Array.from(LEAD_BIMAP.values).join(',');

  @Field(type => String) id?: string;
  @Field(type => Boolean) isNewsletter?: boolean;
  @Field(type => Boolean) isWebinar?: boolean;
  @Field(type => Boolean) isContactform?: boolean;
  @Field(type => Boolean) isEmailCancellation?: boolean;
  @Field(type => String) role?: string;
  @Field(type => Date) signOutDate?: Date;
  @Field(type => Date) signInDate?: Date;
  @Field(type => Date) confirmDate?: Date;
  @Field(type => Date) klicktippSignIn?: Date;
  @Field(type => String) status?: string;
  @Field(type => String) emailStatus?: ContactEmailStatus;
  @Field(type => String) leadSource?: string;
  @Field(type => String) industry?: string;
  @Field(type => [String]) academyRoles?: string[];
  @Field(type => String) otherAcademyRole?: string;
  @Field(type => String) title?: string;
  @Field(type => String) secondEmail?: string;
  @Field(type => String) mobile?: string;
  @Field(type => Date) birthday?: Date;
  @Field(type => [String]) communicationCampaigns?: string[];
  @Field(type => String) phone?: string;
  @Field(type => String) street?: string;
  @Field(type => String) zip?: string;
  @Field(type => String) city?: string;
  @Field(type => String) state?: string;
  @Field(type => String) country?: string;

  @Field(type => String)
  salutation?: string;

  @validate(options => {
    if (!emailRegexCheck(options.value))
      throw new ValidationError('Bitte gib eine gültige E-Mail an.', {
        key: options.key,
        translationKey: 'validation_email',
      });
  })
  @Field(type => String)
  email!: string;

  @validate(nonOptional())
  @Field(type => String)
  firstName!: string;

  @validate(nonOptional())
  @Field(type => String)
  lastName!: string;

  get toFilteredJSON() {
    const fields = {};
    const keys = Array.from(LEAD_BIMAP.keys);
    keys.forEach(key => {
      if (key && this.toJSON()[key]) {
        fields[key] = this.toJSON()[key];
      }
    });
    return fields;
  }

  private get typeDefs() {
    return new Map<string, ZohoType>([
      [LEAD_ZOHO_FIELDS.EINTRAGUNGSDATUM, ZohoType.DATE],
      [LEAD_ZOHO_FIELDS.AUSTRAGUNGSDATUM, ZohoType.DATE],
      [LEAD_ZOHO_FIELDS.BESTAETIGUNGSDATUM, ZohoType.DATE],
      [LEAD_ZOHO_FIELDS.BIRTHDAY, ZohoType.DATE],
      [LEAD_ZOHO_FIELDS.KLICKTIPP_ERSTEINTRAGUNG, ZohoType.DATE],
      [LEAD_ZOHO_FIELDS.PHONE, ZohoType.PHONE],
      [LEAD_ZOHO_FIELDS.MOBILE, ZohoType.PHONE],
    ]);
  }
  override serializeZoho = (isNew: boolean = false) => {
    return genericSerializeZoho({
      bimap: LEAD_BIMAP,
      instance: this,
      typeDefs: this.typeDefs,
    });
  };

  static override deserializeZoho = (rawData: any) => {
    const fields = {};
    const keys = Array.from(LEAD_BIMAP.reverseKeys);
    keys.forEach(key => {
      const keyLAS = LEAD_BIMAP.reverseGet(key);
      if (keyLAS) {
        fields[keyLAS] = rawData[key] ?? null;
      }
    });
    return new Lead({
      ...fields,
    });
  };
}
