import { Entity, Field } from '@adornis/baseql/decorators';
import { BaseList } from '@adornis/buildify/db/extensions/BaseList';
import { DubniumBaseIcon } from './DubniumBaseIcon';

@Entity()
export class DubniumBaseList extends BaseList {
  static override _class = 'DubniumBaseList';

  @Field(type => DubniumBaseIcon, { default: v => v ?? new DubniumBaseIcon({}) })
  override icon!: DubniumBaseIcon;
}
