import type { Maybe, ValueEvent } from '@adornis/base/utilTypes';
import { XBuildifyGlobalSettingsFormField } from '@adornis/buildify/client/components/x-buildify-global-settings-formfield';
import { injectAttributesOf } from '@adornis/chemistry/directives/inject-attributes';
import '@adornis/chemistry/elements/components/x-button';
import '@adornis/chemistry/elements/components/x-flex';
import '@adornis/chemistry/elements/components/x-icon';
import { xComponents } from '@adornis/chemistry/elements/x-components';
import '@adornis/fonts/fonts';
import '@adornis/forms/x-input';
import '@adornis/popover/x-dropdown-selection';
import { html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { BuildifyGlobalSettingTagsDefinition } from '../../../../../db/buildify/DigitaleHeldenBuildifyGlobalSettings';

/**
 * @element d-buildify-tag-template-picker
 */
@customElement('d-buildify-tag-template-picker')
export class XBuildifySpacingPicker extends XBuildifyGlobalSettingsFormField<BuildifyGlobalSettingTagsDefinition> {
  @property() placeholder?: string;

  override render() {
    const content = this._selectable();
    if (content === nothing) return nothing;
    return xComponents(content);
  }

  protected _selectable() {
    if (!this._globalSettings.value) return nothing;

    return html`
      <x-dropdown-selection
        ${injectAttributesOf(this)}
        .selectables=${this._globalSettings.value?.tags}
        @value-picked=${(e: ValueEvent<Maybe<BuildifyGlobalSettingTagsDefinition>>) => {
          this._commitValue(e.detail.value);
        }}
        .renderString=${(tagDef?: BuildifyGlobalSettingTagsDefinition) => tagDef?.name ?? ''}
      ></x-dropdown-selection>
    `;
  }

  private _commitValue(valueObj: Maybe<BuildifyGlobalSettingTagsDefinition>) {
    if (!valueObj) this.value.next(null);
    else this.value.next(valueObj);
    this.dispatchEvent(new CustomEvent('value-picked', { detail: { value: this.value.value } }));
  }

  protected get configuration() {
    return {
      icons: {
        advancedSettings: 'tune',
      },
    };
  }
}
