import type { Maybe } from '@adornis/base/utilTypes';
import { Arg, Entity, Field, Query, Subscription } from '@adornis/baseql/decorators';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity';
import { getRawCollection } from '@adornis/baseql/server/collections';
import { watchQuery } from '@adornis/baseql/server/watchQuery';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { switchMap } from 'rxjs';
import { DigitaleHeldenPage } from './DigitaleHeldenPage';

@Entity()
export class MarketingPage extends DigitaleHeldenPage {
  static override _class: string = 'MarketingPage';
  static override _collectionName: string = 'marketing-pages';

  @Field(type => Boolean) hideFromGoogleCrawler: Maybe<boolean>;
  @Field(type => SEOMeta) seoMeta: Maybe<SEOMeta>;

  @Query(type => MarketingPage)
  static getPageByPath<T extends MarketingPage = MarketingPage>(@Arg('path', type => String) path: string) {
    return async (gqlFields: BaseQLSelectionSet<T>): Promise<T | null> => {
      const rawCollection = await getRawCollection(MarketingPage._collectionName);
      const page = await rawCollection.findOne<T>({ path });
      return page;
    };
  }

  @Subscription(type => MarketingPage)
  static subscribePageByPath<T extends MarketingPage>(@Arg('path', type => String) path: string) {
    return (gqlFields: BaseQLSelectionSet<T>) => {
      return watchQuery(this._collectionName, { path }).pipe(switchMap(() => this.getPageByPath(path)(gqlFields)));
    };
  }
}

@Entity()
export class SEOMeta extends AdornisEntity {
  static override _class: string = 'SEOMeta';

  @Field(type => String) title: Maybe<string>;
  @Field(type => String) description: Maybe<string>;
}
