import type { Maybe } from '@adornis/base/utilTypes';
import { Entity, Field } from '@adornis/baseql/decorators';
import { validate } from '@adornis/validation/decorators';
import { nonOptional } from '@adornis/validation/functions/nonOptional';
import { LASPaymentFormData } from '../../../_accounting/db/Payments/LASPaymentFormData';
import { SignupCompanyData } from '../../db/SignupCompanyData';
import { SignupLegalData } from '../../db/SignupLegalData';
import { SignupNewFundingData } from '../../db/SignupNewFundingData';
import { SignupNewPaymentData } from '../../db/SignupNewPaymentData';

export enum PaymentMethod {
  PAYPAL = 'paypal',
  KLARNA = 'klarna',
  CARD = 'card',
  ON_ACCOUNT = 'on_account',
}

export enum PaymentModality {
  GESAMT = 'Gesamtrechnung',
  GETEILT = 'Zweiteilrechnung',
}

@Entity()
export class SignupNewMentoringForm extends LASPaymentFormData {
  static override _class = 'SignupNewMentoringForm';

  @validate(nonOptional())
  @Field(type => String)
  productID!: string;

  @validate(nonOptional())
  @Field(type => SignupCompanyData, { default: v => v ?? new SignupCompanyData({}) })
  school!: SignupCompanyData;

  @validate(nonOptional())
  @Field(type => SignupNewFundingData, { default: v => v ?? new SignupNewFundingData({}) })
  funding!: SignupNewFundingData;

  @Field(type => SignupNewPaymentData)
  payment?: Maybe<SignupNewPaymentData>;

  @validate(nonOptional())
  @Field(type => SignupLegalData, { default: v => v ?? new SignupLegalData({}) })
  legal!: SignupLegalData;
}
