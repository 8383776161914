import { Entity, Query } from '@adornis/baseql/decorators';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity';
import { ZohoModule } from '@adornis/digitale-helden-shared/db/zoho/zoho';
import { makeZohoAPIRequest } from '@adornis/digitale-helden-shared/server/zoho/api';

@Entity()
export class UptimeCheck extends AdornisEntity {
  static override _class = 'UptimeCheck';

  @Query(type => Boolean, { allowHTTP: true })
  static checkHealthStateToZoho() {
    return async () => {
      await makeZohoAPIRequest({ method: 'get', endpoint: 'Leads/actions/count', zohoModule: ZohoModule.LEADS });
      return true;
    };
  }
}
