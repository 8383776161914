import { Entity, Field } from '@adornis/baseql/decorators';
import { BaseImageFlipcard } from '@adornis/buildify/db/extensions/BaseImageFlipcard';
import { validate } from '@adornis/validation/decorators';
import { nonOptional } from '@adornis/validation/functions/nonOptional';
import { DubniumBaseImage } from './DubniumBaseImage';

@Entity()
export class DubniumBaseFlipcard extends BaseImageFlipcard {
  static override _class = 'DubniumBaseFlipcard';

  @validate(nonOptional())
  @Field(type => DubniumBaseImage, { default: v => v ?? new DubniumBaseImage({}) })
  override file!: DubniumBaseImage;
}
