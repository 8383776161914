/* eslint-disable max-statements */
import { ID, Int } from '@adornis/baseql/baseqlTypes';
import { Arg, Entity, Field, Mutation, Query, Subscription } from '@adornis/baseql/decorators';
import { constructValue, type EntityData } from '@adornis/baseql/entities/adornisEntity.js';
import { getCollection, getCollectionHandle, getRawCollection } from '@adornis/baseql/server/collections';
import { context, publishFilter } from '@adornis/baseql/server/context';
import { type BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { AdornisFilter } from '@adornis/filter/AdornisFilter';
import { GLOBAL_CONTEXT } from '@adornis/users/db/a-roles';
import { AdornisUser } from '@adornis/users/db/a-user';
import { AdornisRoleToContextsWrapper } from '@adornis/users/db/roleToContextsWrapper';
import { setUserOnContext } from '@adornis/users/server/baseqlContextHelper';
import { generatePassword, hashPassword } from '@adornis/users/server/password';
import { assignRoleToUser } from '@adornis/users/server/roles';
import { DateTime } from 'luxon';
import { from, switchMap } from 'rxjs';
import { getContactByID } from '../_api/contact/queries/getContactByID';
import { getAllGroupContactRelationsByContactIDCOQL } from '../_api/group-contact-relations/queries/getAllGroupContactRelationsByContactIDCOQL';
import { makeZohoCOQLRequest } from '../server/zoho/api';
import { ActionTokenData } from './ActionTokenData';
import { Contact } from './Contact.js';
import { ORDER_BIMAP, ORDER_ZOHO_FIELDS, Order } from './Order.js';
import { PRODUCT_ZOHO_FIELDS } from './Product';
import { ContactCompanyRelation } from './Relations/ContactCompanyRelation';
import { ContactCompanyPermission, OrderAttendenceStatus, ProductLearningSituation } from './enums.js';
import { checkPermission } from './helpers';

export enum UserRoles {
  SUPER_ADMIN = 'LAS Super-Admin',
  ADMIN = 'LAS Admin',
  INSTITUTION_ADMIN = 'Administrator:in (Institution)',
  GROUP_ADMIN = 'Gruppen-Administrator:in',
  CONTACT_PERSON = 'Ansprechpartner:in (Institution)',
  PARTICIPANT = 'Teilnehmer:in',
  USER = 'Nutzer',
  HELDEN_TEAM = 'Helden Team',
  SUPPORT = 'Support',
  ACCOUNTING = 'Accounting',
  DESIGNER = 'Designer',
}

export const defaultPermissions = ['Users.ChangePassword', 'Users.Impersonate', 'Users.ChangeRoles'];
export const BuildifyForceViewPermission = 'Buildify.ForceView';

export type DefaultPermission = typeof defaultPermissions;

// team
export enum TeamPermission {
  VIEW = 'Team.View',
}

export const TEAM_PERMISSIONS = Object.values(TeamPermission);
// ----

// support
export enum SupportPermission {
  VIEW = 'Support.View',
  EDIT = 'Support.Edit',
}

export const SUPPORT_PERMISSIONS = Object.values(SupportPermission);
// ----

// accounting
export enum AccountingPermission {
  VIEW = 'Accounting.View',
  EDIT = 'Accounting.Edit',
}

export const ACCOUNTING_PERMISSIONS = Object.values(AccountingPermission);
// ----

// buildify
export enum DesignerPermission {
  VIEW = 'Designer.View',
  EDIT = 'Designer.Edit',
}

export const DESIGNER_PERMISSIONS = Object.values(DesignerPermission);
// ----

// admin
export enum SuperAdminOnlyPermission {
  VIEW = 'Admin.View',
  EDIT = 'Admin.Edit',
  VIEW_USERLIST = 'Admin.ViewUserlist',
  CREATE_EDIT_USERS = 'Admin.CreateAndEditUsers',
  EDIT_PERMISSIONS = 'Admin.EditUserPermissions',
}

export const SUPER_ADMIN_ONLY_PERMISSIONS = Object.values(SuperAdminOnlyPermission);
// ----

export const permissions = [
  // stammdaten-user
  'User.Stammdaten.View',
  'User.Stammdaten.WriteViaForm',
  'User.Stammdaten.EditRecordViaInterface',
  'User.Stammdaten.DeleteRecord',
  // stammdaten-institution
  'Institution.Stammdaten.View',
  'Institution.Stammdaten.WriteViaForm',
  'Institution.Stammdaten.EditRecordViaInterface',
  'Institution.Stammdaten.DeleteRecord',
  // password reset
  'PasswordReset.View',
  'PasswordReset.TriggerEmail',
  // marketing
  'Marketing.View',
  'Marketing.UpdateEmailStatus',
  'Marketing.SubscribeUnsubscribeMailinglist',
  // akademie
  'Akademie.ViewOrder',
  'Akademie.DeactivateAndReactivate',
  'Akademie.ViewContent',
  // group
  'Group.View',
  'Group.WriteRecord',
  'Group.DeleteRecord',
  'Group.EditPermission',

  // designer
  ...DESIGNER_PERMISSIONS,

  // team
  ...TEAM_PERMISSIONS,

  // support
  ...SUPPORT_PERMISSIONS,

  // accounting
  ...ACCOUNTING_PERMISSIONS,

  // super-admin
  ...SUPER_ADMIN_ONLY_PERMISSIONS,

  ...defaultPermissions,

  BuildifyForceViewPermission,
] as const;

const basicPermissions = [
  'User.Stammdaten.View',
  'User.Stammdaten.WriteViaForm',
  'User.Stammdaten.EditRecordViaInterface',
  'User.Stammdaten.DeleteRecord',
  'PasswordReset.View',
  'PasswordReset.TriggerEmail',
  'Marketing.View',
  'Marketing.UpdateEmailStatus',
  'Marketing.SubscribeUnsubscribeMailinglist',
  'Akademie.ViewContent',
  'Akademie.ViewOrder',
  'Akademie.DeactivateAndReactivate',
] as Permission[];

export type Permission = (typeof permissions)[number] | DefaultPermission;

export const defaultRoles: Array<{ name: string; permissions: Permission[] }> = [
  { name: UserRoles.USER, permissions: [...basicPermissions, 'Users.ChangePassword'] },
  {
    name: UserRoles.GROUP_ADMIN,
    permissions: ['Group.View', 'Group.EditPermission', 'Group.WriteRecord', 'Group.DeleteRecord'],
  },
  { name: UserRoles.PARTICIPANT, permissions: [] },
  {
    name: UserRoles.INSTITUTION_ADMIN,
    permissions: [
      'Group.View',
      'Group.EditPermission',
      'Group.WriteRecord',
      'Group.DeleteRecord',
      'Institution.Stammdaten.DeleteRecord',
      'Institution.Stammdaten.EditRecordViaInterface',
      'Institution.Stammdaten.WriteViaForm',
      'Institution.Stammdaten.View',
    ],
  },
  {
    name: UserRoles.CONTACT_PERSON,
    permissions: [
      'Group.View',
      'Group.EditPermission',
      'Group.WriteRecord',
      'Group.DeleteRecord',
      'Institution.Stammdaten.DeleteRecord',
      'Institution.Stammdaten.EditRecordViaInterface',
      'Institution.Stammdaten.WriteViaForm',
      'Institution.Stammdaten.View',
    ],
  },
  { name: UserRoles.HELDEN_TEAM, permissions: [...TEAM_PERMISSIONS, BuildifyForceViewPermission] },
  { name: UserRoles.SUPPORT, permissions: [...SUPPORT_PERMISSIONS, ...TEAM_PERMISSIONS] },
  { name: UserRoles.ACCOUNTING, permissions: [...ACCOUNTING_PERMISSIONS, ...TEAM_PERMISSIONS] },
  {
    name: UserRoles.DESIGNER,
    permissions: [...DESIGNER_PERMISSIONS, ...TEAM_PERMISSIONS, BuildifyForceViewPermission],
  },
  {
    name: UserRoles.ADMIN,
    permissions: [...permissions].filter(permission => ![...SUPER_ADMIN_ONLY_PERMISSIONS].includes(permission)),
  },
  { name: UserRoles.SUPER_ADMIN, permissions: [...permissions] },
];

export const ensureTestUser = async () => {
  const collection = await getCollection<LASUser>(LASUser._class);
  const testuser = await collection.findOne({ username: 'TestUser' });
  if (testuser) return;
  const roleWrapper = new AdornisRoleToContextsWrapper({ name: UserRoles.SUPER_ADMIN, contexts: [GLOBAL_CONTEXT] });
  await LASUser.registerUser({
    username: 'TestUser',
    email: 'test@adornis.de',
    password: 'uh%d@tW3VJk^DFLny$',
    additionalFields: { roles: [roleWrapper] },
  });
};

/**
 * @entity LASUser
 */
@Entity()
export class LASUser extends AdornisUser<Permission> {
  static override _class = 'LASUser';

  // de facto the zoho contact ID
  @Field(type => ID)
  zohoID!: string;

  @Field(type => Contact, {
    resolve(this: LASUser) {
      return async (gqlFields: BaseQLSelectionSet<Contact>) => {
        if (!this.zohoID) return null;
        try {
          const contact = await getContactByID(this.zohoID)(gqlFields);
          return contact;
        } catch {
          return null;
        }
      };
    },
  })
  contact?: Contact;

  hasRole(role: UserRoles) {
    return !!this.roles.find(r => r.name === role);
  }

  @Query(type => Boolean)
  public static settingsChangePassword(
    @Arg('oldPassword', type => String) oldPassword: string,
    @Arg('newPassword', type => String) newPassword: string,
  ) {
    return async () => {
      await checkPermission({ context, permission: 'Users.ChangePassword' });

      const passwordHash = hashPassword(oldPassword);
      const collection = await getCollection<LASUser>(this._class);
      const result = await collection.findOne({ _id: context.userID, password: passwordHash });

      if (!result) throw new Error('Das alte Passwort stimmt nicht überein');

      await LASUser.changePassword(context.userID, newPassword);
    };
  }

  @Query(type => Boolean, { requestHandlerMeta: { queryCache: { cachePolicy: 'bypass' } } })
  public static checkPassword(
    @Arg('userId', type => String) userId: string,
    @Arg('password', type => String) password: string,
  ) {
    return async () => {
      const passwordHash = hashPassword(password);
      const collection = await getCollection<LASUser>(this._class);
      const result = await collection.findOne({ _id: userId, password: passwordHash });

      if (result) return true;
      else return false;
    };
  }

  @Query(type => LASUser)
  static getLASUserByEmail(@Arg('email', type => String) email: string) {
    return async (gqlFields: BaseQLSelectionSet<LASUser>) => {
      const collection = await getRawCollection<LASUser>(LASUser._collectionName);
      const existingUser = await collection.findOne<LASUser>({
        $or: [
          { email: { $regex: new RegExp(`^${email.replace(/([^\w]{1})/g, '\\$1')}$`, 'i') } },
          { username: { $regex: new RegExp(`^${email.replace(/([^\w]{1})/g, '\\$1')}$`, 'i') } },
        ],
      });
      return existingUser;
    };
  }

  @Query(type => Boolean)
  static isExistingUser(@Arg('email', type => String) email: string) {
    return async () => {
      const user = await this.getLASUserByEmail(email)({ _id: 1 });
      return !!user;
    };
  }

  @Query(type => LASUser)
  static getByZohoId(@Arg('zohoId', type => String) zohoId: string) {
    return async (gqlFields: BaseQLSelectionSet<LASUser>) => {
      const collection = await getCollection<LASUser>(this._class);
      const result = await collection.findOne<LASUser>({ zohoID: zohoId });
      return result;
    };
  }

  @Query(type => String)
  static generateSafePassword() {
    return async () => {
      const password = generatePassword(12).replace('-', '#').replace('~', 'X');
      return `${password.substring(0, 4)}-${password.substring(4, 8)}-${password.substring(8, 12)}`;
    };
  }

  @Mutation(type => String)
  static setPasswordWithActionToken(
    @Arg('tokenId', type => String) tokenId: string,
    @Arg('password', type => String) password: string,
  ) {
    return async () => {
      const token = await ActionTokenData.getByID<ActionTokenData>(tokenId)(ActionTokenData.allFields);

      if (!token) throw new Error('Token ist invalide');
      if (token.used) throw new Error('token already used');
      if (token.accountCreated) throw new Error('Token wurde bereits verwendet!');

      this.passwordValidator({ value: password, key: 'password', target: null as any });

      let userId = '';

      const contact = await Contact.getContactByEmailCOQL(token.contact?.email ?? token.email)({
        id: 1,
        email: 1,
        hasAcademyAccount: 1,
      });
      if (!contact) throw new Error('Token invalide');

      contact.hasAcademyAccount = true;
      await Contact.upsertContact(contact)({ id: 1 });

      const collection = await getRawCollection<LASUser>(LASUser._collectionName);

      let existingUser = await collection.findOne<LASUser>({
        $or: [
          { email: { $regex: new RegExp(`^${contact.email.replace(/([^\w]{1})/g, '\\$1')}$`, 'i') } },
          { username: { $regex: new RegExp(`^${contact.email.replace(/([^\w]{1})/g, '\\$1')}$`, 'i') } },
        ],
      });

      if (existingUser) existingUser = constructValue(existingUser);

      if (!existingUser && !token.createAccount) throw new Error('Fehler');

      // create Account if needed
      if (!existingUser) {
        userId = await LASUser.registerUser({
          username: contact.email,
          email: contact.email,
          password,
          additionalFields: { zohoID: contact.id },
        });
        token.accountCreated = true;
      } else {
        await collection.updateOne({ _id: existingUser._id }, { $set: { password: hashPassword(password) } });

        existingUser.zohoID = contact.id!;
        userId = existingUser._id;
        // @ts-ignore
        existingUser.contact = null;

        try {
          await existingUser.save();
        } catch (err) {
          throw new Error('nasdjahsd');
        }
      }

      console.log('bis hier kommen wa nicht');

      token.used = true;
      token.usedAt = DateTime.now();
      await token.save();

      await setUserOnContext({ _id: userId, _class: LASUser._class });
    };
  }

  @Mutation(type => String)
  public static registerUserWithZoho(
    @Arg('email', type => String) email: string,
    @Arg('password', type => String) password: string,
    @Arg('contactData', type => Contact) contactData: Contact,
    @Arg('isAdmin', type => Boolean) isAdmin?: boolean,
  ) {
    return async () => {
      const id = await this.requestRegisterUser(new this({ username: email, email }), password, false)();
      await ensureUserOnZoho(id, contactData);
      if (isAdmin) await assignRoleToUser(id, UserRoles.ADMIN, [GLOBAL_CONTEXT]);

      return id;
    };
  }

  /**
   * this saves redundant data. Real relationships between entities should be represented in the data model, not in roles as we do here.
   *
   * @param userID
   * @returns
   */
  // eslint-disable-next-line max-statements
  static async ensureRoles(userID?: string) {
    if (!userID) return;

    // get logged in user from db
    const currentUser = await LASUser.getByID<LASUser>(userID)({
      _id: 1,
      zohoID: 1,
      roles: AdornisRoleToContextsWrapper.allFields,
    });
    if (!currentUser) throw new Error('Could not find logged in user in DB!');
    if (!currentUser.zohoID) return;

    // get groups data from zoho
    const groupContactRelations = await getAllGroupContactRelationsByContactIDCOQL(currentUser.zohoID)({
      groupContactRole: 1,
      groupId: 1,
      status: 1,
    });

    // get orders data from zoho
    const zohoOrders = await Order.getOrdersByBuyerIDCOQL(currentUser.zohoID)(Order.allFields);
    console.log(
      'zoho orders',
      zohoOrders.map(o => `${o.productConfiguration.name}, ${o.productConfiguration.learningSituation}`),
    );

    const groupOrders = zohoOrders.filter(
      order => order.productConfiguration.learningSituation === ProductLearningSituation.GROUP,
    );
    const groupPermissionByOrder = groupOrders.map(order => ({ name: UserRoles.GROUP_ADMIN, context: order.groupId }));

    // nur Produktfreigabe
    const allOrdersForContact = await Order.getAllOrdersByContactId(currentUser.zohoID)(Order.allFields);
    const productPermissions = groupContactRelations
      .filter(relation => relation.status !== OrderAttendenceStatus.ABGEBROCHEN)
      .map(groupContactRelation => ({
        name: groupContactRelation.groupContactRole,
        context: groupContactRelation.groupId,
      }))
      .concat(zohoOrders.map(order => ({ name: UserRoles.PARTICIPANT, context: order.productId })))
      .concat(allOrdersForContact.map(order => ({ name: UserRoles.PARTICIPANT, context: order.productId })));

    // company permissions
    const contactCompanyRelations = await ContactCompanyRelation.getContactCompanyRelationsByUserAndPermission(
      currentUser.zohoID,
      ContactCompanyPermission.ADMIN,
    )({ companyId: 1 });

    const companyRoles = contactCompanyRelations.map(relation => ({
      name: UserRoles.INSTITUTION_ADMIN,
      context: relation.companyId!,
    }));

    // im kontext der companies, braucht die Person auch Zugriff auf jede einzelne Gruppe dieser Institution
    let companyIds = Array.from(new Set(contactCompanyRelations.map(relation => relation.companyId)).values());
    companyIds = companyIds.filter(id => !!id);
    const ordersByCompany =
      companyIds.length === 0
        ? []
        : ((
            (
              await makeZohoCOQLRequest<Order>({
                gqlFields: {
                  id: 1,
                  groupId: 1,
                } as BaseQLSelectionSet<Order>,
                filter: `${ORDER_ZOHO_FIELDS.BUYER_COMPANY} in ${companyIds.join(',')} AND ${
                  PRODUCT_ZOHO_FIELDS.LEARNING_SITUATION
                } = '${ProductLearningSituation.GROUP}'`,
                moduleBiMap: ORDER_BIMAP,
                moduleName: Order.ZOHO_MODULE,
              })
            )?.data ?? []
          ).map(x => Order.deserializeZoho(x)) as Order[]);
    const companyAdminGroupPermission = ordersByCompany.map(order => ({
      name: UserRoles.GROUP_ADMIN,
      context: order.groupId,
    }));

    // all roles for user
    const allRoles = [
      { name: UserRoles.USER, context: userID },
      ...companyAdminGroupPermission,
      ...groupPermissionByOrder,
      ...productPermissions,
      ...companyRoles,
    ];

    // write
    await syncRoles(allRoles, currentUser);
  }

  serializeZoho = () => {
    const info = JSON.stringify({
      data: [
        {
          Email: this.email,
          Last_Name: this.username,
          Double_Opt_In: this.emailConfirmed,
        },
      ],
    });
    return info;
  };

  @Subscription(type => [LASUser])
  static subscribeLASUsers(
    @Arg('skip', type => Int) skip: number,
    @Arg('limit', type => Int) limit: number,
    @Arg('filter', type => AdornisFilter) filter: AdornisFilter,
  ) {
    return (gqlFields: BaseQLSelectionSet<LASUser>) => {
      const filterObject = filter.toObject();
      if (filterObject.search) filterObject.search = filterObject.search.replace(/([()[{*+.$^\\|?])/g, '\\$1');

      const query = filterObject.search
        ? {
            $or: [
              { username: { $regex: filterObject.search, $options: 'i' } },
              { email: { $regex: filterObject.search, $options: 'i' } },
            ],
          }
        : {};

      const sorting = {};

      return getCollectionHandle<LASUser>(LASUser._collectionName)
        .watchQuery({})
        .pipe(
          switchMap(async e => {
            const pubFilter = await publishFilter(this._class);
            const filtering = pubFilter ? { $and: [pubFilter, query] } : query;

            const collection = await getCollection<LASUser>(LASUser._class);
            const result = await collection
              .find(filtering, {
                sort: sorting,
                skip,
                limit,
              })
              .toArray();
            return result;
          }),
        );
    };
  }

  @Subscription(type => [LASUser])
  static subscribeCurrentLASUsers(@Arg('userIDs', type => [ID]) userIDs: string[]) {
    return (gqlFields: BaseQLSelectionSet<LASUser>) => {
      return from(Promise.all(userIDs.map(userID => LASUser.ensureRoles(userID)))).pipe(
        switchMap(() => getCollectionHandle<LASUser>(this._collectionName).watchQuery({ _id: { $in: userIDs } })),
      );
    };
  }
}

async function syncRoles(necessaryRoles: Array<{ name: string; context: string }>, user: LASUser) {
  // make sure all old roles are removed, but keep global roles because those are set from within the LAS
  for (const roleAssignment of user.roles) {
    if (roleAssignment.contexts.length === 1 && roleAssignment.contexts[0] === GLOBAL_CONTEXT) continue;
    if (roleAssignment.contexts.length !== 1 && roleAssignment.contexts.includes(GLOBAL_CONTEXT))
      throw new Error(`Global context is not allowed when another context is also present. On user ${user._id}`);

    user.roles.splice(user.roles.indexOf(roleAssignment), 1);
  }

  const rawUserCollection = await getRawCollection<LASUser>(LASUser._collectionName);
  await rawUserCollection.updateOne(
    { _id: user._id },
    { $set: { roles: user.roles.map(r => r.toJSON() as EntityData<AdornisRoleToContextsWrapper>) } },
  );

  const flattenedRoles = necessaryRoles.reduce<Array<{ name: string; contexts: string[] }>>((acc, curr) => {
    const entry = acc.find(e => e.name === curr.name);
    if (entry) entry.contexts.push(curr.context);
    else acc.push({ name: curr.name, contexts: [curr.context] });
    return acc;
  }, []);

  // make sure all necessary roles are present
  for (const { name, contexts } of flattenedRoles) await assignRoleToUser(user._id, name, contexts);
}

async function ensureUserOnZoho(userID: string, contact?: Contact) {
  const user = await LASUser.getByID<LASUser>(userID)({ zohoID: 1 });
  if (!user) throw new Error(`User ${userID} not found`);
  const existingContact = user.zohoID ? Contact.getContactByIDCOQL(user.zohoID) : null;
  if (existingContact) return;

  if (!contact) throw new Error('Need contact information when creating user on Zoho.');
  const contactID = (await Contact.upsertContact(contact)({ id: 1 })).id;
  if (!contactID) throw new Error('Expecting ID to be there after upsert but it isnt.');
  user.zohoID = contactID;
  await user.save();
}
