import { css } from '@adornis/chemistry/directives/css';
import { type XFlex } from '@adornis/chemistry/elements/components/x-flex';
import '@adornis/chemistry/elements/components/x-icon';
import { XTooltip } from '@adornis/popover/x-tooltip';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { ref } from 'lit/directives/ref.js';
import { type Props } from 'tippy.js';

/**
 * @element d-tooltip
 */
@customElement('d-tooltip')
export class DubniumTooltip extends XTooltip {
  protected override _defaultOptions(): Partial<Props> {
    return {
      ...super._defaultOptions(),
      arrow: true,
      followCursor: false,
      delay: 0,
    };
  }

  override render() {
    return html`
      <d-flex
        padding="sm"
        ${css({
          background: this.colors.tone.darkGrey,
          color: this.colors.white,
          borderRadius: this.sizes.borderRadius,
          fontFamily: this.fontFamily,
          padding: '2px 7px 4px',
          maxWidth: '300px',
          fontSize: '10px',
        })}
        ${ref(content => (this._contentFlex = content as XFlex | undefined))}
      >
      </d-flex>
    `;
  }
}
