import { A } from '@adornis/base/env-info';
import { type Maybe } from '@adornis/base/utilTypes';
import { Arg, Entity, Query } from '@adornis/baseql/decorators';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity';
import { getRawCollection } from '@adornis/baseql/server/collections';
import { context } from '@adornis/baseql/server/context';
import { MongoCache } from '@adornis/cache/server/cache';
import { Contact } from '@adornis/digitale-helden-shared/db/Contact';
import { type LASUser } from '@adornis/digitale-helden-shared/db/las-user';
import DomParser from 'dom-parser';
import { DateTime } from 'luxon';
import needle from 'needle';
import { getContactByIDCOQL } from '../_api/contact/queries/getContactByIDCOQL';
import { CampusRoute, PathToCampusRoute } from '../_routing/db/enums';

@Entity()
export class DigimemberContent extends AdornisEntity {
  static override _class = 'DigimemberContent';

  private static async _getMongoCache() {
    return A.isServer
      ? (MongoCache.init(await getRawCollection('digimember-cache'), 60 * 60) as Promise<MongoCache<{ html: string }>>)
      : null;
  }

  private static readonly dataPrivacyCookie = `klaro={"klaro":false,"wordfence":false,"matomo":false,"usersnap":false,"googlemaps":false,"vimeo":true,"youtube":true}`;
  private static setCookies: string[] = [];
  private static authenticationTimestamp?: DateTime;
  private static get accumulatedSetCookies() {
    return this.setCookies.map(setCookie => setCookie.split(';')[0]).join('; ');
  }

  private static async authenticateWordpressSession() {
    const content = await needle('post', 'https://akademie.digitale-helden.de/wp-login.php', {
      log: 'Adornis',
      pwd: 'm&QuvBUx5YZsug(sR3F',
      'wp-submit': 'Anmelden',
    });
    this.setCookies = [this.dataPrivacyCookie].concat(
      (content.headers['set-cookie'] ?? []).filter(setCookie => !setCookie.startsWith('klaro=')),
    );
    this.authenticationTimestamp = DateTime.now();
  }

  private static async ensureAuthenticatedWordpressSession() {
    if (
      this.setCookies.length &&
      this.authenticationTimestamp &&
      this.authenticationTimestamp > DateTime.now().minus({ hours: 3 })
    )
      return;

    await this.authenticateWordpressSession();
  }

  @Query(type => String)
  static getDigimemberContentForLink(
    @Arg('link', type => String) link: string,
    @Arg('productID', type => String) productID: string,
  ) {
    return async () => {
      if (!link.endsWith('/')) link += '/';
      const validDomains = ['https://akademie.digitale-helden.de', 'https://digitale-helden.de'];
      const domain = link.replace(/(https:\/\/(akademie\.){0,1}digitale-helden\.de).*/, '$1');

      if (context.serverContext) throw new Error("Cannot call 'getDigimemberContentForLink' from server context");

      const user = (await context.user()) as Maybe<LASUser>;
      if (!user) throw new Error("Can only call 'getDigimemberContentForLink' if you are logged in");

      const contact = await getContactByIDCOQL(user.zohoID)(Contact.allFields);
      if (!contact) throw new Error("Couldn't find zoho contact data for user in 'getDigimemberContentForLink'");

      const content = await this.getLinkContents(link);
      const dom = new DomParser().parseFromString(content);

      const styleLinkNodes = (dom
        .getElementsByTagName('link')
        ?.filter(linkNode =>
          linkNode.attributes.some(attribute => attribute.name === 'rel' && attribute.value === 'stylesheet'),
        ) ?? []) as Element[];
      const styleNodes = (dom.getElementsByTagName('style') ?? []) as Element[];

      const contentWithoutBreaks = content.replaceAll('\n', '');

      console.log(styleLinkNodes, contentWithoutBreaks, link);
      return `
        ${styleLinkNodes.map(styleNode => styleNode.outerHTML).join('')}
        ${styleNodes.map(styleNode => styleNode.outerHTML).join('')}
        ${
          // cannot use domparse, because it seems to strip some div elements from the dom resulting in wrongly applied css
          contentWithoutBreaks
            .slice(
              contentWithoutBreaks.match(/<main[^>]*>/)?.index ?? 0,
              (contentWithoutBreaks.match(/<\/main>/)?.index ?? 0) + 8,
            )
            .replaceAll(/Adornis Admin/g, `${contact.firstName} ${contact.lastName}`) // replace logged in users name
            .replace(/href="([^"]+)"/g, (fullMatch, groupMatch) => {
              if (groupMatch.includes('proxy.php')) return `href="${groupMatch.split('url=')[1]}"`;
              if (groupMatch.endsWith('.pdf')) return fullMatch;
              if (groupMatch.startsWith('/'))
                return `href="${PathToCampusRoute(CampusRoute.COURSE)}/${productID}?${new URLSearchParams({
                  path: `${domain}${groupMatch}`,
                }).toString()}"`;
              if (!validDomains.some(d => groupMatch.startsWith(d))) return fullMatch; // dont touch external links
              return `href="${PathToCampusRoute(CampusRoute.COURSE)}/${productID}?${new URLSearchParams({
                path: groupMatch,
              }).toString()}"`;
            }) ?? ''
        }
      `;
    };
  }

  private static async getLinkContents(link: string) {
    const cache = (await this._getMongoCache())!;

    const cacheResult = await cache.get(link);
    if (cacheResult) return cacheResult.html;

    await this.ensureAuthenticatedWordpressSession();
    const content = await needle('get', link, {}, { headers: { cookie: this.accumulatedSetCookies } });

    await cache.set(link, { html: content.body });
    return content.body as string;
  }
}
