import { css } from '@adornis/chemistry/directives/css.js';
import { XAccordeon } from '@adornis/chemistry/elements/components/x-accordeon';
import '@adornis/chemistry/elements/components/x-icon';
import { XToggleContent } from '@adornis/chemistry/elements/components/x-toggle-content';
import { xComponents } from '@adornis/chemistry/elements/x-components';
import { html, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import './d-icon';

/**
 * @element d-accordeon
 */
@customElement('d-accordeon')
export class DubniumAccordeon extends XAccordeon {
  override render() {
    return html`
      <d-flex space="md">
        ${repeat(this.items.value || [], (item, index) => {
          console.log(this.selected === index || this.isPrinting.value, this.selected);

          return html`
            <d-toggle-content
              ${css(this.selected === index || this.isPrinting.value ? { marginBottom: '0' } : {})}
              .label=${item.title}
              @open-changed=${(e: CustomEvent) => {
                if (e.detail.value !== undefined && e.detail.value !== null) {
                  if (e.detail.value && this.selected !== index) this.selected = index;
                  else if (!e.detail.value && this.selected === index) this.selected = -1;
                }
              }}
              no-icon=${this.hasAttribute('no-icon')}
              ?open=${this.selected === index || this.isPrinting.value}
              .content=${typeof item.text === 'function' ? item.text : null}
            >
              ${typeof item.text !== 'function'
                ? typeof item.text === 'string'
                  ? xComponents(html` <x-text> ${item.text} </x-text> `)
                  : item.text
                : nothing}
            </d-toggle-content>
            ${this.spacer(index)}
          `;
        })}
      </d-flex>
    `;
  }

  protected override spacer(index: number) {
    return index < (this.items.value?.length ?? 0) - 1
      ? html` <d-hr ${css({ background: '#bbbbb4ff !important' })}></d-hr> `
      : nothing;
  }
}

/**
 * @element d-toggle-content
 */
@customElement('d-toggle-content')
export class DubniumToggleContent extends XToggleContent {
  override render() {
    return xComponents(html`
      <x-flex
        horizontal
        crossaxis-center
        space="md"
        space-between
        ${css({ cursor: 'pointer', userSelect: 'none' })}
        @click=${() => {
          this.open = !this.open;
          if (!this.open) this.blur();
        }}
      >
        <d-flex>${typeof this.label === 'string' ? html` <x-h4> ${this.label} </x-h4> ` : this.label}</d-flex>
        ${this.icon()}
      </x-flex>
      <x-flex
        id="content"
        ${css({
          ...(this.open
            ? { height: 'unset', paddingTop: '20px', paddingBottom: '20px' }
            : { height: '0', opacity: '0', pointerEvents: 'none', overflow: 'hidden' }),
        })}
      >
        ${this._content()}
      </x-flex>
    `);
  }

  protected override icon() {
    return html`
      <d-icon secondary ${css({ fontSize: '16px' })}> ${this.open ? 'chevron-up' : 'chevron-down'} </d-icon>
    `;
  }
}
