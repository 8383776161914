import { DesignSystem } from '@adornis/chemistry/elements/theming/design';
import { setGlobalStyle } from '@adornis/chemistry/elements/theming/global-style';
import { XDropdown } from '@adornis/popover/x-dropdown';
import { customElement } from 'lit/decorators.js';
import './d-link';
import './d-nav-button';

// HACK: workaround to style tippy content
setGlobalStyle(() => ({
  '#tippyListItem': {
    alignSelf: 'flex-start',
    borderTop: '1px dotted #b3b3b3',
    color: '#807f80',
    width: '100%',
    textDecoration: 'none',
    fontSize: '1rem',
    padding: '0.5rem 0',
    fontFamily: DesignSystem.currentTheme.getValue().fontFamily,
    '&:last-child': {
      borderBottom: '1px dotted #b3b3b3',
    },
  },
  '#tippyListItem:hover': {
    color: DesignSystem.currentTheme.getValue().colors.accent,
  },
}));

/**
 * @element d-dropdown
 */
@customElement('d-dropdown')
export class DubniumDropdown extends XDropdown {}
