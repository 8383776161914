import type { Styles } from '@adornis/ass/style';
import type { Maybe } from '@adornis/base/utilTypes';
import { Size } from '@adornis/buildify/client/globals/enums';
import { __renderBase } from '@adornis/buildify/client/globals/methods';
import '@adornis/buildify/client/x-buildify-dropzone';
import { BaseContainer } from '@adornis/buildify/db/extensions/BaseContainer';
import type { Renderable } from '@adornis/chemistry/renderable';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import type { DubniumBaseContainer } from '../../../../../db/extensions/DubniumBaseContainer';
import { CampusContainerExtension } from '../campus-container-extension';

@customElement('campus-container-grid-extension')
export class CampusContainerGridExtension extends CampusContainerExtension {
  protected override _content(content: DubniumBaseContainer): any {
    const renderables: Array<Maybe<Renderable>> = [];

    for (
      let i = 0;
      i <
      this.defaultValueBySize('rows', { global: 1 }, Size.DESKTOP) *
        this.defaultValueBySize('columns', { global: 3 }, Size.DESKTOP);
      i++
    ) {
      const cellContent = this._contentFilteredSorted?.find(base => base.index === i);
      if (cellContent) {
        const isContainer = cellContent instanceof BaseContainer;
        const defaultValues = isContainer ? { margin: { global: '0' } } : {};
        renderables.push(__renderBase(this._consumedExtensions.value, cellContent, { defaultValues }));
        continue;
      }

      if (this.isViewMode) {
        renderables.push(html` <div></div> `);
        continue;
      }

      renderables.push(html`
        <x-buildify-dropzone hide-text no-swap .index=${i} .parentID=${this.content.value?._id}></x-buildify-dropzone>
      `);
    }

    return this.__contentWrapper(html` ${renderables} `);
  }

  // @ts-ignore
  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          display: 'grid',
          rowGap: this.defaultValue('rowGap', { global: '20px', [Size.MOBILE]: '16px' }),
          columnGap: this.defaultValue('columnGap', { global: '24px', [Size.MOBILE]: '20px' }),
          gridTemplateColumns: `repeat(${this.defaultValue('columns', { global: 3 })}, 1fr)`,
        },
      },
    ] as Styles[];
  }
}
