import type { Maybe, ValueEvent } from '@adornis/base/utilTypes';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import { html, nothing } from 'lit';
import { DubniumBaseIcon } from '../../../../db/extensions/DubniumBaseIcon';
import { DubniumBaseLinkList, DubniumBaseLinkListItem } from '../../../../db/extensions/DubniumBaseLinkList';
import { LASPromptChooseFile } from '../../../prompts/las-prompt-choose-file';
import { LASPromptSwapIndexList } from '../../../prompts/las-prompt-swap-index-list';
import '../../d-link-editor';
import { BuildifyCampusIconExtension } from '../icon/BuildifyCampusIconExtension';
import './campus-link-list-extension';

export const BuildifyCampusLinkListExtension: BuildifyExtension<DubniumBaseLinkList> = {
  class: DubniumBaseLinkList,
  toolbar: { icon: 'list', text: { default: 'Link-Liste' } },
  render(instance, { defaultValues, isSubelement }) {
    return html`
      <campus-link-list-extension
        .content=${instance}
        .defaultValues=${defaultValues}
        ?sub=${isSubelement}
      ></campus-link-list-extension>
    `;
  },
  editor({ content, contentController, controllerBaseKeyPath, host }) {
    return html`
      <d-flex space="lg">
        <d-button
          @click=${async () => {
            if (!content) return;
            const items = content.items;
            const resorted = await LASPromptSwapIndexList.swapIndexes(items);
            if (resorted) content.items = resorted as any[];
            host.requestUpdate();
          }}
        >
          Reihenfolge verändern
        </d-button>
        <d-flex space="sm">
          <d-flex crossaxis-center space-between horizontal space="sm" wrap>
            <d-text bold> Text </d-text>
            <d-icon
              pointer
              @click=${() => {
                content.items.push(new DubniumBaseLinkListItem({}));
                host.requestUpdate();
              }}
            >
              plus
            </d-icon>
          </d-flex>
          ${content.items.map(
            (item, index) => html`
              <d-flex crossaxis-center space-between horizontal space="sm" wrap>
                <d-text> ${index + 1}. </d-text>
                <d-icon
                  pointer
                  @click=${() => {
                    content.items.splice(index, 1);
                    host.requestUpdate();
                  }}
                >
                  trash
                </d-icon>
              </d-flex>
              <d-flex space="xs">
                <d-checkbox
                  .label=${'Digitaler Notfall?'}
                  ${contentController.field(...controllerBaseKeyPath, 'items', index, 'isDigitalEmergency')}
                ></d-checkbox>
                <d-checkbox
                  .label=${'Icon ausblenden'}
                  ${contentController.field(...controllerBaseKeyPath, 'items', index, 'hideIcon')}
                  @value-picked=${() => host.requestUpdate()}
                ></d-checkbox>
                ${item.hideIcon
                  ? nothing
                  : html`
                      <d-checkbox
                        .value=${!!item.icon}
                        .label=${'Benutzerdefiniertes Icon'}
                        @value-picked=${(e: ValueEvent<boolean>) => {
                          if (e.detail.value) {
                            item.icon = new DubniumBaseIcon({});
                          } else {
                            item.icon = null;
                          }
                          host.requestUpdate();
                        }}
                      ></d-checkbox>
                    `}
                ${item.icon
                  ? BuildifyCampusIconExtension.editor?.({
                      content: item.icon,
                      contentController,
                      host,
                      controllerBaseKeyPath: [...controllerBaseKeyPath, 'items', index, 'icon'],
                    })
                  : nothing}

                <d-input
                  placeholder="Titel"
                  ${contentController.field(...controllerBaseKeyPath, 'items', index, 'title')}
                ></d-input>
                <d-input
                  placeholder="Quelle"
                  ${contentController.field(...controllerBaseKeyPath, 'items', index, 'source')}
                ></d-input>
                <d-input
                  placeholder="Info"
                  ${contentController.field(...controllerBaseKeyPath, 'items', index, 'info')}
                ></d-input>
                <d-link-editor
                  ${contentController.field(...controllerBaseKeyPath, 'items', index, 'link')}
                ></d-link-editor>
                <d-button
                  @click=${async () => {
                    const fileID = await LASPromptChooseFile.showPopup<Maybe<string>>({
                      props: { selectedFileID: item.fileID },
                    });
                    if (!fileID) return;
                    item.fileID = fileID;
                  }}
                >
                  <d-text> Datei </d-text>
                </d-button>
              </d-flex>
            `,
          )}
        </d-flex>
      </d-flex>
    `;
  },
  group: 'Medien',
};
