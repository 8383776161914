import { injectAttributesOf } from '@adornis/chemistry/directives/inject-attributes';
import { XPlainTextarea } from '@adornis/forms/x-plain-textarea';
import { html, type PropertyValueMap } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { live } from 'lit/directives/live.js';
import '../fonts';
import './d-formfield-wrapper';

/**
 * @element d-textarea
 */
@customElement('d-textarea')
export class DubniumTextarea extends XPlainTextarea {
  // need to extend XPlainInput because we need different formfield-wrapper behavior

  constructor() {
    super();
    this['placeholder-mode'] = 'static-floating';
  }

  @property({ attribute: true }) icon = '';
  @property({ type: Boolean, attribute: true }) clearable = false;
  @property({ attribute: true }) postfix = '';
  @property({ type: Boolean, attribute: true }) bold = false;

  protected get _showLabel() {
    return (this.value.value !== undefined && this.value.value !== null && this.value.value !== '') || !!this.error;
  }

  override updated(changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    super.updated(changedProperties);
    const textarea = this.shadowRoot?.querySelector('textarea');
    if (!textarea) return;
    textarea.style.height = 'auto';
  }

  override render() {
    return html`
      <d-formfield-wrapper
        multi-line
        ?show-label=${this._showLabel}
        ${injectAttributesOf(this)}
        @cleared=${() => {
          this.value.next('');
          this.dispatchEvent(new CustomEvent('value-picked', { detail: { value: this.value.value } }));
          this.dispatchEvent(new CustomEvent('cleared'));
        }}
        @icon-click=${() => {
          this.dispatchEvent(new CustomEvent('icon-click'));
        }}
      >
        <textarea
          @input=${(e: Event) => {
            const area = e.currentTarget as HTMLTextAreaElement;
            this.value.next(area.value);
            if (this['no-auto-resize']) return;
            area.style.height = 'auto';
          }}
          @search="${(e: Event) => this.value.next((e.currentTarget as HTMLTextAreaElement).value)}"
          @keydown="${(e: KeyboardEvent) => {
            if (e.key === 'Enter') {
              const shift = e.getModifierState('Shift'); // if enter-submit is enabled, shift supresses
              const ctrl = e.getModifierState('Control'); // if enter-submit is disabled, ctrl enables
              if ((this['enter-submits'] && !shift) || (!this['enter-submits'] && ctrl)) {
                e.preventDefault();
                this.dispatchEvent(
                  new CustomEvent('submit', {
                    detail: { value: (e.currentTarget as HTMLTextAreaElement).value },
                    composed: true,
                    bubbles: true,
                  }),
                );
              }
            }
            if (e.key === 'Escape') return this.dispatchEvent(new CustomEvent('escape'));
          }}"
          @blur=${(e: Event) => {
            this.value.next((e.currentTarget as HTMLTextAreaElement).value);
            this.dispatchEvent(new CustomEvent('value-picked', { detail: { value: this.value.value } }));
          }}
          .value=${live(this.value.value ?? '')}
          ?disabled=${this.disabled}
          ${injectAttributesOf(this, this['placeholder-mode'] === 'static-floating' ? ['placeholder'] : undefined)}
        ></textarea>
      </d-formfield-wrapper>
    `;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        textarea: {
          height: 'auto',
        },
        ':host': {
          marginBottom: '.625rem',
          '--placeholder-color': '807f80',
          fontSize: this.baseFontSize,
          cursor: 'text',
        },
        ':host d-flex': {
          paddingTop: '1.1rem',
          paddingBottom: '1.1rem',
          paddingLeft: '.625rem',
          paddingRight: '.625rem',
          border: this._showLabel ? '1px solid rgba(128,127,128,.6)' : '1px solid rgba(128,127,128,.3)',
          boxShadow: this._showLabel ? '0 0 4px rgb(128 127 128 / 60%)' : 'inset 0 0 3px rgb(128 127 128 / 30%)',
          borderRadius: this.sizes.borderRadiusSecondary,
          color: this._showLabel ? '#1d1d1b' : '#807f80',
          height: 'unset',
          transition: 'box-shadow .5s,border-color .25s ease-in-out,-webkit-box-shadow .5s',
          backgroundColor: this._showLabel ? this.colors.white : this.colors.tone.whiteInput,
        },
        ':host d-text': {
          fontSize: '1rem',
        },
      },
    ];
  }
}
