import { ChemistryLitElement } from '@adornis/chemistry/chemistry-lit-element';
import { css } from '@adornis/chemistry/directives/css';
import '@adornis/chemistry/elements/components/x-icon';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import PatternCardGreen from './assets/Pattern-Card-Green.svg';

/**
 * @element d-card
 *
 * @attribute hover - set this to get mouse hover effects
 */
@customElement('d-card')
export class DubniumCard extends ChemistryLitElement {
  @property({ attribute: true, reflect: true }) type: 'primary' | 'secondary' | 'image' = 'primary';

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          // margin: '1rem',
          padding: '25px',
          backgroundColor: this.type === 'primary' ? this.colors.tone.neutralAlabster : this.colors.tone.whitePrimary,
          borderRadius: this.sizes.borderRadiusSecondary,
          width: 'fit-content',
        },
        ':host([type="image"])': {
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url('${PatternCardGreen as string}')`,
        },
        ':host([hover]:hover)': {
          backgroundColor: this.colors.accent.shade(300),
          cursor: 'pointer',
          userSelect: 'none',
        },
      },
    ];
  }

  override render() {
    return html`
      <d-flex class="card" ${css({ gap: '1rem' })}>
        <slot></slot>
      </d-flex>
    `;
  }
}
