import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import { html } from 'lit';
import { DubniumBaseContainerGrid } from '../../../../../db/extensions/grid-container/DubniumBaseContainerGrid';
import { BuildifyCampusContainerExtension } from '../BuildifyCampusContainerExtension';
import './campus-container-grid-extension';

export const BuildifyCampusContainerGridExtension: BuildifyExtension<DubniumBaseContainerGrid> = {
  class: DubniumBaseContainerGrid,
  toolbar: { icon: 'grid', text: { default: 'Grid' } },
  render(instance, { defaultValues, isSubelement }) {
    return html`
      <campus-container-grid-extension
        .content=${instance}
        .defaultValues=${defaultValues}
        ?sub=${isSubelement}
      ></campus-container-grid-extension>
    `;
  },
  editor(options) {
    return html`
      <d-flex space="sm">
        ${BuildifyCampusContainerExtension.editor?.(options)}
        <d-input ${options.contentController.field('columns')} type="number" placeholder="Columns"></d-input>
        <d-input ${options.contentController.field('rows')} type="number" placeholder="Rows"></d-input>
        <d-input ${options.contentController.field('gridRowGap')} placeholder="Grid Row Gap"></d-input>
        <d-input ${options.contentController.field('gridColumnGap')} placeholder="Grid Column Gap"></d-input>
      </d-flex>
    `;
  },
  group: 'Layout',
};
