import { ExtensionBoilerplate } from '@adornis/buildify/client/extensions/ContentExtensionBoilerplate';
import type { BaseH3 } from '@adornis/buildify/db/extensions/text/BaseH3';
import type { BaseText } from '@adornis/buildify/db/extensions/text/BaseText';
import { css } from '@adornis/chemistry/directives/css';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { DTiptapBuildify } from '../../../../../client/tiptap-editor/d-tiptap-buildify';
import { extensionsForContentType } from '../../../../../client/tiptap-editor/visual-text-extensions';

@customElement('campus-h3-extension')
export class CampusH3Extension extends ExtensionBoilerplate<BaseH3> {
  protected override _content(content: BaseText) {
    const emptyPlaceholder = html`
      <x-text ${css({ color: this.colors.tone.warning })}> Der Text muss mindestens ein Zeichen lang sein! </x-text>
    `;

    if (!content.text) return emptyPlaceholder;

    const parsedContent = unsafeHTML(
      DTiptapBuildify.stringToHTMLConverter(content.text, extensionsForContentType(content)),
    );

    const jsonTextContent = DTiptapBuildify.toJSON(content.text);

    if (!jsonTextContent) return emptyPlaceholder;

    return html` ${parsedContent} `;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          maxWidth: '100%',
          width: '100%',
        },
        ':host>d-text:last-of-type': {
          paddingBottom: '0 !important',
        },
      },
    ];
  }
}
