import { ContainerExtension } from '@adornis/buildify/client/extensions/ContainerExtension';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import '@campus/client/theme/components/d-checkbox';
import '@campus/client/theme/components/d-input';
import { html } from 'lit';
import { DubniumBaseContainer } from '../../../../db/extensions/DubniumBaseContainer';
import '../../d-link-editor';
import './campus-container-extension';

export const BuildifyCampusContainerExtension: BuildifyExtension<DubniumBaseContainer> = {
  class: DubniumBaseContainer,
  toolbar: ContainerExtension.toolbar,
  render(instance, { defaultValues, isSubelement }) {
    return html`
      <campus-container-extension
        .content=${instance}
        .defaultValues=${defaultValues}
        ?sub=${isSubelement}
      ></campus-container-extension>
    `;
  },
  editor(options) {
    return html`
      <d-flex space="sm">
        <d-checkbox
          ${options.contentController.field(...options.controllerBaseKeyPath, 'hasLineLeft')}
          .label=${'Hat Linie links'}
        ></d-checkbox>
        <d-input
          ${options.contentController.field(...options.controllerBaseKeyPath, 'lineLeftWidth')}
          placeholder="Linie links Breite"
        ></d-input>
        <d-input
          ${options.contentController.field(...options.controllerBaseKeyPath, 'lineLeftColor')}
          placeholder="Linie links Farbe"
        ></d-input>
        <d-link-editor ${options.contentController.field(...options.controllerBaseKeyPath, 'link')}></d-link-editor>
      </d-flex>
    `;
  },
  group: 'Layout',
};
