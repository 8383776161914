import type { Maybe } from '@adornis/base/utilTypes';
import { Arg, Entity, Field, Query } from '@adornis/baseql/decorators';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { type BHMaybe } from '@adornis/digitale-helden-shared/db/Contact';
import { BiMap } from '@adornis/digitale-helden-shared/db/zoho/BiMap';
import { ZohoModule } from '@adornis/digitale-helden-shared/db/zoho/zoho';
import { ZohoEntity } from '@adornis/digitale-helden-shared/db/zoho/zoho-entity';
import {
  getAllZohoRecords,
  makeZohoAPIRequest,
  makeZohoCOQLRequest,
  upsertZohoRecord,
} from '@adornis/digitale-helden-shared/server/zoho/api';
import { genericSerializeZoho } from '@adornis/digitale-helden-shared/server/zoho/interface-zoho-adornis';

@Entity()
export class Participant extends AdornisEntity {
  static override _class = 'Participant';

  static override resolveID(entity: any) {
    return entity.id;
  }

  @Field(type => String) id: BHMaybe<string>;
  @Field(type => String) participant: BHMaybe<string>;
  @Field(type => String) type: BHMaybe<string>;
}

enum MEETING_ADORNIS_FIELDS {
  ID = 'id',
  EVENT_TITLE = 'title',
  DESCRIPTION = 'description',
  LEAD_COMPANY_RELATION = 'leadCompanyRelation',
  CONTACT_RELATION = 'contactRelation',
  PARTICIPANTS = 'participants',
  // meta fields
  $SE_MODULE = 'se_module',
}

enum MEETING_ZOHO_FIELDS {
  ID = 'id',
  EVENT_TITLE = 'Event_Title',
  DESCRIPTION = 'Description',
  LEAD_COMPANY_RELATION = 'What_Id',
  CONTACT_RELATION = 'Who_Id',
  PARTICIPANTS = 'Participants',
  // meta fields
  $SE_MODULE = '$se_module',
}

export const MEETING_BIMAP = new BiMap<string, string>([
  [MEETING_ADORNIS_FIELDS.ID, MEETING_ZOHO_FIELDS.ID],
  [MEETING_ADORNIS_FIELDS.EVENT_TITLE, MEETING_ZOHO_FIELDS.EVENT_TITLE],
  [MEETING_ADORNIS_FIELDS.DESCRIPTION, MEETING_ZOHO_FIELDS.DESCRIPTION],
  [MEETING_ADORNIS_FIELDS.LEAD_COMPANY_RELATION, MEETING_ZOHO_FIELDS.LEAD_COMPANY_RELATION],
  [MEETING_ADORNIS_FIELDS.CONTACT_RELATION, MEETING_ZOHO_FIELDS.CONTACT_RELATION],
  [MEETING_ADORNIS_FIELDS.PARTICIPANTS, MEETING_ZOHO_FIELDS.PARTICIPANTS],
  // meta fields
  [MEETING_ADORNIS_FIELDS.$SE_MODULE, MEETING_ZOHO_FIELDS.$SE_MODULE],
]);

export const MEETING_FOREIGN_KEYS = [MEETING_ZOHO_FIELDS.LEAD_COMPANY_RELATION, MEETING_ZOHO_FIELDS.CONTACT_RELATION];

@Entity()
export class Meeting extends ZohoEntity {
  static override _class = 'Meeting';
  static override ZOHO_MODULE = ZohoModule.MEETINGS;
  static override ZOHO_FIELDS = Array.from(MEETING_BIMAP.values).join(',');

  @Field(type => String) [MEETING_ADORNIS_FIELDS.ID]?: string;

  @Field(type => String) [MEETING_ADORNIS_FIELDS.EVENT_TITLE]: BHMaybe<string>;
  @Field(type => String) [MEETING_ADORNIS_FIELDS.DESCRIPTION]: BHMaybe<string>;
  @Field(type => String) [MEETING_ADORNIS_FIELDS.LEAD_COMPANY_RELATION]: BHMaybe<string>;
  @Field(type => String) [MEETING_ADORNIS_FIELDS.CONTACT_RELATION]: BHMaybe<string>;
  @Field(type => [Participant]) [MEETING_ADORNIS_FIELDS.PARTICIPANTS]: Maybe<Participant[]>;

  @Field(type => String) [MEETING_ADORNIS_FIELDS.$SE_MODULE]: BHMaybe<string>;

  get toFilteredJSON() {
    const fields = {};
    const keys = Array.from(MEETING_BIMAP.keys);
    keys.forEach(key => {
      if (key && this.toJSON()[key]) {
        fields[key] = this.toJSON()[key];
      }
    });
    return fields;
  }

  override serializeZoho = () => {
    return genericSerializeZoho({
      bimap: MEETING_BIMAP,
      instance: this,
      foreignKeys: MEETING_FOREIGN_KEYS,
    });
  };

  static override deserializeZoho = (rawData: any) => {
    const fields = {};
    const zohoKeys = Array.from(MEETING_BIMAP.reverseKeys);

    for (const keyZoho of zohoKeys) {
      const keyLAS = MEETING_BIMAP.reverseGet(keyZoho);
      if (keyLAS) {
        if (MEETING_FOREIGN_KEYS.includes(keyZoho as MEETING_ZOHO_FIELDS)) {
          fields[keyLAS] = rawData[keyZoho]?.id ?? null;
        } else {
          fields[keyLAS] = rawData[keyZoho] ?? null;
        }
      }
    }

    return new Meeting({
      ...fields,
    });
  };

  @Query(type => [Meeting])
  public static getAllMeetings() {
    return async (gqlFields: BaseQLSelectionSet<Meeting>) => {
      const rawMeetings = await getAllZohoRecords(this.ZOHO_MODULE, { fields: this.ZOHO_FIELDS });
      rawMeetings.data.forEach(meeting => console.log(meeting));

      const meetings = rawMeetings.data.map(data => this.deserializeZoho(data));
      return meetings;
    };
  }

  @Query(type => [Meeting])
  static getMeetingsForLeadID(@Arg('leadID', type => String) leadID: string) {
    return async (gqlFields: BaseQLSelectionSet<Meeting>) => {
      // zoho coql endpoint can't access this field...
      delete gqlFields['participants'];

      const result = await makeZohoCOQLRequest({
        filter: `(${MEETING_ZOHO_FIELDS.LEAD_COMPANY_RELATION} = '${leadID}')`,
        gqlFields,
        moduleName: this.ZOHO_MODULE,
        moduleBiMap: MEETING_BIMAP,
      });

      if (!result?.data?.[0]) return [];
      const meetings = result.data.map(data => this.deserializeZoho(data)) as Meeting[];
      return meetings;
    };
  }

  @Query(type => [Meeting])
  static getMeetingsForContactIDCOQL(@Arg('contactID', type => String) contactID: string) {
    return async (gqlFields: BaseQLSelectionSet<Meeting>) => {
      const endpoint = `coql`;
      const selectedFields = this.gqlFieldsZoho(gqlFields, MEETING_BIMAP);
      const query = `SELECT ${selectedFields.join(',')} FROM ${this.ZOHO_MODULE} WHERE Participants = ${contactID}`;

      const rawData = await makeZohoAPIRequest({
        method: 'post',
        endpoint,
        data: { select_query: query },
        zohoModule: this.ZOHO_MODULE,
        isRawRequest: true,
      });

      if (!rawData?.data?.[0]) return [];
      const meetings = rawData.data.map(data => JSON.stringify(data));

      return meetings;
    };
  }

  @Query(type => String)
  public static upsertMeeting(@Arg('meeting', type => Meeting) meeting: Meeting) {
    return async () => {
      const id = await upsertZohoRecord(this.ZOHO_MODULE, meeting);
      return id;
    };
  }

  @Query(type => String)
  public static updateMeeting(@Arg('meeting', type => Meeting) meeting: Meeting) {
    return async () => {
      const endpoint = `${this.ZOHO_MODULE}/${meeting.id}`;
      await makeZohoAPIRequest({
        method: 'put',
        endpoint,
        data: meeting.serializeZoho(),
        zohoModule: this.ZOHO_MODULE,
      });
    };
  }
}
