import '@adornis/chemistry/elements/components/x-empty-placeholder';
import '@adornis/chemistry/elements/components/x-flex';
import '@adornis/chemistry/elements/components/x-grid';
import '@adornis/chemistry/elements/components/x-icon';
import '@adornis/chemistry/elements/components/x-loader';
import '@adornis/chemistry/elements/components/x-loading-state.js';
import '@adornis/chemistry/elements/components/x-section';
import '@adornis/chemistry/elements/components/x-stack';
import '@adornis/chemistry/elements/components/x-tabs';
import '@adornis/chemistry/elements/components/x-vr';
import { createComponentsFromChemistryBase } from '@adornis/chemistry/elements/x-components';
import '@adornis/data-view/client/x-data-view-table';
import '@adornis/datetime/x-basic-date-picker';
import '@adornis/datetime/x-date-picker-dropdown.js';
import '@adornis/dragdrop/x-drag-drop';
import '@adornis/file-utils/client/x-image';
import '@adornis/forms/x-input';
import '@adornis/forms/x-radiogroup.js';
import '@adornis/internationalization/client/language-picker/x-country-picker';
import '@adornis/popover/x-dropdown-multiselection';
import '@adornis/popover/x-dropdown-selection';
import './animations';
import './components';
import './fonts';
import './global-styles';
import './theme';

import '@adornis/data-view/client/x-data-view';
import '@adornis/data-view/client/x-data-view-card-list';
import '@adornis/data-view/client/x-data-view-table';

import '@adornis/chemistry/elements/components/x-card';
import '@adornis/datetime/x-basic-date-picker';
import '@adornis/datetime/x-date-picker';

// tiptap
import '@adornis/tiptap/client/x-tiptap';

// buildify
import '@adornis/buildify/client/components/x-buildify-border-picker';
import '@adornis/buildify/client/components/x-buildify-border-radius-picker';
import '@adornis/buildify/client/components/x-icon-button';
import '@adornis/buildify/client/editors/x-buildify-inline-editor';
import '@adornis/buildify/client/extensions/CustomContentExtension';

// drawer
import '@adornis/dialog/x-drawer-panel';

// shared theme
import '@adornis/digitale-helden-shared/client/theme';
import '@adornis/digitale-helden-shared/client/theme/headings_text';

createComponentsFromChemistryBase(
  'x-flex',
  'x-loader',
  'x-tabs',
  'x-tab',
  'x-image',
  'x-grid',
  'x-dropdown-selection-content',
  'x-date-picker-dropdown-content',
  'x-country-picker',
  'x-radiobutton',
  'x-radiogroup',
  'x-loading-state',
  'x-date-picker-dropdown',
  'x-drag-drop',
  'x-tiptap',

  // dataview
  'x-data-view',
  'x-data-view-card-list',
  'x-data-view-table',

  // date
  'x-basic-card',
  'x-basic-date-picker',

  // buildify
  'x-buildify-inline-editor',
  'x-icon-button',
  'x-custom-content-toolbar-items',

  // dialog
  'x-drawer-panel',

  // stack
  'x-stack',
);
