import { A } from '@adornis/base/env-info';
import { acss } from '@adornis/chemistry/directives/acss';
import { injectAttributesOf } from '@adornis/chemistry/directives/inject-attributes';
import { XLink, linkReset } from '@adornis/chemistry/elements/components/x-link';
import { goTo } from '@adornis/router/client/open-href';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';

/**
 * @element d-link
 */
@customElement('d-link')
export class DubniumLink extends XLink {
  override render() {
    return html`
      <a
        ${acss({ ...linkReset, flex: '1', fontSize: '' })}
        href=${ifDefined(this.href)}
        @click=${(e: Event) => {
          const href = this.href;
          if (this.useNativeFunctionality()) {
            if (A.isNative && href && !this.hasSpecialPrefix()) {
              e.preventDefault();
              goTo(href, { target: '_blank' });
            }
            return;
          }
          e.preventDefault();
          if (!href) return;
          if (!href.startsWith('http')) {
            goTo(href);
            document.documentElement.scrollTop = 0;
          } else goTo(href);
        }}
        ${injectAttributesOf(this, ['href'])}
      >
        <slot></slot>
      </a>
    `;
  }

  override styles() {
    const highlightColorAttribute = this.getAttribute('highlight');
    const defaultHighlightColor =
      this.getAttribute('isdigitalemergency') === 'true'
        ? this.colors.tone.emergency?.string
        : this.colors.accent.string;
    const highlightColor = highlightColorAttribute || defaultHighlightColor;

    return [
      ...super.styles(),
      {
        ':host': {
          transition: 'color 100ms',
          textDecoration: 'none',
          fontWeight: '400',
          fontFamily: 'NettoOT-Black',
          fontSize: 'inherit',
        },
        ':host(:hover)': {
          color: highlightColor,
        },
        ':host(:focus-visible), a:focus-visible': {
          outline: 'none',
        },
      },
    ];
  }
}
