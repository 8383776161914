import { BaseQLClient } from '@adornis/baseql/client/baseqlClient';
import '@adornis/fonts/fonts';
import { clientTranslator } from '@adornis/translation-core/client/clientTranslator.js';
import { Capacitor } from '@capacitor/core';
import 'construct-style-sheets-polyfill';
import { subscribeCurrentUser } from '../client/currentUser-hotfix';
import '../client/theme';
import '../db';
import { translations } from '../db/translation';

// very dirty hack to avoid jumping of page when focussing an input
// focussing an input triggers an window size change, and to avoid problems with ios safari we currenty do scrollTop=0 in chemistry/viewport.ts
// by setting isNative=true we disable setting the scrollTop
Capacitor.isNative = true;

BaseQLClient.initDefault();

subscribeCurrentUser().catch(e => console.error('There was an error subscribing current user: ', e));

clientTranslator.updateDictionary(translations);
