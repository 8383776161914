import { DateTime } from 'luxon';
import { PRODUCT_ZOHO_FIELDS } from '../../../db/Product';
import { serializeDateTimeZohoSQL } from '../../../db/serialize-helper';

export const activeProductCriteriaSQL = (): string => {
  const activeCheck = () => `(${PRODUCT_ZOHO_FIELDS.INACTIVE} = 'false')`;
  const hasNoSalesDateCheck = () => `(${PRODUCT_ZOHO_FIELDS.HAS_NO_SALES_DATE} = 'true')`;
  const salesStartDateCheck = () =>
    `(${PRODUCT_ZOHO_FIELDS.SALES_START_DATE} <= '${serializeDateTimeZohoSQL(DateTime.now())}')`;
  const salesEndDateCheck = () =>
    `(${PRODUCT_ZOHO_FIELDS.SALES_END_DATE} >= '${serializeDateTimeZohoSQL(DateTime.now())}')`;
  return `(${activeCheck()} AND (${hasNoSalesDateCheck()} OR (${salesStartDateCheck()} AND ${salesEndDateCheck()})))`;
};
