import { AccordeonExtension } from '@adornis/buildify/client/extensions/AccordeonExtension';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import type { BaseAccordeon } from '@adornis/buildify/db/extensions/BaseAccordeon';
import '../../d-accordeon';

export const BuildifyCampusAccordeonExtension = (
  textExtension: BuildifyExtension,
): BuildifyExtension<BaseAccordeon> => ({
  ...AccordeonExtension(textExtension),
  toolbar: {
    icon: 'chevron-down',
    text: AccordeonExtension(textExtension).toolbar.text,
  },
});
