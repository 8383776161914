import { registerMutation } from '@adornis/baseql/metadata/register';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { Contact } from '@adornis/digitale-helden-shared/db/Contact';
import { ContactEmailStatus } from '@adornis/digitale-helden-shared/db/enums';
import { LASUser } from '@adornis/digitale-helden-shared/db/las-user';
import { UpsertContactOptions } from '@adornis/digitale-helden-shared/db/UpsertContactOptions';
import { getLASUserByEmail } from '../../user/queries/getUserByEmail';
import { getContactEmailStatusByEmail } from '../queries/getContactEmailStatusByEmail';
import { sendContactDOIMail } from './sendContactDOIMail';
import { upsertContact } from './upsertContact';

export const upsertContactAndCheckDOI = registerMutation({
  type: () => Contact,
  operationName: 'upsertContactAndCheckDOI',
  resolve: (instance: Contact, createAccount: boolean, options?: UpsertContactOptions, redirectURL?: string) => {
    return async (gqlFields: BaseQLSelectionSet<Contact>) => {
      try {
        const emailStatus = await getContactEmailStatusByEmail(instance.email)();
        instance.emailStatus = emailStatus;
      } catch {}
      instance = await upsertContact(instance, options)(Contact.allFields);

      const lasAccount = await getLASUserByEmail(instance.email)(LASUser.allFields);
      if (instance.emailStatus !== ContactEmailStatus.DOI || (!lasAccount && createAccount)) {
        await sendContactDOIMail(instance, createAccount, redirectURL)();

        if (
          ![ContactEmailStatus.SOI_BOOKINGS, ContactEmailStatus.SOI, ContactEmailStatus.DOI].includes(
            instance.emailStatus as ContactEmailStatus,
          )
        ) {
          instance.emailStatus = ContactEmailStatus.OPT_IN_PENDING;
          instance = await upsertContact(instance, options)(Contact.allFields);
        }
      }

      return instance;
    };
  },
  params: [
    { name: 'instance', type: () => Contact },
    { name: 'createAccount', type: () => Boolean },
    { name: 'options', type: () => UpsertContactOptions },
    { name: 'redirectURL', type: () => String },
  ],
});
