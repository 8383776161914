import { VimeoExtension } from '@adornis/buildify/client/extensions/VimeoExtension';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';

export const BuildifyCampusVimeoExtension: BuildifyExtension = {
  ...VimeoExtension,
  toolbar: {
    icon: 'video',
    text: VimeoExtension.toolbar.text,
  },
};
