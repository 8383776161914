import type { Styles } from '@adornis/ass/style';
import { XBuildifyGlobalSettings } from '@adornis/buildify/client/components/x-buildify-global-settings';
import { XStack } from '@adornis/chemistry/elements/components/x-stack';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import './d-buildify-global-settings-tag-templates';

@customElement('d-buildify-global-settings')
export class DBuildifyGlobalSettings extends XBuildifyGlobalSettings {
  override customGlobalSettings() {
    return html`
      <x-button
        @click=${e => {
          XStack.addItem(e.target, {
            heading: 'Tag Templates',
            render: () =>
              html`
                <d-buildify-global-settings-tag-templates
                  ${this._form.field('tags')}
                ></d-buildify-global-settings-tag-templates>
              `,
          });
        }}
      >
        Tag Templates
      </x-button>
    `;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {},
      },
    ] as Styles[];
  }
}
