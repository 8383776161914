import type { Maybe } from '@adornis/base/utilTypes';
import { Entity, Field } from '@adornis/baseql/decorators';
import { BaseIcon } from '@adornis/buildify/db/extensions/BaseIcon';

@Entity()
export class DubniumBaseIcon extends BaseIcon {
  static override _class = 'DubniumBaseIcon';

  @Field(type => Boolean) isBold: Maybe<boolean>;
}
