import type { Maybe, ValueEvent } from '@adornis/base/utilTypes';
import { RXController } from '@adornis/chemistry/controllers/RXController';
import { css } from '@adornis/chemistry/directives/css.js';
import type { DialogOptions } from '@adornis/dialog/x-plain-dialog';
import { DubniumDialog } from '@adornis/digitale-helden-shared/client/theme/d-dialog';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../components/las-file-explorer';
import type { SupportedFileType } from '../components/las-file-explorer';

@customElement('las-prompt-choose-file')
export class LASPromptChooseFile extends DubniumDialog<Maybe<string>> {
  @property({ type: String }) selectedFileID: Maybe<string>;
  @property({ attribute: false }) allowedFileTypes = new RXController<Array<SupportedFileType>>(this, []);

  override content() {
    return html`
      <las-file-explorer
        selectable
        .selectedFileID=${this.selectedFileID}
        @file-changed=${(e: ValueEvent<Maybe<string>>) => {
          this.selectedFileID = e.detail.value;
        }}
        .allowedFileTypes=${this.allowedFileTypes.value}
      ></las-file-explorer>
      <d-flex horizontal crossaxis-center space-between ${css({ marginTop: this.spacing.md })}>
        <d-button mode="outline" @click=${() => this.cancel()}> Abbrechen </d-button>
        <d-button ?disabled=${!this.selectedFileID} @click=${() => this.close(this.selectedFileID)}>
          Speichern
        </d-button>
      </d-flex>
    `;
  }

  override async cancel() {
    return super.close(this.selectedFileID);
  }

  override getInnerWrapper(options: Required<DialogOptions>): HTMLDivElement {
    const wrapper = super.getInnerWrapper(options);
    // this.innerWrapper = wrapper;
    wrapper.style.setProperty('width', 'min(800px, 95%)');
    return wrapper;
  }

  static override get element_name() {
    return 'las-prompt-choose-file';
  }
}
