import type { Styles } from '@adornis/ass/style';
import { XImageFlipcardExtension } from '@adornis/buildify/client/extensions/ImageFlipcardExtension';
import { css } from '@adornis/chemistry/directives/css';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import '../../../../../client/theme/components/las-icon-button';

@customElement('d-image-flipcard-extension')
export class DImageFlipcardExtension extends XImageFlipcardExtension {
  protected override _flipButton() {
    return html`
      <las-icon-button
        icon="clone"
        background="alabster"
        color="secondary"
        hover
        ${css({ position: 'absolute', right: '16px', bottom: '16px', zIndex: '11' })}
        @click=${() => {
          this._flipped = !this._flipped;
          const className = 'flip-card-rotate';
          if (this.classList.contains(className)) this.classList.remove(className);
          else this.classList.add(className);
        }}
      ></las-icon-button>
    `;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          width: this.defaultValue('width', { global: '100%' }),
          MozPerspective: '1000px',
          MozWidth: this.defaultValue('width', { global: '200px' }),
          MozHeight: this.defaultValue('height', { global: 'auto' }),
          MozAspectRatio: this.defaultValue('aspectRatio', { global: '1 / 1' }),
        },

        '.flip-card-inner': {
          MozPosition: 'relative',
          MozWidth: '100%',
          MozHeight: '100%',
          MozTransition: 'transform 0.6s',
          MozTransformStyle: 'preserve-3d',
          MozTransform: this._flipped ? 'rotateY(180deg)' : '',
        },

        '.flip-card-front, .flip-card-back': {
          MozPosition: 'absolute',
          MozWidth: '100%',
          MozHeight: '100%',
          MozBackfaceVisibility: 'hidden',
          MozBorderRadius: this.defaultValue('borderRadius', { global: '5px' }),
          MozOverflow: 'hidden',
        },

        '.flip-card-front': {
          zIndex: '0',
          MozBackgroundColor: '#bbb',
          MozColor: 'black',
          MozPointerEvents: this._flipped ? 'none' : '',
        },

        '.flip-card-back': {
          MozBackgroundColor: this.defaultValue('backgroundColor', { global: '#f7f7f7' }),
          MozBoxSizing: 'border-box',
          MozPadding: '24px',
          MozTransform: 'rotateY(180deg)',
        },
      },
    ] as Styles[];
  }
}
