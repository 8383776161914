import { registerQuery } from '@adornis/baseql/metadata/register';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { CONTACT_BIMAP, CONTACT_ZOHO_FIELDS, Contact } from '@adornis/digitale-helden-shared/db/Contact';
import { makeZohoCOQLRequest } from '@adornis/digitale-helden-shared/server/zoho/api';

export const getContactByEmailCOQL = registerQuery({
  type: () => Contact,
  operationName: 'getContactByEmailCOQL',
  resolve: (email: string) => {
    return async (gqlFields: BaseQLSelectionSet<Contact>) => {
      const result = await makeZohoCOQLRequest({
        filter: `${CONTACT_ZOHO_FIELDS.EMAIL} = '${email}'`,
        gqlFields,
        moduleBiMap: CONTACT_BIMAP,
        moduleName: Contact.ZOHO_MODULE,
      });

      const data = result?.data?.[0];
      if (!data) return null;
      const resultData = result.data[0];
      const deserializedContact = Contact.deserializeZoho(resultData);
      return deserializedContact;
    };
  },
  params: [{ name: 'email', type: () => String }],
});
