import { A } from '@adornis/base/env-info';

import nettow01regularwoff2 from './netto-regular/052be335-d0d2-49d2-867d-5e26b9c7b302.woff2';
import nettow01regularwoff from './netto-regular/6c94e13c-af1f-4f6f-9d64-f68bf977e7ee.woff';

import nettow01boldwoff2 from './netto-bold/7f042938-58bd-453f-b9ab-c7948ab829df.woff2';
import nettow01boldwoff from './netto-bold/98dfd474-1f15-4956-9026-01c709c22fad.woff';

import nettow01blackwoff2 from './netto-black/32e774ee-d7a6-48b5-bd02-5ab744d88b33.woff2';
import nettow01blackwoff from './netto-black/5a2867a0-91b4-4077-9dec-e98610b345ec.woff';

import fontAwesomeLightOtf from './icons/FontAwesome6Pro-Light-300.otf';
import fontAwesomeLightTtf from './icons/fa-light-300.ttf';
import fontAwesomeLightWoff2 from './icons/fa-light-300.woff2';

import fontAwesomeSolidOtf from './icons/FontAwesome6Pro-Solid-900.otf';
import fontAwesomeSolidTtf from './icons/fa-solid-900.ttf';
import fontAwesomeSolidWoff2 from './icons/fa-solid-900.woff2';

const style = document.createElement('style');

export enum DigitaleHeldenFont {
  REGULAR = 'Netto W01 Regular',
  BOLD = 'Netto W01 Bold',
  BLACK = 'NettoOT-Black',
  FONT_AWESOME_LIGHT = 'Font Awesome Light',
  FONT_AWESOME_SOLID = 'Font Awesome Solid',
}

export const FontDefinitions = `
@font-face {
  font-family: ${DigitaleHeldenFont.REGULAR};
  src: url('${A.absoluteUrl(nettow01regularwoff2)}') format("woff2"), url('${A.absoluteUrl(
  nettow01regularwoff,
)}') format("woff");
}
@font-face {
  font-family: ${DigitaleHeldenFont.BOLD};
  src: url('${A.absoluteUrl(nettow01boldwoff2)}') format("woff2"), url('${A.absoluteUrl(
  nettow01boldwoff,
)}') format("woff");
}
@font-face {
  font-family: ${DigitaleHeldenFont.BLACK};
  src: url('${A.absoluteUrl(nettow01blackwoff2)}') format("woff2"), url('${A.absoluteUrl(
  nettow01blackwoff,
)}') format("woff");
}

@font-face {
  font-family: ${DigitaleHeldenFont.FONT_AWESOME_LIGHT};
  src: url('${A.absoluteUrl(fontAwesomeLightOtf)}') format("opentype"), url('${A.absoluteUrl(
  fontAwesomeLightWoff2,
)}') format("woff2"), url('${A.absoluteUrl(fontAwesomeLightTtf)}') format("truetype");
}
@font-face {
  font-family: ${DigitaleHeldenFont.FONT_AWESOME_SOLID};
  src: url('${A.absoluteUrl(fontAwesomeSolidOtf)}') format("opentype"), url('${A.absoluteUrl(
  fontAwesomeSolidWoff2,
)}') format("woff2"), url('${A.absoluteUrl(fontAwesomeSolidTtf)}') format("truetype");
}
`;

style.innerHTML = FontDefinitions;

document.head.appendChild(style);
