import { XImageExtension } from '@adornis/buildify/client/extensions/ImageExtension';
import type { BaseImage } from '@adornis/buildify/db/extensions/file/BaseImage';
import { css } from '@adornis/chemistry/directives/css';
import { xComponents } from '@adornis/chemistry/elements/x-components';
import '@adornis/file-utils/client/x-image';
import { html, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import '../../../../../client/theme/components/las-icon-button';
import { LASFile } from '../../../../../db/files/LASFile';

@customElement('campus-image-extension')
export class CampusImageExtension extends XImageExtension {
  protected override _content(content: BaseImage) {
    return xComponents(
      html`
        <x-image
          disabled
          mode=${content.viewMode ?? this.defaultMode}
          .fileEntity=${LASFile}
          .value=${content.fileID}
          ${css({
            width: '100%',
            height: content.viewMode !== 'width' ? '100%' : 'unset',
            aspectRatio: content.viewMode !== 'width' ? content.aspectRatio ?? '' : 'unset',
            ...(content.borderRadius ? { borderRadius: content.borderRadius, overflow: 'hidden' } : {}),
          })}
        ></x-image>
        ${content.viewDownloadButton ? this.__downloadButton(content.fileID) : nothing}
      `,
    );
  }

  protected override __downloadButton(fileID: string) {
    return html`
      <las-icon-button
        icon="folder-arrow-down"
        background="accent"
        color="white"
        hover
        ${css({ position: 'absolute', right: '16px', bottom: '16px' })}
        @click=${() => {
          this._onDownload(fileID);
        }}
      ></las-icon-button>
    `;
  }

  protected override get defaultMode(): string {
    if (this.getValue('height')) return 'cover';
    return 'width';
  }

  protected override get fileClass() {
    return LASFile;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          ...(this.getValue('alignHorizontal') === 'center' ? { left: '50%', transform: 'translateX(-50%)' } : {}),
          ...(this.getValue('alignVertical') === 'center' ? { top: '50%', transform: 'translateY(-50%)' } : {}),
          ...(this.getValue('alignVertical') === 'center' && this.getValue('alignHorizontal') === 'center'
            ? { transform: 'translate(-50%, -50%)' }
            : {}),
        },
      },
    ];
  }
}
