import { setGlobalStyle } from '@adornis/chemistry/elements/theming/global-style';
import { firstValueFrom, timer } from 'rxjs';
import { colors, customDesignSizes } from '../theme';

// container
let checkAnimationContainer: HTMLDivElement | undefined;
// eslint-disable-next-line max-statements
const ensureCheckAnimationContainer = async () => {
  if (checkAnimationContainer) return;
  checkAnimationContainer = document.createElement('div');
  checkAnimationContainer.classList.add('success-checkmark', 'display-none');

  const checkIconContainer = document.createElement('div');
  checkIconContainer.classList.add('check-icon');

  const checkAnimationChildOne = document.createElement('span');
  checkAnimationChildOne.classList.add('icon-line', 'line-tip');
  const checkAnimationChildTwo = document.createElement('span');
  checkAnimationChildTwo.classList.add('icon-line', 'line-long');
  const checkAnimationChildThree = document.createElement('div');
  checkAnimationChildThree.classList.add('icon-circle');
  const checkAnimationChildFour = document.createElement('div');
  checkAnimationChildFour.classList.add('icon-fix');
  checkIconContainer.appendChild(checkAnimationChildOne);
  checkIconContainer.appendChild(checkAnimationChildTwo);
  checkIconContainer.appendChild(checkAnimationChildThree);
  checkIconContainer.appendChild(checkAnimationChildFour);
  checkAnimationContainer.appendChild(checkIconContainer);
  document.body.appendChild(checkAnimationContainer);

  await new Promise<void>(resolve => requestAnimationFrame(() => resolve()));
};

// styles
setGlobalStyle(() => ({
  '.display-none': {
    display: 'none',
  },

  '.success-checkmark': {
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    padding: '20px',
    borderRadius: customDesignSizes.borderRadiusSecondary,
    boxShadow: '0 0 2px rgb(128 127 128)',
    background: colors.primary,
    transition: '200ms',

    '.check-icon': {
      width: '80px',
      height: '80px',
      position: 'relative',
      borderRadius: '50%',
      boxSizing: 'content-box',
      border: `4px solid #fff`,

      '.icon-line': {
        height: '5px',
        backgroundColor: '#fff',
        display: 'block',
        borderRadius: '2px',
        position: 'absolute',
        zIndex: '10',
        '&.line-tip': {
          top: '46px',
          left: '14px',
          width: '25px',
          transform: 'rotate(45deg)',
          animation: 'icon-line-tip .7s',
        },
        '&.line-long': {
          top: '38px',
          right: '8px',
          width: '47px',
          transform: 'rotate(-45deg)',
          animation: 'icon-line-long .7s',
        },
      },
    },
  },

  '@keyframes icon-line-tip': {
    '0%': {
      width: '0',
      left: '1px',
      top: '19px',
    },
    '54%': {
      width: '0',
      left: '1px',
      top: '19px',
    },
    '70%': {
      width: '50px',
      left: '-8px',
      top: '37px',
    },
    '84%': {
      width: '17px',
      left: '21px',
      top: '48px',
    },
    '100%': {
      width: '25px',
      left: '14px',
      top: '45px',
    },
  },

  '.scale-up': {
    animation: 'scale-in-center 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
  },

  '.scale-down': {
    animation: 'scale-out-center 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both',
    animationDelay: '.10s',
  },

  '@keyframes icon-line-long': {
    '0%': {
      width: '0',
      right: '46px',
      top: '54px',
    },
    '65%': {
      width: '0',
      right: '46px',
      top: '54px',
    },
    '84%': {
      width: '55px',
      right: '0px',
      top: '35px',
    },
    '100%': {
      width: '47px',
      right: '8px',
      top: '38px',
    },
  },
}));

export async function triggerSuccessIcon() {
  await ensureCheckAnimationContainer();
  if (!checkAnimationContainer) return;
  checkAnimationContainer.classList.remove('display-none');
  checkAnimationContainer.addEventListener('animationend', async () => {
    await firstValueFrom(timer(200));
    if (checkAnimationContainer) checkAnimationContainer.classList.add('display-none');
  });
}
