import { ExtensionBoilerplate } from '@adornis/buildify/client/extensions/ContentExtensionBoilerplate';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import { BaseText } from '@adornis/buildify/db/extensions/text/BaseText';
import { css } from '@adornis/chemistry/directives/css';
import { DesignSystem } from '@adornis/chemistry/elements/theming/design';
import '@campus/client/theme/fonts';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { DTiptapBuildify } from '../../../../../client/tiptap-editor/d-tiptap-buildify';
import { extensionsForContentType } from '../../../../../client/tiptap-editor/visual-text-extensions';

export const BuildifyCampusTextExtension: BuildifyExtension = {
  class: BaseText,
  toolbar: { icon: 'text', text: { default: 'Text' } },
  group: 'Schriften',
  render(instance, { defaultValues, isSubelement }) {
    return html`
      <campus-text-extension
        .content=${instance}
        .defaultValues=${defaultValues}
        ?sub=${isSubelement}
      ></campus-text-extension>
    `;
  },
  editor({ contentController, controllerBaseKeyPath }) {
    return html`
      <d-tiptap-buildify hide-typo ${contentController.field(...controllerBaseKeyPath, 'text')}></d-tiptap-buildify>
    `;
  },
};

@customElement('campus-text-extension')
export class CampusTextExtension extends ExtensionBoilerplate<BaseText> {
  protected override _content(content: BaseText) {
    const emptyPlaceholder = html`
      <x-text ${css({ color: this.colors.tone.warning })}> Der Text muss mindestens ein Zeichen lang sein! </x-text>
    `;

    if (!content.text) return emptyPlaceholder;

    const parsedContent = unsafeHTML(
      DTiptapBuildify.stringToHTMLConverter(content.text, extensionsForContentType(content)),
    );

    const jsonTextContent = DTiptapBuildify.toJSON(content.text);

    if (!jsonTextContent) return emptyPlaceholder;

    return html` ${parsedContent} `;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          maxWidth: '100%',
          width: '100%',
          fontSize: `${this.defaultValue('fontSize', { global: '16px' })}`,
        },
        [`:host>${DesignSystem.prefix}-text:first-of-type`]: {
          marginTop: '0 !important',
        },
        [`:host>${DesignSystem.prefix}-text:last-of-type`]: {
          marginBottom: '0 !important',
        },
      },
    ];
  }
}
