import { Arg, Entity, Query } from '@adornis/baseql/decorators';
import { getRawCollection } from '@adornis/baseql/server/collections';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { BasicProduct } from '@adornis/payments/db/basic-product';

@Entity()
export class LASProduct extends BasicProduct {
  static override _class = 'LASProduct';
  static override _collectionName = 'synced-products';

  @Query(type => LASProduct)
  static getLASProductByZohoID(@Arg('productID', type => String) productID: string) {
    return async (gqlFields: BaseQLSelectionSet<LASProduct>) => {
      const collection = await getRawCollection<LASProduct>(this._collectionName);
      const result = await collection.findOne<LASProduct>({ _id: productID });
      return result;
    };
  }
}
