import { ChemistryLitElement } from '@adornis/chemistry/chemistry-lit-element';
import { RXController } from '@adornis/chemistry/controllers/RXController';
import { css } from '@adornis/chemistry/directives/css.js';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('las-live-animation')
export class LASLiveAnimation extends ChemistryLitElement {
  @property({ attribute: false }) color = new RXController(this, '#fff');

  override render() {
    return html`
      <d-flex horizontal class="tone-animation" crossaxis-center>
        <div class="tone-line" ${css({ height: '3px' })}></div>
        <div class="tone-line" ${css({ height: '5px' })}></div>
        <div class="tone-line" ${css({ height: '15px' })}></div>
        <div class="tone-line" ${css({ height: '25px' })}></div>
        <div class="tone-line" ${css({ height: '10px' })}></div>
        <div class="tone-line" ${css({ height: '15px' })}></div>
        <div class="tone-line" ${css({ height: '3px' })}></div>
      </d-flex>
    `;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        '.tone-animation': {
          gap: '2px',
          height: '30px',
        },

        '.tone-animation>div': {
          width: '4px',
          background: this.color.value,
          borderRadius: this.sizes.borderRadius,
        },
        '.tone-line:nth-child(1)': {
          animation: 'first-tone-line 2s infinite',
        },
        '.tone-line:nth-child(2)': {
          animation: 'second-tone-line 1s infinite',
        },
        '.tone-line:nth-child(3)': {
          animation: 'third-tone-line 2s infinite',
        },
        '.tone-line:nth-child(4)': {
          animation: 'fourth-tone-line 1.7s infinite',
        },
        '.tone-line:nth-child(5)': {
          animation: 'fifth-tone-line 1.4s infinite',
        },
        '.tone-line:nth-child(6)': {
          animation: 'sixth-tone-line 2.3s infinite',
        },
        '.tone-line:nth-child(7)': {
          animation: 'seventh-tone-line 1.2s infinite',
        },
        '@keyframes first-tone-line': {
          '0%': {
            height: '3px',
          },
          '50%': {
            height: '8px',
          },
          '100%': {
            height: '3px',
          },
        },
        '@keyframes second-tone-line': {
          '0%': {
            height: '5px',
          },
          '50%': {
            height: '15px',
          },
          '100%': {
            height: '5px',
          },
        },
        '@keyframes third-tone-line': {
          '0%': {
            height: '15px',
          },
          '50%': {
            height: '25px',
          },
          '100%': {
            height: '15px',
          },
        },
        '@keyframes fourth-tone-line': {
          '0%': {
            height: '25px',
          },
          '50%': {
            height: '7px',
          },
          '100%': {
            height: '25px',
          },
        },
        '@keyframes fifth-tone-line': {
          '0%': {
            height: '16px',
          },
          '50%': {
            height: '4px',
          },
          '100%': {
            height: '16px',
          },
        },
        '@keyframes sixth-tone-line': {
          '0%': {
            height: '15px',
          },
          '50%': {
            height: '22px',
          },
          '100%': {
            height: '15px',
          },
        },
        '@keyframes seventh-tone-line': {
          '0%': {
            height: '3px',
          },
          '50%': {
            height: '8px',
          },
          '100%': {
            height: '3px',
          },
        },
      },
    ];
  }
}
