import { ExtensionBoilerplate } from '@adornis/buildify/client/extensions/ContentExtensionBoilerplate';
import { Size } from '@adornis/buildify/client/globals/enums';
import { __renderBase } from '@adornis/buildify/client/globals/methods';
import { css } from '@adornis/chemistry/directives/css.js';
import type { Renderable } from '@adornis/chemistry/renderable';
import { html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { DTiptapBuildify } from '../../../../../client/tiptap-editor/d-tiptap-buildify';
import { biggerTextExtensions } from '../../../../../client/tiptap-editor/visual-text-extensions';
import type { DubniumBaseQuiz } from '../../../../db/extensions/DubniumBaseQuiz';

@customElement('campus-quiz-extension')
export class CampusQuizExtension extends ExtensionBoilerplate<DubniumBaseQuiz> {
  @state() private _currentIndex = 0;

  protected override _content(content: DubniumBaseQuiz) {
    const countSteps = content.items.length * 2;

    const renderables: Renderable[] = [];
    for (let i = 0; i < countSteps; i++) {
      const currentStep = (i % 2 === 0 ? i : i - 1) / 2 + 1;
      renderables.push(html`
        <d-flex
          ${css({
            gridColumn: '1',
            gridRow: '1',
            zIndex: i === this._currentIndex ? '10' : '-20',
            visibility: i === this._currentIndex ? 'visible' : 'hidden',
            background: 'transparent',
            borderRadius: this.sizes.borderRadius,
            rowGap: this.defaultValue('rowGap', { global: '20px', [Size.MOBILE]: '16px' }),
          })}
        >
          <d-flex horizontal crossaxis-center space-between space="md">
            <d-text
              bold
              ${css({ color: content.isDigitalEmergency ? this.colors.tone.emergency : this.colors.accent })}
            >
              ${i % 2 === 0 ? content.questionText ?? nothing : content.answerText ?? nothing}
              ${content.items.length > 1 ? html`&nbsp;${currentStep}/${content.items.length}` : nothing}
            </d-text>
            <d-flex horizontal crossaxis-center space="md">
              <d-icon
                pointer
                small
                ${css({
                  color: i <= 0 ? this.colors.tone.secondaryText : this.colors.tone.black,
                  fontSize: '16px',
                })}
                @click=${() => {
                  if (i <= 0) return;
                  this._currentIndex--;
                }}
              >
                chevron-left
              </d-icon>
              <d-icon
                pointer
                small
                ${css({
                  color: i + 1 >= content.items.length * 2 ? this.colors.tone.secondaryText : this.colors.tone.black,
                  fontSize: '16px',
                })}
                @click=${() => {
                  if (i + 1 >= content.items.length * 2) return;
                  this._currentIndex++;
                }}
              >
                chevron-right
              </d-icon>
            </d-flex>
          </d-flex>
          ${unsafeHTML(
            DTiptapBuildify.stringToHTMLConverter(
              content.items.at(currentStep - 1)?.title.text ?? '',
              biggerTextExtensions,
            ),
          )}
          ${i % 2 === 0
            ? html`
                ${__renderBase(this._consumedExtensions.value, content.items.at(currentStep - 1)?.question, {
                  isSubelement: true,
                })}
                <d-grid columns="max-content 1fr" space="sm">
                  ${content.items.at(currentStep - 1)?.options.map(
                    option => html`
                      <d-checkbox></d-checkbox>
                      <d-text ${css({ alignSelf: 'center' })}> ${option} </d-text>
                    `,
                  )}
                </d-grid>
              `
            : html`
                ${__renderBase(this._consumedExtensions.value, content.items.at(currentStep - 1)?.answer, {
                  isSubelement: true,
                })}
              `}
        </d-flex>
      `);
    }

    return html` <div ${css({ display: 'grid' })}>${renderables}</div> `;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          width: '100%',
        },
      },
    ];
  }
}
