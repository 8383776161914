import { registerQuery } from '@adornis/baseql/metadata/register';
import { generatePassword } from '@adornis/users/server/password';

export const generateSafePassword = registerQuery({
  type: () => String,
  operationName: 'generateSafePassword',
  resolve: () => {
    return async () => {
      const password = generatePassword(12).replace('-', '#').replace('~', 'X');
      return `${password.substring(0, 4)}-${password.substring(4, 8)}-${password.substring(8, 12)}`;
    };
  },
  params: [],
});
