import { XListExtension } from '@adornis/buildify/client/extensions/ListExtension';
import { customElement } from 'lit/decorators.js';

@customElement('campus-list-extension')
export class CampusListExtension extends XListExtension {
  protected override get icon() {
    return 'arrow-right';
  }

  override styles() {
    return [
      ...super.styles(),
      {
        'x-grid, d-grid': {
          gap: '10px 16px !important',
        },
      },
    ];
  }
}
