import { emailRegexCheck } from '@adornis/users/db/validators';
import { ValidationError } from '@adornis/validation/errors/ValidationError';

// ========================================================================================
// Validation Helpers
export const validateNonOptional = ({
  value,
  key,
  allowFalsy = false,
}: {
  value: any;
  key: string;
  allowFalsy?: boolean;
}) => {
  if (value || (allowFalsy && value !== undefined && value !== null)) return;
  ThrowNonOptionalError(key);
};

export const validateEmail = ({
  value,
  key,
  allowFalsy = false,
}: {
  value: any;
  key: string;
  allowFalsy?: boolean;
}) => {
  if (emailRegexCheck(value)) return;

  throw new ValidationError('Bitte gib eine gültige E-Mail an.', {
    key: key,
    translationKey: 'validation_email',
  });
};

export const validateEmailRepeat = ({
  emailRepeat,
  email,
  key,
  allowFalsy = false,
}: {
  emailRepeat: string;
  email: string;
  key: string;
  allowFalsy?: boolean;
}) => {
  if (emailRepeat == email) return;

  throw new ValidationError('Die E-Mail-Adressen müssen übereinstimmen.', {
    key: key,
    translationKey: 'email_repeat_error',
  });
};
// End Validation Helpers
// ----------------------------------------------------------------------------------------

// ========================================================================================
// Throw Validation-Errors
export const ThrowNonOptionalError = (key: string) => {
  throw new ValidationError('Dies ist ein Pflichtfeld', {
    key: key,
    translationKey: 'validation_non_optional',
  });
};
// End Errors
// ----------------------------------------------------------------------------------------
