import '@adornis/buildify/client/x-buildify-dropzone';
import '@adornis/buildify/client/x-buildify-visualization';
import { createComponentsFromChemistryBase } from '@adornis/chemistry/elements/x-components';
import './d-buildify';
import './d-buildify-bar';
import './d-buildify-sidebar';
import './d-buildify-size-picker';
import './d-buildify-toolbar';
// global settings
import '@adornis/buildify/client/components/x-buildify-global-settings';
// extensions
import '@adornis/buildify/client/extensions/AccordeonExtension';
import '@adornis/buildify/client/extensions/ButtonExtension';
import '@adornis/buildify/client/extensions/ContainerExtension';
import '@adornis/buildify/client/extensions/ContainerFlexExtension';
import '@adornis/buildify/client/extensions/ContainerGridExtension';
import '@adornis/buildify/client/extensions/ContainerSectionExtension';
import '@adornis/buildify/client/extensions/ExcalidrawExtension';
import '@adornis/buildify/client/extensions/H1Extension';
import '@adornis/buildify/client/extensions/H2Extension';
import '@adornis/buildify/client/extensions/H3Extension';
import '@adornis/buildify/client/extensions/IconExtension';
import '@adornis/buildify/client/extensions/IconTextExtension';
import '@adornis/buildify/client/extensions/ImageExtension';
import '@adornis/buildify/client/extensions/ImageFlipcardExtension';
import '@adornis/buildify/client/extensions/ListExtension';
import '@adornis/buildify/client/extensions/SpacingExtension';
import '@adornis/buildify/client/extensions/TagExtension';
import '@adornis/buildify/client/extensions/TextExtension';
import '@adornis/buildify/client/extensions/VimeoExtension';
import '@adornis/buildify/client/extensions/YoutubeExtenions';
// components
import '@adornis/buildify/client/components/x-buildify-aspect-ratio-picker';
import '@adornis/buildify/client/components/x-buildify-border-radius-picker';
import '@adornis/buildify/client/components/x-buildify-color-picker';
import '@adornis/buildify/client/components/x-buildify-drawer-wrapper';
import '@adornis/buildify/client/components/x-buildify-file-selection';
import '@adornis/buildify/client/components/x-buildify-page-settings';
import '@adornis/buildify/client/components/x-buildify-permission-selection';
import '@adornis/buildify/client/components/x-buildify-size-picker';
import '@adornis/buildify/client/components/x-buildify-spacing-picker';
import '@adornis/buildify/client/components/x-infobox';
import '@adornis/buildify/client/extensions/x-extension-editor';
// dubnium extensions
import './extensions';

createComponentsFromChemistryBase(
  // buildify global settings
  'x-buildify-global-settings',
  'x-buildify-global-settings-color-picker',
  'x-buildify-global-settings-spacing-picker',
  'x-buildify-global-settings-border-radius-picker',

  // components
  'x-buildify-dropzone',
  'x-buildify-visualization',
  'x-buildify-aspect-ratio-picker',
  'x-buildify-color-picker',
  'x-buildify-spacing-picker',
  'x-buildify-border-radius-picker',
  'x-buildify-file-selection',
  'x-buildify-page-settings',
  'x-buildify-permission-selection',
  'x-infobox',
  'x-buildify-color-picker-dropdown-content',
  'x-buildify-default-editor',
  'x-extension-editor',

  // buildify extensions
  'x-accordeon-extension',
  'x-icon-text-extension',
  'x-spacing-extension',
  'x-tag-extension',
  'x-vimeo-extension',
  'x-youtube-extension',
  'x-image-extension',

  // buildify content editors
  'x-icon-extension-content-editor',
);
