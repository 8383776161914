import { ChemistryLiteElement } from '@adornis/chemistry/chemistry-lite-element';
import { customElement } from 'lit/decorators.js';

/**
 * @element d-highlight
 */
@customElement('d-highlight')
export class DubniumHighlight extends ChemistryLiteElement {
  override connectedCallback() {
    super.connectedCallback();

    if (!this.getAttribute('type')) this.setAttribute('type', 'primary');
  }

  override styles() {
    return [
      ...(super.styles?.() ?? []),
      {
        display: 'inline !important',
        '&[type=primary]': {
          color: this.colors.accent,
        },
        '&[type=secondary]': {
          color: this.colors.secondary,
        },
      },
    ];
  }
}
