import { XExtensionEditor } from '@adornis/buildify/client/extensions/x-extension-editor';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import type { DubniumBaseButton } from '../../../../db/extensions/DubniumBaseButton';
import '../../d-link-editor';

@customElement('campus-button-extension-editor')
export class CampusButtonExtensionEditor extends XExtensionEditor<DubniumBaseButton> {
  override _content() {
    const content = this.content.value;

    if (!content) return html` 404: content not found `;
    return html`
      <d-flex space="sm">
        <d-checkbox ${this._controller.value?.field('isDigitalEmergency')} .label=${'Digitaler Notfall?'}></d-checkbox>
        <d-input ${this._controller.value?.field('display')} placeholder="Display"></d-input>
        <d-input ${this._controller.value?.field('margin')} placeholder="Margin"></d-input>
        <d-input ${this._controller.value?.field('padding')} placeholder="Padding"></d-input>
        <d-input ${this._controller.value?.field('text')} placeholder="Text"></d-input>
        <d-checkbox ${this._controller.value?.field('invert')} .label=${'Invertieren?'}></d-checkbox>

        <d-link-editor ${this._controller.value?.field('link')}></d-link-editor>

        <!-- ausrichtung -->
        <d-dropdown-selection
          clearable
          .selectables=${['center']}
          ${this._controller.value?.field('alignHorizontal')}
          placeholder="Ausrichtung horizontal"
        ></d-dropdown-selection>
        <d-dropdown-selection
          clearable
          .selectables=${['center']}
          ${this._controller.value?.field('alignVertical')}
          placeholder="Ausrichtung vertikal"
        ></d-dropdown-selection>
      </d-flex>
    `;
  }
}
