import { ChemistryLiteElement } from '@adornis/chemistry/chemistry-lite-element';
import { customElement } from 'lit/decorators.js';

@customElement('d-bold')
export class DubniumBold extends ChemistryLiteElement {
  override styles() {
    return [
      {
        fontFamily: 'NettoOT-Black !important',
        fontSize: 'inherit',
        lineHeight: 'inherit',
        '&[inline]': {
          display: 'inline',
        },
      },
    ];
  }
}
