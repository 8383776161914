import { A } from '@adornis/base/env-info';
import type { Maybe } from '@adornis/base/utilTypes';
import { Float, Int } from '@adornis/baseql/baseqlTypes';
import { Arg, Entity, Field, Mutation, Query } from '@adornis/baseql/decorators';
import { selectionSet, type BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { DateTime } from 'luxon';
import {
  getAllZohoRecords,
  getZohoRecordByID,
  makeZohoAPIRequest,
  makeZohoCOQLRequest,
  upsertZohoRecord,
} from '../server/zoho/api';
import { type ZohoRecord } from '../server/zoho/types';
import { BiMap } from './BiMap';
import { Company } from './Company';
import { Order } from './Order';
import { ZohoModule } from './enumns/zoho';
import { ZohoEntity } from './zoho-entity';

export enum FUNDING_ZOHO_FIELDS {
  ID = 'id',
  NAME = 'Name',
  FUNDING_CODE = 'Funding_Code',
  FUNDING_KEY = 'Funding_Key',
  FUNDING_BY = 'Funding_By',
  FUNDING_PART_EURO = 'FundingPartEuro',
  FUNDING_PART_PERCENT = 'FundingPartPercent',
  GUELTIG_VON = 'Gueltig_von',
  GUELTIG_BIS = 'Gueltig_bis',
  LIMITIERUNGEN = 'Limitations',
  MAX_ORDERS = 'Max_Bestellungen',
  PRODUKTFOERDERUNG = 'Produktfoerderung',
  SITUATIONSFOERDERUNG = 'Situationsfoerderung',
  IS_COMPANY_FUNDING = 'Is_Company_Funding',
  FIRMAFOERDERUNG = 'Firmafoerderung',
  IS_CONTACT_FUNDING = 'Is_Contact_Funding',
  KONTAKTFOERDERUNG = 'Kontaktfoerderung',
  TEXT = 'Text',
  PRIORITY = 'Priority',
  IST_FINANZIERUNG = 'Ist_Finanzierung',
}

export const FUNDING_BIMAP = new BiMap<string, FUNDING_ZOHO_FIELDS>([
  ['id', FUNDING_ZOHO_FIELDS.ID],
  ['name', FUNDING_ZOHO_FIELDS.NAME],
  ['maxOrders', FUNDING_ZOHO_FIELDS.MAX_ORDERS],
  ['fundingPortionPercent', FUNDING_ZOHO_FIELDS.FUNDING_PART_PERCENT],
  ['fundingPortionEuro', FUNDING_ZOHO_FIELDS.FUNDING_PART_EURO],
  ['validAt', FUNDING_ZOHO_FIELDS.GUELTIG_VON],
  ['validTill', FUNDING_ZOHO_FIELDS.GUELTIG_BIS],
  ['limitations', FUNDING_ZOHO_FIELDS.LIMITIERUNGEN],
  ['fundingCode', FUNDING_ZOHO_FIELDS.FUNDING_CODE],
  ['fundingKey', FUNDING_ZOHO_FIELDS.FUNDING_KEY],
  ['fundingBy', FUNDING_ZOHO_FIELDS.FUNDING_BY],
  ['isCompanyFunding', FUNDING_ZOHO_FIELDS.IS_COMPANY_FUNDING],
  ['companyId', FUNDING_ZOHO_FIELDS.FIRMAFOERDERUNG],
  ['isContactFunding', FUNDING_ZOHO_FIELDS.IS_CONTACT_FUNDING],
  ['contactId', FUNDING_ZOHO_FIELDS.KONTAKTFOERDERUNG],
  ['productId', FUNDING_ZOHO_FIELDS.PRODUKTFOERDERUNG],
  ['situation', FUNDING_ZOHO_FIELDS.SITUATIONSFOERDERUNG],
  ['text', FUNDING_ZOHO_FIELDS.TEXT],
  ['priority', FUNDING_ZOHO_FIELDS.PRIORITY],
  ['isFinancing', FUNDING_ZOHO_FIELDS.IST_FINANZIERUNG],
]);

export const FUNDING_FOREIGN_KEYS = [
  FUNDING_ZOHO_FIELDS.KONTAKTFOERDERUNG,
  FUNDING_ZOHO_FIELDS.FIRMAFOERDERUNG,
  FUNDING_ZOHO_FIELDS.FUNDING_BY,
  FUNDING_ZOHO_FIELDS.PRODUKTFOERDERUNG,
];

// API_KEY,Module,Value,Operation

@Entity()
export class Funding extends ZohoEntity {
  static override _class = 'Funding';
  static override ZOHO_MODULE = ZohoModule.FUNDING_STRUCTURES;
  static override ZOHO_FIELDS = Array.from(FUNDING_BIMAP.values).join(',');

  static override get allFields() {
    return selectionSet(() => this, 3);
  }

  @Field(type => String) id!: string;
  @Field(type => String) name!: string;
  @Field(type => Int) maxOrders!: number;
  @Field(type => Float) fundingPortionPercent!: number;
  @Field(type => Float) fundingPortionEuro!: number;
  @Field(type => Date) validAt!: Date;
  @Field(type => DateTime) validTill!: DateTime;
  @Field(type => [Limitation]) limitations!: Maybe<Limitation[]>;
  @Field(type => String) fundingCode!: string;
  @Field(type => String) fundingKey!: string;
  @Field(type => String) fundingBy!: string;
  @Field(type => Boolean) isCompanyFunding!: boolean;
  @Field(type => String) companyId!: string;
  @Field(type => Boolean) isContactFunding!: boolean;
  @Field(type => Boolean) isFinancing?: boolean;
  @Field(type => String) contactId!: string;
  @Field(type => String) productId!: string;
  @Field(type => String) situation!: string;
  @Field(type => String) text!: string;
  @Field(type => Int) priority: Maybe<number>;

  get toFilteredJSON() {
    const fields = {};
    const keys = Array.from(FUNDING_BIMAP.keys);
    keys.forEach(key => {
      if (key && this.toJSON()[key]) {
        fields[key] = this.toJSON()[key];
      }
    });
    return fields;
  }

  override serializeZoho = (isNew: boolean = false) => {
    const fields = {};
    const keys = Array.from(FUNDING_BIMAP.keys);
    keys.forEach(key => {
      const keyZoho = FUNDING_BIMAP.get(key);
      if (keyZoho && (this[key] || typeof this[key] === 'boolean')) {
        if (FUNDING_FOREIGN_KEYS.includes(keyZoho)) {
          fields[keyZoho] = {
            id: this[key],
          };
        } else {
          if (keyZoho === FUNDING_ZOHO_FIELDS.LIMITIERUNGEN) fields[keyZoho] = [];
          else fields[keyZoho] = this[key];
        }
      }
    });
    const data: ZohoRecord<any> = {
      data: [fields],
      trigger: ['workflow'],
    };
    return JSON.stringify(data).replace(/"id":[ ]?"([0-9]+)"/g, '"id":$1');
  };

  static override deserializeZoho = (rawData: any) => {
    const fields = {};
    const keys = Array.from(FUNDING_BIMAP.reverseKeys);
    keys.forEach(key => {
      const keyLAS = FUNDING_BIMAP.reverseGet(key);
      if (keyLAS) {
        if (FUNDING_FOREIGN_KEYS.includes(key)) {
          fields[keyLAS] = rawData[key]?.id ?? null;
        } else {
          if (key === FUNDING_ZOHO_FIELDS.LIMITIERUNGEN) {
            fields[keyLAS] = (rawData[key] ?? []).map(limitation => {
              return new Limitation({
                id: limitation[LIMITATION_ZOHO_FIELDS.ID],
                apiKey: limitation[LIMITATION_ZOHO_FIELDS.API_KEY],
                moduleKey: limitation[LIMITATION_ZOHO_FIELDS.MODULE],
                value: limitation[LIMITATION_ZOHO_FIELDS.VALUE],
                operation: limitation[LIMITATION_ZOHO_FIELDS.OPERATION],
              });
            });
          } else {
            fields[keyLAS] = rawData[key] ?? null;
          }
        }
      }
    });

    return new Funding({
      ...fields,
    });
  };

  @Mutation(type => Funding)
  public static upsertFunding(@Arg('entity', type => Funding) entity: Funding) {
    return async (gqlFields: BaseQLSelectionSet<Funding>) => {
      const id = await upsertZohoRecord(this.ZOHO_MODULE, entity);
      entity.id = id;
      return entity;
    };
  }

  @Query(type => [Funding], { requestHandlerMeta: { queryCache: { cachePolicy: 'bypass' } } })
  public static getAllFundings() {
    return async (gqlFields: BaseQLSelectionSet<Funding>) => {
      const rawData = await getAllZohoRecords(this.ZOHO_MODULE, {
        fields: this.ZOHO_FIELDS,
      });
      if (!rawData) return [];
      const data = rawData.data.map(funding => this.deserializeZoho(funding));
      return data;
    };
  }

  @Query(type => [Funding], { requestHandlerMeta: { queryCache: { cachePolicy: 'bypass' } } })
  public static getAllFundingsWithLimitations() {
    return async (gqlFields: BaseQLSelectionSet<Funding>) => {
      const fundingIds = await this.getAllFundingIds()();
      const fundings: Funding[] = [];

      for (let i = 0; i < fundingIds.length; i++) {
        const id = fundingIds[i];
        if (!id) continue;
        const funding = await Funding.getFundingById(id)(Funding.allFields);

        fundings.push(funding);
      }

      return fundings;
    };
  }

  @Query(type => [Funding])
  static getAvaiableCompanyFundings(
    @Arg('company', type => Company) company: Company,
    @Arg('allowKeyFundings', type => Boolean) allowKeyFundings?: boolean,
  ) {
    return async (gqlFields: BaseQLSelectionSet<Funding>) => {
      const fundings = await this.getAllFundingsWithLimitations()(Funding.allFields);

      return fundings.filter(funding => {
        if (funding.fundingCode && !allowKeyFundings) return false;
        if (funding.contactId) return false;
        if (funding.validAt > DateTime.now().toJSDate() || funding.validTill < DateTime.now()) return false;

        if (funding.limitations) {
          for (let i = 0; i < funding.limitations.length; i++) {
            const limitation = funding.limitations[i];
            if (!limitation) continue;

            if (limitation.moduleKey !== Company.ZOHO_MODULE) return false;
            if (company[limitation.apiKey] !== limitation.value) return false;
          }
        }

        if (funding.isCompanyFunding) return true;
        if (funding.companyId) {
          if (funding.companyId === company?.id) return true;
          else return false;
        }

        if (funding.isContactFunding) return false;

        return true;
      });
    };
  }

  @Query(type => [Funding])
  static getAvaiableCompanyFinancings(@Arg('school', type => Company) school: Company) {
    return async (gqlFields: BaseQLSelectionSet<Funding>) => {
      const fundings = await this.getAvaiableCompanyFundings(school)(Funding.allFields);
      const financings = fundings.filter(f => f.isFinancing);
      return financings;
    };
  }

  @Query(type => Funding)
  static getAvaiableCompanyFundingWithHighestPrio(@Arg('school', type => Company) school: Company) {
    return async (gqlFields: BaseQLSelectionSet<Funding>) => {
      const fundings = await this.getAvaiableCompanyFundings(school)(Funding.allFields);
      const filteredFundings = fundings.filter(f => !f.isFinancing);
      const highestFundings = new Map<number, Funding>();

      for (const funding of filteredFundings) {
        const currPrio = funding.priority ?? 0;
        const isLowerPrio = Array.from(highestFundings.keys()).every(prio => prio > currPrio);
        const isSamePrio = Array.from(highestFundings.keys()).every(prio => prio === currPrio);

        if (isLowerPrio && !isSamePrio) continue;

        if (funding.maxOrders) {
          const countOrers = await Order.countOrdersByFunding(funding.id)();
          if (countOrers >= funding.maxOrders) continue;
        }

        if (!isSamePrio) highestFundings.clear();
        highestFundings.set(currPrio, funding);
      }

      const funding = Array.from(highestFundings.values())[0];
      return funding;
    };
  }

  @Query(type => Funding)
  public static getFundingById(@Arg('zohoID', type => String) zohoID: string) {
    return async (gqlFields: BaseQLSelectionSet<Funding>) => {
      const rawData = await getZohoRecordByID(this.ZOHO_MODULE, zohoID);
      const deserialized = this.deserializeZoho(rawData);
      return deserialized;
    };
  }

  @Query(type => [String])
  static getAllFundingsId() {
    return async () => {};
  }

  @Query(type => [String])
  public static getAllFundingIds() {
    return async () => {
      const endpoint = `${this.ZOHO_MODULE}/search`;
      const rawData = await makeZohoAPIRequest({
        method: 'get',
        endpoint,
        data: {
          criteria: `((${FUNDING_ZOHO_FIELDS.GUELTIG_VON}:less_equal:${DateTime.now().toFormat('yyyy-LL-dd')})AND(${
            FUNDING_ZOHO_FIELDS.GUELTIG_BIS
          }:greater_equal:${DateTime.now().toFormat("yyyy-LL-dd'T'HH:mm:ss'Z'")}))OR(${
            FUNDING_ZOHO_FIELDS.GUELTIG_VON
          }:equals:null)`,
          fields: [FUNDING_ZOHO_FIELDS.ID].join(','),
        },
        zohoModule: this.ZOHO_MODULE,
      });

      if (!rawData) return [];
      const data = rawData.data.map(data => data.id);
      return data;
    };
  }

  @Query(type => Funding)
  static getFundingByCode(@Arg('key', type => String) key: string, @Arg('school', type => Company) school: Company) {
    return async (gqlFields: BaseQLSelectionSet<Funding>) => {
      delete gqlFields.limitations;

      const result = await makeZohoCOQLRequest({
        moduleName: this.ZOHO_MODULE,
        moduleBiMap: FUNDING_BIMAP,
        gqlFields,
        filter: `(${FUNDING_ZOHO_FIELDS.FUNDING_CODE} = '${key}')AND((${
          FUNDING_ZOHO_FIELDS.GUELTIG_VON
        } <= '${DateTime.now().toFormat('yyyy-LL-dd')}')AND(${
          FUNDING_ZOHO_FIELDS.GUELTIG_BIS
        } >= '${DateTime.now().toFormat("yyyy-LL-dd'T'HH:mm:ss'Z'")}'))`,
      });

      if (!result?.data || !result?.data[0]) return null;

      const availableFundings = await this.getAvaiableCompanyFundings(school, true)({ id: 1 });
      const availableIDs = availableFundings.map(f => f.id);

      const deserializedData = this.deserializeZoho(result.data[0]);
      const id = deserializedData.id;

      if (!availableIDs.includes(id)) return null;

      return this.getFundingById(id)(Funding.allFields);
    };
  }
}

export enum LIMITATION_ZOHO_FIELDS {
  ID = 'id',
  API_KEY = 'API_KEY',
  MODULE = 'Module',
  VALUE = 'Value',
  OPERATION = 'Operation',
}

export const LIMITATION_BIMAP = new BiMap<string, string>([
  ['id', LIMITATION_ZOHO_FIELDS.ID],
  ['apiKey', LIMITATION_ZOHO_FIELDS.API_KEY],
  ['moduleKey', LIMITATION_ZOHO_FIELDS.MODULE],
  ['value', LIMITATION_ZOHO_FIELDS.VALUE],
  ['operation', LIMITATION_ZOHO_FIELDS.OPERATION],
]);

@Entity()
export class Limitation extends ZohoEntity {
  static override _class = 'Limitation';
  static override ZOHO_MODULE = ZohoModule.LIMITATION;
  static override ZOHO_FIELDS = Array.from(FUNDING_BIMAP.values).join(',');

  @Field(type => String, { default: v => v ?? A.getGloballyUniqueID() }) _id!: string;

  @Field(type => String) id!: string;
  @Field(type => String) apiKey!: string;
  @Field(type => String) moduleKey!: string;
  @Field(type => String) value!: string;
  @Field(type => String) operation!: string;

  override serializeZoho = (isNew: boolean = false) => {
    const fields = {};
    const keys = Array.from(LIMITATION_BIMAP.keys);
    keys.forEach(key => {
      const keyZoho = LIMITATION_BIMAP.get(key);
      if (keyZoho && (this[key] || typeof this[key] === 'boolean')) {
        fields[keyZoho] = this[key];
      }
    });
    const data: ZohoRecord<any> = {
      data: [fields],
      trigger: ['workflow'],
    };
    return JSON.stringify(data).replace(/"id":[ ]?"([0-9]+)"/g, '"id":$1');
  };

  static override deserializeZoho = (rawData: any) => {
    const fields = {};
    const keys = Array.from(LIMITATION_BIMAP.reverseKeys);
    keys.forEach(key => {
      const keyLAS = LIMITATION_BIMAP.reverseGet(key);
      if (keyLAS) {
        fields[keyLAS] = rawData[key] ?? null;
      }
    });

    return new Limitation({
      ...fields,
    });
  };

  @Query(type => [Limitation], { requestHandlerMeta: { queryCache: { cachePolicy: 'bypass' } } })
  public static getAllLimitations() {
    return async (gqlFields: BaseQLSelectionSet<Limitation>) => {
      const rawData = await getAllZohoRecords(this.ZOHO_MODULE, {
        fields: this.ZOHO_FIELDS,
      });
      if (!rawData) return [];
      const data = rawData.data.map(limitation => this.deserializeZoho(limitation));
      return data;
    };
  }
}
