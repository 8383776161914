import type { Maybe } from '@adornis/base/utilTypes';
import { CustomContentExtension } from '@adornis/buildify/client/extensions/CustomContentExtension';
import type { BuildifyCustomContentDefinition, BuildifyExtension } from '@adornis/buildify/client/globals/types';
import { XBuildify } from '@adornis/buildify/client/x-buildify';
import { RXController } from '@adornis/chemistry/controllers/RXController';
import { customElement, property, state } from 'lit/decorators.js';
import { filter } from 'rxjs';
import { CurrentUserController } from '../../../client/globals';
import '../../../client/theme/components/d-section';
import { UserRoles } from '../../../db/las-user';
import { getDefaultDubniumBuildifyExtensions } from '../helpers';
import './d-buildify-bar';
import './d-buildify-sidebar';

@customElement('d-buildify')
export class DBuildify extends XBuildify {
  @state() private readonly _currentUser = CurrentUserController(this);
  @property({ attribute: false }) override customContent = new RXController<Maybe<BuildifyCustomContentDefinition>>(
    this,
    CustomContentExtension,
  );
  @property({ attribute: false }) override extensions = new RXController<BuildifyExtension[]>(
    this,
    getDefaultDubniumBuildifyExtensions(),
  );

  override connectedCallback(): void {
    super.connectedCallback();

    this._currentUser.observable.pipe(filter(Boolean)).subscribe(user => {
      const filtered = this.value.value?.filter(base => !base.permission || user.hasRole(UserRoles[base.permission]));
      this.value.next(filtered);
    });
  }
}
