import { registerQuery } from '@adornis/baseql/metadata/register';
import { getCollection } from '@adornis/baseql/server/collections';
import { context } from '@adornis/baseql/server/context';
import { LASUser } from '@adornis/digitale-helden-shared/db/las-user';
import { hashPassword } from '@adornis/users/server/password';
import { checkPermission } from '../../../db/helpers';

export const settingsChangeUserPassword = registerQuery({
  type: () => Boolean,
  operationName: 'settingsChangeUserPassword',
  resolve: (oldPassword: string, newPassword: string) => {
    return async () => {
      if (context.serverContext) throw new Error('change password only accessible on client');
      await checkPermission({ context, permission: 'Users.ChangePassword' });

      const passwordHash = hashPassword(oldPassword);
      const collection = await getCollection<LASUser>(LASUser._class);
      const result = await collection.findOne({ _id: context.userID, password: passwordHash });

      if (!result) throw new Error('Das alte Passwort stimmt nicht überein');

      await LASUser.changePassword(context.userID, newPassword);
    };
  },
  params: [
    { name: 'oldPassword', type: () => String },
    { name: 'newPassword', type: () => String },
  ],
});
