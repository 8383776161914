import { Extension } from '@tiptap/core';
import { DOMParser } from 'prosemirror-model';

export const InsertHTMLExtension = Extension.create({
  name: 'insertHTMLExtension',

  addCommands() {
    return {
      insertHTML:
        value =>
        ({ state, dispatch }) => {
          const { schema, selection } = state;

          const element = document.createElement('div');
          element.innerHTML = value.trim();
          const slice = DOMParser.fromSchema(schema).parseSlice(element);
          const transaction = state.tr.insert(selection.anchor, slice.content);
          dispatch(transaction);
        },
    };
  },
});
