import type { Maybe } from '@adornis/base/utilTypes';
import { Entity, Field } from '@adornis/baseql/decorators';
import { validate } from '@adornis/validation/decorators';
import { nonOptional } from '@adornis/validation/functions/nonOptional';
import { DateTime } from 'luxon';
import { LASPaymentOnAccount } from './LASPaymentOnAccount';

@Entity()
export class LASPaymentOnAccountSplitted extends LASPaymentOnAccount {
  static override _class = 'LASPaymentOnAccountSplitted';
  static override _collectionName = 'payments';

  @validate(nonOptional())
  @Field(type => DateTime, { default: v => v ?? DateTime.now().plus({ months: 6 }) })
  secondMailReminderAt!: DateTime;

  // optionale Felder
  @Field(type => Boolean)
  isFirstHalfPaid?: Maybe<boolean>;

  @Field(type => Boolean)
  isSecondHalfPaid?: Maybe<boolean>;

  @Field(type => Boolean)
  isSecondMailSent?: Maybe<boolean>;
}
