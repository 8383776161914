import { html } from 'lit';
import type { Contact } from '../Contact';
import { emailTemplate } from './e-mail-templates';

export const passwordResetTemplate = (contact: Contact, accessLink: string, hasBeenOrderedByAdmin: boolean = false) =>
  emailTemplate(
    {
      content: html`
        ${hasBeenOrderedByAdmin
          ? html`
              <p style="line-height: 18px; padding-top: 10px;">
                Hallo <strong>${contact.firstName}</strong>,<br />
                ein:e Administrator:in der Helden-Akademie hat für dich einen Passwort-Reset-Link für den Benutzernamen
                <strong>${contact.email}</strong> angefordert. Um dein Passwort zurückzusetzen, rufe bitte den folgenden
                Button-Link auf.
              </p>
            `
          : html`
              <p style="line-height: 18px; padding-top: 10px;">
                Hallo <strong>${contact.firstName}</strong>,<br />
                du hast das Zurücksetzen des Passworts für deinen Campus-Account mit dem Benutzernamen
                <strong>${contact.email}</strong> angefordert. Um dein Passwort zurückzusetzen,
                <a href="${accessLink}" target="_blank"><b style="color:#afca0b;">&nbsp;klicke hier&nbsp;</b></a>
                oder rufe den folgenden Link auf.
              </p>
            `}

        <p style="line-height: 1.2;">
          <span class="button">
            <a href="${accessLink}" target="_blank" style="color:#FFFFFF;">Passwort zurücksetzen </a>
          </span>
        </p>
      `,
    },
    {
      title: html` <p style="line-height: 18px; padding-top: 10px;">Technischer Hinweis</p> `,
      content: html`
        <p style="line-height: 18px; padding-top: 10px;">
          Solltest du Probleme beim Aufrufen des Buttons oder des Links haben, kannst du diesen auch hier kopieren und
          manuell in deinen Browser einfügen:
        </p>
        <div style="padding: 10px; margin-top: 10px; border-radius: 5px; background-color: #f9f9f9;">
          <p style="line-height: 18px">${accessLink}</p>
        </div>
      `,
    },
    {
      title: html` <p style="line-height: 18px; padding-top: 10px;">Hinweis zur Sicherheit</p> `,
      content: html`
        <p style="line-height: 18px; padding-top: 10px;">
          Wurde das Zurücksetzen des Passworts <strong>nicht von dir beantragt,</strong> nimm gerne über unseren
          <strong>IT-Support</strong> Kontakt mit uns auf. Wir verifizieren anschließend, dass hier keine
          Sicherheitslücke besteht und dein Benutzerkonto weiterhin geschützt ist. Gerne kannst du diese E-Mail einfach
          an unseren IT-Support
          <strong
            ><a
              href="mailto:support@campus.de?subject=Technische%20Nachricht%20·%20Akademie%20·%20Support"
              style="color:#afca0b;"
              >support@campus.de</a
            ></strong
          >
          weiterleiten.<br />
          <br />
          Vielen Dank!<br />
          Dein Team Digitale Helden
        </p>
      `,
    },
  );
