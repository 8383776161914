import { Entity, Field } from '@adornis/baseql/decorators';
import { validate } from '@adornis/validation/decorators';
import { nonOptional } from '@adornis/validation/functions/nonOptional';
import type Stripe from 'stripe';
import { LASPayment } from './LASPayment';

@Entity()
export class LASPaymentFunding extends LASPayment {
  static override _class = 'LASPaymentFunding';
  static override _collectionName = 'payments';

  @validate(nonOptional())
  @Field(type => String, { default: v => 'funding' })
  override paymentType!: Stripe.Checkout.SessionCreateParams.PaymentMethodType;
}
