import type { Maybe } from '@adornis/base/utilTypes';
import type { ChemistryLitElement } from '@adornis/chemistry/chemistry-lit-element';
import { ComponentSizeController } from '@adornis/chemistry/controllers/component-size-controller';
import { WindowSizeController } from '@adornis/chemistry/controllers/window-size-controller';
import { type Renderable } from '@adornis/chemistry/renderable';
import { html } from 'lit';
import { LASPromptCheckPassword } from '../../client/prompts/las-prompt-check-password';
import '../../client/theme/components/d-button';
import '../../client/theme/components/d-card';
import { Contact } from '../../db/Contact';
import { LASUser } from '../../db/las-user';

export function _labelContent(content: Renderable, label: string) {
  return html` <d-flex space="xs">
    <d-text>${label}</d-text>
    ${content}
  </d-flex>`;
}

export function _contentBySize(options: { size: WindowSizeController; mobile: any; desktop: any }) {
  if (options.size.mobile && options.mobile !== undefined) {
    return options.mobile;
  }

  return options.desktop;
}

export function _defaultColumnsBySize(size: WindowSizeController) {
  return _contentBySize({ size, mobile: '1', desktop: '2' });
}

export function DefaultWindowSizeController(host: ChemistryLitElement) {
  return new WindowSizeController(host, { debounceTime: 250 });
}

export function DefaultComponentSizeController(host: ChemistryLitElement) {
  return new ComponentSizeController(host, { debounceTime: 250 });
}

export async function _hasPermissionForEmail(email: string, password: Maybe<string>) {
  return await new Promise<Maybe<string>>(async (resolve, reject) => {
    const existingContact = await Contact.getContactByEmailCOQL(email)(Contact.allFields);
    if (!existingContact) return resolve(null);
    const existingUserForEmail = await LASUser.getLASUserByEmail(email)(LASUser.allFields);
    if (!existingUserForEmail) return resolve(null);

    if (password && (await LASUser.checkPassword(existingUserForEmail._id, password)())) return resolve(password);
    password = await LASPromptCheckPassword.showPopup({
      props: { lasUser: existingUserForEmail },
      closeOnNavigate: false,
    });
    if (password) return resolve(password);
    reject(new Error('Falsches Passwort wurde eingegeben.'));
  });
}
