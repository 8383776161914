import { type Maybe } from '@adornis/base/utilTypes';
import { RXController } from '@adornis/chemistry/controllers/RXController.js';
import { css } from '@adornis/chemistry/directives/css';
import { darken } from '@adornis/chemistry/elements/theming/color-shades';
import { FormField } from '@adornis/formfield/form-field';
import { XInput } from '@adornis/forms/x-input';
import { getFlagByLangAbbreviation } from '@adornis/internationalization/client/language-picker/flag-helper';
import { XDropdownSelection } from '@adornis/popover/x-dropdown-selection';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import './d-formfield-wrapper';
import './fonts';

interface TelInputDropdownSelectionValue {
  country: string;
  countryCode: string;
}

export interface TelInputValue extends TelInputDropdownSelectionValue {
  number: string | undefined;
  telString: string | undefined;
}

/**
 * @element d-tel-input
 */
@customElement('d-tel-input')
export class DubniumTelInput extends FormField<TelInputValue> {
  override value = new RXController<Maybe<TelInputValue>>(this, {
    country: 'de',
    countryCode: '+49',
    number: undefined,
    telString: undefined,
  });

  private readonly _dropdownValue = new RXController<TelInputDropdownSelectionValue>(this, {
    country: 'de',
    countryCode: '+49',
  });
  private readonly _numberInputValue = new RXController<string>(this, '');

  private readonly _selectables = [
    { country: 'de', countryCode: '+49' },
    { country: 'au', countryCode: '+43' },
    { country: 'ch', countryCode: '+41' },
    { country: 'fr', countryCode: '+33' },
    { country: 'it', countryCode: '+39' },
    { country: 'es', countryCode: '+34' },
    { country: 'gb', countryCode: '+44' },
    { country: 'us', countryCode: '+1' },
  ];

  override styles() {
    return [...super.styles(), { ':host': {} }];
  }

  override render() {
    return html`
      <d-flex horizontal crossaxis-center>
        <d-tel-input-dropdown-selection
          .value=${{ country: this.value.value?.country, countryCode: this.value.value?.countryCode }}
          .selectables=${this._selectables}
          @value-picked=${(e: CustomEvent<{ value: TelInputDropdownSelectionValue }>) => {
            this._dropdownValue.next(e.detail.value);
            this._combineValues();
          }}
          .renderItem=${(item: { country: string; countryCode: string }) => {
            return html`
              <d-flex
                horizontal
                crossaxis-center
                ${css({ gap: this.spacing.xxs, width: '60px', padding: `${this.spacing.md} ${this.spacing.sm}` })}
              >
                <img src=${getFlagByLangAbbreviation(item.country)} ${css({ height: '1em' })} />
                <d-text flex ${css({ textAlign: 'center' })}>${item.countryCode}</d-text>
              </d-flex>
            `;
          }}
          .renderString=${(item: { country: string; countryCode: string }) => {
            return html`
              <d-flex horizontal ${css({ gap: this.spacing.xxs })} crossaxis-center>
                <img src=${getFlagByLangAbbreviation(item.country)} ${css({ height: '1em' })} />
                <d-text flex ${css({ textAlign: 'center' })}>${item.countryCode}</d-text>
              </d-flex>
            `;
          }}
        ></d-tel-input-dropdown-selection>
        <d-tel-number-input
          .value=${this.value.value?.number}
          @value-picked="${(e: CustomEvent<{ value: string }>) => {
            if (this._validateTelString(e.detail.value)) {
              this._numberInputValue.next(e.detail.value);
              this._combineValues();
            } else {
              this._numberInputValue.next('');
              this.value.next({
                country: this._dropdownValue.value.country || '',
                countryCode: this._dropdownValue.value.countryCode || '',
                number: '',
                telString: '',
              });

              this.requestUpdate();
              this._combineValues();
            }
          }}"
        ></d-tel-number-input>
      </d-flex>
    `;
  }

  private _combineValues() {
    this.value.next({
      country: this._dropdownValue.value.country || '',
      countryCode: this._dropdownValue.value.countryCode || '',
      number: this._numberInputValue.value,
      telString: `${this._dropdownValue.value.countryCode || ''}${this._numberInputValue.value || ''}`,
    });
    this.dispatchEvent(new CustomEvent('value-picked', { detail: { value: this.value.value } }));
  }

  private _validateTelString(telString: string) {
    const regex = /^[1-9][0-9 ]{5,30}$/;
    return regex.test(telString);
  }
}

/**
 * @element d-tel-input-dropdown-selection
 */
@customElement('d-tel-input-dropdown-selection')
export class DubniumTelDropdownSelection extends XDropdownSelection<string> {
  constructor() {
    super();
    this['placeholder-mode'] = 'hide';
  }

  protected override _icon() {
    return 'arrow_drop_down';
  }

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          borderRadius: `${this.sizes.borderRadiusSecondary}`,
          '--placeholder-color': '#807f80',
        },
        ':host #trigger': {
          paddingTop: '1.1rem !important',
          paddingBottom: '1.1rem !important',
          paddingLeft: '.5rem !important',
          paddingRight: '.5rem !important',
          background: `${darken(this.colors.tone.whiteInput?.string ?? '#fff', 1)} !important`,
          borderRadius: `${this.sizes.borderRadiusSecondary} !important`,
          borderTopRightRadius: '0 !important',
          borderBottomRightRadius: '0 !important',
          border: '0 !important',
          color: this.colors.black,
          gap: '0',
          fontSize: '20px !important',
        },
        ':host d-text': {
          fontSize: '1rem',
        },
      },
    ];
  }
}

@customElement('d-tel-number-input')
export class DubniumTelNumberInput extends XInput {
  constructor() {
    super();
    this['placeholder-mode'] = 'hide';
  }
  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          flex: '1 1 auto',
          '--placeholder-color': '#807f80',
          'd-flex > d-flex': {
            paddingTop: '1.1rem',
            paddingBottom: '1.1rem',
            paddingLeft: '.625rem',
            paddingRight: '.625rem',
            border: '0 !important',
            borderRadius: this.sizes.borderRadiusSecondary,
            color: this.focused ? '#1d1d1b' : '#807f80',
            height: 'unset',
            transition: 'box-shadow .5s,border-color .25s ease-in-out,-webkit-box-shadow .5s',
            backgroundColor: this.focused ? this.colors.white : this.colors.tone.whiteInput,
          },
        },
        ':host d-text': {
          fontSize: '1rem',
        },
      },
    ];
  }
}
