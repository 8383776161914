import '@adornis/buildify/client/components/x-buildify-permission-selection';
import { TextExtension } from '@adornis/buildify/client/extensions/TextExtension';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import { BaseH4 } from '@adornis/buildify/db/extensions/text/BaseH4';
import type { AdornisTiptapExtension } from '@adornis/tiptap/client/extensions/ExtensionBase';

export const BuildifyCampusH4Extension = (tiptapExtensions: AdornisTiptapExtension[][]): BuildifyExtension<BaseH4> => ({
  class: BaseH4,
  toolbar: { icon: 'h4', text: { default: 'H4' } },
  group: 'Schriften',
  render: TextExtension(tiptapExtensions).render,
  editor: TextExtension(tiptapExtensions).editor,
});
