import { Entity, Field } from '@adornis/baseql/decorators';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity';
import { Contact } from '@adornis/digitale-helden-shared/db/Contact';
import { GroupContactRelation } from '../Relations/GroupContactRelation';

@Entity()
export class GroupContactRelationData extends AdornisEntity {
  static override _class = 'GroupContactRelationData';

  @Field(type => Contact) contact!: Contact;
  @Field(type => GroupContactRelation) relation!: GroupContactRelation;
}
