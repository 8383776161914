import { registerMutation } from '@adornis/baseql/metadata/register';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { Lead } from '@adornis/digitale-helden-shared/db/Lead';
import { UpsertContactOptions } from '@adornis/digitale-helden-shared/db/UpsertContactOptions';
import { ContactEmailStatus } from '@adornis/digitale-helden-shared/db/enums';
import { getLeadEmailStatusByEmail } from './getLeadEmailStatusByEmail';
import { sendLeadDOIMail } from './sendLeadDOIMail';
import { upsertLead } from './upsertLead';

export const upsertLeadAndCheckDOI = registerMutation({
  type: () => Lead,
  operationName: 'upsertLeadAndCheckDOI',
  resolve: (instance: Lead, createAccount: boolean, options?: UpsertContactOptions) => {
    return async (gqlFields: BaseQLSelectionSet<Lead>) => {
      try {
        const emailStatus = await getLeadEmailStatusByEmail(instance.email)();
        instance.emailStatus = emailStatus;
      } catch {}
      instance = await upsertLead(instance, options)(Lead.allFields);

      if (instance.emailStatus !== ContactEmailStatus.DOI) {
        await sendLeadDOIMail(instance, createAccount)();

        if (instance.emailStatus !== ContactEmailStatus.SOI) {
          instance.emailStatus = ContactEmailStatus.OPT_IN_PENDING;
          instance = await upsertLead(instance, options)(Lead.allFields);
        }
      }

      return instance;
    };
  },
  params: [
    { name: 'instance', type: () => Lead },
    { name: 'createLASUser', type: () => Boolean },
    { name: 'options', type: () => UpsertContactOptions },
  ],
});
