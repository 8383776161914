import { registerMutation } from '@adornis/baseql/metadata/register';
import { Contact } from '@adornis/digitale-helden-shared/db/Contact';
import { LASUser } from '@adornis/digitale-helden-shared/db/las-user';
import { UserRoles } from '@adornis/digitale-helden-shared/db/permissions';
import { GLOBAL_CONTEXT } from '@adornis/users/db/a-roles';
import { assignRoleToUser } from '@adornis/users/server/roles';
import { upsertContact } from '../../contact/mutations/upsertContact';
import { getContactByIDCOQL } from '../../contact/queries/getContactByIDCOQL';

export const registerUserWithZoho = registerMutation({
  type: () => String,
  operationName: 'registerUserWithZoho',
  resolve: (email: string, password: string, contactData: Contact, isAdmin?: boolean) => {
    return async () => {
      const id = await LASUser.requestRegisterUser(new LASUser({ username: email, email }), password, false)();
      await ensureUserOnZoho(id, contactData);
      if (isAdmin) await assignRoleToUser(id, UserRoles.ADMIN, [GLOBAL_CONTEXT]);

      return id;
    };
  },
  params: [
    { name: 'email', type: () => String },
    { name: 'password', type: () => String },
    { name: 'contactData', type: () => Contact },
    { name: 'isAdmin', type: () => Boolean },
  ],
});

async function ensureUserOnZoho(userID: string, contact?: Contact) {
  const user = await LASUser.getByID<LASUser>(userID)({ zohoID: 1 });
  if (!user) throw new Error(`User ${userID} not found`);
  const existingContact = user.zohoID ? getContactByIDCOQL(user.zohoID) : null;
  if (existingContact) return;

  if (!contact) throw new Error('Need contact information when creating user on Zoho.');
  const contactID = (await upsertContact(contact)({ id: 1 })).id;
  if (!contactID) throw new Error('Expecting ID to be there after upsert but it isnt.');
  user.zohoID = contactID;
  await user.save();
}
