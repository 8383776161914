import { registerQuery } from '@adornis/baseql/metadata/register';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { Product } from '../../../db/Product';
import { getAllProducts } from './getAllProducts';

//* Resolver
const getProductsByIDsResolver = (ids: string[], onlyActive?: boolean) => {
  return async (gqlFields: BaseQLSelectionSet<Product>) => {
    if (!ids || ids.length === 0) return [];
    const allProducts = await getAllProducts()(Product.allFields);
    const filteredProducts = allProducts.filter(p => ids.includes(p.id));
    return filteredProducts;
  };
};

//* Query
export const getProductsByIDs = registerQuery({
  type: () => [Product],
  operationName: 'getProductsByIDs',
  resolve: getProductsByIDsResolver,
  params: [
    { type: () => [String], name: 'ids' },
    { type: () => Boolean, name: 'onlyActive' },
  ],
});
