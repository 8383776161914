import { ButtonExtension } from '@adornis/buildify/client/extensions/ButtonExtension';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import { html } from 'lit';
import { DubniumBaseButton } from '../../../../../db/buildify/DubniumBaseButton';
import './campus-button-extension';

export const BuildifyCampusButtonExtension: BuildifyExtension<DubniumBaseButton> = {
  class: DubniumBaseButton,
  toolbar: ButtonExtension.toolbar,
  render(instance, { defaultValues, isSubelement, dropzoneBefore, dropzoneAfter }) {
    return html`
      <campus-button-extension
        .content=${instance}
        .defaultValues=${defaultValues}
        ?sub=${isSubelement}
        .hasDropzoneBefore=${dropzoneBefore}
        .hasDropzoneAfter=${dropzoneAfter}
      ></campus-button-extension>
    `;
  },
  editor({ contentController, controllerBaseKeyPath }) {
    return html`
      <d-flex space="sm">
        <d-checkbox
          ${contentController.field(...controllerBaseKeyPath, 'isDigitalEmergency')}
          .label=${'Digitaler Notfall?'}
        ></d-checkbox>
        <d-input ${contentController.field(...controllerBaseKeyPath, 'display')} placeholder="Display"></d-input>
        <d-input ${contentController.field(...controllerBaseKeyPath, 'margin')} placeholder="Margin"></d-input>
        <d-input ${contentController.field(...controllerBaseKeyPath, 'padding')} placeholder="Padding"></d-input>
        <d-input ${contentController.field(...controllerBaseKeyPath, 'text')} placeholder="Text"></d-input>
        <d-checkbox
          ${contentController.field(...controllerBaseKeyPath, 'invert')}
          .label=${'Invertieren?'}
        ></d-checkbox>

        <d-link-editor ${contentController.field(...controllerBaseKeyPath, 'link')}></d-link-editor>

        <!-- ausrichtung -->
        <d-dropdown-selection
          clearable
          .selectables=${['center']}
          ${contentController.field(...controllerBaseKeyPath, 'alignHorizontal')}
          placeholder="Ausrichtung horizontal"
        ></d-dropdown-selection>
        <d-dropdown-selection
          clearable
          .selectables=${['center']}
          ${contentController.field(...controllerBaseKeyPath, 'alignVertical')}
          placeholder="Ausrichtung vertikal"
        ></d-dropdown-selection>
      </d-flex>
    `;
  },
  group: 'Basics',
};
