import type { Maybe } from '@adornis/base/utilTypes';
import { Entity, Field } from '@adornis/baseql/decorators';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity';
import { CampusRoute, PathToCampusRoute } from '../../_routing/db/enums';
import { DubniumPage } from './DubniumPage';

@Entity()
export class Link extends AdornisEntity {
  static override _class = 'Link';

  @Field(type => String)
  link?: Maybe<string>;

  @Field(type => String)
  target?: Maybe<'_blank' | '_self'>;

  // resolver
  @Field(type => String, {
    resolve(this: Link) {
      return async () => {
        try {
          if (!this.link?.startsWith(':page-')) throw new Error();

          // extract pageid from link
          const pageID = this.link.split('-')[1];

          if (!pageID) throw new Error();

          // get entities
          const rootPage = await DubniumPage.getRootPageOfSubpage(pageID)({
            _id: 1,
            path: 1,
            productIDs: 1,
          });
          const page = await DubniumPage.getByID<DubniumPage>(pageID)({
            _id: 1,
            path: 1,
            parentPageID: 1,
          });

          if (!rootPage || !page) throw new Error();

          const isContentPage = rootPage.productIDs.length > 0;

          if (isContentPage) {
            return `${PathToCampusRoute(CampusRoute.CONTENT)}${
              rootPage._id === page._id ? rootPage.path : `${rootPage.path}${page.path}`
            }`;
          }

          return page.path;
        } catch {
          return this.link;
        }
      };
    },
  })
  resolvedLink?: Maybe<string>;
}
