import { ListExtension } from '@adornis/buildify/client/extensions/ListExtension';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import { html, nothing } from 'lit';
import { DubniumBaseList } from '../../../../../db/buildify/DubniumBaseList';
import './campus-list-extension';
import { LASPromptSwapIndexList } from './las-prompt-swap-index-list';

export const BuildifyCampusListExtension = ({
  textExtension,
  iconExtension,
}: {
  textExtension: BuildifyExtension;
  iconExtension: BuildifyExtension;
}): BuildifyExtension<DubniumBaseList> => {
  return {
    class: DubniumBaseList,
    toolbar: ListExtension({ textExtension, iconExtension }).toolbar,
    render(instance, { defaultValues, isSubelement, dropzoneBefore, dropzoneAfter }) {
      return html`
        <campus-list-extension
          .textExtension=${textExtension}
          .iconExtension=${iconExtension}
          .content=${instance}
          .defaultValues=${defaultValues}
          ?sub=${isSubelement}
          .hasDropzoneBefore=${dropzoneBefore}
          .hasDropzoneAfter=${dropzoneAfter}
        ></campus-list-extension>
      `;
    },
    editor({ content, contentController, host, controllerBaseKeyPath }) {
      return html`
        <d-flex space="lg">
          <d-flex space="sm">
            <d-text> Aufzählungszeichen </d-text>
            <d-checkbox
              .label=${'Bindestrich'}
              ${contentController.field(...controllerBaseKeyPath, 'isHyphen')}
            ></d-checkbox>
            ${!content.value('isHyphen', contentController.size)
              ? iconExtension.editor?.({
                  content: content.icon,
                  contentController,
                  host,
                  controllerBaseKeyPath: [...controllerBaseKeyPath, 'icon'],
                })
              : nothing}
          </d-flex>
          <d-button
            @click=${async () => {
              const items = content.items;
              if (!items) return;
              const resorted = await LASPromptSwapIndexList.swapIndexes(items);
              // @ts-ignore
              if (resorted) content.items = resorted;
              host.requestUpdate();
            }}
          >
            Reihenfolge verändern
          </d-button>
          <d-flex space="sm">
            <d-flex crossaxis-center space-between horizontal space="sm" wrap>
              <d-text bold> Text </d-text>
              <d-icon
                pointer
                @click=${() => {
                  content.addText();
                  host.requestUpdate();
                }}
              >
                plus
              </d-icon>
            </d-flex>
            ${content.items.map(
              (textContent, index) => html`
                <d-flex crossaxis-center space-between horizontal space="sm" wrap>
                  <d-text> ${index + 1}. </d-text>
                  <d-icon
                    pointer
                    @click=${() => {
                      content.items.splice(index, 1);
                      host.requestUpdate();
                    }}
                  >
                    trash
                  </d-icon>
                </d-flex>
                ${textExtension.editor?.({
                  content: textContent,
                  contentController,
                  host,
                  controllerBaseKeyPath: [...controllerBaseKeyPath, 'items', index],
                })}
              `,
            )}
          </d-flex>
        </d-flex>
      `;
    },
    group: 'Basics',
  };
};
