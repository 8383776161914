import type { Maybe } from '@adornis/base/utilTypes';
import { Entity, Query } from '@adornis/baseql/decorators.js';
import type { EntityData } from '@adornis/baseql/entities/types';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { CONTACT_BIMAP, CONTACT_ZOHO_FIELDS, Contact } from '@adornis/digitale-helden-shared/db/Contact';
import { makeZohoCOQLRequest } from '@adornis/digitale-helden-shared/server/zoho/api';
import { ConvertConfig, FieldMappingEntry } from '@adornis/import-export/db/convert-config.js';
import { ExportJob } from '@adornis/import-export/db/export-job.js';
import { exportCSVFromEntities } from '@adornis/import-export/server/export-internals.js';
import fs from 'fs/promises';

/**
 * Caches the result of a async operation that is related to some key. This can be used to fetch foreign
 * data in a export field and don't re-fetch it in another fields that needs that data.
 * @param fetchFn The function to fetch something by a key
 * @returns Maybe cached value if the fetch function returned something truthy
 */
export function newOperationCache<T>(fetchFn: (id: string) => Promise<Maybe<T>>) {
  const cache: Record<string, Maybe<T>> = {};

  const get = async (id: string): Promise<Maybe<T>> => {
    if (id === '') {
      return null;
    }

    if (cache[id]) {
      return cache[id];
    }

    const elem = await fetchFn(id);
    cache[id] = elem;
    return elem;
  };

  const toString = async (id: string, fmt: (elem: T) => Promise<string>, ifEmpty: string = ''): Promise<string> => {
    const elem = await get(id);
    if (!elem) {
      return ifEmpty;
    }
    return fmt(elem);
  };

  return {
    get,
    toString,
  };
}

@Entity()
export class DATEVDebitorenConvertConfig extends ConvertConfig<Contact> {
  static override _class = 'DATEVDebitorenConvertConfig';

  constructor() {
    super({
      className: Contact._class,
      delimiter: ';',
      fieldMappings: [
        new FieldMappingEntry({ input: 'Konto', output: null }, undefined, async (_, data: Contact) =>
          data.getDebitorennummer(),
        ),
        new FieldMappingEntry({ input: 'Name (Adressattyp Unternehmen)', output: null }),
        new FieldMappingEntry({ input: 'Unternehmensgegenstand', output: null }),
        new FieldMappingEntry(
          { input: 'Name (Adressattyp natürl. Person)', output: null },
          undefined,
          (_, data: EntityData<Contact>) => data.lastName ?? '',
        ),
        new FieldMappingEntry(
          { input: 'Vorname (Adressattyp natürl. Person)', output: null },
          undefined,
          (_, data: EntityData<Contact>) => data.firstName ?? '',
        ),
        new FieldMappingEntry({ input: 'Name (Adressattyp keine Angabe)', output: null }),
        new FieldMappingEntry({ input: 'Adressattyp', output: null }, undefined, () => '1'),
        new FieldMappingEntry({ input: 'Kurzbezeichnung', output: null }),
        new FieldMappingEntry({ input: 'EU-Land', output: null }),
        new FieldMappingEntry({ input: 'EU-UStID', output: null }),
        new FieldMappingEntry({ input: 'Anrede', output: null }),
        new FieldMappingEntry({ input: 'Titel/Akad. Grad', output: null }),
        new FieldMappingEntry({ input: 'Adelstitel', output: null }),
        new FieldMappingEntry({ input: 'Namensvorsatz', output: null }),
        new FieldMappingEntry({ input: 'Adressart', output: null }),
        new FieldMappingEntry({ input: 'Straße', output: null }),
        new FieldMappingEntry({ input: 'Postfach', output: null }),
        new FieldMappingEntry({ input: 'Postleitzahl', output: null }),
        new FieldMappingEntry({ input: 'Ort', output: null }),
        new FieldMappingEntry({ input: 'Land', output: null }),
        new FieldMappingEntry({ input: 'Versandzusatz', output: null }),
        new FieldMappingEntry({ input: 'Adresszusatz', output: null }),
        new FieldMappingEntry({ input: 'Abweichende Anrede', output: null }),
        new FieldMappingEntry({ input: 'Abw. Zustellbezeichnung 1', output: null }),
        new FieldMappingEntry({ input: 'Abw. Zustellbezeichnung 2', output: null }),
        new FieldMappingEntry({ input: 'Kennz. Korrespondenzadresse', output: null }),
        new FieldMappingEntry({ input: 'Adresse Gültig von', output: null }),
        new FieldMappingEntry({ input: 'Adresse Gültig bis', output: null }),
        new FieldMappingEntry({ input: 'Telefon', output: null }),
        new FieldMappingEntry({ input: 'Bemerkung (Telefon)', output: null }),
        new FieldMappingEntry({ input: 'Telefon GL', output: null }),
        new FieldMappingEntry({ input: 'Bemerkung (Telefon GL)', output: null }),
        new FieldMappingEntry({ input: 'E-Mail', output: null }),
        new FieldMappingEntry({ input: 'Bemerkung (E-Mail)', output: null }),
        new FieldMappingEntry({ input: 'Internet', output: null }),
        new FieldMappingEntry({ input: 'Bemerkung (Internet)', output: null }),
        new FieldMappingEntry({ input: 'Fax', output: null }),
        new FieldMappingEntry({ input: 'Bemerkung (Fax)', output: null }),
        new FieldMappingEntry({ input: 'Sonstige', output: null }),
        new FieldMappingEntry({ input: 'Bemerkung (Sonstige)', output: null }),
        new FieldMappingEntry({ input: 'Bankleitzahl 1', output: null }),
        new FieldMappingEntry({ input: 'Bankbezeichnung 1', output: null }),
        new FieldMappingEntry({ input: 'Bank-Kontonummer 1', output: null }),
        new FieldMappingEntry({ input: 'Länderkennzeichen 1', output: null }),
        new FieldMappingEntry({ input: 'IBAN-Nr. 1', output: null }),
        new FieldMappingEntry({ input: 'IBAN1 korrekt', output: null }),
        new FieldMappingEntry({ input: 'SWIFT-Code 1', output: null }),
        new FieldMappingEntry({ input: 'Abw. Kontoinhaber 1', output: null }),
        new FieldMappingEntry({ input: 'Kennz. Hauptbankverb. 1', output: null }),
        new FieldMappingEntry({ input: 'Bankverb 1 Gültig von', output: null }),
        new FieldMappingEntry({ input: 'Bankverb 1 Gültig bis', output: null }),
        new FieldMappingEntry({ input: 'Bankleitzahl 2', output: null }),
        new FieldMappingEntry({ input: 'Bankbezeichnung 2', output: null }),
        new FieldMappingEntry({ input: 'Bank-Kontonummer 2', output: null }),
        new FieldMappingEntry({ input: 'Länderkennzeichen 2', output: null }),
        new FieldMappingEntry({ input: 'IBAN-Nr. 2', output: null }),
        new FieldMappingEntry({ input: 'IBAN2 korrekt', output: null }),
        new FieldMappingEntry({ input: 'SWIFT-Code 2', output: null }),
        new FieldMappingEntry({ input: 'Abw. Kontoinhaber 2', output: null }),
        new FieldMappingEntry({ input: 'Kennz. Hauptbankverb. 2', output: null }),
        new FieldMappingEntry({ input: 'Bankverb 2 Gültig von', output: null }),
        new FieldMappingEntry({ input: 'Bankverb 2 Gültig bis', output: null }),
        new FieldMappingEntry({ input: 'Bankleitzahl 3', output: null }),
        new FieldMappingEntry({ input: 'Bankbezeichnung 3', output: null }),
        new FieldMappingEntry({ input: 'Bank-Kontonummer 3', output: null }),
        new FieldMappingEntry({ input: 'Länderkennzeichen 3', output: null }),
        new FieldMappingEntry({ input: 'IBAN-Nr. 3', output: null }),
        new FieldMappingEntry({ input: 'IBAN3 korrekt', output: null }),
        new FieldMappingEntry({ input: 'SWIFT-Code 3', output: null }),
        new FieldMappingEntry({ input: 'Abw. Kontoinhaber 3', output: null }),
        new FieldMappingEntry({ input: 'Kennz. Hauptbankverb. 3', output: null }),
        new FieldMappingEntry({ input: 'Bankverb 3 Gültig von', output: null }),
        new FieldMappingEntry({ input: 'Bankverb 3 Gültig bis', output: null }),
        new FieldMappingEntry({ input: 'Bankleitzahl 4', output: null }),
        new FieldMappingEntry({ input: 'Bankbezeichnung 4', output: null }),
        new FieldMappingEntry({ input: 'Bank-Kontonummer 4', output: null }),
        new FieldMappingEntry({ input: 'Länderkennzeichen 4', output: null }),
        new FieldMappingEntry({ input: 'IBAN-Nr. 4', output: null }),
        new FieldMappingEntry({ input: 'IBAN4 korrekt', output: null }),
        new FieldMappingEntry({ input: 'SWIFT-Code 4', output: null }),
        new FieldMappingEntry({ input: 'Abw. Kontoinhaber 4', output: null }),
        new FieldMappingEntry({ input: 'Kennz. Hauptbankverb. 4', output: null }),
        new FieldMappingEntry({ input: 'Bankverb 4 Gültig von', output: null }),
        new FieldMappingEntry({ input: 'Bankverb 4 Gültig bis', output: null }),
        new FieldMappingEntry({ input: 'Bankleitzahl 5', output: null }),
        new FieldMappingEntry({ input: 'Bankbezeichnung 5', output: null }),
        new FieldMappingEntry({ input: 'Bank-Kontonummer 5', output: null }),
        new FieldMappingEntry({ input: 'Länderkennzeichen 5', output: null }),
        new FieldMappingEntry({ input: 'IBAN-Nr. 5', output: null }),
        new FieldMappingEntry({ input: 'IBAN5 korrekt', output: null }),
        new FieldMappingEntry({ input: 'SWIFT-Code 5', output: null }),
        new FieldMappingEntry({ input: 'Abw. Kontoinhaber 5', output: null }),
        new FieldMappingEntry({ input: 'Kennz. Hauptbankverb. 5', output: null }),
        new FieldMappingEntry({ input: 'Bankverb 5 Gültig von', output: null }),
        new FieldMappingEntry({ input: 'Bankverb 5 Gültig bis', output: null }),
        new FieldMappingEntry({ input: 'Leerfeld', output: null }),
        new FieldMappingEntry({ input: 'Briefanrede', output: null }),
        new FieldMappingEntry({ input: 'Grußformel', output: null }),
        new FieldMappingEntry({ input: 'Kundennummer', output: null }),
        new FieldMappingEntry({ input: 'Steuernummer', output: null }),
        new FieldMappingEntry({ input: 'Sprache', output: null }),
        new FieldMappingEntry({ input: 'Ansprechpartner', output: null }),
        new FieldMappingEntry({ input: 'Vertreter', output: null }),
        new FieldMappingEntry({ input: 'Sachbearbeiter', output: null }),
        new FieldMappingEntry({ input: 'Diverse-Konto', output: null }),
        new FieldMappingEntry({ input: 'Ausgabeziel', output: null }),
        new FieldMappingEntry({ input: 'Währungssteuerung', output: null }),
        new FieldMappingEntry({ input: 'Kreditlimit (Debitor)', output: null }),
        new FieldMappingEntry({ input: 'Zahlungsbedingung', output: null }),
        new FieldMappingEntry({ input: 'Fälligkeit in Tagen (Debitor)', output: null }),
        new FieldMappingEntry({ input: 'Skonto in Prozent (Debitor)', output: null }),
        new FieldMappingEntry({ input: 'Kreditoren-Ziel 1 Tg.', output: null }),
        new FieldMappingEntry({ input: 'Kreditoren-Skonto 1 %', output: null }),
        new FieldMappingEntry({ input: 'Kreditoren-Ziel 2 Tg.', output: null }),
        new FieldMappingEntry({ input: 'Kreditoren-Skonto 2 %', output: null }),
        new FieldMappingEntry({ input: 'Kreditoren-Ziel 3 Brutto Tg.', output: null }),
        new FieldMappingEntry({ input: 'Kreditoren-Ziel 4 Tg.', output: null }),
        new FieldMappingEntry({ input: 'Kreditoren-Skonto 4 %', output: null }),
        new FieldMappingEntry({ input: 'Kreditoren-Ziel 5 Tg.', output: null }),
        new FieldMappingEntry({ input: 'Kreditoren-Skonto 5 %', output: null }),
        new FieldMappingEntry({ input: 'Mahnung', output: null }),
        new FieldMappingEntry({ input: 'Kontoauszug', output: null }),
        new FieldMappingEntry({ input: 'Mahntext 1', output: null }),
        new FieldMappingEntry({ input: 'Mahntext 2', output: null }),
        new FieldMappingEntry({ input: 'Mahntext 3', output: null }),
        new FieldMappingEntry({ input: 'Kontoauszugstext', output: null }),
        new FieldMappingEntry({ input: 'Mahnlimit Betrag', output: null }),
        new FieldMappingEntry({ input: 'Mahnlimit %', output: null }),
        new FieldMappingEntry({ input: 'Zinsberechnung', output: null }),
        new FieldMappingEntry({ input: 'Mahnzinssatz 1', output: null }),
        new FieldMappingEntry({ input: 'Mahnzinssatz 2', output: null }),
        new FieldMappingEntry({ input: 'Mahnzinssatz 3', output: null }),
        new FieldMappingEntry({ input: 'Lastschrift', output: null }),
        new FieldMappingEntry({ input: 'Verfahren', output: null }),
        new FieldMappingEntry({ input: 'Mandantenbank', output: null }),
        new FieldMappingEntry({ input: 'Zahlungsträger', output: null }),
        new FieldMappingEntry({ input: 'Indiv. Feld 1', output: null }),
        new FieldMappingEntry({ input: 'Indiv. Feld 2', output: null }),
        new FieldMappingEntry({ input: 'Indiv. Feld 3', output: null }),
        new FieldMappingEntry({ input: 'Indiv. Feld 4', output: null }),
        new FieldMappingEntry({ input: 'Indiv. Feld 5', output: null }),
        new FieldMappingEntry({ input: 'Indiv. Feld 6', output: null }),
        new FieldMappingEntry({ input: 'Indiv. Feld 7', output: null }),
        new FieldMappingEntry({ input: 'Indiv. Feld 8', output: null }),
        new FieldMappingEntry({ input: 'Indiv. Feld 9', output: null }),
        new FieldMappingEntry({ input: 'Indiv. Feld 10', output: null }),
        new FieldMappingEntry({ input: 'Indiv. Feld 11', output: null }),
        new FieldMappingEntry({ input: 'Indiv. Feld 12', output: null }),
        new FieldMappingEntry({ input: 'Indiv. Feld 13', output: null }),
        new FieldMappingEntry({ input: 'Indiv. Feld 14', output: null }),
        new FieldMappingEntry({ input: 'Indiv. Feld 15', output: null }),
        new FieldMappingEntry({ input: 'Abweichende Anrede (Rechnungsadresse)', output: null }),
        new FieldMappingEntry({ input: 'Adressart (Rechnungsadresse)', output: null }),
        new FieldMappingEntry({ input: 'Straße (Rechnungsadresse)', output: null }),
        new FieldMappingEntry({ input: 'Postfach (Rechnungsadresse)', output: null }),
        new FieldMappingEntry({ input: 'Postleitzahl (Rechnungsadresse)', output: null }),
        new FieldMappingEntry({ input: 'Ort (Rechnungsadresse)', output: null }),
        new FieldMappingEntry({ input: 'Land (Rechnungsadresse)', output: null }),
        new FieldMappingEntry({ input: 'Versandzusatz (Rechnungsadresse)', output: null }),
        new FieldMappingEntry({ input: 'Adresszusatz (Rechnungsadresse)', output: null }),
        new FieldMappingEntry({ input: 'Abw. Zustellbezeichnung 1 (Rechnungsadresse)', output: null }),
        new FieldMappingEntry({ input: 'Abw. Zustellbezeichnung 2 (Rechnungsadresse)', output: null }),
        new FieldMappingEntry({ input: 'Adresse Gültig von (Rechnungsadresse)', output: null }),
        new FieldMappingEntry({ input: 'Adresse Gültig bis (Rechnungsadresse)', output: null }),
        new FieldMappingEntry({ input: 'Bankleitzahl 6', output: null }),
        new FieldMappingEntry({ input: 'Bankbezeichnung 6', output: null }),
        new FieldMappingEntry({ input: 'Bank-Kontonummer 6', output: null }),
        new FieldMappingEntry({ input: 'Länderkennzeichen 6', output: null }),
        new FieldMappingEntry({ input: 'IBAN-Nr. 6', output: null }),
        new FieldMappingEntry({ input: 'IBAN6 korrekt', output: null }),
        new FieldMappingEntry({ input: 'SWIFT-Code 6', output: null }),
        new FieldMappingEntry({ input: 'Abw. Kontoinhaber 6', output: null }),
        new FieldMappingEntry({ input: 'Kennz. Hauptbankverb. 6', output: null }),
        new FieldMappingEntry({ input: 'Bankverb 6 Gültig von', output: null }),
        new FieldMappingEntry({ input: 'Bankverb 6 Gültig bis', output: null }),
        new FieldMappingEntry({ input: 'Bankleitzahl 7', output: null }),
        new FieldMappingEntry({ input: 'Bankbezeichnung 7', output: null }),
        new FieldMappingEntry({ input: 'Bank-Kontonummer 7', output: null }),
        new FieldMappingEntry({ input: 'Länderkennzeichen 7', output: null }),
        new FieldMappingEntry({ input: 'IBAN-Nr. 7', output: null }),
        new FieldMappingEntry({ input: 'IBAN7 korrekt', output: null }),
        new FieldMappingEntry({ input: 'SWIFT-Code 7', output: null }),
        new FieldMappingEntry({ input: 'Abw. Kontoinhaber 7', output: null }),
        new FieldMappingEntry({ input: 'Kennz. Hauptbankverb. 7', output: null }),
        new FieldMappingEntry({ input: 'Bankverb 7 Gültig von', output: null }),
        new FieldMappingEntry({ input: 'Bankverb 7 Gültig bis', output: null }),
        new FieldMappingEntry({ input: 'Bankleitzahl 8', output: null }),
        new FieldMappingEntry({ input: 'Bankbezeichnung 8', output: null }),
        new FieldMappingEntry({ input: 'Bank-Kontonummer 8', output: null }),
        new FieldMappingEntry({ input: 'Länderkennzeichen 8', output: null }),
        new FieldMappingEntry({ input: 'IBAN-Nr. 8', output: null }),
        new FieldMappingEntry({ input: 'IBAN8 korrekt', output: null }),
        new FieldMappingEntry({ input: 'SWIFT-Code 8', output: null }),
        new FieldMappingEntry({ input: 'Abw. Kontoinhaber 8', output: null }),
        new FieldMappingEntry({ input: 'Kennz. Hauptbankverb. 8', output: null }),
        new FieldMappingEntry({ input: 'Bankverb 8 Gültig von', output: null }),
        new FieldMappingEntry({ input: 'Bankverb 8 Gültig bis', output: null }),
        new FieldMappingEntry({ input: 'Bankleitzahl 9', output: null }),
        new FieldMappingEntry({ input: 'Bankbezeichnung 9', output: null }),
        new FieldMappingEntry({ input: 'Bank-Kontonummer 9', output: null }),
        new FieldMappingEntry({ input: 'Länderkennzeichen 9', output: null }),
        new FieldMappingEntry({ input: 'IBAN-Nr. 9', output: null }),
        new FieldMappingEntry({ input: 'IBAN9 korrekt', output: null }),
        new FieldMappingEntry({ input: 'SWIFT-Code 9', output: null }),
        new FieldMappingEntry({ input: 'Abw. Kontoinhaber 9', output: null }),
        new FieldMappingEntry({ input: 'Kennz. Hauptbankverb. 9', output: null }),
        new FieldMappingEntry({ input: 'Bankverb 9 Gültig von', output: null }),
        new FieldMappingEntry({ input: 'Bankverb 9 Gültig bis', output: null }),
        new FieldMappingEntry({ input: 'Bankleitzahl 10', output: null }),
        new FieldMappingEntry({ input: 'Bankbezeichnung 10', output: null }),
        new FieldMappingEntry({ input: 'Bank-Kontonummer 10', output: null }),
        new FieldMappingEntry({ input: 'Länderkennzeichen 10', output: null }),
        new FieldMappingEntry({ input: 'IBAN-Nr. 10', output: null }),
        new FieldMappingEntry({ input: 'IBAN10 korrekt', output: null }),
        new FieldMappingEntry({ input: 'SWIFT-Code 10', output: null }),
        new FieldMappingEntry({ input: 'Abw. Kontoinhaber 10', output: null }),
        new FieldMappingEntry({ input: 'Kennz. Hauptbankverb. 10', output: null }),
        new FieldMappingEntry({ input: 'Bankverb 10 Gültig von', output: null }),
        new FieldMappingEntry({ input: 'Bankverb 10 Gültig bis', output: null }),
        new FieldMappingEntry({ input: 'Nummer Fremdsystem', output: null }),
        new FieldMappingEntry({ input: 'Insolvent', output: null }),
        new FieldMappingEntry({ input: 'Mandatsreferenz 1', output: null }),
        new FieldMappingEntry({ input: 'Mandatsreferenz 2', output: null }),
        new FieldMappingEntry({ input: 'Mandatsreferenz 3', output: null }),
        new FieldMappingEntry({ input: 'Mandatsreferenz 4', output: null }),
        new FieldMappingEntry({ input: 'Mandatsreferenz 5', output: null }),
        new FieldMappingEntry({ input: 'Mandatsreferenz 6', output: null }),
        new FieldMappingEntry({ input: 'Mandatsreferenz 7', output: null }),
        new FieldMappingEntry({ input: 'Mandatsreferenz 8', output: null }),
        new FieldMappingEntry({ input: 'Mandatsreferenz 9', output: null }),
        new FieldMappingEntry({ input: 'Mandatsreferenz 10', output: null }),
        new FieldMappingEntry({ input: 'Verknüpftes OPOS-Konto', output: null }),
        new FieldMappingEntry({ input: 'Mahnsperre bis', output: null }),
        new FieldMappingEntry({ input: 'Lastschriftsperre bis', output: null }),
        new FieldMappingEntry({ input: 'Zahlungssperre bis', output: null }),
        new FieldMappingEntry({ input: 'Gebührenberechnung', output: null }),
        new FieldMappingEntry({ input: 'Mahngebühr 1', output: null }),
        new FieldMappingEntry({ input: 'Mahngebühr 2', output: null }),
        new FieldMappingEntry({ input: 'Mahngebühr 3', output: null }),
        new FieldMappingEntry({ input: 'Pauschalenberechnung', output: null }),
        new FieldMappingEntry({ input: 'Verzugspauschale 1', output: null }),
        new FieldMappingEntry({ input: 'Verzugspauschale 2', output: null }),
        new FieldMappingEntry({ input: 'Verzugspauschale 3', output: null }),
      ],
    });
  }
}

@Entity()
export class DATEVDebitorenExportJob extends ExportJob {
  static override _class = 'DebitorenExportJob';

  @Query(type => String)
  static exportDebitoren() {
    return async () => {
      let contacts: Contact[] = [];
      let limit = 500;
      let skip = 0;

      while (true) {
        const result = await makeZohoCOQLRequest({
          filter: `(${CONTACT_ZOHO_FIELDS.LAST_NAME} != '') LIMIT ${skip}, ${limit}`,
          gqlFields: { id: 1, customerNumber: 1, firstName: 1, lastName: 1 } as BaseQLSelectionSet<Contact>,
          moduleBiMap: CONTACT_BIMAP,
          moduleName: Contact.ZOHO_MODULE,
        });
        if (!result?.data) break;

        const fetchedContacts = result.data.map(rawContact => Contact.deserializeZoho(rawContact)) as Contact[];
        contacts = contacts.concat(fetchedContacts);

        if (fetchedContacts.length !== limit) break;
        skip += limit;
      }

      const csvPath = await exportCSVFromEntities(new DATEVDebitorenConvertConfig(), contacts);
      const file = await fs.readFile(csvPath, 'utf-8');

      return file;
    };
  }
}
