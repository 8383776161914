import { Arg, Entity, Field, Mutation } from '@adornis/baseql/decorators';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity';
import { baseConfig } from '@adornis/config/baseConfig';
import { Recipient } from '@adornis/mails/db/recipients';
import { Message } from '@adornis/mails/server/communication';
import { validate } from '@adornis/validation/decorators';
import { nonOptional } from '@adornis/validation/functions/nonOptional';
import { html, nothing } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { DateTime } from 'luxon';
import { getProductByID } from '../../../_api/product/queries/getProductByID';
import { emailTemplate } from '../../../db/mail-templates/e-mail-templates';
import { mailer } from '../../../server/communication';
import { ThrowNonOptionalError, validateEmail, validateEmailRepeat, validateNonOptional } from '../../db/helpers';
import { SupportTopic, SupportTopicWithProductNeed as SupportTopicsWithProductNeed } from './enums';
import { supportFormularTranslations } from './translations';

@Entity()
export class FormularSupport extends AdornisEntity {
  static override _class = 'FormularSupport';

  @validate(nonOptional())
  @Field(type => String)
  topic!: SupportTopic;

  @validate(options => {
    if (options.value) return;
    if (!SupportTopicsWithProductNeed.includes(options.target.topic)) return;
    ThrowNonOptionalError(options.key);
  })
  @Field(type => String)
  productID!: string;

  @validate(nonOptional())
  @Field(type => String)
  salutation!: string;

  @validate(nonOptional())
  @Field(type => String)
  firstName!: string;

  @validate(nonOptional())
  @Field(type => String)
  lastName!: string;

  @validate(options => {
    validateNonOptional({ key: options.key, value: options.value });
    validateEmail({ key: options.key, value: options.value });
  })
  @Field(type => String)
  email!: string;

  @validate(options => {
    validateNonOptional({ key: options.key, value: options.value });
    validateEmailRepeat({ key: options.key, email: options.target.email, emailRepeat: options.value });
  })
  @Field(type => String)
  emailRepeat!: string;

  @validate(nonOptional())
  @Field(type => String)
  message!: string;

  @validate(nonOptional())
  @Field(type => Boolean)
  hasAcceptedPrivacy!: boolean;

  @Mutation(type => String)
  static sendSupportEmail(@Arg('data', type => FormularSupport) data: FormularSupport) {
    return async () => {
      const topicTranslated = supportFormularTranslations.topic[data.topic][0];
      const product = SupportTopicsWithProductNeed.includes(data.topic)
        ? await getProductByID(data.productID)({ name: 1, id: 1 })
        : null;

      await mailer.sendMail(
        await Message.compose({
          subject: `Support - ${topicTranslated} - ${DateTime.now().toLocaleString(DateTime.DATE_MED)}`,
          html: emailTemplate({
            content: html`
              <div class="section">
                <div
                  style="height: auto; background: #fff;width: 100%;padding: 0px 30px 10px 30px; box-sizing: border-box; font-size:14px; text-align:left; line-height:18px;"
                >
                  <div style="display: grid; grid-template-columns: max-content 1fr;">
                    ${product
                      ? html`
                          <span style="margin-right: 10px"> <b> Produkt </b> </span>
                          <span>${product.name} (${product.id}) </span>
                        `
                      : nothing}

                    <span style="margin-right: 10px"><b>Anrede</b></span>
                    <span>${data.salutation}</span>

                    <span style="margin-right: 10px"><b>Vorname</b></span>
                    <span>${data.firstName}</span>

                    <span style="margin-right: 10px"><b>Nachname</b></span>
                    <span>${data.lastName}</span>

                    <span style="margin-right: 10px"><b>E-Mail-Adresse</b></span>
                    <span>${data.email}</span>

                    <span style="margin-right: 10px"><b>Nachricht</b></span>
                    <span>${unsafeHTML(data.message)}</span>
                  </div>
                </div>
              </div>
            `,
          }),
        }),
        [
          baseConfig.get('DEPLOYMENT') === 'production'
            ? new Recipient(['support@campus.de'])
            : new Recipient(['testing+support@novopol.firma.cc', 'michael@adornis.de']),
        ],
      );
    };
  }
}
