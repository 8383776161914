import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import type { AdornisTiptapExtension } from '@adornis/tiptap/client/extensions/ExtensionBase';
import { BuildifyCampusAccordeonExtension } from './extensions/BuildifyCampusAccordeonExtension';
import { BuildifyCampusSectionExtension } from './extensions/BuildifyCampusSectionExtension';
import { BuildifyCampusVimeoExtension } from './extensions/BuildifyCampusVimeoExtension';
import { BuildifyCampusYoutubeExtension } from './extensions/BuildifyCampusYoutubeExtension';
import { BuildifyCampusButtonExtension } from './extensions/button/BuildifyCampusButtonExtension';
import { BuildifyCampusIconTextExtension } from './extensions/icon-text/BuildifyCampusIconTextExtension';
import { BuildifyCampusIconExtension } from './extensions/icon/BuildifyCampusIconExtension';
import { BuildifyCampusListExtension } from './extensions/list/BuildifyCampusListExtension';
import { BuildifyCampusSpacingExtension } from './extensions/spacing/BuildifyCampusSpacingExtension';
import { BuildifyCampusTagExtension } from './extensions/tag/BuildifyCampusTagExtension';
// default extensions
import { TextExtension } from '@adornis/buildify/client/extensions/TextExtension';
import { Spacer } from '@adornis/tiptap/client/extensions/ActionbarSpacer';
import { BulletList } from '@adornis/tiptap/client/extensions/BulletList';
import { Color } from '@adornis/tiptap/client/extensions/Color';
import { Document } from '@adornis/tiptap/client/extensions/Document';
import { Dropcursor } from '@adornis/tiptap/client/extensions/Dropcursor';
import { HardBreak } from '@adornis/tiptap/client/extensions/HardBreak';
import { Link } from '@adornis/tiptap/client/extensions/Link/Link';
import { ListItem } from '@adornis/tiptap/client/extensions/ListItem';
import { LiteralTab } from '@adornis/tiptap/client/extensions/LiteralTab';
import { Paragraph } from '@adornis/tiptap/client/extensions/Paragraph';
import { Text } from '@adornis/tiptap/client/extensions/Text';
import { TextStyle } from '@adornis/tiptap/client/extensions/TextStyle';
import { TransformerHTML } from '@adornis/tiptap/client/extensions/TransformerHTML';
import { TransformerJSONObject } from '@adornis/tiptap/client/extensions/TransformerJSONObject';
import { TransformerJSONString } from '@adornis/tiptap/client/extensions/TransformerJSONString';
import { TransformerMarkdown } from '@adornis/tiptap/client/extensions/TransformerMarkdown';
import { BuildifyCampusContainerFlexBackgroundExtension } from './extensions/container/flex/BuildifyCampusContainerFlexBackgroundExtension';
import { BuildifyCampusContainerFlexExtension } from './extensions/container/flex/BuildifyCampusContainerFlexExtension';
import { BuildifyCampusContainerFlexLineExtension } from './extensions/container/flex/BuildifyCampusContainerFlexLineExtension';
import { BuildifyCampusContainerGridExtension } from './extensions/container/grid/BuildifyCampusContainerGridExtension';
import { BuildifyCampusContainerGridFourExtension } from './extensions/container/grid/BuildifyCampusContainerGridFourExtension';
import { BuildifyCampusContainerGridThreeExtension } from './extensions/container/grid/BuildifyCampusContainerGridThreeExtension';
import { BuildifyCampusContainerGridTwoExtension } from './extensions/container/grid/BuildifyCampusContainerGridTwoExtension';

// theme
import { type Maybe } from '@adornis/base/utilTypes';
import { BuildifyGlobalSettings, subscribeBuildifyGlobalSettings } from '@adornis/buildify/db/GlobalSettings';
import { Highlight } from '@adornis/tiptap/client/extensions/Highlight';
import { DigitaleHeldenBuildifyGlobalSettings } from '../../../db/buildify/DigitaleHeldenBuildifyGlobalSettings';
import { FontFamilyExtension } from '../../tiptap/FontFamilyExtension';
import { IconNodeExtension } from '../../tiptap/IconNode';
import { InsertHTMLExtension } from '../../tiptap/InsertHTMLExtension';
import { SuperscriptExtension } from '../../tiptap/SuperscriptExtension';
import { TagMarkExtension } from '../../tiptap/TagMark';
import '../index';
import {
  DigitaleHeldenTiptapBold,
  DigitaleHeldenTiptapHistory,
  DigitaleHeldenTiptapItalic,
  DigitaleHeldenTiptapStrike,
  DigitaleHeldenTiptapTextAlign,
  DigitaleHeldenTiptapUnderline,
} from '../tiptap/d-tiptap';
import { BuildifyCampusH1Extension } from './extensions/BuildifyCampusH1Extension';
import { BuildifyCampusH2Extension } from './extensions/BuildifyCampusH2Extension';
import { BuildifyCampusH3Extension } from './extensions/BuildifyCampusH3Extension';
import { BuildifyCampusH4Extension } from './extensions/BuildifyCampusH4Extension';
import { BuildifyCampusLeadSignupFormularExtension } from './extensions/_formulars/lead-signup-formular/BuildifyCampusLeadSignupFormularExtension';
import { BuildifyCampusSupportFormularExtension } from './extensions/_formulars/support-formular/BuildifyCampusSupportFormularExtension';

export const customParagraph = (
  tagName: string = 'text',
  options?: {
    fontSize: string;
  },
): AdornisTiptapExtension => {
  const defaultExtension = Paragraph(tagName);
  return {
    ...defaultExtension,
    styles: ({ host }) => [
      ...(defaultExtension.styles?.({ host }) ?? []),
      {
        ...(options?.fontSize ? { '[paragraph]': { fontSize: options.fontSize } } : {}),
        '[paragraph]:first-of-type': { marginTop: '0' },
        '[paragraph]:last-of-type': { marginBottom: '0' },
      },
    ],
  };
};

export const buildifyDefaultTiptapExtensions = (
  paragraph: AdornisTiptapExtension,
  globalSettings: Maybe<BuildifyGlobalSettings>,
): AdornisTiptapExtension[][] => {
  return [
    [FontFamilyExtension],
    [Document, Text, TextStyle, HardBreak, Dropcursor, Color, LiteralTab, ListItem, paragraph, BulletList],
    [TransformerHTML, TransformerJSONObject, TransformerJSONString, TransformerMarkdown],
    [DigitaleHeldenTiptapBold, DigitaleHeldenTiptapItalic, DigitaleHeldenTiptapUnderline, DigitaleHeldenTiptapStrike],
    [DigitaleHeldenTiptapTextAlign],
    [Link],
    [Spacer],
    [DigitaleHeldenTiptapHistory],
    [
      {
        ...Highlight,
        buttons: ({ editor }) => {
          const newColorButtons = (globalSettings?.colors ?? []).map(color => {
            return {
              name: `toggleHighlight${color}`,
              icon: 'droplet',
              color: '#fff',
              background: color,
              action: () => editor.chain().focus().toggleHighlight({ color }).run(),
            };
          });

          return newColorButtons;
        },
      },
    ],
    // mein custom shit
    [TagMarkExtension, IconNodeExtension, InsertHTMLExtension, SuperscriptExtension],
  ];
};

export const getDefaultDubniumBuildifyExtensions = async ({
  imageExtension,
}: {
  imageExtension?: BuildifyExtension<any>;
}): Promise<BuildifyExtension<any>[]> => {
  const globalSettings = await new Promise<Maybe<BuildifyGlobalSettings>>((res, rej) => {
    subscribeBuildifyGlobalSettings(DigitaleHeldenBuildifyGlobalSettings._class)({
      colors: 1,
    }).subscribe(settings => {
      if (!settings) return rej('settings not found');
      return res(settings as BuildifyGlobalSettings);
    });
  });

  const textExtension = TextExtension(buildifyDefaultTiptapExtensions(customParagraph('text'), globalSettings));
  const iconExtension = BuildifyCampusIconExtension;

  return [
    // - Layout
    // Flex
    BuildifyCampusContainerFlexBackgroundExtension,
    BuildifyCampusContainerFlexExtension,
    BuildifyCampusContainerFlexLineExtension,
    // Grid
    BuildifyCampusContainerGridExtension,
    BuildifyCampusContainerGridFourExtension,
    BuildifyCampusContainerGridThreeExtension,
    BuildifyCampusContainerGridTwoExtension,
    // Section
    BuildifyCampusSectionExtension,

    // - Schriften
    textExtension,
    BuildifyCampusH1Extension(buildifyDefaultTiptapExtensions(customParagraph('h1'), globalSettings)),
    BuildifyCampusH2Extension(buildifyDefaultTiptapExtensions(customParagraph('h2'), globalSettings)),
    BuildifyCampusH3Extension(buildifyDefaultTiptapExtensions(customParagraph('h3'), globalSettings)),
    BuildifyCampusH4Extension(buildifyDefaultTiptapExtensions(customParagraph('h4'), globalSettings)),

    BuildifyCampusSpacingExtension,
    iconExtension,
    BuildifyCampusIconTextExtension({ textExtension, iconExtension }),
    BuildifyCampusListExtension({ textExtension, iconExtension }),
    BuildifyCampusAccordeonExtension(textExtension),
    BuildifyCampusTagExtension({
      textExtension: TextExtension(
        buildifyDefaultTiptapExtensions(customParagraph('text', { fontSize: '14px' }), globalSettings),
      ),
      iconExtension,
    }),
    BuildifyCampusButtonExtension,

    // - Medien
    BuildifyCampusVimeoExtension,
    BuildifyCampusYoutubeExtension,
    ...(imageExtension ? [imageExtension] : []),

    // - Formulare
    BuildifyCampusLeadSignupFormularExtension,
    BuildifyCampusSupportFormularExtension,
  ];
};
