import { A } from '@adornis/base/env-info';
import { logger } from '@adornis/base/logging';
import { Arg, Entity, Field, Mutation, Query } from '@adornis/baseql/decorators';
import { type BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { Recipient } from '@adornis/mails/db/recipients';
import { Message } from '@adornis/mails/server/communication';
import { emailRegexCheck } from '@adornis/users/db/validators';
import { validate } from '@adornis/validation/decorators';
import { ValidationError } from '@adornis/validation/errors/ValidationError';
import { nonOptional } from '@adornis/validation/functions/nonOptional';
import { CampusRoute, PathToCampusRoute } from '../_routing/db/enums';
import { mailer } from '../server/communication';
import { insertZohoRecord, makeZohoAPIRequest, makeZohoCOQLRequest, upsertZohoRecord } from '../server/zoho/api';
import { genericSerializeZoho } from '../server/zoho/interface-zoho-adornis';
import { ActionTokenData, TokenAction } from './ActionTokenData';
import { BiMap } from './BiMap';
import { Contact, emptyValue } from './Contact';
import { Meeting } from './Meeting';
import { ZohoModule } from './enumns/zoho';
import { ContactEmailStatus, ZohoType, getDifficultyIndex } from './enums';
import { doubleOptInTempate } from './mail-templates/double-opt-in';
import { UpsertContactOptions } from './use-case-entities/UpsertContactOptions';
import { ZohoEntity } from './zoho-entity';

export enum LEAD_ZOHO_FIELDS {
  ID = 'id',
  BIRTHDAY = 'Date_of_Birth',
  EMAIL = 'Email',
  SECOND_EMAIL = 'Secondary_Email',
  FIRST_NAME = 'First_Name',
  LAST_NAME = 'Last_Name',
  STATUS = 'Lead_Status',
  LEAD_SOURCE = 'Lead_Source',
  NEWSLETTER = 'Newsletter',
  SALUTATION = 'Salutation',
  TITEL = 'Titel',
  FAX = 'Fax',
  PHONE = 'Phone',
  MOBILE = 'Mobile',
  WEBSITE = 'Website',
  TWITTER = 'Twitter',
  STREET = 'Street',
  ZIP_CODE = 'Zip_Code',
  CITY = 'City',
  STATE = 'State',
  COUNTRY = 'Country',
  WEBINARE = 'Webinare',
  AKADEMIE_ROLLEN = 'Akademie_Rollen',
  SONSTIGE_AKADEMIE_ROLLE = 'Sonstige_Akademie_Rolle',
  AUSTRAGUNGSDATUM = 'Austragungsdatum',
  EINTRAGUNGSDATUM = 'Eintragungsdatum',
  BESTAETIGUNGSDATUM = 'Bestaetigungsdatum',
  KOMMUNIKATIONS_KAMPAGNEN = 'Kommunikations_Kampagnen',
  EMAIL_STATUS = 'E_Mail_Status',
  KONTAKTFORMULAR = 'Kontaktformular',
  EMAIL_OPT_OUT = 'Email_Opt_Out',
  KLICKTIPP_ERSTEINTRAGUNG = 'KlickTipp_Ersteintragung',
}

export const LEAD_BIMAP = new BiMap<string, string>([
  ['id', LEAD_ZOHO_FIELDS.ID],
  ['birthday', LEAD_ZOHO_FIELDS.BIRTHDAY],
  ['email', LEAD_ZOHO_FIELDS.EMAIL],
  ['secondEmail', LEAD_ZOHO_FIELDS.SECOND_EMAIL],
  ['firstName', LEAD_ZOHO_FIELDS.FIRST_NAME],
  ['lastName', LEAD_ZOHO_FIELDS.LAST_NAME],
  ['status', LEAD_ZOHO_FIELDS.STATUS],
  ['leadSource', LEAD_ZOHO_FIELDS.LEAD_SOURCE],
  ['isNewsletter', LEAD_ZOHO_FIELDS.NEWSLETTER],
  ['salutation', LEAD_ZOHO_FIELDS.SALUTATION],
  ['title', LEAD_ZOHO_FIELDS.TITEL],
  ['fax', LEAD_ZOHO_FIELDS.FAX],
  ['phone', LEAD_ZOHO_FIELDS.PHONE],
  ['mobile', LEAD_ZOHO_FIELDS.MOBILE],
  ['website', LEAD_ZOHO_FIELDS.WEBSITE],
  ['twitter', LEAD_ZOHO_FIELDS.TWITTER],
  ['street', LEAD_ZOHO_FIELDS.STREET],
  ['zip', LEAD_ZOHO_FIELDS.ZIP_CODE],
  ['city', LEAD_ZOHO_FIELDS.CITY],
  ['state', LEAD_ZOHO_FIELDS.STATE],
  ['country', LEAD_ZOHO_FIELDS.COUNTRY],
  ['isWebinar', LEAD_ZOHO_FIELDS.WEBINARE],
  ['academyRoles', LEAD_ZOHO_FIELDS.AKADEMIE_ROLLEN],
  ['otherAcademyRole', LEAD_ZOHO_FIELDS.SONSTIGE_AKADEMIE_ROLLE],
  ['signOutDate', LEAD_ZOHO_FIELDS.AUSTRAGUNGSDATUM],
  ['signInDate', LEAD_ZOHO_FIELDS.EINTRAGUNGSDATUM],
  ['confirmDate', LEAD_ZOHO_FIELDS.BESTAETIGUNGSDATUM],
  ['communicationCampaigns', LEAD_ZOHO_FIELDS.KOMMUNIKATIONS_KAMPAGNEN],
  ['emailStatus', LEAD_ZOHO_FIELDS.EMAIL_STATUS],
  ['isContactform', LEAD_ZOHO_FIELDS.KONTAKTFORMULAR],
  ['isEmailCancellation', LEAD_ZOHO_FIELDS.EMAIL_OPT_OUT],
  ['klicktippSignIn', LEAD_ZOHO_FIELDS.KLICKTIPP_ERSTEINTRAGUNG],
]);

@Entity()
export class Lead extends ZohoEntity {
  static override _class = 'Lead';
  static override ZOHO_MODULE = ZohoModule.LEADS;
  static override ZOHO_FIELDS = Array.from(LEAD_BIMAP.values).join(',');

  @Field(type => String) id?: string;
  @Field(type => Boolean) isNewsletter?: boolean;
  @Field(type => Boolean) isWebinar?: boolean;
  @Field(type => Boolean) isContactform?: boolean;
  @Field(type => Boolean) isEmailCancellation?: boolean;
  @Field(type => String) role?: string;
  @Field(type => Date) signOutDate?: Date;
  @Field(type => Date) signInDate?: Date;
  @Field(type => Date) confirmDate?: Date;
  @Field(type => Date) klicktippSignIn?: Date;
  @Field(type => String) status?: string;
  @Field(type => String) emailStatus?: ContactEmailStatus;
  @Field(type => String) leadSource?: string;
  @Field(type => String) industry?: string;
  @Field(type => [String]) academyRoles?: string[];
  @Field(type => String) otherAcademyRole?: string;
  @Field(type => String) title?: string;
  @Field(type => String) secondEmail?: string;
  @Field(type => String) mobile?: string;
  @Field(type => Date) birthday?: Date;
  @Field(type => [String]) communicationCampaigns?: string[];
  @Field(type => String) phone?: string;
  @Field(type => String) street?: string;
  @Field(type => String) zip?: string;
  @Field(type => String) city?: string;
  @Field(type => String) state?: string;
  @Field(type => String) country?: string;

  @Field(type => String)
  salutation?: string;

  @validate(options => {
    if (!emailRegexCheck(options.value))
      throw new ValidationError('Bitte gib eine gültige E-Mail an.', {
        key: options.key,
        translationKey: 'validation_email',
      });
  })
  @Field(type => String)
  email!: string;

  @validate(nonOptional())
  @Field(type => String)
  firstName!: string;

  @validate(nonOptional())
  @Field(type => String)
  lastName!: string;

  get toFilteredJSON() {
    const fields = {};
    const keys = Array.from(LEAD_BIMAP.keys);
    keys.forEach(key => {
      if (key && this.toJSON()[key]) {
        fields[key] = this.toJSON()[key];
      }
    });
    return fields;
  }

  private get typeDefs() {
    return new Map<string, ZohoType>([
      [LEAD_ZOHO_FIELDS.EINTRAGUNGSDATUM, ZohoType.DATE],
      [LEAD_ZOHO_FIELDS.AUSTRAGUNGSDATUM, ZohoType.DATE],
      [LEAD_ZOHO_FIELDS.BESTAETIGUNGSDATUM, ZohoType.DATE],
      [LEAD_ZOHO_FIELDS.BIRTHDAY, ZohoType.DATE],
      [LEAD_ZOHO_FIELDS.KLICKTIPP_ERSTEINTRAGUNG, ZohoType.DATE],
      [LEAD_ZOHO_FIELDS.PHONE, ZohoType.PHONE],
      [LEAD_ZOHO_FIELDS.MOBILE, ZohoType.PHONE],
    ]);
  }
  override serializeZoho = (isNew: boolean = false) => {
    return genericSerializeZoho({
      bimap: LEAD_BIMAP,
      instance: this,
      typeDefs: this.typeDefs,
    });
  };

  static override deserializeZoho = (rawData: any) => {
    const fields = {};
    const keys = Array.from(LEAD_BIMAP.reverseKeys);
    keys.forEach(key => {
      const keyLAS = LEAD_BIMAP.reverseGet(key);
      if (keyLAS) {
        fields[keyLAS] = rawData[key] ?? null;
      }
    });
    return new Lead({
      ...fields,
    });
  };

  @Query(type => Lead)
  static getLeadByEmail(@Arg('email', type => String) email: string) {
    return async (gqlFields: BaseQLSelectionSet<Lead>) => {
      const result = await makeZohoCOQLRequest({
        filter: `(${LEAD_ZOHO_FIELDS.EMAIL} = '${email}')`,
        gqlFields,
        moduleBiMap: LEAD_BIMAP,
        moduleName: this.ZOHO_MODULE,
      });

      if (!result?.data?.[0]) return null;
      const resultData = result.data[0];
      const deserializedLead = this.deserializeZoho(resultData);
      return deserializedLead;
    };
  }

  @Query(type => String)
  static deleteLeadById(
    @Arg('leadID', type => String) leadID: string,
    @Arg('newContactID', type => String) newContactID: string,
  ) {
    return async () => {
      const meetings = await Meeting.getMeetingsForLeadID(leadID)(Meeting.allFields);

      for (const meeting of meetings) {
        // change ID's from leadID -> newContactID
        if (meeting.leadCompanyRelation === leadID) {
          meeting.contactRelation = newContactID;
          meeting.leadCompanyRelation = emptyValue;
        }
        for (const participant of meeting.participants ?? []) {
          if (participant.participant === leadID && participant.type === 'lead') {
            participant.participant = newContactID;
            participant.type = 'contact';
          }
        }

        // hier noch meeting abspeichern
        await Meeting.updateMeeting(meeting)();
      }

      const endpoint = `${this.ZOHO_MODULE}/${leadID}`;
      await makeZohoAPIRequest({ method: 'delete', endpoint, zohoModule: this.ZOHO_MODULE });
    };
  }

  @Mutation(type => Lead)
  public static upsertLead(
    @Arg('instance', type => Lead) instance: Lead,
    @Arg('options', type => UpsertContactOptions) options?: UpsertContactOptions,
  ) {
    return async (gqlFields: BaseQLSelectionSet<Lead>) => {
      const existingContact = await Contact.getContactByEmailCOQL(instance.email)(Contact.allFields);
      if (existingContact) throw new Error('Es existiert bereits ein Kontakt mit dieser E-Mail-Adresse.');
      const existingLead = await Lead.getLeadByEmail(instance.email)(Lead.allFields);
      if (!existingLead) instance.signInDate = new Date();

      // options check methods
      const checkStatus = (oldStatus, newStatus): string => {
        return getDifficultyIndex(newStatus) < getDifficultyIndex(oldStatus) ? oldStatus : newStatus;
      };
      const checkAcademyRoles = (oldRoles: string[], newRoles: string[]): string[] => {
        const allAcademyRoles = new Set<string>([...oldRoles, ...newRoles]);
        return Array.from(allAcademyRoles.values());
      };
      const checkOtherAcademyRole = (oldOthers: string, newOthers: string): string => {
        const otherAcademyRoles = new Set<string>([...oldOthers.split(','), ...newOthers.split(',')]);
        return Array.from(otherAcademyRoles.values())
          .filter(value => !!value)
          .join(',');
      };
      const checkCommunicationCampaigns = (oldCampaigns: string[], newCampaigns: string[]) => {
        const campaigns = new Set<string>([...oldCampaigns, ...newCampaigns]);
        return Array.from(campaigns.values());
      };

      // check options and configure data as needed
      const optionsCheck = async () => {
        if (options && (options.checkStatus || options.checkAcademyRole || options.checkOtherAcademyRole)) {
          if (!options) return;

          if (!existingLead) return;

          if (options.checkStatus) {
            instance.status = checkStatus(existingLead.status, instance.status);
          }

          if (options.checkAcademyRole) {
            instance.academyRoles = checkAcademyRoles(existingLead.academyRoles ?? [], instance.academyRoles ?? []);
          }

          if (options.checkOtherAcademyRole) {
            instance.otherAcademyRole = checkOtherAcademyRole(
              existingLead.otherAcademyRole ?? '',
              instance.otherAcademyRole ?? '',
            );
          }

          if (options.checkCommunicationCampaigns) {
            instance.communicationCampaigns = checkCommunicationCampaigns(
              existingLead.communicationCampaigns ?? [],
              instance.communicationCampaigns ?? [],
            );
          }
        }
      };
      await optionsCheck();

      console.log('upsert instance lead', instance);

      const id = await upsertZohoRecord(this.ZOHO_MODULE, instance);
      instance.id = id;
      return instance;
    };
  }

  @Mutation(type => Lead)
  public static upsertLeadAndCheckDOI(
    @Arg('instance', type => Lead) instance: Lead,
    @Arg('createAccount', type => Boolean) createAccount: boolean,
    @Arg('options', type => UpsertContactOptions) options?: UpsertContactOptions,
  ) {
    return async (gqlFields: BaseQLSelectionSet<Lead>) => {
      try {
        const emailStatus = await this.getLeadEmailStatusByEmail(instance.email)();
        instance.emailStatus = emailStatus;
      } catch {}
      instance = await this.upsertLead(instance, options)(Lead.allFields);
      console.log('updated/upserted instance', instance);

      if (instance.emailStatus !== ContactEmailStatus.DOI) {
        await Lead.sendLeadDOIMail(instance, createAccount)();

        if (instance.emailStatus !== ContactEmailStatus.SOI) {
          instance.emailStatus = ContactEmailStatus.OPT_IN_PENDING;
          instance = await this.upsertLead(instance, options)(Lead.allFields);
        }
      }

      return instance;
    };
  }

  @Query(type => String)
  public static getLeadEmailStatusByEmail(@Arg('email', type => String) email: string) {
    return async () => {
      const result = await makeZohoCOQLRequest({
        filter: `(${LEAD_ZOHO_FIELDS.EMAIL} = '${email}')`,
        gqlFields: { id: 1, email: 1, emailStatus: 1 } as BaseQLSelectionSet<Lead>,
        moduleName: this.ZOHO_MODULE,
        moduleBiMap: LEAD_BIMAP,
      });

      if (!result?.data?.[0]) return null;
      const resultData = result.data[0];
      return resultData[LEAD_ZOHO_FIELDS.EMAIL_STATUS];
    };
  }

  @Mutation(type => Boolean)
  static sendLeadDOIMail(@Arg('lead', type => Lead) lead: Lead, @Arg('createAccount', type => Boolean) createAccount) {
    return async () => {
      const token = new ActionTokenData({ action: TokenAction.DOI, lead, createAccount });
      const tokenId = await token.create();
      const accessLink = A.absoluteUrl(PathToCampusRoute(CampusRoute.ACTION), new URLSearchParams({ tokenId }));

      await mailer.sendMail(
        await Message.compose({
          subject: 'E-Mail bestätigen.',
          html: doubleOptInTempate({
            accessLink,
            firstName: lead.firstName ?? '',
            lastName: lead.lastName ?? '',
            createAccount,
          }),
        }),
        new Recipient([lead.email]),
      );
    };
  }

  @Mutation(type => String)
  public static upsertLeadOrContact(
    @Arg('entity', type => Lead) entity: Lead,
    @Arg('options', type => UpsertContactOptions) options?: UpsertContactOptions,
  ) {
    return async () => {
      try {
        await this.upsertLead(entity, options)(Lead.allFields);
      } catch (err) {
        const contact = new Contact({ ...entity.toJSON() });
        await Contact.upsertContact(contact, options)(Contact.allFields);
      }
    };
  }

  @Mutation(type => String)
  public static upsertLeadOrContactAndCheckDOI(
    @Arg('instance', type => Lead) instance: Lead,
    @Arg('createLASUser', type => Boolean) createAccount: boolean,
    @Arg('options', type => UpsertContactOptions) options?: UpsertContactOptions,
  ) {
    return async () => {
      try {
        await this.upsertLeadAndCheckDOI(instance, createAccount, options)(Lead.allFields);
      } catch (err) {
        logger.error(err, 'Fehler in upsertLeadOrContactAndCheckDOI catch block');
        const contact = new Contact({ ...instance.toJSON() });
        await Contact.upsertContactAndCheckDOI(contact, createAccount, options)(Contact.allFields);
      }
    };
  }

  @Mutation(type => Lead)
  public static insertLead(@Arg('entity', type => Lead) entity: Lead) {
    return async (gqlFields: BaseQLSelectionSet<Lead>) => {
      entity.signInDate = new Date();
      const leadId = await insertZohoRecord(this.ZOHO_MODULE, entity);
      entity.id = leadId;
      return entity;
    };
  }
}
