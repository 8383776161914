import type { Styles } from '@adornis/ass/style';
import { ChemistryLiteElement } from '@adornis/chemistry/chemistry-lite-element';
import { customElement } from 'lit/decorators.js';
import { DigitaleHeldenFont } from './fonts';

@customElement('d-bold')
export class DubniumBold extends ChemistryLiteElement {
  override styles() {
    return [
      {
        fontFamily: `${DigitaleHeldenFont.BLACK} !important`,
        fontSize: 'inherit',
        lineHeight: 'inherit',
        '&[inline]': {
          display: 'inline',
        },
      },
    ] as Styles[];
  }
}
