import type { Maybe, ValueEvent } from '@adornis/base/utilTypes';
import { css } from '@adornis/chemistry/directives/css.js';
import '@adornis/chemistry/elements/components/x-flex';
import { DesignSystem } from '@adornis/chemistry/elements/theming/design';
import { TranslationController } from '@adornis/translation-core/client/translation-controller';
import { html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import type { LASTranslationDictionary } from '../../db/translation';
import '../theme/components/d-button';
import '../theme/components/d-checkbox';
import { DubniumDialog } from '../theme/components/d-dialog';
import '../theme/components/d-dropdown-selection';

export type PromptTiptapExtensionLink = Maybe<{ href: string; target: string; isDigitalEmergency: boolean }>;

/**
 * @element las-prompt-tiptap-extension-link
 */
@customElement('las-prompt-tiptap-extension-link')
export class LASPromptTiptapExtensionLink extends DubniumDialog<PromptTiptapExtensionLink> {
  static override get element_name(): string {
    return 'las-prompt-tiptap-extension-link';
  }

  @property({ attribute: false }) href = '';
  @property({ attribute: false }) openInNewTab = true;
  @property({ attribute: false }) isDigitalEmergency = false;

  @state() private readonly _translation = new TranslationController<LASTranslationDictionary>(this);

  override content() {
    return html`
      <d-flex
        ${css({
          padding: `${DesignSystem.currentTheme.getValue().spacing.sm} ${
            DesignSystem.currentTheme.getValue().spacing.lg
          }`,
          gap: '24px',
        })}
      >
        <d-h4> Erstelle einen Link </d-h4>

        <d-input
          .value=${this.href}
          placeholder=${'Link'}
          @value-picked=${(e: ValueEvent<string>) => {
            this.href = e.detail.value;
          }}
        ></d-input>

        <d-checkbox
          .value=${this.isDigitalEmergency}
          .label=${'Digitaler Notfall?'}
          @value-picked=${(e: ValueEvent<boolean>) => {
            this.isDigitalEmergency = e.detail.value;
          }}
        ></d-checkbox>

        <d-checkbox
          .value=${this.openInNewTab}
          .label=${'In neuem Fenster öffnen'}
          @value-picked=${(e: ValueEvent<boolean>) => {
            this.openInNewTab = e.detail.value;
          }}
        ></d-checkbox>

        <d-flex horizontal center space="md">
          <d-button tone="neutral" mode="outline" @click=${() => this.cancel()}> Abbrechen </d-button>
          <d-button
            mode="action"
            @click=${() =>
              this.close({
                href: this.href,
                target: this.openInNewTab ? '_blank' : '_self',
                isDigitalEmergency: this.isDigitalEmergency,
              })}
          >
            Speichern
          </d-button>
        </d-flex>
      </d-flex>
    `;
  }

  override async cancel(returnVal?: any) {
    await this.transitionOut();
    return this.close(null);
  }
}
