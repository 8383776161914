import { logger } from '@adornis/base/logging';
import type { Maybe } from '@adornis/base/utilTypes';
import { getRawCollection } from '@adornis/baseql/server/collections';
import type { RequestQueue } from '@adornis/cache/server/requestQueue';
import { DateTime } from 'luxon';

// ##########################################################################################################
// typing
interface DigitaleHeldenSettings {
  _id: string;
  accessToken?: Maybe<string>;
  expires?: Maybe<string>;
}
// end typing
// -----------------------------------------------------------------------------------------------------------

// ##########################################################################################################
// vars
const settingsCollectionName = 'settings';
const globalSettingsID = 'global-settings';
const settingsRawCollection = async () => await getRawCollection<DigitaleHeldenSettings>(settingsCollectionName);
// end vars
// -----------------------------------------------------------------------------------------------------------

// ##########################################################################################################
// methods
export const createSettings = async ({ _id }: { _id: string }) => {
  const col = await settingsRawCollection();
  const settings = { _id, accessToken: '' };
  await col.insertOne(settings);
  return settings;
};

export const getGlobalSettings = async () => {
  const col = await settingsRawCollection();
  const result = await col.findOne<DigitaleHeldenSettings>({ _id: globalSettingsID });
  if (result) return result;
  await createSettings({ _id: globalSettingsID });
  const createdResult = await col.findOne<DigitaleHeldenSettings>({ _id: globalSettingsID });
  if (createdResult) return createdResult;
  throw new Error("global settings should exist. That's weird");
};

export const updateGlobalAccessToken = async (accessToken: string, expires: DateTime) => {
  const col = await settingsRawCollection();
  const setData = { accessToken, expires: expires.toISO() };
  await col.updateOne({ _id: globalSettingsID }, { $set: setData });
};

export const getNewAccessToken = async (accessToken: string) => {
  const col = await settingsRawCollection();
  await col.updateOne({ _id: globalSettingsID }, { $set: { accessToken } });
};

export const getAccessToken = async (requestQueue: RequestQueue) => {
  // get access token from settings
  const settings = await getGlobalSettings();
  if (settings.accessToken && settings.expires && DateTime.fromISO(settings.expires) > DateTime.now()) {
    return settings.accessToken;
  }
  const newToken = await generateNewAccessToken(requestQueue);
  return newToken.accessToken;
};

export const generateNewAccessToken = async (requestQueue: RequestQueue) => {
  console.log({ CLIENT_SECRET: process.env['CLIENT_SECRET'] });
  // request new access token and save it in database
  // -----------------------------------------------------------------
  // @ts-ignore we dont have a specific config we can reference here,
  // but those configs are necessary.
  const params = new URLSearchParams({
    // @ts-ignore read above
    refresh_token: process.env['REFRESH_TOKEN'],
    // @ts-ignorer ead above
    client_id: process.env['CLIENT_ID'],
    // @ts-ignore read above
    client_secret: process.env['CLIENT_SECRET'],
    grant_type: 'refresh_token',
  });

  const url = 'https://accounts.zoho.eu/oauth/v2/token?' + params.toString();
  await requestQueue.removeFromCache(url);
  const res = await requestQueue
    .request({
      method: 'post',
      url,
      data: {},
      options: {},
    })
    .catch(err => {
      logger.error(err.message);
    });

  if (res.body.error) throw new Error(`An error occurred while attempting to get the access token: ${res.body.error}`);
  const accessToken = res.body.access_token;
  const expireDate = DateTime.now().plus({ seconds: Number(res.body.expires_in) });
  await updateGlobalAccessToken(accessToken, expireDate);
  return accessToken;
};

// end methods
// -----------------------------------------------------------------------------------------------------------
