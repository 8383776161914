import { context } from '@adornis/baseql/server/context';
/* eslint-disable max-statements */
import { GroupContactRelation } from './Relations/GroupContactRelation';
/* eslint-disable max-lines */
/* eslint-disable complexity */
import { A } from '@adornis/base/env-info';
import { logger } from '@adornis/base/logging';
import { type Maybe } from '@adornis/base/utilTypes';
import { Arg, Entity, Field, Mutation, Query } from '@adornis/baseql/decorators';
import { getRawCollection } from '@adornis/baseql/server/collections';
import { type BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { Recipient } from '@adornis/mails/db/recipients';
import { Message } from '@adornis/mails/server/communication';
import { emailRegexCheck } from '@adornis/users/db/validators';
import { validate } from '@adornis/validation/decorators';
import { ValidationError } from '@adornis/validation/errors/ValidationError';
import { nonOptional } from '@adornis/validation/functions/nonOptional';
import { html } from 'lit';
import { DateTime } from 'luxon';
import {
  ZOHO_API_URL,
  getAllZohoRecords,
  makeZohoAPIRequest,
  makeZohoCOQLRequest,
  requestQueue,
  upsertZohoRecord,
} from 'server/zoho/api';
import { type ZohoResponse } from 'server/zoho/types';
import { getAllContactCompanyRelationsByContactCOQL } from '../_api/contact-company-relation/queries/getAllContactCompanyRelationsByContactCOQL';
import { getAllGroupContactRelationsByContactIDCOQL } from '../_api/group-contact-relations/queries/getAllGroupContactRelationsByContactIDCOQL';
import { CampusRoute, PathToCampusRoute } from '../_routing/db/enums';
import { mailer } from '../server/communication';
import { genericSerializeZoho } from '../server/zoho/interface-zoho-adornis';
import { convertLeadToContact } from '../server/zoho/operations';
import { ActionTokenData, TokenAction } from './ActionTokenData';
import { BiMap } from './BiMap';
import { getAccessToken } from './GlobalSettings';
import { Lead } from './Lead';
import { Order } from './Order';
import { RegistrationData } from './RegistrationData';
import { ContactCompanyRelation } from './Relations/ContactCompanyRelation';
import { ZohoModule } from './enumns/zoho';
import { ContactEmailStatus, OrderAttendenceStatus, ZohoType, getDifficultyIndex, type ContactCategory } from './enums';
import { LASFile } from './files/LASFile';
import { checkRole } from './helpers';
import { LASUser, UserRoles } from './las-user';
import { doubleOptInTempate } from './mail-templates/double-opt-in';
import { inviteToGroupTemplate } from './mail-templates/invite-to-group';
import { passwordResetTemplate } from './mail-templates/password-reset';
import { singleOptInTemplate } from './mail-templates/single-opt-in';
import { GroupContactRelationData } from './use-case-entities/GroupContactRelationData';
import { UpsertContactOptions } from './use-case-entities/UpsertContactOptions';
import { ZohoEntity } from './zoho-entity';

export const generateRandomPassword = () => {
  return String.fromCharCode(...[...new Array(10)].map(() => Math.floor(Math.random() * 25) + 65)) + 'cS22!';
};

export enum CONTACT_ZOHO_FIELDS {
  ID = 'id',
  BIRTHDAY = 'Date_of_Birth',
  EMAIL = 'Email',
  SECOND_EMAIL = 'Secondary_Email',
  FIRST_NAME = 'First_Name',
  LAST_NAME = 'Last_Name',
  KATEGORIE = 'Kategorie',
  STATUS = 'Kontakt_Status',
  LEAD_SOURCE = 'Lead_Source',
  NEWSLETTER = 'Newsletter',
  SALUTATION = 'Salutation',
  TITEL = 'Titel',
  FAX = 'Fax',
  PHONE = 'Phone',
  MOBILE = 'Mobile',
  INSTAGRAM = 'Instagram',
  LINKEDIN = 'LinkedIn',
  WEBSITE = 'Website',
  TWITTER = 'Twitter',
  STREET = 'Street',
  ZIP_CODE = 'Zip_Code',
  CITY = 'City',
  STATE = 'State',
  COUNTRY = 'Country',
  WEBINARE = 'Webinare',
  AKADEMIE_ROLLE = 'Akademie_Rolle',
  SONSTIGE_AKADEMIE_ROLLE = 'Sonstige_Akademie_Rolle',
  AUSTRAGUNGSDATUM = 'Austragungsdatum',
  EINTRAGUNGSDATUM = 'Eintragungsdatum',
  BESTAETIGUNGSDATUM = 'Bestaetigungsdatum',
  INTERNE_BEREICHE = 'Interne_Bereiche',
  KOMMUNIKATIONS_KAMPAGNEN = 'Kommunikations_Kampagnen',
  EMAIL_STATUS = 'E_Mail_Status',
  KONTAKTFORMULAR = 'Kontaktformular',
  EMAIL_OPT_OUT = 'Email_Opt_Out',
  KLICKTIPP_ERSTEINTRAGUNG = 'KlickTipp_Ersteintragung',
  PROBONO = 'ProBono',
  HAS_ACADEMY_ACCOUNT = 'Akademie_Account',
  HB_EX = 'HB_ehemalig',
  KUNDENNUMMER = 'Kundennummer',
  RECORD_IMAGE = 'Record_Image',
}

export const CONTACT_BIMAP = new BiMap<string, string>([
  ['id', CONTACT_ZOHO_FIELDS.ID],
  ['birthday', CONTACT_ZOHO_FIELDS.BIRTHDAY],
  ['email', CONTACT_ZOHO_FIELDS.EMAIL],
  ['secondEmail', CONTACT_ZOHO_FIELDS.SECOND_EMAIL],
  ['firstName', CONTACT_ZOHO_FIELDS.FIRST_NAME],
  ['lastName', CONTACT_ZOHO_FIELDS.LAST_NAME],
  ['category', CONTACT_ZOHO_FIELDS.KATEGORIE],
  ['status', CONTACT_ZOHO_FIELDS.STATUS],
  ['leadSource', CONTACT_ZOHO_FIELDS.LEAD_SOURCE],
  ['isNewsletter', CONTACT_ZOHO_FIELDS.NEWSLETTER],
  ['isProBono', CONTACT_ZOHO_FIELDS.PROBONO],
  ['salutation', CONTACT_ZOHO_FIELDS.SALUTATION],
  ['title', CONTACT_ZOHO_FIELDS.TITEL],
  ['fax', CONTACT_ZOHO_FIELDS.FAX],
  ['phone', CONTACT_ZOHO_FIELDS.PHONE],
  ['mobile', CONTACT_ZOHO_FIELDS.MOBILE],
  ['instagram', CONTACT_ZOHO_FIELDS.INSTAGRAM],
  ['linkedIn', CONTACT_ZOHO_FIELDS.LINKEDIN],
  ['website', CONTACT_ZOHO_FIELDS.WEBSITE],
  ['twitter', CONTACT_ZOHO_FIELDS.TWITTER],
  ['street', CONTACT_ZOHO_FIELDS.STREET],
  ['zip', CONTACT_ZOHO_FIELDS.ZIP_CODE],
  ['city', CONTACT_ZOHO_FIELDS.CITY],
  ['state', CONTACT_ZOHO_FIELDS.STATE],
  ['country', CONTACT_ZOHO_FIELDS.COUNTRY],
  ['isWebinar', CONTACT_ZOHO_FIELDS.WEBINARE],
  ['academyRoles', CONTACT_ZOHO_FIELDS.AKADEMIE_ROLLE],
  ['otherAcademyRole', CONTACT_ZOHO_FIELDS.SONSTIGE_AKADEMIE_ROLLE],
  ['signOutDate', CONTACT_ZOHO_FIELDS.AUSTRAGUNGSDATUM],
  ['signInDate', CONTACT_ZOHO_FIELDS.EINTRAGUNGSDATUM],
  ['confirmDate', CONTACT_ZOHO_FIELDS.BESTAETIGUNGSDATUM],
  ['internalAreas', CONTACT_ZOHO_FIELDS.INTERNE_BEREICHE],
  ['communicationCampaigns', CONTACT_ZOHO_FIELDS.KOMMUNIKATIONS_KAMPAGNEN],
  ['emailStatus', CONTACT_ZOHO_FIELDS.EMAIL_STATUS],
  ['isContactform', CONTACT_ZOHO_FIELDS.KONTAKTFORMULAR],
  ['isEmailCancellation', CONTACT_ZOHO_FIELDS.EMAIL_OPT_OUT],
  ['klicktippSignIn', CONTACT_ZOHO_FIELDS.KLICKTIPP_ERSTEINTRAGUNG],
  ['hasAcademyAccount', CONTACT_ZOHO_FIELDS.HAS_ACADEMY_ACCOUNT],
  ['isHbEx', CONTACT_ZOHO_FIELDS.HB_EX],
  ['customerNumber', CONTACT_ZOHO_FIELDS.KUNDENNUMMER],
  ['profileImageID', CONTACT_ZOHO_FIELDS.RECORD_IMAGE],
]);

export const emptyValue = 'NONE';
export declare type BHMaybe<T> = null | undefined | T | typeof emptyValue;

@Entity()
export class Contact extends ZohoEntity {
  static override _class = 'Contact';
  static override ZOHO_MODULE = ZohoModule.CONTACTS;
  static override ZOHO_FIELDS = Array.from(CONTACT_BIMAP.values).join(',');

  @Field(type => String) id?: string;
  @Field(type => String) title?: string;
  @Field(type => String) category: Maybe<ContactCategory>;
  @Field(type => Boolean) isProBono?: boolean;
  @Field(type => String) leadSource?: string;
  @Field(type => String) secondEmail?: string;
  @Field(type => Boolean) isNewsletter?: boolean;
  @Field(type => Boolean) isEmailCancellation?: boolean;
  @Field(type => Boolean) isHbEx?: boolean;
  @Field(type => Boolean) isContactform?: boolean;
  @Field(type => Boolean) isWebinar?: boolean;
  @Field(type => String) firstLeadSource?: string;
  @Field(type => String) customerNumber?: string;
  @Field(type => String) street?: string;
  @Field(type => String) zip?: string;
  @Field(type => String) city?: string;
  @Field(type => String) state: BHMaybe<string>;
  @Field(type => String) country?: string;
  @Field(type => Date) signOutDate?: Date;
  @Field(type => Date) signInDate?: Date;
  @Field(type => Date) confirmDate?: Date;
  @Field(type => Date) klicktippSignIn!: Date;
  @Field(type => [String]) internalAreas?: string[];
  @Field(type => [String]) communicationCampaigns?: string[];
  @Field(type => String) twitter?: string;
  @Field(type => String) instagram?: string;
  @Field(type => String) linkedIn?: string;
  @Field(type => String) skypeId?: string;
  @Field(type => String) website?: string;
  @Field(type => String) emailStatus?: string;
  @Field(type => String) description?: string;
  @Field(type => String) status?: string;
  @Field(type => [String]) academyRoles?: string[];
  @Field(type => String) otherAcademyRole?: string;
  @Field(type => String) phone?: string;
  @Field(type => String) mobile?: string;
  @Field(type => String) fax?: string;
  @Field(type => Date) birthday?: Date;
  @Field(type => Boolean) hasAcademyAccount?: boolean;
  @Field(type => String) profileImageID: Maybe<string>;

  @Field(type => String)
  salutation?: string;

  @validate(nonOptional())
  @Field(type => String)
  firstName?: string;

  @validate(nonOptional())
  @Field(type => String)
  lastName?: string;

  @validate(options => {
    if (!emailRegexCheck(options.value))
      throw new ValidationError('Bitte gib eine gültige E-Mail an.', {
        key: options.key,
        translationKey: 'validation_email',
      });
  })
  @Field(type => String)
  email!: string;

  getDebitorennummer() {
    const cn = (this.customerNumber ?? '').replace('KN-', '');
    if (cn.length !== 6) {
      // TODO: error handling? how to react in export?
      return 'NICHT_VORHANDEN';
    }
    return '1' + cn;
  }

  get toFilteredJSON() {
    const fields = {};
    const keys = Array.from(CONTACT_BIMAP.keys);
    keys.forEach(key => {
      if (key && this.toJSON()[key]) {
        fields[key] = this.toJSON()[key];
      }
    });
    return fields;
  }

  private get typeDefs() {
    return new Map<string, ZohoType>([
      [CONTACT_ZOHO_FIELDS.EINTRAGUNGSDATUM, ZohoType.DATE],
      [CONTACT_ZOHO_FIELDS.AUSTRAGUNGSDATUM, ZohoType.DATE],
      [CONTACT_ZOHO_FIELDS.BESTAETIGUNGSDATUM, ZohoType.DATE],
      [CONTACT_ZOHO_FIELDS.BIRTHDAY, ZohoType.DATE],
      [CONTACT_ZOHO_FIELDS.KLICKTIPP_ERSTEINTRAGUNG, ZohoType.DATE],
      [CONTACT_ZOHO_FIELDS.PHONE, ZohoType.PHONE],
      [CONTACT_ZOHO_FIELDS.MOBILE, ZohoType.PHONE],
    ]);
  }

  override serializeZoho = (isNew: boolean = false) => {
    return genericSerializeZoho({
      bimap: CONTACT_BIMAP,
      instance: this,
      typeDefs: this.typeDefs,
    });
  };

  static override deserializeZoho = (rawData: any) => {
    const fields = {};
    const keys = Array.from(CONTACT_BIMAP.reverseKeys);
    keys.forEach(key => {
      const keyLAS = CONTACT_BIMAP.reverseGet(key);
      if (keyLAS) {
        fields[keyLAS] = rawData[key] ?? null;
      }
    });

    return new Contact({
      ...fields,
    });
  };

  @Query(type => String)
  static deleteContactByID(@Arg('id', type => String) id: string) {
    return async () => {
      await checkRole({ context, role: UserRoles.SUPER_ADMIN });
      const endpoint = `${this.ZOHO_MODULE}/${id}`;
      await makeZohoAPIRequest({ method: 'delete', endpoint, zohoModule: this.ZOHO_MODULE });
    };
  }

  @Query(type => Contact)
  static getContactByIDCOQL(@Arg('id', type => String) id: string) {
    return async (gqlFields: BaseQLSelectionSet<Contact>) => {
      if (!id) return null;

      const result = await makeZohoCOQLRequest({
        gqlFields,
        filter: `${CONTACT_ZOHO_FIELDS.ID} = '${id}'`,
        moduleBiMap: CONTACT_BIMAP,
        moduleName: Contact.ZOHO_MODULE,
      });

      if (!result?.data?.[0]) return null;
      const data = result.data[0];
      const deserialized = this.deserializeZoho(data);
      return deserialized;
    };
  }

  @Mutation(type => Contact)
  static removeContactByIDSafe(@Arg('id', type => String) id: string) {
    return async () => {
      await checkRole({ context, role: UserRoles.SUPER_ADMIN });
      if (!id) return null;

      const contact = await this.getContactByIDCOQL(id)(Contact.allFields);
      if (!contact) return;

      if (
        contact.isNewsletter ||
        contact.isWebinar ||
        (contact.communicationCampaigns ?? []).length > 0 ||
        (contact.emailStatus !== ContactEmailStatus.OPT_IN_PENDING && contact.emailStatus !== ContactEmailStatus.SOI)
      )
        return;

      const countOrdersOfContact = await Order.countOrderByContactID(id)();
      if ((countOrdersOfContact ?? 0) > 0) return;

      const companyRelations = await getAllContactCompanyRelationsByContactCOQL(id)(ContactCompanyRelation.allFields);
      if (companyRelations.length > 0) return;

      const groupRelations = await getAllGroupContactRelationsByContactIDCOQL(id)(GroupContactRelation.allFields);
      if (groupRelations.length > 0) return;

      await this.deleteContactByID(id)();
    };
  }

  @Query(type => [Contact])
  static getContactsByIDsCOQL(@Arg('contactIDs', type => [String]) contactIDs: string[]) {
    return async (gqlFields: BaseQLSelectionSet<Contact>) => {
      contactIDs = contactIDs.filter(id => !!id);
      if (contactIDs.length === 0) return [];
      const endpoint = `coql`;

      const selectedFields = this.gqlFieldsZoho(gqlFields, CONTACT_BIMAP);

      const query = `SELECT ${selectedFields.join(',')} FROM ${this.ZOHO_MODULE} WHERE ${
        CONTACT_ZOHO_FIELDS.ID
      } in (${contactIDs.join(',')})`;

      const result = await makeZohoAPIRequest({
        method: 'post',
        endpoint,
        data: { select_query: query },
        zohoModule: this.ZOHO_MODULE,
        isRawRequest: true,
      });
      if (!result?.data) return null;

      const resultData = result.data;
      const deserialized = resultData.map(data => this.deserializeZoho(data)) as Contact[];
      return deserialized;
    };
  }

  @Query(type => Contact)
  static getContactByEmailCOQL(@Arg('email', type => String) email: string) {
    return async (gqlFields: BaseQLSelectionSet<Contact>) => {
      const result = await makeZohoCOQLRequest({
        filter: `${CONTACT_ZOHO_FIELDS.EMAIL} = '${email}'`,
        gqlFields,
        moduleBiMap: CONTACT_BIMAP,
        moduleName: this.ZOHO_MODULE,
      });

      const data = result?.data?.[0];
      if (!data) return null;
      const resultData = result.data[0];
      const deserializedContact = this.deserializeZoho(resultData);
      return deserializedContact;
    };
  }

  @Query(type => String)
  public static getContactEmailStatusByEmail(@Arg('email', type => String) email: string) {
    return async () => {
      const contact = await this.getContactByEmailCOQL(email)({ id: 1, email: 1, emailStatus: 1 });
      return contact?.emailStatus;
    };
  }

  @Mutation(type => Contact)
  public static updateContactFields(
    @Arg('id', type => String) id: string,
    @Arg('jsonString', type => String) jsonString: string,
  ) {
    return async (gqlFields: BaseQLSelectionSet<Contact>) => {
      const json = JSON.parse(jsonString);
      const zohoResponse: ZohoResponse = await makeZohoAPIRequest({
        method: 'patch',
        endpoint: `${this.ZOHO_MODULE}/${id}`,
        data: JSON.stringify({
          data: [json],
        }),
        zohoModule: this.ZOHO_MODULE,
      });
      if (zohoResponse.data[0]?.code !== 'SUCCESS')
        throw new Error(`Zoho record update failed: ${JSON.stringify(zohoResponse, null, 2)}`);
    };
  }

  @Mutation(type => Contact)
  public static upsertContact(
    @Arg('instance', type => Contact) instance: Contact,
    @Arg('options', type => UpsertContactOptions) options?: UpsertContactOptions,
  ) {
    return async (gqlFields: BaseQLSelectionSet<Contact>) => {
      // options check methods
      const checkStatus = (oldStatus, newStatus): string => {
        return getDifficultyIndex(newStatus) < getDifficultyIndex(oldStatus) ? oldStatus : newStatus;
      };
      const checkAcademyRoles = (oldRoles: string[], newRoles: string[]): string[] => {
        const allAcademyRoles = new Set<string>([...oldRoles, ...newRoles]);
        return Array.from(allAcademyRoles.values());
      };
      const checkOtherAcademyRole = (oldOthers: string, newOthers: string): string => {
        const otherAcademyRoles = new Set<string>([...oldOthers.split(','), ...newOthers.split(',')]);
        return Array.from(otherAcademyRoles.values())
          .filter(value => !!value)
          .join(',');
      };
      const checkCommunicationCampaigns = (oldCampaigns: string[], newCampaigns: string[]) => {
        const campaigns = new Set<string>([...oldCampaigns, ...newCampaigns]);
        return Array.from(campaigns.values());
      };

      // check if a lead exists, and add data to contact
      const existingLead = await Lead.getLeadByEmail(instance.email)(Lead.allFields);
      if (existingLead) {
        // reihenfolge des Spreads ist wichtig
        instance = new Contact({
          ...existingLead.toFilteredJSON,
          id: null,
          ...instance.toFilteredJSON,
          status: checkStatus(existingLead.status, instance.status),
          academyRoles: checkAcademyRoles(existingLead.academyRoles ?? [], instance.academyRoles ?? []),
          otherAcademyRole: checkOtherAcademyRole(existingLead.otherAcademyRole ?? '', instance.otherAcademyRole ?? ''),
        });
      }

      const existingContact = await Contact.getContactByEmailCOQL(instance.email)(Contact.allFields);
      // check options and configure data as needed
      const optionsCheck = async () => {
        if (
          options &&
          (options.checkStatus ||
            options.checkAcademyRole ||
            options.checkOtherAcademyRole ||
            options.checkCommunicationCampaigns) &&
          !existingLead
        ) {
          if (!options) return;

          if (!existingContact) return;

          if (options.checkStatus) {
            instance.status = checkStatus(existingContact.status, instance.status);
          }

          if (options.checkAcademyRole) {
            instance.academyRoles = checkAcademyRoles(existingContact.academyRoles ?? [], instance.academyRoles ?? []);
          }

          if (options.checkOtherAcademyRole) {
            instance.otherAcademyRole = checkOtherAcademyRole(
              existingContact.otherAcademyRole ?? '',
              instance.otherAcademyRole ?? '',
            );
          }

          if (options.checkCommunicationCampaigns) {
            instance.communicationCampaigns = checkCommunicationCampaigns(
              existingContact.communicationCampaigns ?? [],
              instance.communicationCampaigns ?? [],
            );
          }
        }
      };
      await optionsCheck();

      if (!existingContact && !existingLead) instance.signInDate = new Date();

      instance.id = await upsertZohoRecord(this.ZOHO_MODULE, instance);
      if (existingLead) {
        if (!existingLead.id) {
          const msg = 'Existing Lead had no id while registering contact.';
          logger.error({ existingLead }, msg);
          throw new Error(msg);
        }
        await convertLeadToContact(existingLead.id, instance.id);
      }
      return instance;
    };
  }

  @Mutation(type => String)
  public static registerContact(
    @Arg('instance', type => RegistrationData) instance: RegistrationData,
    @Arg('redirectURL', type => String) redirectURL?: string,
  ) {
    return async () => {
      const contact = new Contact({
        salutation: instance.salutation,
        firstName: instance.firstName,
        lastName: instance.lastName,
        email: instance.email,
        academyRoles: [instance.academyRole],
        otherAcademyRole: instance.otherAcademyRole,
        state: instance.state,
        country: instance.country,
      });
      await Contact.upsertContactAndCheckDOI(contact, true, undefined, redirectURL)({ id: 1 });
    };
  }

  @Mutation(type => Contact)
  public static upsertContactAndCheckDOI(
    @Arg('instance', type => Contact) instance: Contact,
    @Arg('createAccount', type => Boolean) createAccount: boolean,
    @Arg('options', type => UpsertContactOptions) options?: UpsertContactOptions,
    @Arg('redirectURL', type => String) redirectURL?: string,
  ) {
    return async (gqlFields: BaseQLSelectionSet<Contact>) => {
      try {
        const emailStatus = await this.getContactEmailStatusByEmail(instance.email)();
        instance.emailStatus = emailStatus;
      } catch {}
      instance = await this.upsertContact(instance, options)(Contact.allFields);

      const lasAccount = await LASUser.getLASUserByEmail(instance.email)(LASUser.allFields);
      if (instance.emailStatus !== ContactEmailStatus.DOI || (!lasAccount && createAccount)) {
        await Contact.sendContactDOIMail(instance, createAccount, redirectURL)();

        if (
          ![ContactEmailStatus.SOI_BOOKINGS, ContactEmailStatus.SOI, ContactEmailStatus.DOI].includes(
            instance.emailStatus as ContactEmailStatus,
          )
        ) {
          instance.emailStatus = ContactEmailStatus.OPT_IN_PENDING;
          instance = await this.upsertContact(instance, options)(Contact.allFields);
        }
      }

      return instance;
    };
  }

  @Mutation(type => Contact)
  public static upsertContactAndCheckSOI(
    @Arg('instance', type => Contact) instance: Contact,
    @Arg('createAccount', type => Boolean) createAccount: boolean,
    @Arg('options', type => UpsertContactOptions) options?: UpsertContactOptions,
  ) {
    return async (gqlFields: BaseQLSelectionSet<Contact>) => {
      const emailStatus = await this.getContactEmailStatusByEmail(instance.email)();

      instance.emailStatus = emailStatus;
      instance = await this.upsertContact(instance, options)(Contact.allFields);

      if (!instance.emailStatus || instance.emailStatus === ContactEmailStatus.OPT_IN_PENDING) {
        await this.sendContactSOIMail(instance, createAccount)();
        instance.emailStatus = ContactEmailStatus.OPT_IN_PENDING;
      }

      instance = await this.upsertContact(instance, options)(Contact.allFields);
      return instance;
    };
  }

  @Query(type => [Contact], { requestHandlerMeta: { queryCache: { cachePolicy: 'bypass' } } })
  static getAllContacts() {
    return async (gqlFields: BaseQLSelectionSet<Contact>) => {
      const rawContacts = await getAllZohoRecords(this.ZOHO_MODULE, { fields: this.ZOHO_FIELDS }, true);

      const contacts = rawContacts.data.map(contact => this.deserializeZoho(contact));

      return contacts;
    };
  }

  @Query(type => LASFile)
  static getProfileImage(@Arg('contactID', type => String) contactID: Maybe<string>) {
    return async (gqlFields: BaseQLSelectionSet<LASFile>) => {
      if (!contactID) return;
      const col = await getRawCollection<LASFile>(LASFile._collectionName);
      const existingFileDoc = await col.findOne(
        { 'meta.fileName': { $regex: contactID } },
        { projection: { _id: 1, createdAt: 1 } },
      );
      let existingFile: Maybe<LASFile> = undefined;
      if (existingFileDoc) {
        existingFile = await LASFile.getByID<LASFile>(existingFileDoc._id)(LASFile.allFields);
        if (existingFile && DateTime.fromJSDate(existingFileDoc.createdAt) > DateTime.now().minus({ minutes: 15 })) {
          return existingFile;
        }
      }
      if (!existingFile) {
        existingFile = new LASFile({});
      }

      const url = `${ZOHO_API_URL}/${this.ZOHO_MODULE}/${contactID}/photo`;

      let token = await getAccessToken(requestQueue).catch(err => {
        throw new Error(`token couldn't be generated: ${err.message}`);
      });

      try {
        const requestOptions = (token: string) => ({
          method: 'get',
          url,
          data: '',
          options: {
            headers: {
              Authorization: `Zoho-oauthtoken ${token}`,
            },
          },
        });

        const res = await requestQueue.request(requestOptions(token));
        const buf = new Buffer(res.body);
        await existingFile.replaceByBuffer(buf, 'profile-image-' + contactID + '.png');

        return existingFile as LASFile;
      } catch (e) {
        logger.error({ err: e }, 'Something went wrong fetching the image. Returning last known file.');
        return existingFile;
      }
    };
  }

  @Mutation(type => Boolean)
  static sendContactDOIMail(
    @Arg('contact', type => Contact) contact: Contact,
    @Arg('createAccount', type => Boolean) createAccount: boolean = true,
    @Arg('redirectURL', type => String) redirectURL?: string,
  ) {
    return async () => {
      const token = new ActionTokenData({ action: TokenAction.DOI, contact, createAccount });
      const tokenId = await token.create();
      const accessLink = A.absoluteUrl(
        PathToCampusRoute(CampusRoute.ACTION),
        new URLSearchParams({ tokenId: tokenId }),
      );

      await mailer.sendMail(
        await Message.compose({
          subject: 'E-Mail bestätigen.',
          html: doubleOptInTempate({
            accessLink,
            firstName: contact.firstName ?? '',
            lastName: contact.lastName ?? '',
            createAccount,
            redirectURL,
          }),
        }),
        new Recipient([contact.email]),
      );
    };
  }

  @Mutation(type => String)
  static sendSpecificConcernMailOKMP(
    @Arg('contact', type => Contact) contact: Contact,
    @Arg('text', type => String) text: string,
  ) {
    return async () => {
      return await this.sendSpecificConcernMail(contact, text, 'mentorenprogramm@campus.de')();
    };
  }

  @Mutation(type => String)
  static sendSpecificConcernMailMediaPackage(
    @Arg('contact', type => Contact) contact: Contact,
    @Arg('text', type => String) text: string,
  ) {
    return async () => {
      return await this.sendSpecificConcernMail(contact, text, 'presse@campus.de')();
    };
  }

  @Mutation(type => String)
  private static sendSpecificConcernMail(
    @Arg('contact', type => Contact) contact: Contact,
    @Arg('text', type => String) text: string,
    @Arg('recipient', type => String) recipient: string,
  ) {
    return async () => {
      await mailer.sendMail(
        await Message.compose({
          subject: `Konkretes anliegen von ${contact.firstName} ${contact.lastName} (${contact.email})`,
          html: html` <d-h4>Ich habe ein Konkretes anliegen.</d-h4>
            <div>${text}</div>`,
        }),
        [new Recipient(recipient)],
      );
    };
  }

  @Mutation(type => Boolean)
  static sendContactSOIMail(
    @Arg('contact', type => Contact) contact: Contact,
    @Arg('createAccount', type => Boolean) createAccount: boolean = false,
  ) {
    return async () => {
      const token = new ActionTokenData({ action: TokenAction.SOI, contact, createAccount });
      const tokenId = await token.create();
      const accessLink = A.absoluteUrl(PathToCampusRoute(CampusRoute.ACTION), new URLSearchParams({ tokenId }));

      await mailer.sendMail(
        await Message.compose({
          subject: 'Digitale Helden-Account aktivieren',
          html: singleOptInTemplate({ contact, accessLink, createAccount }),
        }),
        new Recipient([contact.email]),
      );
    };
  }

  @Mutation(type => Boolean)
  static sendContactOrderMail(@Arg('contact', type => Contact) contact: Contact) {
    return async () => {
      const token = new ActionTokenData({ action: TokenAction.DOI, contact, createAccount: true });
      const tokenId = await token.create();
      const accessLink = A.absoluteUrl(
        PathToCampusRoute(CampusRoute.ACTION),
        new URLSearchParams({ tokenId: tokenId }),
      );

      logger.info(`SEND MAIL AN ${contact.email}`);
      await mailer.sendMail(
        await Message.compose({
          subject: 'Digitale Helden-Account aktivieren',
          html: singleOptInTemplate({ contact, accessLink, createAccount: token.createAccount }),
        }),
        new Recipient([contact.email]),
      );
    };
  }

  @Mutation(type => Boolean)
  static sendForgotPasswordMail(
    @Arg('email', type => String) email: string,
    @Arg('createAccount', type => Boolean) createAccount: boolean,
    @Arg('hasBeenOrderedByAdmin', type => Boolean) hasBeenOrderedByAdmin: boolean = false,
  ) {
    return async () => {
      const contact = await Contact.getContactByEmailCOQL(email)(Contact.allFields);

      if (!contact) throw new Error('Ein(e) Nutzer*in mit dieser E-Mail Adresse existiert nicht.');

      const token = new ActionTokenData({ email, action: TokenAction.CHANGE_PASSWORD, createAccount });
      const tokenId = await token.create();
      const accessLink = A.absoluteUrl(
        PathToCampusRoute(CampusRoute.ACTION),
        new URLSearchParams({ tokenId: tokenId }),
      );

      await mailer.sendMail(
        await Message.compose({
          subject: 'Passwort vergessen',
          html: passwordResetTemplate(contact, accessLink, hasBeenOrderedByAdmin),
        }),
        new Recipient([email]),
      );
    };
  }

  @Mutation(type => String)
  public static setDoubleOptInContact(@Arg('id', type => String) id: string) {
    return async () => {
      const zohoResponse: ZohoResponse = await makeZohoAPIRequest({
        method: 'patch',
        endpoint: `${this.ZOHO_MODULE}/${id}`,
        data: JSON.stringify({
          data: [
            {
              [CONTACT_ZOHO_FIELDS.EMAIL_STATUS]: ContactEmailStatus.DOI,
              [CONTACT_ZOHO_FIELDS.BESTAETIGUNGSDATUM]: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            },
          ],
        }),
        zohoModule: this.ZOHO_MODULE,
      });
      if (zohoResponse.data[0]?.code !== 'SUCCESS')
        throw new Error(`Zoho record update failed: ${JSON.stringify(zohoResponse, null, 2)}`);
    };
  }

  @Mutation(type => GroupContactRelationData)
  static createContactPersonForGroup(
    @Arg('contact', type => Contact) contact: Contact,
    @Arg('groupContactRelation', type => GroupContactRelation) groupContactRelation: GroupContactRelation,
  ) {
    return async (gqlFields: BaseQLSelectionSet<GroupContactRelationData>) => {
      const upsertedContact = await Contact.upsertContactAndCheckSOI(
        contact,
        true,
      )(gqlFields.contact as BaseQLSelectionSet<Contact>);

      if (!upsertedContact.id) throw new Error('id not given');

      groupContactRelation.contactId = upsertedContact.id;

      let upsertedRelation: GroupContactRelation;
      try {
        upsertedRelation = await GroupContactRelation.upsertGroupContactRelation(groupContactRelation)(
          gqlFields.relation as BaseQLSelectionSet<GroupContactRelation>,
        );
      } catch (err) {
        // hier landet man, wenn bereits eine relation für den Kontakt vorhanden ist.
        upsertedRelation = await GroupContactRelation.upsertGroupContactRelation(
          new GroupContactRelation({
            ...groupContactRelation.toFilteredJSON,
            status: OrderAttendenceStatus.ANGEMELDET,
          }),
          true,
        )(gqlFields.relation as BaseQLSelectionSet<GroupContactRelation>);
      }

      const data = new GroupContactRelationData({ contact: upsertedContact, relation: upsertedRelation });

      if (upsertedContact.emailStatus !== ContactEmailStatus.OPT_IN_PENDING) {
        // send mail
        await mailer.sendMail(
          await Message.compose({
            html: inviteToGroupTemplate(),
            subject: 'Du wurdest zur Mentorenprogramm-Gruppe hinzugefügt',
          }),
          new Recipient(contact.email),
        );
      }

      return data;
    };
  }
}
