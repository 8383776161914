import { DesignSystem } from '@adornis/chemistry/elements/theming/design';
import { setGlobalStyle } from '@adornis/chemistry/elements/theming/global-style';

// HACK: workaround to style tippy content
// dropdown-content styles
setGlobalStyle(() => ({
  '.tippy-content > d-flex': {
    borderRadius: `${DesignSystem.currentTheme.getValue().sizes?.borderRadiusSecondary} !important`,
    'd-flex': {
      paddingTop: '0 !important',
    },
  },
}));
