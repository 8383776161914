import { Beleg } from '@adornis/accounting/api/beleg';
import { Kostenstellenzuweisung } from '@adornis/accounting/api/buchung';
import { CurrencyAmount } from '@adornis/accounting/api/currency-amount';
import { GeschaeftsvorfallBuchung } from '@adornis/accounting/api/geschaeftsvorfall-buchung';
import { SKR04 } from '@adornis/accounting/api/kontenrahmen';
import { Zusammenhangskomponente } from '@adornis/accounting/api/zusammenhangskomponente';
import type { Maybe } from '@adornis/base/utilTypes';
import { Float } from '@adornis/baseql/baseqlTypes';
import { Arg, Entity, Field, Query } from '@adornis/baseql/decorators';
import { DataLoadedField } from '@adornis/baseql/decorators/data-loaded-field.js';
import type { PartialEntityData } from '@adornis/baseql/entities/adornisEntity';
import { getCollection, getRawCollection } from '@adornis/baseql/server/collections';
import { loadMongoData } from '@adornis/baseql/server/data-loader.js';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { LASUser } from '@adornis/digitale-helden-shared/db/las-user';
import { validate } from '@adornis/validation/decorators';
import { nonOptional } from '@adornis/validation/functions/nonOptional';
import { getContactByIDCOQL } from '../../_api/contact/queries/getContactByIDCOQL';
import { Order } from '../../db/Order';
import { assertExisting, assertOneOrZeroAndReturn } from '../../db/assert/utils';
import { ContactNotFoundError } from '../../errors/ContactNotFoundError';
import { PaymentNotFoundError } from '../../errors/PaymentNotFoundError';
import { UserNotFoundError } from '../../errors/UserNotFoundError';
import { LASAccountingDeposit } from './LASAccountingDeposit';
import { LASReceipt, createInvoiceInformation } from './LASReceipts';
import { LASPayment } from './Payments/LASPayment';

export enum DATEV_ACCOUNTS {
  GELDTRANSIT = '70550',
  ERLOES_7 = '600500',
  ERLOES_0 = '600000',
}

// forderung
@Entity()
export class LASAccountingTransaction extends GeschaeftsvorfallBuchung<typeof SKR04> {
  static override _class = 'LASAccountingTransaction';

  constructor(doc: PartialEntityData<LASAccountingTransaction>) {
    super(doc);
  }

  @validate(nonOptional())
  @Field(type => String)
  userID: Maybe<string>;

  @DataLoadedField(type => LASUser, ({ userID }) => userID)
  user: Maybe<LASUser>;

  @Field(type => String)
  paymentID: Maybe<string>;

  @DataLoadedField(type => LASPayment, ({ paymentID }) => paymentID)
  payment: Maybe<LASPayment>;

  @Field(type => Float, {
    resolve(this: LASAccountingTransaction) {
      return async () => {
        const deposits = await loadMongoData(LASAccountingDeposit, { residentAccountingTransactionID: this._id });
        return deposits.reduce((sum, deposit) => sum + deposit.betrag.betrag, 0);
      };
    },
  })
  settledAmount!: number;

  @Field(type => Beleg, {
    resolve(this: LASAccountingTransaction) {
      return async () => {
        const zsmhang = assertOneOrZeroAndReturn(
          await loadMongoData(Zusammenhangskomponente, { buchungsIDs: this._id }),
        );
        const invoices = await loadMongoData(Beleg, {
          _id: { $in: zsmhang?.belegIDs ?? [] },
        });
        return assertOneOrZeroAndReturn(invoices, 'Rechnung nicht eindeutig zu identifizieren') as unknown as Beleg;
      };
    },
  })
  invoice: Maybe<Beleg>;

  @Field(type => String, {
    resolve(this: LASAccountingTransaction) {
      return async () => {
        const order = await Order.getOrderByPaymentIDCOQL(assertExisting(this.paymentID))({
          productConfiguration: {
            id: 1,
          },
        });
        if (!order) {
          return '';
        }
        return 'Art. ' + order.productConfiguration.id;
      };
    },
  })
  override buchungsText!: string;

  @Field(type => Order, {
    resolve(this: LASAccountingTransaction) {
      return async () => {
        const order = await Order.getOrderByPaymentIDCOQL(assertExisting(this.paymentID))(Order.allFields);
        return order;
      };
    },
  })
  order: Maybe<Order>;

  /**
   * Fetch accounting transaction by payment ID
   * @param paymentID Payment ID
   * @returns Accounting transaction
   */
  @Query(type => LASPayment)
  public static getAccountingTransactionByPaymentID(paymentID: string) {
    return async (gqlFields: BaseQLSelectionSet<LASAccountingTransaction>) => {
      const foundPaymentID = await loadMongoData(
        LASAccountingTransaction,
        { paymentID },
        { projection: { _id: 1 } },
      )[0];
      if (foundPaymentID === null || !foundPaymentID?._id) throw new Error('Payment not found in accounting');
      const payment = await LASAccountingTransaction.getByID<LASAccountingTransaction>(foundPaymentID._id)(gqlFields);
      return payment;
    };
  }

  /**
   * Create a new accounting transaction based on a payment
   * @param entity The payment entity
   * @returns The created belegNummer / invoice number
   */
  public static async createAccountingTransactionFromPayment(paymentID: string, userID: string): Promise<Beleg> {
    console.log('ERSTELLE JETZT GANZ VIEL STUFF 1');
    const payment = await LASPayment.getByID<LASPayment>(paymentID)({
      _id: 1,
      amount: 1,
      tax: 1,
    });
    if (!payment) {
      throw new PaymentNotFoundError();
    }

    console.log('ERSTELLE JETZT GANZ VIEL STUFF 2');
    const rawUserCollection = await getRawCollection(LASUser._collectionName);
    const user = await rawUserCollection.findOne<LASUser>({ _id: userID });
    if (!user || !user.zohoID) {
      throw new UserNotFoundError();
    }

    const contact = await getContactByIDCOQL(user.zohoID)({ id: 1, customerNumber: 1 });
    if (!contact) throw new ContactNotFoundError();

    const debitorennummer = contact.getDebitorennummer();
    const kostenstelleNummer = ' ';
    const kostenstellen = [new Kostenstellenzuweisung({ kostenstelle: kostenstelleNummer, anteilig: 1 })];
    const betrag = new CurrencyAmount({
      betrag: payment.amount / 100, // convert cent to euro
    });

    const tax = payment.tax ? Math.round((payment.tax / payment.amount) * 100) : 0;
    const erloesKonto = {
      0: DATEV_ACCOUNTS.ERLOES_0,
      7: DATEV_ACCOUNTS.ERLOES_7,
      // 19: DATEV_ACCOUNTS.ERLOES_19,
    }[tax];

    if (!erloesKonto) {
      throw new Error('No erloesKonto found');
    }

    console.log('ERSTELLE JETZT GANZ VIEL STUFF 4');
    // * 1. create forderung
    const trans = await LASAccountingTransaction.erstelleGeschaeftsvorfallDebitorenForderung<LASAccountingTransaction>(
      debitorennummer,
      erloesKonto,
      {
        userID,
        kostenstellen,
        paymentID: payment._id,
        betrag,
        committed: true,
      },
    );
    await trans.create();

    // * 2. create deposit
    const deposit = LASAccountingDeposit.bucheZahlung<LASAccountingDeposit>(trans as unknown as LASAccountingDeposit, {
      betrag,
      kostenstellen,
      committed: true,
      accountingTransactionID: trans._id,
    });

    // @ts-expect-error We use the GELDTRANSIT account for the deposit
    deposit.sollKonto = DATEV_ACCOUNTS.GELDTRANSIT;

    await deposit.create();

    const invoiceInformation = await createInvoiceInformation(kostenstelleNummer);

    // * 3. create beleg
    const beleg = await LASReceipt.createBelegForBuchung<LASReceipt>(
      LASAccountingTransaction._class,
      trans._id,
      new LASReceipt({
        ...invoiceInformation,
      }),
    )({
      belegNummer: 1,
    });

    if (!beleg) throw new Error('BelegNummer is missing');
    return beleg;
  }

  /**
   * Request all transactions by a list of payment ID's
   * @param {string[]} paymentIDs array of payment ID's
   * @returns all found transactions, according to the given ID's
   */
  @Query(type => [LASAccountingTransaction])
  public static getTransactionsByPayments(@Arg('paymentIDs', type => [String]) paymentIDs: string[]) {
    return async (gqlFields: BaseQLSelectionSet<LASAccountingTransaction>) => {
      const collection = await getCollection<LASAccountingTransaction>(LASAccountingTransaction._class);
      const result = await collection.find<LASAccountingTransaction>({ paymentID: { $in: paymentIDs } }).toArray();
      return result;
    };
  }
}
