export enum MediaType {
  FUNK_FERNSEHEN = 'Funk/Fernsehen',
  FÖRDERER_IN = 'Förderer*in',
  PARTNER_IN = 'Partner*in',
  POLITIK = 'Politik',
  PRESSE = 'Presse',
  SPENDER_IN = 'Spender*in',
  SONSTIGES = 'Sonstiges',
}

export const outsideGermany = 'Außerhalb von Deutschland';

export enum ProfileTab {
  DETAILS = 'Details',
  NOTIFICATIONS = 'Benachrichtigungen',
}

export enum InstitutionTab {
  DETAILS = 'Details',
  USERS = 'Benutzer',
}

export enum ZohoType {
  DATE_TIME = 'DateTime',
  DATE = 'Date',
  PHONE = 'Phone',
}

export enum OrderStatus {
  ACTIVE = 'Aktiviert',
  DEACTIVE = 'Deaktiviert',
}

export enum OrderAttendenceStatus {
  ANGEMELDET = 'Angemeldet',
  TEILGENOMMEN = 'Teilgenommen',
  NICHT_TEILGENOMMEN = 'Nicht teilgenommen',
  WIEDEREINSTIEG = 'Wiedereinstieg',
  ABGEBROCHEN = 'Abgebrochen',
  ABGESCHLOSSEN = 'Abgeschlossen',
}

export enum ProductType {
  WEBINAR = 'Webinar',
  WEBINAR_ARCHIV = 'Webinar-Archiv',
  WORKSHOP = 'Workshop',
  DOWNLOAD_INFO = 'Download Infomaterialien',
  DOWNLOAD_LEARNING_MATERIALS = 'Download Lernmaterialien',
  LEARNING_MATERIALS = 'Lernmaterialien',
  ONLINE_COURSE = 'Online-Kurs',
  ONLINE_TRAINING = 'Online-Training',
  AUDIO = 'Audio',
  LIVE_WEBINAR = 'Live-Webinar',
  PEER_PROGRAMM = 'Peer Programm',
}

export enum ProductDifficulty {
  BEGINNER = 'Einsteiger:in',
  ADVANCED = 'Fortgeschrittene',
  PROFI = 'Profi',
}

export function getDifficultyIndex(value: string | ProductDifficulty) {
  switch (value) {
    case ProductDifficulty.BEGINNER:
      return 0;
    case ProductDifficulty.ADVANCED:
      return 1;
    case ProductDifficulty.PROFI:
      return 2;
    default:
      return -1;
  }
}

export enum ProductGroupType {
  AG = 'AG',
  COMPULSORY_VOTING = 'Wahlpflichtfach',
  WORKSHOP = 'Workshop',
}

export enum ProductLearningSituation {
  SINGLE = 'Single',
  GROUP = 'Gruppe',
}

export enum Titel {
  PROF = 'Prof.',
  DR = 'Dr.',
  PROF_DR = 'Prof. Dr.',
}

export enum ProductHKEFunding {
  DIGITAL_EMERGENCY = 'Digitaler Notfall',
  RESEARCH_SKILLS = 'Recherchekompetenz',
}

export enum ProductPaymentModality {
  TOTAL_BILL = 'Gesamtrechnung',
  SPLITTED_BILL = 'Zwei Teilrechnungen',
}

export enum GroupStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum GroupContactRole {
  PARTICIPANT = 'Teilnehmer:in',
  GROUP_ADMIN = 'Gruppen-Administrator:in',
}

export enum ContactCategory {
  APPLICANT = 'Bewerber:in',
  CO_FINANCE = 'Co-Finanzierer:in',
  DH_TEAM = 'DH-Team',
  SERVICE_PROVIDER = 'Dienstleister:in',
  RADIO_TELEVISION = 'Funk/Fernsehen',
  FUNDING = 'Förderer:in',
  PARTNER = 'Partner:in',
  POLITICS = 'Politik',
  PRESS = 'Presse',
  SOCIAL_MEDIA = 'Social Media',
  DONOR = 'Spender:in',
}

export enum EmbedFormContactCategory {
  APPLICANT = ContactCategory.APPLICANT,
  CO_FINANCE = ContactCategory.CO_FINANCE,
  DH_TEAM = ContactCategory.DH_TEAM,
  SERVICE_PROVIDER = ContactCategory.SERVICE_PROVIDER,
  FUNDING = ContactCategory.FUNDING,
  PARTNER = ContactCategory.PARTNER,
  POLITICS = ContactCategory.POLITICS,
  PRESS = ContactCategory.PRESS,
  SOCIAL_MEDIA = ContactCategory.SOCIAL_MEDIA,
  DONOR = ContactCategory.DONOR,
  SONSTIGES = 'Sonstiges',
}

export enum ContactEmailStatus {
  OPT_IN_PENDING = 'Opt-In ausstehend',
  DOI = 'DOI',
  SOI = 'SOI',
  SOI_BOOKINGS = 'SOI_Bookings',
  DISCHARGED = 'Ausgetragen',
}

export enum Salutation {
  MADAM = 'Frau',
  MISTER = 'Herr',
  DIVERS = 'Divers',
}

export enum FederalState {
  BADEN_WÜRTTEMBERG = 'Baden-Württemberg',
  BAYERN = 'Bayern',
  BERLIN = 'Berlin',
  BRANDENBURG = 'Brandenburg',
  BREMEN = 'Bremen',
  HAMBURG = 'Hamburg',
  HESSEN = 'Hessen',
  MECKLENBURG_VORPOMMERN = 'Mecklenburg-Vorpommern',
  NIEDERSACHSEN = 'Niedersachsen',
  NORDRHEIN_WESTFALEN = 'Nordrhein-Westfalen',
  RHEINLAND_PFALZ = 'Rheinland-Pfalz',
  SAARLAND = 'Saarland',
  SACHSEN = 'Sachsen',
  SACHSEN_ANHALT = 'Sachsen-Anhalt',
  SCHLESWIG_HOLSTEIN = 'Schleswig-Holstein',
  THÜRINGEN = 'Thüringen',
}

export enum AcademyRoles {
  SECRETARIAT = 'Sekretariat',
  PEDAGOGOU = 'Pädagog:in',
  ELTERNTEIL = 'Elternteil',
  ELTERNVERTRETUNG = 'Elternvertretung',
  SCHULLEITER = 'Schulleiter:in',
  SOZIALARBEITER = 'Sozialarbeiter:in',
  SCHUELER = 'Schüler:in',
  STUDENT = 'Student:in',
  SONSTIGES = 'Sonstiges',
}

export enum ContactAcademyRole {
  SCHULLEITER = AcademyRoles.SCHULLEITER,
  PEDAGOGOU = AcademyRoles.PEDAGOGOU,
  ELTERNTEIL = AcademyRoles.ELTERNTEIL,
  SCHUELER = AcademyRoles.SCHUELER,
  STUDENT = AcademyRoles.STUDENT,
  SONSTIGES = AcademyRoles.SONSTIGES,
}

export enum ContactRegisterAcademyRoles {
  ELTERNTEIL = AcademyRoles.ELTERNTEIL,
  SCHULLEITER = AcademyRoles.SCHULLEITER,
  STUDENT = AcademyRoles.STUDENT,
  SCHUELER = AcademyRoles.SCHUELER,
  SONSTIGES = AcademyRoles.SONSTIGES,
}

export enum MentoringContactAcademyRole {
  SCHULLEITER = AcademyRoles.SCHULLEITER,
  PEDAGOGOU = AcademyRoles.PEDAGOGOU,
  ELTERNTEIL = AcademyRoles.ELTERNTEIL,
  STUDENT = AcademyRoles.STUDENT,
  SONSTIGES = AcademyRoles.SONSTIGES,
}

export enum ContactCompanyPermission {
  ADMIN = 'Firmen-Admin',
  MEMBER = 'Firmen-Zugehörige:r',
}

export enum ContactDepartment {
  IT = 'IT',
  MARKETING = 'Marketing',
  EMPLOYEES = 'Personal',
  DISTRIBUTION = 'Vertrieb',
  SUPPORT = 'Support',
  FINANCE = 'Finanzen',
}

export enum ContactPosition {
  MANAGEMENT = 'Geschäftsleitung/Schulleitung',
  ANSPRECHPARTNERIN = '(Schul-)Ansprechpartner:in',
  BEREICHSLEITUNG = 'Bereichsleitung/Teamleitung',
  PROJEKTLEITUNG = 'Projektleitung',
  HEAD_OF_DEPARTMENT = 'Bereichsleitung/Teamleitung',
  HEAD_OF_PROJECT = 'Projektleitung',
  EMPLOYEE = 'Mitarbeiter:in',
  APPRENTICE = 'Auszubildende:r/Praktikant:in',
  PEDAGOGUE = AcademyRoles.PEDAGOGOU,
  ELTERNTEIL = AcademyRoles.ELTERNTEIL,
  SCHUELER = AcademyRoles.SCHUELER,
  STUDENT = AcademyRoles.STUDENT,
  SONSTIGES = AcademyRoles.SONSTIGES,
}

export enum CompanyType {
  SERVICE_PROVIDER = 'Dienstleister',
  SPONSOR = 'Förder/in',
  HERO_SCHOOL = 'Helden-Schule',
  SUPPLIER = 'Lieferant/in',
  NETWORK = 'Netzwerk',
  PARTNER = 'Partner/in',
  POLITICS = 'Politik',
  PRESS = 'Presse/Medien',
  SCHOOL = 'Schule',
  RESELLER = 'Reseller',
  SOCIAL_MEDIA = 'Social Media',
  RADIO_TELEVISION = 'Funk/Fernsehen',
  SOCIAL_ORGANISATION = 'Soziale Organisation',
  CO_FINANCE = 'Co-Finanzierer:in',
}

export enum SchoolType {
  GRUNDSCHULE = 'Grundschule',
  HAUPTSCHULE = 'Hauptschule',
  REALSCHULE = 'Realschule',
  GYMNASIUM_OHNE_OBERSTUFE = 'Gymnasium ohne gymnasiale Oberstufe',
  GYMNASIUM_MIT_OBERSTUFE = 'Gymnasium mit gymnasialer Oberstufe',
}

export enum State {
  BW = 'Baden-Württemberg',
  BY = 'Bayern',
  BE = 'Berlin',
  BB = 'Brandenburg',
  HB = 'Bremen',
  HH = 'Hamburg',
  HE = 'Hessen',
  MV = 'Mecklenburg-Vorpommern',
  NI = 'Niedersachsen',
  NRW = 'Nordrhein-Westfalen',
  RP = 'Rheinland-Pfalz',
  SL = 'Saarland',
  SN = 'Sachsen',
  ST = 'Sachsen-Anhalt',
  SH = 'Schleswig Holstein',
  TH = 'Thüringen',
}
