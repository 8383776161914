import type { Maybe, ValueEvent } from '@adornis/base/utilTypes';
import { css } from '@adornis/chemistry/directives/css.js';
import '@adornis/chemistry/elements/components/x-flex';
import { DesignSystem } from '@adornis/chemistry/elements/theming/design';
import '@adornis/digitale-helden-shared/client/theme/d-button';
import '@adornis/digitale-helden-shared/client/theme/d-checkbox';
import { DubniumDialog } from '@adornis/digitale-helden-shared/client/theme/d-dialog';
import '@adornis/digitale-helden-shared/client/theme/d-dropdown-selection';
import { html } from 'lit';
import { customElement, state } from 'lit/decorators.js';

/**
 * @element las-prompt-tiptap-extension-link
 */
@customElement('las-prompt-tiptap-extension-inserthtml')
export class LASPromptTiptapExtensionInsertHTML extends DubniumDialog<Maybe<string>> {
  static override get element_name(): string {
    return 'las-prompt-tiptap-extension-inserthtml';
  }

  @state() private _html = '';

  override content() {
    return html`
      <d-flex
        ${css({
          padding: `${DesignSystem.currentTheme.getValue().spacing.sm} ${
            DesignSystem.currentTheme.getValue().spacing.lg
          }`,
          gap: '24px',
        })}
      >
        <d-h4> HTML Code </d-h4>

        <d-flex horizontal crossaxis-center space="xxs" wrap>
          <d-chip
            ${css({ cursor: 'pointer' })}
            @click=${() => {
              this._html += '&nbsp;';
            }}
          >
            ${'&nbsp;'}
          </d-chip>
        </d-flex>

        <d-textarea
          .value=${this._html}
          placeholder=${'HTML'}
          @value-picked=${(e: ValueEvent<string>) => {
            this._html = e.detail.value;
          }}
        ></d-textarea>

        <d-flex horizontal center space="md">
          <d-button tone="neutral" mode="outline" @click=${() => this.cancel()}> Abbrechen </d-button>
          <d-button mode="action" @click=${() => this.close(this._html)}> Speichern </d-button>
        </d-flex>
      </d-flex>
    `;
  }

  override async cancel(returnVal?: any) {
    // await this.transitionOut();
    return this.close(null);
  }
}
