import { css } from '@adornis/chemistry/directives/css';
import { FormField } from '@adornis/formfield/form-field';
import { Editor, generateHTML, generateJSON, generateText, type Extensions } from '@tiptap/core';
import { html, nothing, type PropertyValueMap } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { createRef, ref } from 'lit/directives/ref.js';
import { LASPromptTiptapExtensionInsertHTML } from '../prompts/las-prompt-tiptap-extension-inserthtml';
import {
  LASPromptTiptapExtensionLink,
  type PromptTiptapExtensionLink,
} from '../prompts/las-prompt-tiptap-extension-link';
import '../theme/components/d-bold';
import '../theme/components/d-highlight';
import '../theme/fonts';
import { defaultExtensions } from './global-extensions';

/**
 * @element d-tiptap-buildify
 */
@customElement('d-tiptap-buildify')
export class DTiptapBuildify extends FormField<string> {
  @property({ attribute: 'hide-typo', type: Boolean }) hideTypoButtons = false;
  @property({ attribute: false }) extensions: Extensions = defaultExtensions;

  @state() private _allowHTMLPaste = false;
  @state() private _isDarkMode = false;

  private _container = createRef<HTMLDivElement>();
  private _editor?: Editor;

  static stringToHTMLConverter(string: string, extensions: Extensions = defaultExtensions) {
    const json = this.toJSON(string);

    if (Object.keys(json).length === 0) return '';

    return generateHTML(this.toJSON(string), extensions);
  }

  static toJSON(string: string) {
    try {
      const json = JSON.parse(string);
      if (!json) return {};
      return json;
    } catch (err) {
      return {};
    }
  }

  private toJSON(string: string = this.value.value ?? '') {
    return DTiptapBuildify.toJSON(string);
  }

  protected override firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    super.firstUpdated(_changedProperties);

    if (!this._container.value) return console.warn('No container found for tiptap editor');

    const thisInstance = this;

    this._editor = new Editor({
      element: this._container.value,
      extensions: this.extensions,
      injectCSS: false,

      content: this.toJSON(),

      editorProps: {
        transformPastedText(text) {
          return text.replace(/\xA0/g, ' ');
        },
        transformPastedHTML(html) {
          const parsed = html.replace(/\xA0/g, ' ');
          if (thisInstance._allowHTMLPaste) return parsed;
          const jsonFromHTML = generateJSON(html, thisInstance.extensions);
          return generateText(jsonFromHTML, thisInstance.extensions);
        },
      },

      onSelectionUpdate: () => {
        this.requestUpdate();
      },

      onUpdate: ({ editor, transaction }) => {
        if (!transaction.docChanged) return;

        const json = editor.getJSON();
        const jsonStringified = JSON.stringify(json);

        this.value.next(jsonStringified);
        this.dispatchEvent(new CustomEvent('value-picked', { detail: { value: jsonStringified } }));

        this.requestUpdate();
      },
    });
  }

  override render() {
    return html`
      <d-flex space="sm" ${css({ padding: '8px', background: this.colors.tone.neutralAlabster })}>
        ${this._actionbar()}
        <div class="tiptap-container" ${ref(this._container)}></div>
      </d-flex>
    `;
  }

  private _actionbar() {
    return html`
      <d-flex space="xs">
        <d-flex horizontal crossaxis-start space="xs" wrap>
          <!-- bold -->
          ${this._actionButton({ icon: 'bold', action: () => this._editor?.chain().focus().toggleBold().run() })}
          ${this._actionButton({
            icon: 'link',
            action: async () => {
              const existingLink = this._editor?.getAttributes('link');

              if (existingLink?.href) {
                const editedLink = await LASPromptTiptapExtensionLink.showPopup<PromptTiptapExtensionLink>({
                  props: {
                    href: existingLink.href,
                    openInNewTab: existingLink?.target === '_blank',
                    isDigitalEmergency: existingLink?.isDigitalEmergency,
                  },
                });
                if (!editedLink) return;

                console.log(editedLink);
                this._editor
                  ?.chain()
                  .extendMarkRange('link')
                  .updateAttributes('link', {
                    href: editedLink.href,
                    target: editedLink.target,
                    isDigitalEmergency: editedLink.isDigitalEmergency,
                  })
                  .run();
                return;
              }

              const link = await LASPromptTiptapExtensionLink.showPopup<PromptTiptapExtensionLink>();
              if (link?.href === null || link?.href === undefined) return;
              this._editor?.commands.setLink({ ...link });
            },
          })}
          ${!!this._editor?.getAttributes('link').href
            ? this._actionButton({
                icon: 'link-slash',
                action: async () => {
                  this._editor?.commands.unsetLink();
                },
              })
            : nothing}

          <!-- link -->
          ${this.hideTypoButtons
            ? nothing
            : html`
                <!-- heading 2 -->
                ${this._actionButton({
                  icon: 'h1',
                  action: () => this._editor?.chain().focus().toggleHeading({ level: 2 }).run(),
                })}

                <!-- heading 3 -->
                ${this._actionButton({
                  icon: 'h2',
                  action: () => this._editor?.chain().focus().toggleHeading({ level: 3 }).run(),
                })}

                <!-- heading 4 -->
                ${this._actionButton({
                  icon: 'h3',
                  action: () => this._editor?.chain().focus().toggleHeading({ level: 4 }).run(),
                })}
              `}
          ${this._actionButton({
            icon: 'superscript',
            action: () => this._editor?.commands.toggleSuperscript(),
          })}
          ${this._actionButton({
            icon: 'droplet',
            color: this.colors.tone.emergency,
            solid: true,
            background: 'black',
            action: () =>
              this._editor
                ?.chain()
                .focus()
                .toggleHighlight({ color: this.colors.tone.emergency ?? '' })
                .run(),
          })}
          ${this._actionButton({
            icon: 'droplet',
            color: this.colors.accent,
            solid: true,
            background: 'black',
            action: () => this._editor?.chain().focus().toggleHighlight({ color: this.colors.accent }).run(),
          })}
          ${this._actionButton({
            icon: 'droplet',
            color: '#fff',
            solid: true,
            background: 'black',
            action: () => this._editor?.chain().focus().toggleHighlight({ color: '#fff' }).run(),
          })}
          ${this._actionButton({
            icon: 'droplet',
            color: '#BBBBB4',
            solid: true,
            background: 'black',
            action: () => this._editor?.chain().focus().toggleHighlight({ color: '#BBBBB4' }).run(),
          })}
          ${this._actionButton({
            icon: 'code',
            background: 'black',
            action: async () => {
              const htmlCode = await LASPromptTiptapExtensionInsertHTML.showPopup();
              if (!htmlCode) return;

              // @ts-ignore idk why he doesnt get it
              this._editor?.commands.insertHTML(htmlCode);
            },
          })}

          <!-- undo - redo -->
          <d-flex flex horizontal space="xs" crossaxis-center end>
            ${this._actionButton({
              icon: this._isDarkMode ? 'sun' : 'moon',
              background: 'black',
              action: () => (this._isDarkMode = !this._isDarkMode),
            })}
            ${this._actionButton({
              icon: this._allowHTMLPaste ? 'file-code' : 'file-lines',
              background: this._editor?.can().undo() ? 'black' : 'secondary',
              action: () => (this._allowHTMLPaste = !this._allowHTMLPaste),
            })}
            ${this._actionButton({
              icon: 'arrow-turn-down-left',
              background: this._editor?.can().undo() ? 'black' : 'secondary',
              action: () => this._editor?.commands.undo(),
            })}
            ${this._actionButton({
              icon: 'arrow-turn-down-right',
              background: this._editor?.can().redo() ? 'black' : 'secondary',
              action: () => this._editor?.commands.redo(),
            })}
          </d-flex>
        </d-flex>
      </d-flex>

      <!-- <d-h2></d-h2> -->
    `;
  }

  private _actionButton({
    icon,
    background = 'black',
    color,
    tooltip,
    solid = false,
    action = () => {},
  }: {
    icon: string;
    background?: string;
    tooltip?: string;
    color?: string;
    solid?: boolean;
    action?: () => void;
  }) {
    return html`
      <las-icon-button
        ${css({ color: color ? color : '' })}
        hover
        icon=${icon}
        @click=${() => {
          action();
        }}
        background=${background}
        color=${color ?? 'white'}
        .solid=${solid}
      ></las-icon-button>
    `;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          padding: '8px',
        },
        '.tiptap.ProseMirror': {
          padding: '1rem',
          boxSizing: 'border-box',
        },

        '.tiptap.ProseMirror:focus-visible': {
          outline: 'none',
        },

        '.tiptap-container': {
          background: this._isDarkMode ? '#000' : '#fff',
          borderRadius: '5px',
        },

        '.my-custom-class': {
          height: '20px',
          width: '2px',
          background: 'red',
        },
      },
    ];
  }
}
