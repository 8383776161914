import { Size } from '@adornis/buildify/client/globals/enums';

export enum AcademyRoles {
  SECRETARIAT = 'Sekretariat',
  PEDAGOGOU = 'Pädagog:in',
  ELTERNTEIL = 'Elternteil',
  ELTERNVERTRETUNG = 'Elternvertretung',
  SCHULLEITER = 'Schulleiter:in',
  SOZIALARBEITER = 'Sozialarbeiter:in',
  SCHUELER = 'Schüler:in',
  STUDENT = 'Student:in',
  SONSTIGES = 'Sonstiges',
}

export const DigitaleHeldenSizeBreakpoint = (size: Size) => {
  switch (size) {
    case Size.MOBILE:
      return 767;
    case Size.TABLET:
      return 991;
    case Size.DESKTOP:
      return 1440;
  }
};

export const desktop = 1200;
export const tablet = 992;
export const mobile = 768;

export enum ContactAcademyRole {
  SCHULLEITER = AcademyRoles.SCHULLEITER,
  PEDAGOGOU = AcademyRoles.PEDAGOGOU,
  ELTERNTEIL = AcademyRoles.ELTERNTEIL,
  SCHUELER = AcademyRoles.SCHUELER,
  STUDENT = AcademyRoles.STUDENT,
  SONSTIGES = AcademyRoles.SONSTIGES,
}

export const emptyValue = 'NONE';

export enum Salutation {
  MADAM = 'Frau',
  MISTER = 'Herr',
  DIVERS = 'Divers',
}

export enum ContactEmailStatus {
  OPT_IN_PENDING = 'Opt-In ausstehend',
  DOI = 'DOI',
  SOI = 'SOI',
  SOI_BOOKINGS = 'SOI_Bookings',
  DISCHARGED = 'Ausgetragen',
}

export enum ContactCategory {
  APPLICANT = 'Bewerber:in',
  CO_FINANCE = 'Co-Finanzierer:in',
  DH_TEAM = 'DH-Team',
  SERVICE_PROVIDER = 'Dienstleister:in',
  RADIO_TELEVISION = 'Funk/Fernsehen',
  FUNDING = 'Förderer:in',
  PARTNER = 'Partner:in',
  POLITICS = 'Politik',
  PRESS = 'Presse',
  SOCIAL_MEDIA = 'Social Media',
  DONOR = 'Spender:in',
}
