import { IconTextExtension } from '@adornis/buildify/client/extensions/IconTextExtension';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import type { BaseIcon } from '@adornis/buildify/db/extensions/BaseIcon';
import type { BaseText } from '@adornis/buildify/db/extensions/text/BaseText';
import { DubniumBaseIconText } from '../../../../../db/buildify/DubniumBaseIconText';

export const BuildifyCampusIconTextExtension = ({
  textExtension,
  iconExtension,
}: {
  textExtension: BuildifyExtension<BaseText>;
  iconExtension: BuildifyExtension<BaseIcon>;
}): BuildifyExtension<DubniumBaseIconText> => ({
  class: DubniumBaseIconText,
  toolbar: { icon: 'spell-check', text: { default: 'Icon mit Text' } },
  render: IconTextExtension({ textExtension, iconExtension }).render,
  editor: IconTextExtension({ textExtension, iconExtension }).editor,
  group: 'Basics',
});
