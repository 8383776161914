import type { Maybe } from '@adornis/base/utilTypes';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import type { AdornisTiptapExtension } from '@adornis/tiptap/client/extensions/ExtensionBase';
import { starterPack } from '@adornis/tiptap/client/extensions/starterPack';
import { DocumentType } from '../db/enums';
import { DubniumDocumentContentExtension } from './theme-components/document/content/DubniumDocumentContentExtension';
import { DubniumDocumentExtension } from './theme-components/document/plain/DubniumDocumentExtension';
import { BuildifyCampusAccordeonExtension } from './theme-components/extensions/BuildifyCampusAccordeonExtension';
import { BuildifyCampusVimeoExtension } from './theme-components/extensions/BuildifyCampusVimeoExtension';
import { BuildifyCampusYoutubeExtension } from './theme-components/extensions/BuildifyCampusYoutubeExtension';
import { BuildifyCampusLeadSignupFormularExtension } from './theme-components/extensions/_formulars/lead-signup-formular/BuildifyCampusLeadSignupFormularExtension';
import { BuildifyCampusSupportFormularExtension } from './theme-components/extensions/_formulars/support-formular/BuildifyCampusSupportFormularExtension';
import { BuildifyCampusButtonExtension } from './theme-components/extensions/button/BuildifyCampusButtonExtension';
import { BuildifyCampusContainerFlexBackgroundExtension } from './theme-components/extensions/container/flex/BuildifyCampusContainerFlexBackgroundExtension';
import { BuildifyCampusContainerFlexExtension } from './theme-components/extensions/container/flex/BuildifyCampusContainerFlexExtension';
import { BuildifyCampusContainerFlexLineExtension } from './theme-components/extensions/container/flex/BuildifyCampusContainerFlexLineExtension';
import { BuildifyCampusContainerGridFourExtension } from './theme-components/extensions/container/grid/BuildifyCampusContainerGridFourExtension';
import { BuildifyCampusContainerGridThreeExtension } from './theme-components/extensions/container/grid/BuildifyCampusContainerGridThreeExtension';
import { BuildifyCampusContainerGridTwoExtension } from './theme-components/extensions/container/grid/BuildifyCampusContainerGridTwoExtension';
import { BuildifyCampusImageFlipcardExtension } from './theme-components/extensions/flipcard/BuildifyCampusImageFlipcardExtension';
import { BuildifyCampusH1Extension } from './theme-components/extensions/h1/BuildifyCampusH1Extension';
import { BuildifyCampusH2Extension } from './theme-components/extensions/h2/BuildifyCampusH2Extension';
import { BuildifyCampusH3Extension } from './theme-components/extensions/h3/BuildifyCampusH3Extension';
import { BuildifyCampusIconTextExtension } from './theme-components/extensions/icon-text/BuildifyCampusIconTextExtension';
import { BuildifyCampusIconExtension } from './theme-components/extensions/icon/BuildifyCampusIconExtension';
import { BuildifyCampusImageExtension } from './theme-components/extensions/image/BuildifyCampusImageExtension';
import { BuildifyCampusLinkListExtension } from './theme-components/extensions/link-list/BuildifyCampusLinkListExtension';
import { BuildifyCampusListExtension } from './theme-components/extensions/list/BuildifyCampusListExtension';
import { BuildifyCampusQuizExtension } from './theme-components/extensions/quiz/BuildifyCampusQuizExtension';
import { BuildifyCampusSpacingExtension } from './theme-components/extensions/spacing/BuildifyCampusSpacingExtension';
import { BuildifyCampusTagExtension } from './theme-components/extensions/tag/BuildifyCampusTagExtension';
import { BuildifyCampusTextExtension } from './theme-components/extensions/text/BuildifyCampusTextExtension';

export const getDocumentExtensionByType = (type: Maybe<DocumentType>) => {
  if (!type) type = DocumentType.PLAIN;

  return {
    [DocumentType.PLAIN]: DubniumDocumentExtension,
    [DocumentType.CONTENT]: DubniumDocumentContentExtension,
  }[type];
};

export const getDefaultDubniumBuildifyExtensions = (
  tiptapExtensions: AdornisTiptapExtension[][] = starterPack,
): BuildifyExtension[] => {
  const textExtension = BuildifyCampusTextExtension;
  const iconExtension = BuildifyCampusIconExtension;
  const imageExtension = BuildifyCampusImageExtension;

  return [
    BuildifyCampusLeadSignupFormularExtension,
    BuildifyCampusSupportFormularExtension,
    BuildifyCampusContainerGridTwoExtension,
    BuildifyCampusContainerGridThreeExtension,
    BuildifyCampusContainerGridFourExtension,
    BuildifyCampusContainerFlexExtension,
    BuildifyCampusContainerFlexBackgroundExtension,
    BuildifyCampusContainerFlexLineExtension,
    textExtension,
    BuildifyCampusH1Extension,
    BuildifyCampusH2Extension,
    BuildifyCampusH3Extension,
    BuildifyCampusSpacingExtension,
    iconExtension,
    BuildifyCampusIconTextExtension({ textExtension, iconExtension }),
    BuildifyCampusListExtension({ textExtension, iconExtension }),
    BuildifyCampusLinkListExtension,
    BuildifyCampusAccordeonExtension(textExtension),
    BuildifyCampusQuizExtension,
    BuildifyCampusTagExtension,
    imageExtension,
    BuildifyCampusVimeoExtension,
    BuildifyCampusYoutubeExtension,
    BuildifyCampusImageFlipcardExtension({ textExtension, imageExtension }),
    BuildifyCampusButtonExtension,
  ];
};
