import { Entity, Field } from '@adornis/baseql/decorators';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity';
import { AcademyRoles } from '@adornis/digitale-helden-shared/db/enums';
import { validate } from '@adornis/validation/decorators';
import { ValidationError } from '@adornis/validation/errors/ValidationError';
import { nonOptional } from '@adornis/validation/functions/nonOptional';
import { validateEmail, validateEmailRepeat, validateNonOptional } from 'db/helpers';

@Entity()
export class FormularLeadSignup extends AdornisEntity {
  static override _class = 'FormularLeadSignup';

  @validate(nonOptional())
  @Field(type => String)
  salutation!: string;

  @validate(nonOptional())
  @Field(type => String)
  firstName!: string;

  @validate(nonOptional())
  @Field(type => String)
  lastName!: string;

  @validate(options => {
    validateNonOptional({ key: options.key, value: options.value });
    validateEmail({ key: options.key, value: options.value });
  })
  @Field(type => String)
  email!: string;

  @validate(options => {
    validateNonOptional({ key: options.key, value: options.value });
    validateEmailRepeat({ key: options.key, email: options.target.email, emailRepeat: options.value });
  })
  @Field(type => String)
  emailRepeat!: string;

  @validate(nonOptional())
  @Field(type => String)
  academyRole!: string;

  @validate(options => {
    if (options.target.academyRole === AcademyRoles.SONSTIGES && !options.value)
      throw new ValidationError('Geben Sie eine Akademie-Rolle an..', {
        key: options.key,
        translationKey: 'validation_non_optional',
      });
  })
  @Field(type => String)
  otherAcademyRole?: Maybe<string>;

  @validate(nonOptional())
  @Field(type => Boolean)
  hasAcceptedPrivacy!: boolean;
}
