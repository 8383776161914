import { DesignSystem } from '@adornis/chemistry/elements/theming/design';
import type { AdornisTiptapExtension } from '@adornis/tiptap/client/extensions/ExtensionBase';
import FontFamily from '@tiptap/extension-font-family';
import '../theme/d-icon';
import { DigitaleHeldenFont } from '../theme/fonts';

export const FontFamilyExtension: AdornisTiptapExtension = {
  buttons: ({ editor }) => {
    const settings = (font: DigitaleHeldenFont.BOLD | DigitaleHeldenFont.REGULAR) => ({
      font,
      isActive: editor.isActive('textStyle', { fontFamily: `${font}` }),
    });
    const boldSettings = settings(DigitaleHeldenFont.BOLD);

    const result = [
      {
        name: 'toggleBoldFont',
        icon: 'bolt-lightning',
        action: () => {
          if (boldSettings.isActive) editor.chain().focus().unsetFontFamily().run();
          else editor.chain().focus().setFontFamily(boldSettings.font).run();
        },
        background: boldSettings.isActive ? DesignSystem.currentTheme.getValue().colors.accent : 'transparent',
      },
    ];
    return result;
  },
  extensions: [FontFamily],
};
