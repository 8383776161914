import type { Maybe } from '@adornis/base/utilTypes';
import { Int } from '@adornis/baseql/baseqlTypes';
import { Arg, Entity, Field, Query, Subscription } from '@adornis/baseql/decorators';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity';
import { getRawCollection } from '@adornis/baseql/server/collections';
import { watchQuery } from '@adornis/baseql/server/watchQuery';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { CustomContent } from '@adornis/buildify/db/CustomContent';
import { DigitaleHeldenPage } from '@adornis/digitale-helden-shared/db/DigitaleHeldenPage';
import { MarketingPage } from '@adornis/digitale-helden-shared/db/MarketingPage';
import { combineLatest, switchMap } from 'rxjs';
import { BuildifyUnionFilter } from './BuildifyUnionFilter';
import { CampusPage } from './CampusPage';
import { DubniumPDP } from './DubniumPDP';
import { DubniumPagePublished } from './DubniumPagePublished';

export enum BuildifyUnionType {
  MARKETING = 'Marketing',
  CAMPUS = 'Campus',
  CUSTOM_CONTENT = 'Individuelle Komponenten',
  PRODUCT_DETAIL_PAGE = 'Produkt Details',
}

const classToTypeMapping = {
  [CampusPage._class]: BuildifyUnionType.CAMPUS,
  [MarketingPage._class]: BuildifyUnionType.MARKETING,
  [DubniumPDP._class]: BuildifyUnionType.PRODUCT_DETAIL_PAGE,
  [CustomContent._class]: BuildifyUnionType.CUSTOM_CONTENT,
};

@Entity()
export class BuildifyUnion extends AdornisEntity {
  static override _class = 'BuildifyUnion';

  static override resolveID(entity: BuildifyUnion): string {
    return entity._id;
  }

  @Field(type => String) _id!: string;
  @Field(type => String) name!: string;
  @Field(type => String) type!: BuildifyUnionType;
  @Field(type => String) path?: Maybe<string>;
  @Field(type => Boolean) published?: Maybe<boolean>;
  @Field(type => String) parentPageID?: Maybe<string>;
  @Field(type => [String]) productIDs?: Maybe<string[]>;

  @Query(type => [BuildifyUnion])
  static getDirectChildren(@Arg('parentPageID', type => String) parentPageID: string) {
    return async (gqlFields: BaseQLSelectionSet<BuildifyUnion>): Promise<BuildifyUnion[]> => {
      const rawPagesCollection = await getRawCollection(DigitaleHeldenPage._collectionName);
      const children = await rawPagesCollection
        .find({
          parentPageID,
        })
        .toArray();
      const rawPublishmentsCollection = await getRawCollection(DubniumPagePublished._collectionName);
      const publishments = await rawPublishmentsCollection
        .find({ pageID: { $in: children.map(child => child._id) } })
        .toArray();

      const mappedChildren = children.map(
        child =>
          new BuildifyUnion({
            ...child,
            _id: child._id,
            _class: BuildifyUnion._class,
            type: classToTypeMapping[child._class],
            published: !!publishments.find(p => p.pageID === child._id),
          }),
      );

      return mappedChildren;
    };
  }

  @Subscription(type => [BuildifyUnion])
  static subscribeBuildifyUnions(
    @Arg('skip', type => Int) skip: number,
    @Arg('limit', type => Int) limit: number,
    @Arg('filter', type => BuildifyUnionFilter) filter: BuildifyUnionFilter,
  ) {
    return (gqlFields: BaseQLSelectionSet<BuildifyUnion>) => {
      const typeToClassMapping = {
        [BuildifyUnionType.CAMPUS]: CampusPage._class,
        [BuildifyUnionType.MARKETING]: MarketingPage._class,
        [BuildifyUnionType.PRODUCT_DETAIL_PAGE]: DubniumPDP._class,
        [BuildifyUnionType.CUSTOM_CONTENT]: CustomContent._class,
      };

      return combineLatest([
        watchQuery(DubniumPagePublished._collectionName, {}),
        watchQuery(CustomContent._collectionName, {}),
        watchQuery(CampusPage._collectionName, {}),
        watchQuery(MarketingPage._collectionName, {}),
        watchQuery(DubniumPDP._collectionName, {}),
      ]).pipe(
        switchMap(async () => {
          const rawPagesCollection = await getRawCollection(DigitaleHeldenPage._collectionName);
          const unionResult = await rawPagesCollection
            .aggregate<BuildifyUnion & { publishments: DubniumPagePublished[] }>([
              {
                $match: {
                  $or: [
                    {
                      parentPageID: {
                        $exists: false,
                      },
                    },
                    {
                      parentPageID: null,
                    },
                  ],
                },
              },
              { $unionWith: MarketingPage._collectionName },
              { $unionWith: CustomContent._collectionName },
              { $unionWith: DubniumPDP._collectionName },
              {
                $project: {
                  _id: 1,
                  _class: 1,
                  name: 1,
                  parentPageID: 1,
                  path: 1,
                  productIDs: 1,
                },
              },
              {
                $lookup: {
                  from: DubniumPagePublished._collectionName,
                  localField: '_id',
                  foreignField: 'pageID',
                  as: 'publishments',
                },
              },
              ...(filter.types.length > 0
                ? [
                    {
                      $match: {
                        _class: {
                          $in: filter.types.map(type => typeToClassMapping[type]),
                        },
                      },
                    },
                  ]
                : []),
              ...(filter.search
                ? [
                    {
                      $match: {
                        name: new RegExp(filter.search, 'i'),
                      },
                    },
                  ]
                : []),
              ...(skip ? [{ $skip: skip }] : []),
              ...(limit ? [{ $limit: limit }] : []),
            ])
            .toArray();

          // data
          const rootData: BuildifyUnion[] = [];

          // init root
          for (const rawRoot of unionResult) {
            const data = new BuildifyUnion({
              ...rawRoot,
              _id: rawRoot._id,
              _class: BuildifyUnion._class,
              type: classToTypeMapping[rawRoot._class],
              published: rawRoot.publishments.length,
            });
            rootData.push(data);
          }

          return rootData;
        }),
      );
    };
  }
}
