import { type Maybe } from '@adornis/base/utilTypes';
import { ChemistryLiteElement } from '@adornis/chemistry/chemistry-lite-element';
import type { AdornisTiptapExtension } from '@adornis/tiptap/client/extensions/ExtensionBase';
import { Mark, mergeAttributes } from '@tiptap/core';
import { customElement } from 'lit/decorators.js';
import { TagMarkEditPrompt, type TagMarkProperties } from './TagMarkEditPrompt';

export const TagMarkExtension: AdornisTiptapExtension = {
  buttons: ({ editor }) => {
    const tagMarkAttributes = editor.getAttributes('TagMark');
    const result = [
      ...(Object.values(tagMarkAttributes).length
        ? [
            {
              name: 'deleteIconNode',
              icon: 'ban',
              action: () => {
                // @ts-expect-error
                editor.chain().extendMarkRange('TagMark').toggleTagMark().run();
              },
            },
            {
              name: 'changeTagMark',
              icon: 'tags',
              action: async () => {
                // current properties on tag element
                const currentProperties: TagMarkProperties = {
                  'background-color': tagMarkAttributes['background-color'],
                  border: tagMarkAttributes['border'],
                  'border-radius': tagMarkAttributes['border-radius'],
                  icon: tagMarkAttributes['icon'],
                  padding: tagMarkAttributes['padding'],
                  'font-size': tagMarkAttributes['font-size'],
                };

                console.log('current props', currentProperties);
                // ask for new properties
                const newProperties = await TagMarkEditPrompt.showPopup<Maybe<TagMarkProperties>>({
                  props: { tagMarkProperties: currentProperties },
                });
                if (!newProperties) return;

                editor
                  .chain()
                  .extendMarkRange('TagMark', tagMarkAttributes)
                  .updateAttributes('TagMark', newProperties)
                  .run();
              },
            },
          ]
        : [
            {
              name: 'toggleTagMark',
              icon: 'tag',
              // @ts-expect-error
              action: () => editor.chain().focus().toggleTagMark().run(),
            },
          ]),
    ];
    return result;
  },
  extensions: [
    Mark.create({
      name: 'TagMark',
      renderHTML({ HTMLAttributes }) {
        return ['tag-mark', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
      },

      parseHTML: () => [
        {
          tag: 'tag-mark',
        },
      ],
      addAttributes() {
        return {
          ['border-radius']: {
            default: null,
          },
          ['background-color']: {
            default: '#C3DCDC',
          },
          ['border']: {
            default: null,
          },
          ['font-size']: {
            default: null,
          },
          ['color']: {
            default: null,
          },
          ['padding']: {
            default: null,
          },
        };
      },
      // @ts-expect-error
      addCommands() {
        return {
          toggleTagMark: () => {
            return ({ commands }) => {
              return commands.toggleMark(this.name);
            };
          },
        };
      },
    }),
  ],
};

@customElement('tag-mark')
export class TagMark extends ChemistryLiteElement {
  override connectedCallback(): void {
    super.connectedCallback();
    this._setDefaultStyling();
  }

  private _setDefaultStyling() {
    const host = this as HTMLElement;
    host.style.backgroundColor = this.getAttribute('background-color') ?? '';
    host.style.borderRadius = this.getAttribute('border-radius') ?? '';
    host.style.color = this.getAttribute('color') ?? '';
    host.style.border = this.getAttribute('border') ?? '';
    host.style.padding = this.getAttribute('padding') ?? '';
    host.style.fontSize = this.getAttribute('font-size') ?? '';
  }

  override styles() {
    return [
      {
        display: 'inline',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '4px 10px',
        margin: `-2px 0`,
        fontSize: '14px',
      },
    ];
  }
}
