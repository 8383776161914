import { registerQuery } from '@adornis/baseql/metadata/register';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { CONTACT_BIMAP, CONTACT_ZOHO_FIELDS, Contact } from '../../../db/Contact';
import { makeZohoCOQLRequest } from '../../../server/zoho/api';

//* Resolver
const getContactByIDResolver = (id: string) => {
  return async (gqlFields: BaseQLSelectionSet<Contact>) => {
    if (!id) return null;
    const result = await makeZohoCOQLRequest({
      moduleName: Contact.ZOHO_MODULE,
      moduleBiMap: CONTACT_BIMAP,
      filter: `${CONTACT_ZOHO_FIELDS.ID} = '${id}'`,
      gqlFields,
    });
    if (!result?.data?.[0]) return null;
    const data = result.data[0];
    const deserialized = Contact.deserializeZoho(data);
    return deserialized;
  };
};

//* Query
export const getContactByID = registerQuery({
  type: () => Contact,
  operationName: 'getContactByID',
  resolve: getContactByIDResolver,
  params: [{ type: () => String, name: 'id' }],
});
