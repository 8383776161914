import { TagExtension } from '@adornis/buildify/client/extensions/TagExtension';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import type { BaseTag } from '@adornis/buildify/db/extensions/BaseTag';
import '@campus/client/theme/components/d-icon';
import '@campus/client/theme/components/d-input';
import '@campus/client/theme/fonts';
import { html } from 'lit';

export const BuildifyCampusTagExtension: BuildifyExtension<BaseTag> = {
  class: TagExtension.class,
  toolbar: TagExtension.toolbar,
  render: TagExtension.render,
  editor({ content, contentController, controllerBaseKeyPath, host }) {
    return html`
      <d-flex space="sm">
        <d-flex crossaxis-center space-between horizontal space="sm" wrap>
          <d-text bold> Tags: </d-text>
          <d-icon
            pointer
            @click=${() => {
              content.texts.push('');
              host.requestUpdate();
            }}
          >
            plus
          </d-icon>
        </d-flex>
        ${content.texts.map(
          (textContent, index) => html`
            <d-flex crossaxis-center space-between horizontal space="sm" wrap>
              <d-text> ${index + 1}. Tag </d-text>
              <d-icon
                pointer
                @click=${() => {
                  content.texts.splice(index, 1);
                  host.requestUpdate();
                }}
              >
                trash
              </d-icon>
            </d-flex>
            <d-input placeholder="Text" ${contentController.field(...controllerBaseKeyPath, 'texts', index)}></d-input>
          `,
        )}
      </d-flex>
    `;
  },
  group: 'Basics',
};
