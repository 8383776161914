import { Arg, Entity, Field, Mutation } from '@adornis/baseql/decorators';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity';
import { baseConfig } from '@adornis/config/baseConfig';
import { emailTemplate } from '@adornis/digitale-helden-shared/mailing/e-mail-templates';
import { mailer } from '@adornis/digitale-helden-shared/server/communication';
import { Recipient } from '@adornis/mails/db/recipients';
import { Message } from '@adornis/mails/server/communication';
import { emailRegexCheck } from '@adornis/users/db/validators';
import { validate } from '@adornis/validation/decorators';
import { ValidationError } from '@adornis/validation/errors/ValidationError';
import { nonOptional } from '@adornis/validation/functions/nonOptional';
import { html } from 'lit';

@Entity()
export class ContactFormData extends AdornisEntity {
  static override _class = 'ContactFormData';

  @validate(nonOptional())
  @Field(type => String)
  salutation!: string;

  @validate(nonOptional())
  @Field(type => String)
  firstName!: string;

  @validate(nonOptional())
  @Field(type => String)
  lastName!: string;

  @validate(options => {
    if (!emailRegexCheck(options.value))
      throw new ValidationError('Bitte gib eine gültige E-Mail an.', {
        key: options.key,
        translationKey: 'validation_email',
      });
  })
  @Field(type => String)
  email!: string;

  @validate(nonOptional())
  @Field(type => String)
  message!: string;

  @Field(type => Boolean) isNewsletter!: boolean;
  @Field(type => Boolean) isWebinar!: boolean;

  @Mutation(type => String)
  static sendContactEmail(@Arg('data', type => ContactFormData) data: ContactFormData) {
    return async () => {
      await mailer.sendMail(
        await Message.compose({
          subject: 'Jemand möchte Kontakt aufnehmen',
          html: emailTemplate({
            content: html`
              <div class="section">
                <div
                  style="height: auto; background: #fff;width: 100%;padding: 0px 30px 10px 30px; box-sizing: border-box; font-size:14px; text-align:left; line-height:18px;"
                >
                  <div style="display: grid; grid-template-columns: max-content 1fr;">
                    <span style="margin-right: 10px"><b>Anrede</b></span>
                    <span>${data.salutation}</span>

                    <span style="margin-right: 10px"><b>Vorname</b></span>
                    <span>${data.firstName}</span>

                    <span style="margin-right: 10px"><b>Nachname</b></span>
                    <span>${data.lastName}</span>

                    <span style="margin-right: 10px"><b>E-Mail-Adresse</b></span>
                    <span>${data.email}</span>

                    <span style="margin-right: 10px"><b>Nachricht</b></span>
                    <span>${data.message}</span>
                  </div>
                </div>
              </div>
            `,
          }),
        }),
        [
          baseConfig.get('DEPLOYMENT') === 'development'
            ? new Recipient(['michael@adornis.de', 'test@adornis.de'])
            : new Recipient('support@digitale-helden.de'),
        ],
      );
    };
  }
}
