import { BaseH1 } from '@adornis/buildify/db/extensions/text/BaseH1';
import { BaseH2 } from '@adornis/buildify/db/extensions/text/BaseH2';
import { BaseH3 } from '@adornis/buildify/db/extensions/text/BaseH3';
import type { BaseText } from '@adornis/buildify/db/extensions/text/BaseText';
import { mergeAttributes } from '@tiptap/core';
import { Paragraph as ExtensionParagraph } from '@tiptap/extension-paragraph';
import { Extension, defaultExtensionMap, defaultExtensions } from './global-extensions';

// ==================================================================================
//                                    H1
// ==================================================================================

const CustomParagraphH1 = ExtensionParagraph.extend({
  renderHTML({ HTMLAttributes }) {
    return ['d-h2', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
  addAttributes() {
    return {
      paragraph: {
        default: '',
      },
    };
  },
});

export const h1ExtensionMap = () => {
  const map = defaultExtensionMap;
  map.set(Extension.PARAGRAPH, CustomParagraphH1);
  return map;
};

export const h1Extensions = Array.from(h1ExtensionMap().values());

// ==================================================================================
//                                     H2
// ==================================================================================

const CustomParagraphH2 = ExtensionParagraph.extend({
  renderHTML({ HTMLAttributes }) {
    return ['d-h3', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
  addAttributes() {
    return {
      paragraph: {
        default: '',
      },
    };
  },
});

export const h2ExtensionMap = () => {
  const map = defaultExtensionMap;
  map.set(Extension.PARAGRAPH, CustomParagraphH2);
  return map;
};

export const h2Extensions = Array.from(h2ExtensionMap().values());

// ==================================================================================
//                                    H3
// ==================================================================================

const CustomParagraphH3 = ExtensionParagraph.extend({
  renderHTML({ HTMLAttributes }) {
    return ['d-h4', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
  addAttributes() {
    return {
      paragraph: {
        default: '',
      },
    };
  },
});

export const h3ExtensionMap = () => {
  const map = defaultExtensionMap;
  map.set(Extension.PARAGRAPH, CustomParagraphH3);
  return map;
};

export const h3Extensions = Array.from(h3ExtensionMap().values());

// ==================================================================================
//                                    BIGGER TEXT
// ==================================================================================

const CustomParagraphBiggerText = ExtensionParagraph.extend({
  renderHTML({ HTMLAttributes }) {
    return ['d-text', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
  addAttributes() {
    return {
      large: {
        default: '',
      },
    };
  },
});

export const biggerTextExtensionMap = () => {
  const map = defaultExtensionMap;
  map.set(Extension.PARAGRAPH, CustomParagraphBiggerText);
  return map;
};

export const biggerTextExtensions = Array.from(biggerTextExtensionMap().values());

// exports
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
export const extensionsForContentType = (content: BaseText) => {
  switch (content._class) {
    case BaseH1._class:
      return h1Extensions;
    case BaseH2._class:
      return h2Extensions;
    case BaseH3._class:
      return h3Extensions;
    default:
      return defaultExtensions;
  }
};
