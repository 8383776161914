/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import { Beleg } from '@adornis/accounting/api/beleg';
import type { Maybe } from '@adornis/base/utilTypes';
import { ID, Int } from '@adornis/baseql/baseqlTypes';
import { Arg, Entity, Field, Mutation, Query } from '@adornis/baseql/decorators';
import { getRawCollection } from '@adornis/baseql/server/collections';
import { context } from '@adornis/baseql/server/context';
import { runInServerContext } from '@adornis/baseql/server/server';
import { selectionSet, type BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { Contact, type BHMaybe } from '@adornis/digitale-helden-shared/db/Contact';
import { emptyValue } from '@adornis/digitale-helden-shared/db/enums';
import { LASUser } from '@adornis/digitale-helden-shared/db/las-user';
import { UserRoles } from '@adornis/digitale-helden-shared/db/permissions';
import {
  PRODUCT_BIMAP,
  PRODUCT_ZOHO_FIELDS,
  PRODUCT_ZOHO_TYPE_DEFS,
  Product,
} from '@adornis/digitale-helden-shared/db/product/Product';
import type { ProductGroupType, ProductPaymentModality } from '@adornis/digitale-helden-shared/db/product/enums';
import { getDisabledProductIDs } from '@adornis/digitale-helden-shared/db/product/queries/getDisabledProductIDs';
import { getProductByID } from '@adornis/digitale-helden-shared/db/product/queries/getProductByID';
import { getProductByName } from '@adornis/digitale-helden-shared/db/product/queries/getProductByName';
import { BiMap } from '@adornis/digitale-helden-shared/db/zoho/BiMap';
import { ZohoType } from '@adornis/digitale-helden-shared/db/zoho/enums';
import {
  serializeDateTimeZoho,
  serializeDateZoho,
  serializeDefault,
} from '@adornis/digitale-helden-shared/db/zoho/serialize-helper';
import { type ZohoRecord } from '@adornis/digitale-helden-shared/db/zoho/types';
import { ZohoModule } from '@adornis/digitale-helden-shared/db/zoho/zoho';
import { ZohoEntity } from '@adornis/digitale-helden-shared/db/zoho/zoho-entity';
import { mailer } from '@adornis/digitale-helden-shared/server/communication';
import { makeZohoAPIRequest } from '@adornis/digitale-helden-shared/server/zoho/api';
import { Recipient } from '@adornis/mails/db/recipients';
import { Message } from '@adornis/mails/server/communication';
import { GLOBAL_CONTEXT } from '@adornis/users/db/a-roles';
import { CurrentUserInfo } from '@adornis/users/db/currentUserInfo';
import { DateTime } from 'luxon';
import { LASReceipt } from '../_accounting/db/LASReceipts';
import type { LASPayment } from '../_accounting/db/Payments/LASPayment';
import { XRechnung } from '../_accounting/db/XRechnung';
import { getAllContactCompanyRelationsByContactCOQL } from '../_api/contact-company-relation/queries/getAllContactCompanyRelationsByContactCOQL';
import { removeContactByIDSafe } from '../_api/contact/mutations/removeContactByIDSafe';
import { getContactByIDCOQL } from '../_api/contact/queries/getContactByIDCOQL';
import { getAllGroupContactRelationsByContactIDCOQL } from '../_api/group-contact-relations/queries/getAllGroupContactRelationsByContactIDCOQL';
import { upsertOrder } from '../_api/order/mutations/upsertOrder';
import { pathToContent } from '../_helpers/product-helpers';
import { CampusSettingsRoute, PathToCampusSettingsRoute } from '../_routing/db/enums';
import { ContactNotFoundError } from '../errors/ContactNotFoundError';
import { InvoiceLinkNotServedError } from '../errors/InvoiceLinkNotServedError';
import { OrderNotFoundError } from '../errors/OrderNotFoundError';
import { PaymentNotFoundError } from '../errors/PaymentNotFoundError';
import { ProductNotFoundError } from '../errors/ProductNotFoundError';
import { COMPANY_ZOHO_FIELDS, Company } from './Company';
import { Group } from './Group';
import { OrderManagementFilter } from './OrderManagementFilter';
import { ContactCompanyRelation } from './Relations/ContactCompanyRelation';
import { GroupContactRelation } from './Relations/GroupContactRelation';
import { ContactCompanyPermission, GroupContactRole, OrderAttendenceStatus, OrderStatus } from './enums';
import { checkRole } from './helpers';
import { orderConfirmationTemplate } from './mail-templates/order-confirmation';

export enum ORDER_ZOHO_FIELDS {
  ID = 'id',
  NAME = 'Name',
  BESTELLSTATUS = 'Bestellstatus',
  BUYER_COMPANY = 'Buyer_Company',
  BUYER_CONTACT = 'Buyer_Contact',
  FUNDING = 'Fundingstructure',
  PRODUCT = 'Akademie_Produkt',
  GROUP = 'Gruppe',
  GROUP_TYPE = 'Order_Group_Type',
  TEILNAHMESTATUS = 'Teilnahmestatus',
  PRODUCT_CONFIGURATION = 'Dummy_Field',
  PAYMENT_MODALITY = 'Payment_Modality_Order',
  AKADEMIE_ROLLE = 'Akademie_Rolle',
  OTHER_ACADEMY_ROLE = 'Sonstige_Akademie_Rolle',
  PAYMENT_DATE = 'Payment_Date',
  EXPIRY_DATE = 'Expiry_Date',
  PAYMENT_ID = 'PaymentID',
}

export const ORDER_BIMAP = new BiMap<string, ORDER_ZOHO_FIELDS>([
  ['id', ORDER_ZOHO_FIELDS.ID],
  ['name', ORDER_ZOHO_FIELDS.NAME],
  ['status', ORDER_ZOHO_FIELDS.BESTELLSTATUS],
  ['buyerContactId', ORDER_ZOHO_FIELDS.BUYER_CONTACT],
  ['buyerCompanyId', ORDER_ZOHO_FIELDS.BUYER_COMPANY],
  ['groupId', ORDER_ZOHO_FIELDS.GROUP],
  ['groupType', ORDER_ZOHO_FIELDS.GROUP_TYPE],
  ['foerderStructureId', ORDER_ZOHO_FIELDS.FUNDING],
  ['attendanceStatus', ORDER_ZOHO_FIELDS.TEILNAHMESTATUS],
  ['productId', ORDER_ZOHO_FIELDS.PRODUCT],
  ['productConfiguration', ORDER_ZOHO_FIELDS.PRODUCT_CONFIGURATION],
  ['paymentModality', ORDER_ZOHO_FIELDS.PAYMENT_MODALITY],
  ['academyRole', ORDER_ZOHO_FIELDS.AKADEMIE_ROLLE],
  ['otherAcademyRole', ORDER_ZOHO_FIELDS.OTHER_ACADEMY_ROLE],
  ['paymentDate', ORDER_ZOHO_FIELDS.PAYMENT_DATE],
  ['expiryDate', ORDER_ZOHO_FIELDS.EXPIRY_DATE],
  ['paymentID', ORDER_ZOHO_FIELDS.PAYMENT_ID],
]);

export const ORDER_ZOHO_TYPE_DEFS = {
  [ORDER_BIMAP.reverseGet(ORDER_ZOHO_FIELDS.PAYMENT_DATE)!]: ZohoType.DATE_TIME,
  [ORDER_BIMAP.reverseGet(ORDER_ZOHO_FIELDS.EXPIRY_DATE)!]: ZohoType.DATE,
};

export const ORDER_FOREIGN_KEYS = [
  ORDER_ZOHO_FIELDS.BUYER_COMPANY,
  ORDER_ZOHO_FIELDS.BUYER_CONTACT,
  ORDER_ZOHO_FIELDS.PRODUCT,
  ORDER_ZOHO_FIELDS.GROUP,
  ORDER_ZOHO_FIELDS.FUNDING,
];

export const ORDER_DEFAULT_VALUES = [
  ORDER_BIMAP.reverseGet(ORDER_ZOHO_FIELDS.PAYMENT_DATE),
  ORDER_BIMAP.reverseGet(ORDER_ZOHO_FIELDS.NAME),
];

@Entity()
export class Order extends ZohoEntity {
  static override _class = 'Order';
  static override ZOHO_MODULE = ZohoModule.ORDERS;
  static override ZOHO_FIELDS = Array.from(ORDER_BIMAP.values).join(',');

  static override get allFields() {
    return selectionSet(() => this, 2, ['product']);
  }

  @Field(type => String) id!: string;
  @Field(type => String, { default: v => v ?? new Date().getTime().toString() }) name!: string;
  @Field(type => String) status!: OrderStatus;
  @Field(type => String) buyerContactId!: string;
  @Field(type => String) buyerCompanyId!: string;
  @Field(type => String) groupId!: string;
  @Field(type => String) groupType?: Maybe<ProductGroupType>;
  @Field(type => String) foerderStructureId!: string;
  @Field(type => String) attendanceStatus!: string;
  @Field(type => String) productId!: string;
  @Field(type => Product) productConfiguration!: Product;
  @Field(type => String) paymentModality!: ProductPaymentModality;
  @Field(type => String) academyRole!: string;
  @Field(type => String) otherAcademyRole!: string;
  @Field(type => DateTime, { default: v => (v === emptyValue ? null : v ?? DateTime.now()) })
  paymentDate: BHMaybe<DateTime>;
  @Field(type => Date) expiryDate: Maybe<Date>;
  @Field(type => String) paymentID!: string;

  isActive(product: Product): boolean {
    if (product.isInactive) return false;
    if (!this.groupId) {
      // einzelbestellung
      if (
        this.attendanceStatus === OrderAttendenceStatus.ABGEBROCHEN ||
        (this.expiryDate && this.expiryDate < DateTime.now().toJSDate())
      )
        return false;
      return true;
    } else {
      // gruppenbestellung
      if (this.status === OrderStatus.DEACTIVE || (this.expiryDate && this.expiryDate < DateTime.now().toJSDate()))
        return false;
      return true;
    }
  }

  get isUnbefristet(): boolean {
    if (this.expiryDate === null || this.expiryDate === undefined) return true;
    return false;
  }

  get toFilteredJSON() {
    const fields = {};
    const keys = Array.from(ORDER_BIMAP.keys);
    keys.forEach(key => {
      if (key && this.toJSON()[key]) {
        fields[key] = this.toJSON()[key];
      }
    });
    return fields;
  }

  override serializeZoho = (isNew: boolean = false) => {
    const fields = {};
    const keys = Array.from(ORDER_BIMAP.keys);
    keys.forEach(key => {
      const keyZoho = ORDER_BIMAP.get(key);
      if (
        keyZoho &&
        (this[key] || this[key] === emptyValue || typeof this[key] === 'boolean') &&
        keyZoho !== ORDER_ZOHO_FIELDS.PRODUCT_CONFIGURATION
      ) {
        if (ORDER_FOREIGN_KEYS.includes(keyZoho)) {
          fields[keyZoho] = {
            id: this[key],
          };
        } else {
          if (this[key] === emptyValue) fields[keyZoho] = null;
          else {
            switch (ORDER_ZOHO_TYPE_DEFS[key]) {
              case ZohoType.DATE:
                fields[keyZoho] = serializeDateZoho(this[key]);
                break;
              case ZohoType.DATE_TIME:
                fields[keyZoho] = serializeDateTimeZoho(this[key]);
                break;
              default:
                fields[keyZoho] = serializeDefault(this[key]);
            }
          }
        }
      }
    });
    // productconfiguration
    const keysProduct = Array.from(PRODUCT_BIMAP.keys);

    if (this.productConfiguration)
      keysProduct.forEach(keyProduct => {
        const keyProductZoho = PRODUCT_BIMAP.get(keyProduct);
        if (keyProductZoho && keyProductZoho !== PRODUCT_ZOHO_FIELDS.ID && this.productConfiguration[keyProduct]) {
          switch (PRODUCT_ZOHO_TYPE_DEFS[keyProduct]) {
            case ZohoType.DATE:
              fields[keyProductZoho] = serializeDateZoho(this.productConfiguration[keyProduct]);
              break;
            case ZohoType.DATE_TIME:
              fields[keyProductZoho] = serializeDateTimeZoho(this.productConfiguration[keyProduct]);
              break;
            default:
              fields[keyProductZoho] = serializeDefault(this.productConfiguration[keyProduct]);
          }
        }
      });

    const data: ZohoRecord<any> = {
      data: [fields],
      trigger: ['workflow'],
    };

    return JSON.stringify(data).replace(/"id":[ ]?"([0-9]+)"/g, '"id":$1');
  };

  static override deserializeZoho = (rawData: any) => {
    const fields: Record<string, any> = {};
    const keys = Array.from(ORDER_BIMAP.reverseKeys);
    keys.forEach(key => {
      const keyLAS = ORDER_BIMAP.reverseGet(key);
      const value = rawData[key];
      if (keyLAS) {
        if (ORDER_FOREIGN_KEYS.includes(key)) {
          fields[keyLAS] = value?.id ?? null;
        } else if (!value && ORDER_DEFAULT_VALUES.includes(keyLAS)) {
          fields[keyLAS] = emptyValue;
        } else {
          fields[keyLAS] = value ?? null;
        }
      }
    });

    // weil default value gesetzt ist, muss der geändert werden bei neuer Initialisierung
    fields.productConfiguration = Product.deserializeZoho(rawData);
    const order = new Order({
      ...fields,
    });
    const key = ORDER_BIMAP.reverseGet(ORDER_ZOHO_FIELDS.PAYMENT_DATE);
    if (key && !fields[key]) order.paymentDate = null;
    return order;
  };

  /**
   * Send order confirmation mail by orderID
   *
   * @param {string} orderID orderID to reference in mailing
   *
   * possible errors:
   * @throws {FileByInvoiceNotFoundError} fileID not found by invoiceNr
   * @throws {InvoiceLinkNotServedError} invoicePath couldn't be served
   * @throws {OrderNotFoundError} orderID doesn't exist
   * @throws {ProductNotFoundError} productID on order doesn't exist
   * @throws {ContactNotFoundError} contactID on order doesn't exist
   */
  @Mutation(type => String)
  static sendOrderConfirmationMail(
    @Arg('orderID', type => String) orderID: string,
    @Arg('belegID', type => String) belegID: string,
  ) {
    return async () => {
      const rawBelegCollection = await getRawCollection<Beleg>(Beleg._collectionName);
      const beleg = await rawBelegCollection.findOne<Beleg>({ _id: belegID });
      if (!beleg) throw new Error('beleg not found');

      const invoicePath = LASReceipt.getServeLink(belegID);
      if (!invoicePath) throw new InvoiceLinkNotServedError();

      const order = await Order.getOrderByIDCOQL(orderID)({
        id: 1,
        productId: 1,
        buyerContactId: 1,
        paymentID: 1,
        productConfiguration: { name: 1, price: 1 },
      });

      if (!order) throw new OrderNotFoundError();

      const { LASPayment } = await import('../_accounting/db/Payments/LASPayment');
      const payment = await LASPayment.getByID<LASPayment>(order.paymentID)({ paymentType: 1 });

      if (!payment) throw new PaymentNotFoundError();

      const product = await getProductByID(order.productId)({
        id: 1,
        name: 1,
        imageSmallUrl: 1,
        conditionsOfParticipation: 1,
      });
      if (!product) throw new ProductNotFoundError();

      const contact = await getContactByIDCOQL(order.buyerContactId)({
        id: 1,
        firstName: 1,
        lastName: 1,
        email: 1,
      });
      if (!contact) throw new ContactNotFoundError();

      const { PaymentMethod } = await import('../_forms/_new-mentoring-form/db/SignupNewMentoringForm');
      const isFunding = payment.paymentType === 'funding';
      const isFinancing = payment.paymentType === 'financing';

      const attachmentPaths: string[] = [
        `${invoicePath}/${beleg.belegNummer}.pdf`,
        product.conditionsOfParticipation ??
          'https://s3.digitale-helden.de/akademie/public/2023/07/230613-Teilnahmebedingungen.pdf',
      ];

      if (isFinancing) {
        // an dieser Stelle muss nun die XRechnung erstellt werden
        const xRechnungFileID = await runInServerContext(async () => {
          const result = await XRechnung.createXRechnungForOrder(orderID, belegID)();
          return result;
        });
        const xRechnungPath = XRechnung.getServeLink(xRechnungFileID);
        attachmentPaths.push(`${xRechnungPath}/XRechnung.xml`);
      }

      const paymentTypeTranslation = {
        [PaymentMethod.CARD]: 'Kartenzahlung',
        [PaymentMethod.PAYPAL]: 'PayPal',
        [PaymentMethod.KLARNA]: 'Klarna',
        [PaymentMethod.ON_ACCOUNT]: 'Auf Rechnung',
        funding: 'Förderung',
        financing: 'Finanzierung',
      };

      await mailer.sendMail(
        await Message.compose({
          html: orderConfirmationTemplate({
            paymentType: paymentTypeTranslation[payment.paymentType] as string,
            firstName: contact.firstName ?? '',
            lastName: contact.lastName ?? '',
            imageLink: product.imageSmallUrl ?? '',
            price: isFunding ? 0 : order.productConfiguration.price,
            productName: order.productConfiguration.name,
            productLink: await pathToContent(product.id),
            settingsInvoiceLink: PathToCampusSettingsRoute(CampusSettingsRoute.INVOICES),
            isFunding,
          }),
          subject: 'Danke für deine Bestellung',
          attachmentPaths,
        }),
        new Recipient([contact.email]),
      );
    };
  }

  /**
   * Get Order by paymentID
   *
   * @param {string} paymentID paymentID to check if order exists
   * @return {Order} Order with given paymentID. Should always be just one. If multiple orders were found, the first one is returned (TODO: better solution needed)
   */
  @Query(type => Order)
  static getOrderByPaymentIDCOQL(@Arg('paymentID', type => String) paymentID: string) {
    return async (gqlFields: BaseQLSelectionSet<Order>) => {
      if (!paymentID) return null;
      const endpoint = `coql`;
      const query = `SELECT ${this.gqlFieldsZoho(gqlFields, ORDER_BIMAP).join(',')} FROM ${this.ZOHO_MODULE} WHERE ${
        ORDER_ZOHO_FIELDS.PAYMENT_ID
      } = '${paymentID}'`;
      const result = await makeZohoAPIRequest({
        method: 'post',
        endpoint,
        data: { select_query: query },
        zohoModule: this.ZOHO_MODULE,
        isRawRequest: true,
      });
      if (!result?.data?.[0]) return null;
      const data = result.data[0];
      const deserialized = this.deserializeZoho(data);
      return deserialized;
    };
  }

  /**
   * Get Orders by array of payment ids
   *
   * @param {string[]} paymentIDs paymentID to check if order exists
   * @return {Order[]} Order with given paymentID
   */
  @Query(type => [Order])
  static getOrdersByPaymentIDsCOQL(@Arg('paymentIDs', type => [String]) paymentIDs: string[]) {
    return async (gqlFields: BaseQLSelectionSet<Order>) => {
      if (!paymentIDs || paymentIDs.length === 0) return [];
      const endpoint = `coql`;

      const query = `SELECT ${this.gqlFieldsZoho(gqlFields, ORDER_BIMAP).join(',')} FROM ${this.ZOHO_MODULE} WHERE (${
        ORDER_ZOHO_FIELDS.PAYMENT_ID
      } in (${paymentIDs.map(data => `'${data}'`).join(', ')}))`;
      const result = await makeZohoAPIRequest({
        method: 'post',
        endpoint,
        data: { select_query: query },
        zohoModule: this.ZOHO_MODULE,
        isRawRequest: true,
      });
      if (!result?.data) return [];
      const data = result.data.map(data => this.deserializeZoho(data)) as Order[];
      return data;
    };
  }

  @Mutation(type => Order)
  static createOrderForFreeProduct(@Arg('productID', type => String) productID: string) {
    return async (gqlFields: BaseQLSelectionSet<Order>) => {
      if (context.serverContext) throw new Error('cant be called by server currently');
      const user = await CurrentUserInfo.getMyself<LASUser>()({ _id: 1, contact: Contact.allFields });
      if (!user) throw new Error('no user for order creation found');
    };
  }

  @Query(type => Int)
  static countAllOrders() {
    return async () => {
      const zohoResult = await makeZohoAPIRequest({
        method: 'get',
        endpoint: `${this.ZOHO_MODULE}/actions/count`,
        zohoModule: this.ZOHO_MODULE,
      });
      return zohoResult.count || 0;
    };
  }

  @Query(type => Int)
  static countAllOrdersFilteredCOQL(@Arg('filter', type => OrderManagementFilter) filter: OrderManagementFilter) {
    return async () => {
      const endpoint = `coql`;

      const defaultWhere = `${COMPANY_ZOHO_FIELDS.ID} is not null`;

      const orderTypeWhere = filter.orderType
        ? filter.orderType === 'group'
          ? `${ORDER_ZOHO_FIELDS.GROUP} is not null`
          : `${ORDER_ZOHO_FIELDS.GROUP} is null`
        : null;
      const productNameWhere = filter.productName ? `${PRODUCT_ZOHO_FIELDS.NAME} = '${filter.productName}'` : null;
      const orderBy = filter.orderPaymentDate
        ? `ORDER BY ${ORDER_ZOHO_FIELDS.PAYMENT_DATE} ${filter.orderPaymentDate}`
        : ``;

      const filteredWhere: string[] = [];
      if (orderTypeWhere) filteredWhere.push(orderTypeWhere);
      if (productNameWhere) filteredWhere.push(productNameWhere);

      const where = filteredWhere.length === 0 ? defaultWhere : filteredWhere.join(' AND ');
      const countSelector = `COUNT(${ORDER_ZOHO_FIELDS.ID})`;

      const query = `SELECT ${countSelector} FROM ${this.ZOHO_MODULE} WHERE (${where}) ${orderBy}`;

      const result = await makeZohoAPIRequest({
        method: 'post',
        endpoint,
        data: { select_query: query },
        zohoModule: this.ZOHO_MODULE,
        isRawRequest: true,
      });
      if (!result?.data) return null;
      return result.data[countSelector] as number;
    };
  }

  @Query(type => Int)
  static countOrderByContactID(@Arg('id', type => String) id: string) {
    return async () => {
      const endpoint = `coql`;
      const countSelector = `COUNT(${ORDER_ZOHO_FIELDS.ID})`;
      const query = `SELECT ${countSelector} FROM ${this.ZOHO_MODULE} WHERE ${ORDER_ZOHO_FIELDS.BUYER_CONTACT} = ${id}`;
      const result = await makeZohoAPIRequest({
        method: 'post',
        endpoint,
        data: { select_query: query },
        zohoModule: this.ZOHO_MODULE,
        isRawRequest: true,
      });
      if (!result?.data) return null;
      return result.data[countSelector] as number;
    };
  }

  @Query(type => [Order])
  static getOrderPaginated(
    @Arg('page', type => Int) page: number,
    @Arg('perPage', type => Int) perPage: number,
    @Arg('filter', type => OrderManagementFilter) filter: OrderManagementFilter,
  ) {
    return async (gqlFields: BaseQLSelectionSet<Order>) => {
      if (context.serverContext)
        throw new Error('getSchoolsByContactAndPermissionPaginated kann nicht vom server context aufgerufen werden');
      const user = (await context.user()) as Maybe<LASUser>;
      if (!user) return [];

      const userHasViewAllPermission = user.hasPermission('Admin.ViewUserlist', GLOBAL_CONTEXT);
      if (!userHasViewAllPermission) throw new Error('insufficient permissions');

      return await this.getOrdersPaginatedCOQL(page, perPage, filter)(gqlFields);
    };
  }

  @Query(type => Order)
  static getOrderByIDCOQL(@Arg('id', type => String) id: string) {
    return async (gqlFields: BaseQLSelectionSet<Order>) => {
      if (!id) return null;

      const fields = this.gqlFieldsZoho(gqlFields, ORDER_BIMAP).join(',');

      const endpoint = `coql`;
      const query = `SELECT ${fields} FROM ${this.ZOHO_MODULE} WHERE ${ORDER_ZOHO_FIELDS.ID} = '${id}'`;
      const result = await makeZohoAPIRequest({
        method: 'post',
        endpoint,
        data: { select_query: query },
        zohoModule: this.ZOHO_MODULE,
        isRawRequest: true,
      });
      if (!result?.data?.[0]) return null;
      const data = result.data[0];
      const deserialized = this.deserializeZoho(data);
      return deserialized;
    };
  }

  @Query(type => String)
  static deleteOrderByID(@Arg('id', type => String) id: string) {
    return async () => {
      await checkRole({ context, role: UserRoles.SUPER_ADMIN });
      const endpoint = `${this.ZOHO_MODULE}/${id}`;
      await makeZohoAPIRequest({ method: 'delete', endpoint, zohoModule: this.ZOHO_MODULE });
    };
  }

  @Mutation(type => String)
  static removeOrderAndRelationsSafe(@Arg('id', type => String) id: string) {
    return async () => {
      await checkRole({ context, role: UserRoles.SUPER_ADMIN });
      if (context.serverContext) throw new Error('dont call by server');
      const user = (await context.user()) as Maybe<LASUser>;
      if (!user) return [];

      const userHasViewAllPermission = user.hasPermission('Admin.ViewUserlist', GLOBAL_CONTEXT);
      if (!userHasViewAllPermission) throw new Error('insufficient permissions');

      const order = await this.getOrderByIDCOQL(id)(Order.allFields);
      if (!order) return;

      // simple case (einzelbestellung)
      if (!order.groupId) {
        await removeContactByIDSafe(order.buyerContactId)();
      }

      // more complicated case (gruppenbestellung)
      if (order.groupId) {
        await Group.removeGroupByIDSafe(order.groupId)();
        await Company.removeCompanyByIDSafe(order.buyerCompanyId, order.id)();
        // kontakt explizit am Ende löschen, sonst existieren noch relations welche den Löschvorgang verhindern würden.
        await removeContactByIDSafe(order.buyerContactId)();
      }

      await this.deleteOrderByID(order.id)();
    };
  }

  @Query(type => [Order])
  static getOrdersPaginatedCOQL(
    @Arg('page', type => Int) page: number,
    @Arg('perPage', type => Int) perPage: number,
    @Arg('filter', type => OrderManagementFilter) filter: OrderManagementFilter,
  ) {
    return async (gqlFields: BaseQLSelectionSet<Order>) => {
      if (!page || !perPage) return [];

      const endpoint = `coql`;

      const defaultWhere = `${COMPANY_ZOHO_FIELDS.ID} is not null`;

      const orderTypeWhere = filter.orderType
        ? filter.orderType === 'group'
          ? `${ORDER_ZOHO_FIELDS.GROUP} is not null`
          : `${ORDER_ZOHO_FIELDS.GROUP} is null`
        : null;
      const productNameWhere = filter.productName ? `${PRODUCT_ZOHO_FIELDS.NAME} = '${filter.productName}'` : null;
      const orderBy = filter.orderPaymentDate
        ? `ORDER BY ${ORDER_ZOHO_FIELDS.PAYMENT_DATE} ${filter.orderPaymentDate}`
        : ``;

      const filteredWhere: string[] = [];
      if (orderTypeWhere) filteredWhere.push(orderTypeWhere);
      if (productNameWhere) filteredWhere.push(productNameWhere);

      const where = filteredWhere.length === 0 ? defaultWhere : filteredWhere.join(' AND ');

      const query = `SELECT ${[
        // order data
        ORDER_ZOHO_FIELDS.ID,
        ORDER_ZOHO_FIELDS.NAME,
        ORDER_ZOHO_FIELDS.PAYMENT_DATE,
        ORDER_ZOHO_FIELDS.BUYER_COMPANY,
        ORDER_ZOHO_FIELDS.BUYER_CONTACT,
        ORDER_ZOHO_FIELDS.GROUP,
        // order product data
        PRODUCT_ZOHO_FIELDS.NAME,
      ].join(',')} FROM ${this.ZOHO_MODULE} WHERE (${where}) ${orderBy} LIMIT ${perPage} OFFSET ${
        (page - 1) * perPage
      }`;

      const result = await makeZohoAPIRequest({
        method: 'post',
        endpoint,
        data: { select_query: query },
        zohoModule: this.ZOHO_MODULE,
        isRawRequest: true,
      });
      if (!result?.data) return null;
      const resultData = result.data;
      const deserialized = resultData.map(data => this.deserializeZoho(data)) as Order[];
      return deserialized;
    };
  }

  @Query(type => [Order])
  static getOrdersPaginated(
    @Arg('page', type => Int) page: number,
    @Arg('perPage', type => Int) perPage: number,
    @Arg('filter', type => OrderManagementFilter) filter: OrderManagementFilter,
  ) {
    return async (gqlFields: BaseQLSelectionSet<Order>) => {
      if (!page || !perPage) return [];
      const endpoint = `${this.ZOHO_MODULE}`;

      const result = await makeZohoAPIRequest({
        method: 'get',
        endpoint,
        data: {
          fields: [
            // order data
            ORDER_ZOHO_FIELDS.NAME,
            ORDER_ZOHO_FIELDS.PAYMENT_DATE,
            ORDER_ZOHO_FIELDS.BUYER_COMPANY,
            ORDER_ZOHO_FIELDS.BUYER_CONTACT,
            ORDER_ZOHO_FIELDS.GROUP,
            // order product data
            PRODUCT_ZOHO_FIELDS.NAME,
          ].join(','),
          page,
          per_page: perPage,
          module: this.ZOHO_MODULE,
        },
        zohoModule: this.ZOHO_MODULE,
      });

      if (!result?.data) return [];
      const deserialized = result.data.map(data => this.deserializeZoho(data)) as Order[];
      return deserialized;
    };
  }

  @Query(type => [Order])
  static getOrdersByBuyerIDCOQL(
    @Arg('contactId', type => String) contactId: string,
    @Arg('onlyActive', type => Boolean) onlyActive?: boolean,
  ) {
    return async (gqlFields: BaseQLSelectionSet<Order>) => {
      if (!contactId) return [];
      const endpoint = `coql`;

      const buyerEqualsContactID = () => `(${ORDER_ZOHO_FIELDS.BUYER_CONTACT} = '${contactId}')`;
      const bestellStatusActive = () => `(${ORDER_ZOHO_FIELDS.BESTELLSTATUS} is not '${OrderStatus.DEACTIVE}')`;
      const expiryDateValid = () => `(${ORDER_ZOHO_FIELDS.EXPIRY_DATE} >= '${DateTime.now().toFormat('yyyy-LL-dd')}')`;
      const expiryDateNotGiven = () => `(${ORDER_ZOHO_FIELDS.EXPIRY_DATE} is null)`;
      const isVisualProduct = await this.isVisualProductCOQL();

      const fields = this.gqlFieldsZoho(gqlFields, ORDER_BIMAP).join(',');

      const query = `SELECT ${fields} FROM ${this.ZOHO_MODULE} WHERE ${buyerEqualsContactID()}${
        onlyActive
          ? `AND
              (${bestellStatusActive()}AND(${expiryDateValid()}OR${expiryDateNotGiven()}))`
          : ''
      }${isVisualProduct ? `AND${isVisualProduct}` : ''}`;

      const result = await makeZohoAPIRequest({
        method: 'post',
        endpoint,
        data: { select_query: query },
        zohoModule: this.ZOHO_MODULE,
        isRawRequest: true,
      });

      if (!result?.data) return [];

      const deserializedOrders = result.data.map(rawData => this.deserializeZoho(rawData)) as Order[];

      return deserializedOrders;
    };
  }

  @Query(type => [Order])
  static getOrdersWithInvoiceByContactIDCOQL(@Arg('contactId', type => String) contactId: string) {
    return async (gqlFields: BaseQLSelectionSet<Order>) => {
      if (!contactId) return [];
      const endpoint = `coql`;

      const fields = this.gqlFieldsZoho(gqlFields, ORDER_BIMAP).join(',');

      const query = `SELECT ${fields} FROM ${this.ZOHO_MODULE} WHERE ${ORDER_ZOHO_FIELDS.PAYMENT_ID} is not null AND ${ORDER_ZOHO_FIELDS.BUYER_CONTACT} = '${contactId}'`;

      const result = await makeZohoAPIRequest({
        method: 'post',
        endpoint,
        data: { select_query: query },
        zohoModule: this.ZOHO_MODULE,
        isRawRequest: true,
      });

      if (!result?.data) return [];

      const deserializedOrders = result.data.map(rawData => this.deserializeZoho(rawData)) as Order[];

      return deserializedOrders;
    };
  }

  private static async isVisualProductCOQL(): Promise<string> {
    const productIDs = await getDisabledProductIDs()();
    if (!productIDs || productIDs.length <= 0) return '';
    return `(${ORDER_ZOHO_FIELDS.PRODUCT} not in (${productIDs}))`;
  }

  static override gqlFieldsZoho = (gqlFields: BaseQLSelectionSet<any> | any, bimap: BiMap<string, any>): string[] => {
    let fields = super.gqlFieldsZoho(gqlFields, ORDER_BIMAP);

    if (gqlFields.productConfiguration) {
      const productFields = Product.gqlFieldsZoho(gqlFields.productConfiguration, PRODUCT_BIMAP);
      fields = fields.concat(productFields);
    }

    const fieldsUnique = Array.from(new Set(fields).values());
    const filteredFieldsUnique = fieldsUnique.filter(
      key =>
        ![
          ORDER_ZOHO_FIELDS.PRODUCT_CONFIGURATION,
          PRODUCT_ZOHO_FIELDS.DONT_VISUALIZE,
          PRODUCT_ZOHO_FIELDS.DESCRIPTION,
          PRODUCT_ZOHO_FIELDS.EVENT_DATE_TIME,
          PRODUCT_ZOHO_FIELDS.EVENT_DATE_TIME_END,
          PRODUCT_ZOHO_FIELDS.SALES_END_DATE,
          PRODUCT_ZOHO_FIELDS.SALES_START_DATE,
          PRODUCT_ZOHO_FIELDS.URL_LAS,
          PRODUCT_ZOHO_FIELDS.URL_LAS_ADMIN,
          PRODUCT_ZOHO_FIELDS.URL_REGISTER_FORM,
          PRODUCT_ZOHO_FIELDS.ZIELGRUPPEN,
          PRODUCT_ZOHO_FIELDS.TAXES,
          PRODUCT_ZOHO_FIELDS.TAXABLE,
          PRODUCT_ZOHO_FIELDS.PRIO_CATALOG,
          PRODUCT_ZOHO_FIELDS.TIME_STRING,
          PRODUCT_ZOHO_FIELDS.GROUP_TYPE,
          PRODUCT_ZOHO_FIELDS.IMAGE_L,
          PRODUCT_ZOHO_FIELDS.IMAGE_S,
          PRODUCT_ZOHO_FIELDS.PDP_IMAGE_DESKTOP,
          PRODUCT_ZOHO_FIELDS.PDP_IMAGE_MOBILE,
          PRODUCT_ZOHO_FIELDS.PDP_IMAGE_BANNER,
          PRODUCT_ZOHO_FIELDS.PDP_IMAGE_BANNER_MOBILE,
          PRODUCT_ZOHO_FIELDS.INACTIVE,
          PRODUCT_ZOHO_FIELDS.TEILNAHMEBEDINGUNGEN,
          PRODUCT_ZOHO_FIELDS.VERSTECKTES_PRODUKT,
          PRODUCT_ZOHO_FIELDS.WIDERRUFSRECHT,
          PRODUCT_ZOHO_FIELDS.INFO_LIST,
          PRODUCT_ZOHO_FIELDS.PDP_DESCRIPTION,
          PRODUCT_ZOHO_FIELDS.PDP_THEMES,
          PRODUCT_ZOHO_FIELDS.ZIELGRUPPEN_BESCHREIBUNG,
          PRODUCT_ZOHO_FIELDS.LAUFZEIT_BIS,
        ].includes(key as any),
    );

    return filteredFieldsUnique;
  };

  @Query(type => [Order])
  static getOrderOfInstitutionsCOQL(@Arg('institutionIDs', type => [String]) institutionIDs: string[]) {
    return async (gqlFields: BaseQLSelectionSet<Order>) => {
      if (institutionIDs.length === 0) return [];

      const endpoint = `coql`;
      const fields = this.gqlFieldsZoho(gqlFields, ORDER_BIMAP).join(',');

      const query = `SELECT ${fields} FROM ${this.ZOHO_MODULE} WHERE ${
        ORDER_ZOHO_FIELDS.BUYER_COMPANY
      } in (${institutionIDs}) AND ${await this.isVisualProductCOQL()}`;

      const result = await makeZohoAPIRequest({
        method: 'post',
        endpoint,
        data: { select_query: query },
        zohoModule: this.ZOHO_MODULE,
        isRawRequest: true,
      });

      if (!result?.data) return [];

      const deserializedOrders = result.data.map(rawData => this.deserializeZoho(rawData)) as Order[];

      return deserializedOrders;
    };
  }

  @Query(type => [Order])
  static getOrdersByGroups(
    @Arg('groupIDs', type => [ID]) groupIDs: string[],
    @Arg('onlyActive', type => Boolean) onlyActive?: boolean,
  ) {
    return async (gqlFields: BaseQLSelectionSet<Order>) => {
      groupIDs = groupIDs.filter(g => !!g);
      if (!groupIDs || groupIDs.length === 0) return [];

      const endpoint = `coql`;
      const fields = this.gqlFieldsZoho(gqlFields, ORDER_BIMAP).join(',');

      const query = `SELECT ${fields} FROM ${this.ZOHO_MODULE} WHERE ${ORDER_ZOHO_FIELDS.GROUP} in (${groupIDs}) ${
        onlyActive ? `AND (${ORDER_ZOHO_FIELDS.BESTELLSTATUS} is not '${OrderStatus.DEACTIVE}')` : ''
      } AND (${await this.isVisualProductCOQL()})`;

      const result = await makeZohoAPIRequest({
        method: 'post',
        endpoint,
        data: { select_query: query },
        zohoModule: this.ZOHO_MODULE,
        isRawRequest: true,
      });
      if (!result?.data) return null;
      const deserializedOrders = result.data.map(rawData => this.deserializeZoho(rawData)) as Order[];
      return deserializedOrders;
    };
  }

  @Query(type => Boolean)
  static isAdminOfOrder(@Arg('productName', type => String) productName: string) {
    // eslint-disable-next-line max-statements, complexity
    return async () => {
      const product = await getProductByName(productName)(Product.allFields);
      if (!product) return false;
      if (context.serverContext) return false;
      const user = (await context.user()) as Maybe<LASUser>;
      if (!user?.zohoID) return false;
      const orders = await Order.getAllOrdersByContactId(user.zohoID)(Order.allFields);
      if (orders.length === 0) return false;
      const order = orders.find(order => order.productId === product.id);
      if (!order) return false;

      if (user.zohoID === order.buyerContactId) return true;
      if (order.buyerCompanyId) {
        const contactCompanyRelation = await ContactCompanyRelation.getContactCompanyRelation(
          user.zohoID,
          order.buyerCompanyId,
        )(ContactCompanyRelation.allFields);

        if (contactCompanyRelation?.permissions?.includes(ContactCompanyPermission.ADMIN)) return true;
      }

      if (order.groupId) {
        const groupContactRelation = await GroupContactRelation.getGroupContactRelationByGroupAndContact(
          order.groupId,
          user.zohoID,
        )(GroupContactRelation.allFields);
        if (groupContactRelation?.groupContactRole === GroupContactRole.GROUP_ADMIN) return true;
      }

      return false;
    };
  }

  @Query(type => [Order])
  static getAllOrdersByContactId(@Arg('contactId', type => String) contactId: string) {
    return async (gqlFields: BaseQLSelectionSet<Order>) => {
      const buyerOrders = await this.getOrdersByBuyerIDCOQL(contactId)(gqlFields);
      const { DigitaleHelden } = await import('../_api/namespaces');
      const groupContactRelations = await getAllGroupContactRelationsByContactIDCOQL(contactId)({
        groupId: 1,
        status: 1,
      });

      const groupContactRelationsFiltered = groupContactRelations.filter(
        relation =>
          ![OrderAttendenceStatus.ABGEBROCHEN, OrderAttendenceStatus.NICHT_TEILGENOMMEN].includes(relation.status),
      );

      const groupIds = groupContactRelationsFiltered.map(relation => relation.groupId);
      const groupOrders = await Order.getOrdersByGroups(groupIds)(gqlFields);

      const contactCompanyRelations = await getAllContactCompanyRelationsByContactCOQL(contactId)({
        companyId: 1,
      });
      const companyIDs = (contactCompanyRelations ?? []).map(relation => relation.companyId ?? '').filter(e => !!e);
      const ordersOfInstitution = await Order.getOrderOfInstitutionsCOQL(companyIDs)(gqlFields);

      const orderMap = new Map<string, Order>();

      (buyerOrders ?? [])
        .concat(groupOrders ?? [])
        .concat(ordersOfInstitution ?? [])
        .forEach(order => {
          if (!orderMap.has(order.id)) {
            orderMap.set(order.id, order);
          }
        });

      return Array.from(orderMap.values());
    };
  }

  @Query(type => Int)
  static countOrdersByFunding(@Arg('fundingId', type => String) fundingId: string) {
    return async () => {
      const endpoint = `${this.ZOHO_MODULE}/search`;
      const result = await makeZohoAPIRequest({
        method: 'get',
        endpoint,
        data: { criteria: `(Fundingstructure:equals:${fundingId})`, fields: ORDER_ZOHO_FIELDS.ID },
        zohoModule: this.ZOHO_MODULE,
      });
      if (!result?.data) return 0;
      const countOrders = result.data.length ?? 0;
      return countOrders;
    };
  }

  @Query(type => Order)
  static getActiveOrderByBuyer(
    @Arg('buyerId', type => String) buyerId: string,
    @Arg('productId', type => String) productId: string,
  ) {
    return async (gqlFields: BaseQLSelectionSet<Order>) => {
      const endpoint = `${this.ZOHO_MODULE}/search`;
      const result = await makeZohoAPIRequest({
        method: 'get',
        endpoint,
        data: {
          criteria: `(${ORDER_ZOHO_FIELDS.EXPIRY_DATE}:greater_equal:${DateTime.now().toFormat('yyyy-LL-dd')})AND(${
            ORDER_ZOHO_FIELDS.BUYER_CONTACT
          }:equals:${buyerId})AND(${ORDER_ZOHO_FIELDS.PRODUCT}:equals:${productId})`,
          fields: ORDER_ZOHO_FIELDS.ID,
        },
        zohoModule: this.ZOHO_MODULE,
      });

      const data = result?.data?.[0];
      if (!data) return null;

      const resultData = result.data[0];
      const deserializedContact = this.deserializeZoho(resultData);
      return deserializedContact;
    };
  }

  @Mutation(type => Order)
  public static createOrderByContactAndProduct(
    @Arg('contactID', type => String) contactID: string,
    @Arg('productID', type => String) productID: string,
    @Arg('academyRole', type => String) academyRole: string,
    @Arg('otherAcademyRole', type => String) otherAcademyRole: Maybe<string>,
  ) {
    return async (gqlFields: BaseQLSelectionSet<Order>) => {
      const user = await CurrentUserInfo.getMyself<LASUser>()({ zohoID: 1 });
      if (user?.zohoID !== contactID && !context.serverContext) throw new Error('no permission to create this order');

      const contact = await getContactByIDCOQL(contactID)(Contact.allFields);
      const product = await getProductByID(productID)(Product.allFields);

      if (!product || !contact) return;

      const order = new Order({
        status: OrderStatus.ACTIVE,
        buyerContactId: contact.id ?? '',
        productId: product.id,
        productConfiguration: product,
        attendanceStatus: OrderAttendenceStatus.ANGEMELDET,
        paymentDate: DateTime.now(),
        academyRole,
        otherAcademyRole,
      });

      if (!product.durationInDays || product.durationInDays === 0) {
        order.expiryDate = null;
      } else {
        order.expiryDate = DateTime.now().plus({ days: product.durationInDays }).toJSDate();
      }

      await upsertOrder(order)(Order.allFields);
    };
  }
}
