import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import { XBuildify } from '@adornis/buildify/client/x-buildify';
import { RXController } from '@adornis/chemistry/controllers/RXController';
import { customElement, property } from 'lit/decorators.js';

@customElement('d-buildify')
export class DBuildify extends XBuildify {
  // @state() private readonly _currentUser = CurrentUserController(this);
  // @property({ attribute: false }) override customContent = new RXController<Maybe<BuildifyCustomContentDefinition>>(
  //   this,
  //   CustomContentExtension,
  // );
  @property({ attribute: false }) override extensions = new RXController<BuildifyExtension[]>(this, []);

  // override connectedCallback(): void {
  //   super.connectedCallback();

  //   this._currentUser.observable.pipe(filter(Boolean)).subscribe(user => {
  //     const filtered = this.value.value?.filter(base => !base.permission || user.hasRole(UserRoles[base.permission]));
  //     this.value.next(filtered);
  //   });
  // }
}
