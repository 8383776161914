import { Config } from '@adornis/config/config';

export const config = new Config('project_name', {
  refresh_token: { env: true, client: false, required: true, name: 'REFRESH_TOKEN' },
  client_id: { env: true, client: false, required: true, name: 'CLIENT_ID' },
  client_secret: { env: true, client: false, required: true, name: 'CLIENT_SECRET' },
  ZOHO_API_WARN_MAIL: {
    env: true,
    client: false,
    required: false,
    defaultVal: 'digitale-helden@adornis.de',
    name: 'ZOHO_API_WARN_MAIL',
  },
  stripePrivateKey: { env: true, client: false, required: true, name: 'STRIPE_PRIVATE_KEY' },
  stripePublishableKey: { env: true, client: true, required: true, name: 'STRIPE_PUBLISHABLE_KEY' },
});
