import { registerQuery } from '@adornis/baseql/metadata/register';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import {
  GROUP_CONTACT_RELATION_BIMAP,
  GROUP_CONTACT_RELATION_ZOHO_FIELDS,
  GroupContactRelation,
} from '../../../db/Relations/GroupContactRelation';
import { makeZohoAPIRequest } from '../../../server/zoho/api';

//* Resolver
const getAllGroupContactRelationsByContactIDCOQLResolver = (contactID: string) => {
  return async (gqlFields: BaseQLSelectionSet<GroupContactRelation>) => {
    const fields = GroupContactRelation.gqlFieldsZoho(gqlFields, GROUP_CONTACT_RELATION_BIMAP).join(',');
    const query = `SELECT ${fields} FROM ${GroupContactRelation.ZOHO_MODULE} WHERE ${GROUP_CONTACT_RELATION_ZOHO_FIELDS.CONTACT} = '${contactID}'`;

    const result = await makeZohoAPIRequest({
      method: 'post',
      endpoint: 'coql',
      data: { select_query: query },
      zohoModule: GroupContactRelation.ZOHO_MODULE,
      isRawRequest: true,
    });

    if (!result?.data?.[0]) return [];
    const deserializedRelation = result.data.map(data =>
      GroupContactRelation.deserializeZoho(data),
    ) as GroupContactRelation[];
    return deserializedRelation;
  };
};

//* Query
export const getAllGroupContactRelationsByContactIDCOQL = registerQuery({
  type: () => [GroupContactRelation],
  operationName: 'getAllGroupContactRelationsByContactIDCOQL',
  resolve: getAllGroupContactRelationsByContactIDCOQLResolver,
  params: [{ type: () => String, name: 'contactID' }],
});
