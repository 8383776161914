import { ExtensionBoilerplate } from '@adornis/buildify/client/extensions/ContentExtensionBoilerplate';
import { IconExtension } from '@adornis/buildify/client/extensions/IconExtension';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import { css } from '@adornis/chemistry/directives/css.js';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import '../../../../../client/theme/components/d-checkbox';
import '../../../../../client/theme/components/d-icon';
import { DubniumBaseIcon } from '../../../../db/extensions/DubniumBaseIcon';

export const BuildifyCampusIconExtension: BuildifyExtension<DubniumBaseIcon> = {
  class: DubniumBaseIcon,
  toolbar: { icon: 'star', text: { default: 'Icon' } },
  render(instance, { defaultValues, isSubelement }) {
    return html`
      <campus-icon-extension
        .content=${instance}
        .defaultValues=${defaultValues}
        ?sub=${isSubelement}
      ></campus-icon-extension>
    `;
  },
  editor({ content, contentController, controllerBaseKeyPath, host }) {
    return html`
      <d-flex space="sm">
        ${IconExtension.editor?.({ content, contentController, controllerBaseKeyPath, host })}
        <d-checkbox .label=${'Ist fett?'} ${contentController.field(...controllerBaseKeyPath, 'isBold')}></d-checkbox>
      </d-flex>
    `;
  },
  group: 'Basics',
};

@customElement('campus-icon-extension')
export class CampusIconExtension extends ExtensionBoilerplate<DubniumBaseIcon> {
  protected override _content(content: DubniumBaseIcon) {
    return html`
      <d-icon
        ${css({
          color: this.defaultValue('color', { global: '#000' }),
          fontSize: this.defaultValue('size', { global: '24px' }),
        })}
        ?solid=${this.defaultValue('isBold', { global: false })}
      >
        ${this.defaultValue('icon', { global: 'question' })}
      </d-icon>
    `;
  }
}
