import { Beleg } from '@adornis/accounting/api/beleg';
import { Arg, Entity, Mutation } from '@adornis/baseql/decorators';
import { constructValue } from '@adornis/baseql/entities/adornisEntity';
import { getRawCollection } from '@adornis/baseql/server/collections';
import { context } from '@adornis/baseql/server/context';
import { AdornisFile } from '@adornis/file-utils/db/files';
import { Buffer } from 'buffer';
import { getContactByIDCOQL } from '../../_api/contact/queries/getContactByIDCOQL';
import { Company } from '../../db/Company';
import { Order } from '../../db/Order';
import { LASFile } from '../../db/files/LASFile';
import { LASPayment } from './Payments/LASPayment';

@Entity()
export class XRechnung extends LASFile {
  static override _class = 'XRechnung';

  @Mutation(type => String)
  static createXRechnungForOrder(
    @Arg('orderID', type => String) orderID: string,
    @Arg('belegID', type => String) belegID: string,
  ) {
    return async () => {
      if (!context.serverContext) throw new Error('xrechnung only available for server context');

      const xRechnungID = `${belegID}-xrechnung`;

      const rawFilesCollection = await getRawCollection<XRechnung>(AdornisFile._collectionName);
      const existingXRechnung = await rawFilesCollection.findOne<XRechnung>({
        _class: XRechnung._class,
        _id: xRechnungID,
      });
      if (existingXRechnung) return existingXRechnung._id;

      const order = await Order.getOrderByIDCOQL(orderID)({
        id: 1,
        buyerContactId: 1,
        paymentID: 1,
        buyerCompanyId: 1,
      });
      if (!order) throw new Error('order not found');

      const company = await Company.getCompanyById(order.buyerCompanyId)({ id: 1, department: 1, name: 1 });
      if (!company) throw new Error('company not found');

      const contact = await getContactByIDCOQL(order.buyerContactId)({ id: 1, customerNumber: 1 });
      if (!contact) throw new Error('contact not found');

      const rawPaymentCollection = await getRawCollection<LASPayment>(LASPayment._collectionName);
      let payment = await rawPaymentCollection.findOne<LASPayment>({
        _id: order.paymentID,
      });
      payment = constructValue(payment);

      const formData = payment?.formData;
      const { SignupNewMentoringForm } = await import('../../_forms/_new-mentoring-form/db/SignupNewMentoringForm');
      if (!payment || !(formData instanceof SignupNewMentoringForm)) throw new Error('payment not found');

      const recipient = payment.recipient;

      const rawBelegCollection = await getRawCollection<Beleg>(Beleg._collectionName);
      let beleg = await rawBelegCollection.findOne<Beleg>({ _id: belegID });
      beleg = constructValue(beleg);
      if (!beleg) throw new Error('beleg not found');

      const XML_STRING = `<?xml version="1.0" encoding="UTF-8"?>
      <ubl:Invoice xmlns:ubl="urn:oasis:names:specification:ubl:schema:xsd:Invoice-2" xmlns:cac="urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2" xmlns:cbc="urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2">
        <cbc:CustomizationID>urn:cen.eu:en16931:2017#compliant#urn:xeinkauf.de:kosit:xrechnung_3.0</cbc:CustomizationID>
        <cbc:ProfileID>urn:fdc:peppol.eu:2017:poacc:billing:01:1.0</cbc:ProfileID>
        <cbc:ID>${beleg.belegNummer}</cbc:ID>
        <cbc:IssueDate>${beleg.belegDatum?.toFormat('yyyy-MM-dd')}</cbc:IssueDate>
        <cbc:DueDate>${beleg.belegDatum?.toFormat('yyyy-MM-dd')}</cbc:DueDate>
        <cbc:InvoiceTypeCode>380</cbc:InvoiceTypeCode>
        <cbc:DocumentCurrencyCode>EUR</cbc:DocumentCurrencyCode>
        <cbc:BuyerReference>
        <cbc:ID>${formData.funding.leitwegID}</cbc:ID>
        <cbc:ID>${contact.customerNumber}</cbc:ID>
        </cbc:BuyerReference>
        <cac:AccountingSupplierParty>
          <cac:Party>
            <cbc:EndpointID schemeID="9918">DE50430609676033827300</cbc:EndpointID>
            <cac:PostalAddress>
              <cbc:StreetName>Arnsburger Straße 58 d</cbc:StreetName>
              <cbc:CityName>Frankfurt am Main</cbc:CityName>
              <cbc:PostalZone>60385</cbc:PostalZone>
              <cac:Country>
                <cbc:IdentificationCode>DE</cbc:IdentificationCode>
              </cac:Country>
            </cac:PostalAddress>
            <cac:PartyTaxScheme>
              <cbc:CompanyID>045/255/02089</cbc:CompanyID>
              <cac:TaxScheme>
                <cbc:ID />
              </cac:TaxScheme>
            </cac:PartyTaxScheme>
            <cac:PartyLegalEntity>
              <cbc:RegistrationName>Digitale Helden gGmbh</cbc:RegistrationName>
              <cbc:CompanyID>HRB 100064</cbc:CompanyID>
            </cac:PartyLegalEntity>
            <cac:Contact>
              <cbc:Name>Florian Borns</cbc:Name>
              <cbc:Telephone>069874036169</cbc:Telephone>
              <cbc:ElectronicMail>buchhaltung@digitale-helden.de</cbc:ElectronicMail>
            </cac:Contact>
          </cac:Party>
        </cac:AccountingSupplierParty>
        <cac:AccountingCustomerParty>
          <cac:Party>
            <cac:PostalAddress>
              <cbc:StreetName>${recipient.street}</cbc:StreetName>
              <cbc:CityName>${recipient.city}</cbc:CityName>
              <cbc:PostalZone>${recipient.zip}</cbc:PostalZone>
              <cbc:CountrySubentity>${recipient.state}</cbc:CountrySubentity>
              <cac:Country>
                <cbc:IdentificationCode>${recipient.country.toUpperCase()}</cbc:IdentificationCode>
              </cac:Country>
            </cac:PostalAddress>
            <cac:PartyLegalEntity>
              <cbc:RegistrationName>${recipient.companyName}</cbc:RegistrationName>
            </cac:PartyLegalEntity>
            <cbc:Comment>${company.name}, Buchungskreis 2300, Dienststelle ${company.department}</cbc:Comment>
          </cac:Party>
        </cac:AccountingCustomerParty>
        <cac:Delivery>
          <cbc:ActualDeliveryDate>${payment.createdAt.toFormat('yyyy-MM-dd')}</cbc:ActualDeliveryDate>
        </cac:Delivery>
        <cac:PaymentMeans>
          <cbc:PaymentMeansCode>42</cbc:PaymentMeansCode>
          <cac:PayeeFinancialAccount>
            <cbc:ID>DE13520503531004885466</cbc:ID>
            <cbc:Name>Viktor Settel</cbc:Name>
            <cac:FinancialInstitutionBranch>
              <cbc:ID>HELADEF1KAS</cbc:ID>
            </cac:FinancialInstitutionBranch>
          </cac:PayeeFinancialAccount>
        </cac:PaymentMeans>
        <cac:TaxTotal>
          <cbc:TaxAmount currencyID="EUR">${(payment.tax / 100).toFixed(2)}</cbc:TaxAmount>
          <cac:TaxSubtotal>
            <cbc:TaxableAmount currencyID="EUR">${((payment.amount - payment.tax) / 100).toFixed(2)}</cbc:TaxableAmount>
            <cbc:TaxAmount currencyID="EUR">${(payment.tax / 100).toFixed(2)}</cbc:TaxAmount>
            <cac:TaxCategory>
              <cbc:ID>U</cbc:ID>
              <cbc:Percent>0.00</cbc:Percent>
              <cac:TaxScheme>
                <cbc:ID>USt</cbc:ID>
              </cac:TaxScheme>
            </cac:TaxCategory>
          </cac:TaxSubtotal>
        </cac:TaxTotal>
        <cac:LegalMonetaryTotal>
          <cbc:LineExtensionAmount currencyID="EUR">${(payment.tax / 100).toFixed(2)}</cbc:LineExtensionAmount>
          <cbc:TaxExclusiveAmount currencyID="EUR">${((payment.amount - payment.tax) / 100).toFixed(
            2,
          )}</cbc:TaxExclusiveAmount>
          <cbc:TaxInclusiveAmount currencyID="EUR">${((payment.amount + payment.tax) / 100).toFixed(
            2,
          )}</cbc:TaxInclusiveAmount>
          <cbc:AllowanceTotalAmount currencyID="EUR">0.00</cbc:AllowanceTotalAmount>
          <cbc:ChargeTotalAmount currencyID="EUR">0.00</cbc:ChargeTotalAmount>
          <cbc:PrepaidAmount currencyID="EUR">0.00</cbc:PrepaidAmount>
          <cbc:PayableRoundingAmount currencyID="EUR">0.00</cbc:PayableRoundingAmount>
          <cbc:PayableAmount currencyID="EUR">${((payment.amount + payment.tax) / 100).toFixed(2)}</cbc:PayableAmount>
        </cac:LegalMonetaryTotal>
        ${payment.products.map(
          product => `
        <cac:InvoiceLine>
          <cbc:ID>${product.product._id}</cbc:ID>
          <cbc:InvoicedQuantity unitCode="C62">${product.amount}</cbc:InvoicedQuantity>
          <cbc:LineExtensionAmount currencyID="EUR">${((product.amount * product.price.unitAmount) / 100).toFixed(
            2,
          )}</cbc:LineExtensionAmount>
          <cac:Item>
            <cbc:Name>${product.product.name}</cbc:Name>
            <cac:ClassifiedTaxCategory>
              <cbc:ID>U</cbc:ID>
              <cbc:Percent>0.00</cbc:Percent>
              <cac:TaxScheme>
                <cbc:ID>USt</cbc:ID>
              </cac:TaxScheme>
            </cac:ClassifiedTaxCategory>
          </cac:Item>
          <cac:Price>
            <cbc:PriceAmount currencyID="EUR">${(product.price.unitAmount / 100).toFixed(2)}</cbc:PriceAmount>
          </cac:Price>
        </cac:InvoiceLine>
        `,
        )}
      </ubl:Invoice>`;

      const xmlBuffer = Buffer.from(XML_STRING, 'utf-8');
      const file = await XRechnung.createFromBuffer(xmlBuffer, 'XRechnung.xml', {
        _id: xRechnungID,
      });
      return file._id;
    };
  }
}
