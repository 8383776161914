import '@adornis/digitale-helden-shared/db/buildify/_formulars/DubniumBaseLeadSignupFormular';
import '@adornis/digitale-helden-shared/db/buildify/_formulars/DubniumBaseSupportFormular';
import './BuildifyUnion';
import './CampusPage';
import './DubniumPDP';
import './DubniumPageDraft';
import './DubniumPagePublished';
import './extensions/DubniumBaseFlipcard';
import './extensions/DubniumBaseImage';
import './extensions/DubniumBaseLinkList';
import './extensions/DubniumBaseQuiz';
