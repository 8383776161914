import { Entity, Field } from '@adornis/baseql/decorators';
import { AdornisFilter } from '@adornis/filter/AdornisFilter';
import type { BuildifyUnionType } from './BuildifyUnion';

@Entity()
export class BuildifyUnionFilter extends AdornisFilter {
  static override _class = 'BuildifyUnionFilter';

  @Field(type => [String], { default: v => v ?? [] }) types!: BuildifyUnionType[];
}
