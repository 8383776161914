import { Size } from '@adornis/buildify/client/globals/enums';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import { DesignSystem } from '@adornis/chemistry/elements/theming/design';
import { DubniumBaseContainerFlexWithLine } from '../../../../../db/extensions/flex-container/DubniumBaseContainerFlexWithLine';
import { BuildifyCampusContainerFlexExtension } from './BuildifyCampusContainerFlexExtension';

export const BuildifyCampusContainerFlexLineExtension: BuildifyExtension<DubniumBaseContainerFlexWithLine> = {
  class: DubniumBaseContainerFlexWithLine,
  toolbar: { icon: 'diagram-cells', text: { default: 'Flex mit Linie' } },
  render(instance, { defaultValues, isSubelement }) {
    return BuildifyCampusContainerFlexExtension.render(instance, {
      defaultValues: {
        ...(defaultValues ? defaultValues : {}),
        hasLineLeft: { global: true },
        padding: {
          [Size.DESKTOP]: '0 0 0 32px',
          [Size.TABLET]: '0 0 0 24px',
          [Size.MOBILE]: '0 0 0 20px',
        },
        lineLeftWidth: { global: '3px' },
        lineLeftColor: { global: DesignSystem.currentTheme.getValue().colors.accent },
      },
      isSubelement,
    });
  },
  editor: BuildifyCampusContainerFlexExtension.editor,
  group: 'Layout',
};
