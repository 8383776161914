import { registerQuery } from '@adornis/baseql/metadata/register';
import { context } from '@adornis/baseql/server/context';
import { Contact } from '@adornis/digitale-helden-shared/db/Contact';
import { UserRoles } from '@adornis/digitale-helden-shared/db/permissions';
import { makeZohoAPIRequest } from '@adornis/digitale-helden-shared/server/zoho/api';
import { checkRole } from '../../../db/helpers';

export const deleteContactByID = registerQuery({
  type: () => String,
  operationName: 'deleteContactByID',
  resolve: (id: string) => {
    return async () => {
      await checkRole({ context, role: UserRoles.SUPER_ADMIN });
      const endpoint = `${Contact.ZOHO_MODULE}/${id}`;
      await makeZohoAPIRequest({ method: 'delete', endpoint, zohoModule: Contact.ZOHO_MODULE });
    };
  },
  params: [{ name: 'id', type: () => String }],
});
