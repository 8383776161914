import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import { html } from 'lit';
import { DubniumBaseQuiz, DubniumBaseQuizItem } from '../../../../db/extensions/DubniumBaseQuiz';
import './campus-quiz-extension';

export const BuildifyCampusQuizExtension = (textExtension: BuildifyExtension): BuildifyExtension<DubniumBaseQuiz> => ({
  class: DubniumBaseQuiz,
  toolbar: {
    icon: 'game-console-handheld',
    text: {
      default: 'Quiz',
    },
  },
  render(instance, { defaultValues, isSubelement }) {
    return html`
      <campus-quiz-extension
        .textExtension=${textExtension}
        .content=${instance}
        .defaultValues=${defaultValues}
        ?sub=${isSubelement}
      ></campus-quiz-extension>
    `;
  },
  editor({ content, contentController, host, controllerBaseKeyPath }) {
    return html`
      <d-flex space="lg">
        <d-flex space="sm">
          <d-flex crossaxis-center space-between horizontal space="sm" wrap>
            <d-text bold> Text </d-text>
            <d-icon
              pointer
              @click=${() => {
                content.items.push(new DubniumBaseQuizItem({}));
                host.requestUpdate();
              }}
            >
              plus
            </d-icon>
          </d-flex>
          <d-checkbox
            .label=${'Digitaler Notfall?'}
            ${contentController.field(...controllerBaseKeyPath, 'isDigitalEmergency')}
          ></d-checkbox>
          <d-input
            ${contentController.field(...controllerBaseKeyPath, 'questionText')}
            placeholder="Frage-Titel"
          ></d-input>
          <d-input
            ${contentController.field(...controllerBaseKeyPath, 'answerText')}
            placeholder="Antwort-Titel"
          ></d-input>
          <d-input ${contentController.field(...controllerBaseKeyPath, 'rowGap')} placeholder="rowGap"></d-input>

          ${content.items.map(
            (item, indexItem) => html`
              <d-flex crossaxis-center space-between horizontal space="sm" wrap>
                <d-text> ${indexItem + 1}. </d-text>
                <d-icon
                  pointer
                  @click=${() => {
                    content.items.splice(indexItem, 1);
                    host.requestUpdate();
                  }}
                >
                  trash
                </d-icon>
              </d-flex>
              <d-text> Titel </d-text>
              ${textExtension.editor?.({
                content: item.title,
                contentController,
                host,
                controllerBaseKeyPath: [...controllerBaseKeyPath, 'items', indexItem, 'title'],
              })}

              <d-text> Frage </d-text>
              ${textExtension.editor?.({
                content: item.question,
                contentController,
                host,
                controllerBaseKeyPath: [...controllerBaseKeyPath, 'items', indexItem, 'question'],
              })}

              <d-text> Antwort </d-text>
              ${textExtension.editor?.({
                content: item.answer,
                contentController,
                host,
                controllerBaseKeyPath: [...controllerBaseKeyPath, 'items', indexItem, 'answer'],
              })}

              <d-flex space="lg">
                <d-flex space="sm">
                  <d-flex crossaxis-center space-between horizontal space="sm" wrap>
                    <d-icon
                      pointer
                      @click=${() => {
                        item.options.push('');
                        host.requestUpdate();
                      }}
                    >
                      plus
                    </d-icon>
                  </d-flex>
                  ${item.options.map(
                    (option, indexOption) => html`
                      <d-flex crossaxis-center space-between horizontal space="sm" wrap>
                        <d-icon
                          pointer
                          @click=${() => {
                            item.options.splice(indexOption, 1);
                            host.requestUpdate();
                          }}
                        >
                          trash
                        </d-icon>
                      </d-flex>
                      <d-input
                        ${contentController.field(
                          ...controllerBaseKeyPath,
                          'items',
                          indexItem,
                          'options',
                          indexOption.toString(),
                        )}
                        placeholder="Option"
                      ></d-input>
                    `,
                  )}
                </d-flex>
              </d-flex>
            `,
          )}
        </d-flex>
      </d-flex>
    `;
  },
  group: 'Sonstige',
});
