export enum ProductType {
  WEBINAR = 'Webinar',
  WEBINAR_ARCHIV = 'Webinar-Archiv',
  WORKSHOP = 'Workshop',
  DOWNLOAD_INFO = 'Download Infomaterialien',
  DOWNLOAD_LEARNING_MATERIALS = 'Download Lernmaterialien',
  LEARNING_MATERIALS = 'Lernmaterialien',
  ONLINE_COURSE = 'Online-Kurs',
  ONLINE_TRAINING = 'Online-Training',
  AUDIO = 'Audio',
  LIVE_WEBINAR = 'Live-Webinar',
  PEER_PROGRAMM = 'Peer Programm',
}

export enum ProductDifficulty {
  BEGINNER = 'Einsteiger:in',
  ADVANCED = 'Fortgeschrittene',
  PROFI = 'Profi',
}

export function getDifficultyIndex(value: string | ProductDifficulty) {
  switch (value) {
    case ProductDifficulty.BEGINNER:
      return 0;
    case ProductDifficulty.ADVANCED:
      return 1;
    case ProductDifficulty.PROFI:
      return 2;
    default:
      return -1;
  }
}

export function productTypeIcon(type: ProductType): string {
  switch (type) {
    case ProductType.LIVE_WEBINAR:
    case ProductType.WEBINAR:
      return 'presentation-screen';
    case ProductType.WEBINAR_ARCHIV:
      return 'box-archive';
    case ProductType.AUDIO:
      return 'podcast';
    case ProductType.DOWNLOAD_INFO:
      return 'download';
    case ProductType.LEARNING_MATERIALS:
      return 'files';
    case ProductType.ONLINE_COURSE:
      return 'screen-users';
    default:
      return 'rocket';
  }
}

export enum ProductGroupType {
  AG = 'AG',
  COMPULSORY_VOTING = 'Wahlpflichtfach',
  WORKSHOP = 'Workshop',
}

export enum ProductLearningSituation {
  SINGLE = 'Single',
  GROUP = 'Gruppe',
}

export enum ProductHKEFunding {
  DIGITAL_EMERGENCY = 'Digitaler Notfall',
  RESEARCH_SKILLS = 'Recherchekompetenz',
}

export enum ProductPaymentModality {
  TOTAL_BILL = 'Gesamtrechnung',
  SPLITTED_BILL = 'Zwei Teilrechnungen',
}
