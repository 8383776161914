import type { Maybe } from '@adornis/base/utilTypes';
import { Float, Int } from '@adornis/baseql/baseqlTypes';
import { Arg, Entity, Field, Mutation, Query } from '@adornis/baseql/decorators';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity';
import { context } from '@adornis/baseql/server/context';
import { type BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { GLOBAL_CONTEXT } from '@adornis/users/db/a-roles';
import { validate } from '@adornis/validation/decorators';
import { nonOptional } from '@adornis/validation/functions/nonOptional';
import needle from 'needle';
import { getProductByName } from '../_api/product/queries/getProductByName';
import { SchoolHasProductAlreadyError } from '../errors/SchoolHasProductAlreadyError';
import { getAllZohoRecords, makeZohoAPIRequest, makeZohoCOQLRequest, upsertZohoRecord } from '../server/zoho/api';
import { genericSerializeZoho } from '../server/zoho/interface-zoho-adornis';
import { BiMap } from './BiMap';
import { Contact } from './Contact';
import { Order } from './Order';
import { Product } from './Product';
import { ContactCompanyRelation } from './Relations/ContactCompanyRelation';
import { ZohoModule } from './enumns/zoho';
import { CompanyType, ContactCompanyPermission, State, ZohoType, type SchoolType } from './enums';
import { checkRole } from './helpers';
import { UserRoles, type LASUser } from './las-user';
import { ZohoEntity } from './zoho-entity';

export enum COMPANY_ZOHO_FIELDS {
  ID = 'id',
  NAME = 'Account_Name',
  TYPE = 'Account_Type',
  EMAIL = 'Email',
  EMPLOYEES = 'Employees',
  FAX = 'Fax',
  PHONE = 'Phone',
  INDUSTRY = 'Industry',
  PROBONO = 'ProBono',
  SIC_CODE = 'SIC_Code',
  COMPANY_NUMBER = 'Account_Number',
  DESCRIPTION = 'Description',
  PARENT_COMPANY = 'Parent_Company',
  SKYPE = 'Skype_ID',
  INSTAGRAM = 'Instagram',
  LINKEDIN = 'LinkedIn',
  WEBSITE = 'Website',
  TWITTER = 'Twitter',
  STREET = 'Street',
  ZIP = 'Zip_Code',
  CITY = 'City',
  STATE = 'Bundesland',
  COUNTRY = 'Country',
  RESET_MAIL = 'Reset_Mail',
  // school-data
  SCHULFORM = 'Schulform',
  HELDEN_SCHULE_SEIT = 'Helden_Schule_seit',
  SCHULAMTSBEZIRK = 'Schulamtsbezirk',
  DIENSTSTELLE = 'Dienstelle',
}

export const COMPANY_BIMAP = new BiMap<string, string>([
  ['id', COMPANY_ZOHO_FIELDS.ID],
  ['name', COMPANY_ZOHO_FIELDS.NAME],
  ['type', COMPANY_ZOHO_FIELDS.TYPE],
  ['email', COMPANY_ZOHO_FIELDS.EMAIL],
  ['countEmployees', COMPANY_ZOHO_FIELDS.EMPLOYEES],
  ['fax', COMPANY_ZOHO_FIELDS.FAX],
  ['phone', COMPANY_ZOHO_FIELDS.PHONE],
  ['branche', COMPANY_ZOHO_FIELDS.INDUSTRY],
  ['isProBono', COMPANY_ZOHO_FIELDS.PROBONO],
  ['sicCode', COMPANY_ZOHO_FIELDS.SIC_CODE],
  ['companyNumber', COMPANY_ZOHO_FIELDS.COMPANY_NUMBER],
  ['description', COMPANY_ZOHO_FIELDS.DESCRIPTION],
  ['parentCompanyId', COMPANY_ZOHO_FIELDS.PARENT_COMPANY],
  ['skypeId', COMPANY_ZOHO_FIELDS.SKYPE],
  ['instagram', COMPANY_ZOHO_FIELDS.INSTAGRAM],
  ['linkedIn', COMPANY_ZOHO_FIELDS.LINKEDIN],
  ['website', COMPANY_ZOHO_FIELDS.WEBSITE],
  ['twitter', COMPANY_ZOHO_FIELDS.TWITTER],
  ['street', COMPANY_ZOHO_FIELDS.STREET],
  ['zip', COMPANY_ZOHO_FIELDS.ZIP],
  ['city', COMPANY_ZOHO_FIELDS.CITY],
  ['state', COMPANY_ZOHO_FIELDS.STATE],
  ['country', COMPANY_ZOHO_FIELDS.COUNTRY],
  // school-data
  ['schoolType', COMPANY_ZOHO_FIELDS.SCHULFORM],
  ['schoolDistrict', COMPANY_ZOHO_FIELDS.SCHULAMTSBEZIRK],
  ['schoolSince', COMPANY_ZOHO_FIELDS.HELDEN_SCHULE_SEIT],
  ['department', COMPANY_ZOHO_FIELDS.DIENSTSTELLE],
  ['resetMail', COMPANY_ZOHO_FIELDS.RESET_MAIL],
]);

@Entity()
export class Company extends ZohoEntity {
  static override _class = 'Company';
  static override ZOHO_MODULE = ZohoModule.COMPANIES;
  static override ZOHO_FIELDS = Array.from(COMPANY_BIMAP.values).join(',');

  @Field(type => String) id?: string;

  @Field(type => String) parentCompanyId?: string;
  @Field(type => Int) countEmployees?: number;
  @Field(type => Int) companyNumber?: number;
  @Field(type => String) ownership?: string;
  @Field(type => String) evaluation?: string;
  @Field(type => String) type?: CompanyType;
  @Field(type => String) branche?: string;
  @Field(type => Boolean) isProBono?: boolean;
  @Field(type => String) email?: string;
  @Field(type => String) phone?: string;
  @Field(type => String) fax?: string;
  @Field(type => String) street?: string;
  @Field(type => String) zip?: string;
  @Field(type => String) city?: string;
  @Field(type => String) state?: string;
  @Field(type => String) country?: string;
  @Field(type => String) twitter?: string;
  @Field(type => String) instagram?: string;
  @Field(type => String) skypeId?: string;
  @Field(type => String) website?: string;
  @Field(type => String) linkedIn?: string;
  @Field(type => Float) department?: number;
  @Field(type => Float) sicCode?: number;
  @Field(type => String) description?: string;
  @Field(type => String) schoolType?: string;
  @Field(type => String) schoolDistrict?: string;
  @Field(type => Date) schoolSince?: Date;
  @Field(type => String) resetMail: Maybe<string>;

  @validate(nonOptional())
  @Field(type => String)
  name!: string;

  get toFilteredJSON() {
    const fields = {};
    const keys = Array.from(COMPANY_BIMAP.keys);
    keys.forEach(key => {
      if (key && this.toJSON()[key]) {
        fields[key] = this.toJSON()[key];
      }
    });
    return fields;
  }

  private get typeDefs() {
    return new Map<string, ZohoType>([
      [COMPANY_ZOHO_FIELDS.HELDEN_SCHULE_SEIT, ZohoType.DATE],
      [COMPANY_ZOHO_FIELDS.PHONE, ZohoType.PHONE],
    ]);
  }
  override serializeZoho = (isNew: boolean = false) => {
    console.log('serialize company ????');
    const result = genericSerializeZoho({ bimap: COMPANY_BIMAP, instance: this, typeDefs: this.typeDefs });
    const parentCompanyRegex = new RegExp(`"${COMPANY_ZOHO_FIELDS.PARENT_COMPANY}":[ ]?"([0-9]+)"`, 'g');
    return result.replace(parentCompanyRegex, `"${COMPANY_ZOHO_FIELDS.PARENT_COMPANY}":$1`);
  };

  static override deserializeZoho = (rawData: any) => {
    const fields = {};
    const keys = Array.from(COMPANY_BIMAP.reverseKeys);
    keys.forEach(key => {
      const keyLAS = COMPANY_BIMAP.reverseGet(key);
      if (keyLAS) {
        if (key === COMPANY_ZOHO_FIELDS.PARENT_COMPANY && typeof rawData[key] === 'object') {
          fields[keyLAS] = rawData[key]?.id;
        } else {
          fields[keyLAS] = rawData[key] ?? null;
        }
      }
    });

    return new Company(fields);
  };

  @Query(type => String)
  static deleteCompanyByID(@Arg('id', type => String) id: string) {
    return async () => {
      await checkRole({ context, role: UserRoles.SUPER_ADMIN });

      const endpoint = `${this.ZOHO_MODULE}/${id}`;
      await makeZohoAPIRequest({ method: 'delete', endpoint, zohoModule: this.ZOHO_MODULE });
    };
  }

  @Mutation(type => String)
  static removeCompanyByIDSafe(
    @Arg('id', type => String) id: string,
    @Arg('calledByOrderID', type => String) calledByOrderID?: Maybe<string>,
  ) {
    return async () => {
      await checkRole({ context, role: UserRoles.SUPER_ADMIN });
      if (!id) return null;

      const company = await this.getCompanyById(id)(Company.allFields);
      if (!company) return;

      const ordersOfCompany = await Order.getOrderOfInstitutionsCOQL([id])(Order.allFields);
      const filteredOrdersOfCompany = ordersOfCompany.filter(order => order.id !== calledByOrderID);
      if (filteredOrdersOfCompany.length > 0) return;

      const contactCompanyRelations = await ContactCompanyRelation.getContactCompanyRelationsByCompany(id)({
        id: 1,
        contactId: 1,
      });
      const relationIDs = contactCompanyRelations.map(relation => relation.id);

      await ContactCompanyRelation.deleteContactCompanyRelationsByIDs(relationIDs)();

      const contactIDs = contactCompanyRelations.map(relation => relation.contactId);
      for (const contactID of contactIDs) {
        if (!contactID) continue;
        await Contact.removeContactByIDSafe(contactID)();
      }

      await this.deleteCompanyByID(id)();
    };
  }

  @Mutation(type => Company)
  public static upsertCompany(@Arg('entity', type => Company) entity: Company) {
    return async (gqlFields: BaseQLSelectionSet<Company>) => {
      if (!entity.id) {
        const existingCompany = await Company.getSchoolByNameAndZip(entity.name, entity.zip ?? '')(Company.allFields);
        if (existingCompany) {
          entity = new Company({
            ...existingCompany.toFilteredJSON,
            ...entity.toFilteredJSON,
          });
        }
      }

      // console.log("upsert company", )

      console.log('upsert zoho record company', entity);
      const id = await upsertZohoRecord(this.ZOHO_MODULE, entity);
      entity.id = id;
      return entity;
    };
  }

  @Query(type => Company)
  public static getCompanyById(@Arg('zohoID', type => String) zohoID: string) {
    return async (gqlFields: BaseQLSelectionSet<Company>) => {
      // await checkPermission({ context, permission: 'Institution.Stammdaten.View', permissionContext: zohoID });

      if (!zohoID) return undefined;
      const result = await makeZohoCOQLRequest({
        moduleBiMap: COMPANY_BIMAP,
        moduleName: Company.ZOHO_MODULE,
        gqlFields,
        filter: `${COMPANY_ZOHO_FIELDS.ID} = '${zohoID}'`,
      });
      if (!result?.data?.[0]) return null;
      const data = result.data[0];
      const deserialized = this.deserializeZoho(data);
      return deserialized;

      // const rawData = await getZohoRecordByID(this.ZOHO_MODULE, zohoID);
      // const deserializedContact = this.deserializeZoho(rawData);
      // return deserializedContact;
    };
  }

  // SECTION: school queries
  @Query(() => [Company])
  static getSchoolsByState(@Arg('state', type => String) state: string) {
    return async (gqlFields: BaseQLSelectionSet<Company>) => {
      if (state === State.NRW) state = 'nrw';
      // format state
      const stateParsed = state.toLowerCase().replaceAll('ü', 'ue').replaceAll(' ', '-');

      // request schools by state
      const result = await needle(
        'get',
        `https://raw.githubusercontent.com/Datenschule/schulscraper-data/master/schools/${stateParsed}.json`,
        {
          json: true,
        },
      );

      if (state === 'nrw') state = State.NRW;

      if (!result) return [];

      const schoolInfos = JSON.parse(result.body).map(
        schoolJson => new SchoolData({ ...schoolJson, state }),
      ) as SchoolData[];
      const schools = schoolInfos.map(schoolData => schoolData.parseToSchool());

      return schools;
    };
  }

  @Query(type => Company)
  static getSchoolByNameAndZipCOQL(@Arg('name', type => String) name: string, @Arg('zip', type => String) zip: string) {
    return async (gqlFields: BaseQLSelectionSet<Company>) => {
      const endpoint = `coql`;
      const query = `SELECT ${this.ZOHO_FIELDS} FROM ${this.ZOHO_MODULE} WHERE (${COMPANY_ZOHO_FIELDS.NAME} = '${name}' AND ${COMPANY_ZOHO_FIELDS.ZIP} = '${zip}')`;
      const result = await makeZohoAPIRequest({
        method: 'post',
        endpoint,
        data: { select_query: query },
        isRawRequest: true,
        zohoModule: this.ZOHO_MODULE,
      });
      if (!result?.data?.[0]) return null;

      const resultData = result.data[0];
      const deserializedContact = this.deserializeZoho(resultData);
      return deserializedContact;
    };
  }

  @Query(type => Company)
  static getSchoolByNameAndZip(@Arg('name', type => String) name: string, @Arg('zip', type => String) zip: string) {
    return async (gqlFields: BaseQLSelectionSet<Company>) => {
      if (!name) return;

      const filter = `${COMPANY_ZOHO_FIELDS.NAME} = '${name}' AND ${COMPANY_ZOHO_FIELDS.ZIP} = '${zip}'`;
      const result = await makeZohoCOQLRequest({
        moduleName: this.ZOHO_MODULE,
        moduleBiMap: COMPANY_BIMAP,
        filter,
        gqlFields,
      });

      if (!result?.data?.[0]) return null;
      const resultData = result.data[0];
      const deserialized = this.deserializeZoho(resultData);
      return deserialized;
    };
  }

  @Query(type => Company)
  static getSchoolByNameAndZipProductOfInstitutionCheck(
    @Arg('name', type => String) name: string,
    @Arg('zip', type => String) zip: string,
    @Arg('productName', type => String) productName: string,
  ) {
    return async (gqlFields: BaseQLSelectionSet<Company>) => {
      const school = await this.getSchoolByNameAndZipCOQL(name, zip)(Company.allFields);
      const product = await getProductByName(productName)(Product.allFields);
      if (!school?.id || !product) return null;
      const ordersOfSchool = await Order.getOrderOfInstitutionsCOQL([school.id])(Order.allFields);
      if (ordersOfSchool.length === 0) return school;
      const mentoringIndex = ordersOfSchool.findIndex(order => order.productId === product.id);
      if (mentoringIndex === -1) return school;
      throw new SchoolHasProductAlreadyError();
    };
  }

  @Query(type => [Company], { requestHandlerMeta: { queryCache: { cachePolicy: 'bypass' } } })
  public static getAllSchools() {
    return async (gqlFields: BaseQLSelectionSet<Company>) => {
      const rawContacts = await getAllZohoRecords(this.ZOHO_MODULE, { fields: this.ZOHO_FIELDS }, true);
      const deserialized = rawContacts.data.map(contact => this.deserializeZoho(contact));
      return deserialized;
    };
  }

  @Query(type => [Company])
  static getSchoolsByContactAndPermission(
    @Arg('contactId', type => String) contactId: string,
    @Arg('permission', type => String) permission: ContactCompanyPermission,
  ) {
    return async (gqlFields: BaseQLSelectionSet<Company>) => {
      const contactCompanyRelations = await ContactCompanyRelation.getContactCompanyRelationsByUserAndPermission(
        contactId,
        permission,
      )({ companyId: 1 });
      const companyIds = contactCompanyRelations.map(relation => relation.companyId).filter(Boolean) as string[];
      const schools = await Company.getSchoolsByIds(companyIds)(Company.allFields);
      return schools;
    };
  }

  @Query(type => [Company])
  static getSchoolsByContactAndPermissionPaginated(
    @Arg('page', type => Int) page: number,
    @Arg('perPage', type => Int) perPage: number,
    @Arg('contactId', type => String) contactId: string,
  ) {
    return async (gqlFields: BaseQLSelectionSet<Company>) => {
      if (context.serverContext)
        throw new Error('getSchoolsByContactAndPermissionPaginated kann nicht vom server context aufgerufen werden');
      const user = (await context.user()) as Maybe<LASUser>;
      if (!user) return [];

      const userHasViewAllPermission = user.hasPermission('Institution.Stammdaten.View', GLOBAL_CONTEXT);
      if (userHasViewAllPermission) return await this.getCompaniesPaginated(page, perPage)(gqlFields);

      const institutionAdminRole = user.roles.find(role => role.name === UserRoles.INSTITUTION_ADMIN);
      if (!institutionAdminRole) return [];

      const companyIDs = institutionAdminRole.contexts;
      const schools = await Company.getSchoolsByIdsPaginated(page, perPage, companyIDs)(gqlFields);
      return schools;
    };
  }

  @Query(type => Int)
  static countSchoolsByContactAndPermission(@Arg('contactId', type => String) contactId: string) {
    return async () => {
      if (context.serverContext)
        throw new Error('getSchoolsByContactAndPermissionPaginated kann nicht vom server context aufgerufen werden');
      const user = await context.user();
      if (!user) return 0;

      const userHasViewAllPermission = user.hasPermission('Institution.Stammdaten.View', GLOBAL_CONTEXT);
      if (userHasViewAllPermission) {
        const zohoResult = await makeZohoAPIRequest({
          method: 'get',
          endpoint: `${this.ZOHO_MODULE}/actions/count`,
          zohoModule: this.ZOHO_MODULE,
        });
        return zohoResult.count;
      }

      const contactCompanyRelations = await ContactCompanyRelation.getContactCompanyRelationsByUserAndPermission(
        contactId,
        ContactCompanyPermission.ADMIN,
      )({ companyId: 1, id: 1 });
      const companyIds = contactCompanyRelations.map(relation => relation.companyId).filter(Boolean) as string[];
      if (companyIds.length === 0) return 0;

      const zohoResult = await makeZohoAPIRequest({
        method: 'get',
        endpoint: `${this.ZOHO_MODULE}/actions/count`,
        data: {
          criteria: `(ID:in:${companyIds.join(',')})`,
        },
        zohoModule: this.ZOHO_MODULE,
      });
      return zohoResult.count || 0;
    };
  }

  @Query(type => [Company])
  static getSchoolsByIds(@Arg('ids', type => [String]) ids: string[]) {
    return async (gqlFields: BaseQLSelectionSet<Company>) => {
      if (!ids || ids.length === 0) return [];

      const filter = `${COMPANY_ZOHO_FIELDS.ID} in (${ids.join(',')})`;
      const result = await makeZohoCOQLRequest({
        moduleName: this.ZOHO_MODULE,
        moduleBiMap: COMPANY_BIMAP,
        filter,
        gqlFields,
      });

      if (!result?.data?.[0]) return [];

      const products = result.data.map(data => this.deserializeZoho(data)) as Company[];
      return products;
    };
  }

  @Query(type => [Company])
  static getSchoolsByIdsPaginated(
    @Arg('page', type => Int) page: number,
    @Arg('per_page', type => Int) perPage: number,
    @Arg('ids', type => [String]) ids: string[],
  ) {
    return async (gqlFields: BaseQLSelectionSet<Company>) => {
      if (ids.length === 0) return [];
      const endpoint = `${this.ZOHO_MODULE}/search`;

      const criteria = `(ID:in:${ids.join(',')})`;

      const result = await makeZohoAPIRequest({
        method: 'get',
        endpoint,
        data: { fields: this.ZOHO_FIELDS, criteria, page, per_page: perPage },
        zohoModule: this.ZOHO_MODULE,
      });
      if (!result?.data?.[0]) return [];

      console.log(result.data);
      const products = result.data.map(data => this.deserializeZoho(data)) as Company[];
      return products;
    };
  }

  @Query(type => [Company])
  static getCompaniesByIDs(@Arg('ids', type => [String]) ids: string[]) {
    return async (gqlFields: BaseQLSelectionSet<Company>) => {
      ids = ids.filter(id => !!id);
      if (ids.length === 0) return [];

      const filter = `${COMPANY_ZOHO_FIELDS.ID} in (${ids.join(',')})`;
      const result = await makeZohoCOQLRequest({
        moduleName: this.ZOHO_MODULE,
        moduleBiMap: COMPANY_BIMAP,
        filter,
        gqlFields,
      });

      if (!result?.data) return null;
      const resultData = result.data;
      const deserialized = resultData.map(data => this.deserializeZoho(data)) as Company[];
      return deserialized;
    };
  }

  @Query(type => [Company])
  static getCompaniesPaginated(@Arg('page', type => Int) page: number, @Arg('perPage', type => Int) perPage: number) {
    return async (gqlFields: BaseQLSelectionSet<Company>) => {
      if (!page || !perPage) return [];
      const endpoint = `${this.ZOHO_MODULE}`;
      const result = await makeZohoAPIRequest({
        method: 'get',
        endpoint,
        data: {
          fields: 'id,Account_Name,Street,Zip_Code,City,State,Account_Type',
          page,
          per_page: perPage,
          module: this.ZOHO_MODULE,
        },
        zohoModule: this.ZOHO_MODULE,
      });

      if (!result?.data) return [];
      const deserialized = result.data.map(data => this.deserializeZoho(data)) as Company[];
      return deserialized;
    };
  }
}

// SECTION: SCHOOL DATA
@Entity()
export class SchoolData extends AdornisEntity {
  static override _class = 'SchoolData';

  @Field(type => String) name?: string;
  @Field(type => String) id?: string;
  @Field(type => String) official_id?: string;
  @Field(type => String) address?: string;
  @Field(type => String) school_type?: SchoolType;
  @Field(type => String) fax?: string;
  @Field(type => String) state?: string;
  @Field(type => String) phone?: string;
  @Field(type => String) full_time_school?: string;
  @Field(type => Float) lon?: number;
  @Field(type => Float) lat?: number;

  parseToSchool(): Company {
    this.address = (this.address ?? '').replaceAll(',', '');

    const regex = /[0-9]{5}/;
    const match = this.address.match(regex);

    let street = '';
    let zip = '';
    let city = '';

    if (match?.length) {
      zip = match[0].trim();
      city = this.address.slice(match.index + 5).trim();
      street = this.address.slice(0, match.index).trim();
    }

    const department = this.id?.split('-')[1] ?? '';
    const parsedPhone = this.phone?.replace(/[^0-9]/g, '').replace(/^0/, '+49');

    return new Company({
      name: this.name,
      fax: this.fax,
      phone: parsedPhone,
      schoolType: this.school_type,
      type: CompanyType.SCHOOL,
      street,
      zip,
      city,
      state: this.state,
      country: 'DE',
      department: isNaN(parseInt(department)) ? null : department,
    });
  }
}
