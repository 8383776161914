import { Beleg } from '@adornis/accounting/api/beleg';
import { registerMutation } from '@adornis/baseql/metadata/register';
import { getRawCollection } from '@adornis/baseql/server/collections';
import { runInServerContext } from '@adornis/baseql/server/server';
import { getProductByID } from '@adornis/digitale-helden-shared/db/product/queries/getProductByID';
import { mailer } from '@adornis/digitale-helden-shared/server/communication';
import { Recipient } from '@adornis/mails/db/recipients';
import { Message } from '@adornis/mails/server/communication';
import { LASReceipt } from '../../../_accounting/db/LASReceipts';
import { LASPayment } from '../../../_accounting/db/Payments/LASPayment';
import { XRechnung } from '../../../_accounting/db/XRechnung';
import { pathToContent } from '../../../_helpers/product-helpers';
import { CampusSettingsRoute, PathToCampusSettingsRoute } from '../../../_routing/db/enums';
import { Company } from '../../../db/Company';
import { Funding } from '../../../db/Funding';
import { Order } from '../../../db/Order';
import { orderConfirmationTemplate } from '../../../db/mail-templates/order-confirmation';
import { ContactNotFoundError } from '../../../errors/ContactNotFoundError';
import { InvoiceLinkNotServedError } from '../../../errors/InvoiceLinkNotServedError';
import { OrderNotFoundError } from '../../../errors/OrderNotFoundError';
import { PaymentNotFoundError } from '../../../errors/PaymentNotFoundError';
import { ProductNotFoundError } from '../../../errors/ProductNotFoundError';
import { getContactByIDCOQL } from '../../contact/queries/getContactByIDCOQL';

/**
 * Send order confirmation mail by orderID
 *
 * @param {string} orderID orderID to reference in mailing
 *
 * possible errors:
 * @throws {FileByInvoiceNotFoundError} fileID not found by invoiceNr
 * @throws {InvoiceLinkNotServedError} invoicePath couldn't be served
 * @throws {OrderNotFoundError} orderID doesn't exist
 * @throws {ProductNotFoundError} productID on order doesn't exist
 * @throws {ContactNotFoundError} contactID on order doesn't exist
 */

//* Resolver
const sendOrderConfirmationMailResolver = (orderID: string, belegID: string) => {
  return async () => {
    const rawBelegCollection = await getRawCollection<Beleg>(Beleg._collectionName);
    const beleg = await rawBelegCollection.findOne<Beleg>({ _id: belegID });
    if (!beleg) throw new Error('beleg not found');

    const invoicePath = LASReceipt.getServeLink(belegID);
    if (!invoicePath) throw new InvoiceLinkNotServedError();

    const order = await Order.getOrderByIDCOQL(orderID)({
      id: 1,
      productId: 1,
      buyerContactId: 1,
      buyerCompanyId: 1,
      paymentID: 1,
      productConfiguration: { name: 1, price: 1 },
      foerderStructureId: 1,
    });
    if (!order) throw new OrderNotFoundError();

    const company = await Company.getCompanyById(order.buyerCompanyId)({ id: 1, resetMail: 1 });

    const payment = await LASPayment.getByID<LASPayment>(order.paymentID)({ paymentType: 1 });

    if (!payment) throw new PaymentNotFoundError();

    const product = await getProductByID(order.productId)({
      id: 1,
      name: 1,
      imageSmallUrl: 1,
      conditionsOfParticipation: 1,
    });
    if (!product) throw new ProductNotFoundError();

    const contact = await getContactByIDCOQL(order.buyerContactId)({
      id: 1,
      firstName: 1,
      lastName: 1,
      email: 1,
    });
    if (!contact) throw new ContactNotFoundError();

    const { PaymentMethod } = await import('../../../_forms/_new-mentoring-form/db/SignupNewMentoringForm');
    const isFunding = payment.paymentType === 'funding';
    const isFinancing = payment.paymentType === 'financing';

    const financerEmailCCFunc = async () => {
      if (!isFinancing) return null;
      if (!order.foerderStructureId) return null;

      const foerderung = await Funding.getFundingById(order.foerderStructureId)({ id: 1, fundingBy: 1 });
      if (!foerderung.fundingBy) return null;

      const company = await Company.getCompanyById(foerderung.fundingBy)({ id: 1, email: 1 });
      return company?.email;
    };
    const financerEmailCC = await financerEmailCCFunc();

    enum Attachment {
      BELEG = 'beleg',
      TEILNAHMEBEDINGUNGEN = 'teilnahmebedingungen',
      XRECHNUNG = 'xrechnung',
    }
    const attachmentPaths: Map<string, string> = new Map();
    attachmentPaths.set(Attachment.BELEG, `${invoicePath}/${beleg.belegNummer}.pdf`);
    attachmentPaths.set(
      Attachment.TEILNAHMEBEDINGUNGEN,
      product.conditionsOfParticipation ??
        'https://s3.digitale-helden.de/akademie/public/2023/07/230613-Teilnahmebedingungen.pdf',
    );

    if (isFinancing) {
      // an dieser Stelle muss nun die XRechnung erstellt werden
      const xRechnungFileID = await runInServerContext(async () => {
        const result = await XRechnung.createXRechnungForOrder(orderID, belegID)();
        return result;
      });
      const xRechnungPath = XRechnung.getServeLink(xRechnungFileID);
      attachmentPaths.set(Attachment.XRECHNUNG, `${xRechnungPath}/XRechnung.xml`);
    }

    const paymentTypeTranslation = {
      [PaymentMethod.CARD]: 'Kartenzahlung',
      [PaymentMethod.PAYPAL]: 'PayPal',
      [PaymentMethod.KLARNA]: 'Klarna',
      [PaymentMethod.ON_ACCOUNT]: 'Auf Rechnung',
      funding: 'Förderung',
      financing: 'Finanzierung',
    };

    await mailer.sendMail(
      await Message.compose({
        html: orderConfirmationTemplate({
          paymentType: paymentTypeTranslation[payment.paymentType] as string,
          firstName: contact.firstName ?? '',
          lastName: contact.lastName ?? '',
          imageLink: product.imageSmallUrl ?? '',
          price: isFunding ? 0 : order.productConfiguration.price,
          productName: order.productConfiguration.name,
          productLink: await pathToContent(product.id),
          settingsInvoiceLink: PathToCampusSettingsRoute(CampusSettingsRoute.INVOICES),
          isFunding,
        }),
        subject: 'Danke für deine Bestellung',
        attachmentPaths: Array.from(attachmentPaths.values()),
      }),
      new Recipient([contact.email], [...(company?.resetMail ? [company.resetMail] : [])]),
    );

    const xrechnung = attachmentPaths.get(Attachment.XRECHNUNG);
    if (financerEmailCC && xrechnung) {
      // hier wird noch die XRechnungs mail zusätzlich verschickt an den Förderer / Finanzierer (HCC)
      await mailer.sendMail(
        await Message.compose({
          html: `Sehr geehrte Damen und Herren, 
          
          im Folgenden übersenden wir Ihnen die E-Rechnung einer Bestellung des Digitale Helden Mentorenprogramm 24/25.
          
          
          Mit freundlichen Grüßen, 
          Team Digitale Helden


          
          Digitale Helden gGmbH
          Arnsburger Straße 58 d
          (Hinterhaus)
          60385 Frankfurt am Main
                  
          Kontakt:
          Telefon +49 69 8740361·0
          Telefax +49 69 8740361·69
          digitale-helden.de

          Datenschutz: https://digitale-helden.de/startseite/datenschutz
          Impressum: https://digitale-helden.de/startseite/impressum
          `,
          subject: 'E-Rechnung Digitale Helden Mentorenprogramm 24/25 ',
          attachmentPaths: [xrechnung],
        }),
        new Recipient(financerEmailCC),
      );
    }
  };
};

//* Query
export const sendOrderConfirmationMail = registerMutation({
  type: () => String,
  operationName: 'sendOrderConfirmationMail',
  resolve: sendOrderConfirmationMailResolver,
  params: [
    { type: () => String, name: 'orderID' },
    { type: () => String, name: 'belegID' },
  ],
});
