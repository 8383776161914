// import { getAllContactCompanyRelationsByContactCOQL } from './contact-company-relation/queries/getAllContactCompanyRelationsByContactCOQL';
// import { getContactByID } from './contact/queries/getContactByID';
// import { getAllGroupContactRelationsByContactIDCOQL } from './group-contact-relations/queries/getAllGroupContactRelationsByContactIDCOQL';
// import { submitMentoringOrder } from './order/queries/submitMentoringOrder';
// import { createMentoringOrder } from './order/queries/createMentoringOrder';
// import { getAllActiveProducts } from './product/queries/getAllActiveProducts';
// import { getAllProducts } from './product/queries/getAllProducts';
// import { getDisabledProductIDs } from './product/queries/getDisabledProductIDs';
// import { getProductByID } from './product/queries/getProductByID';
// import { getProductByName } from './product/queries/getProductByName';
// import { getProductsByContextID } from './product/queries/getProductsByContextID';
// import { getProductsByIDs } from './product/queries/getProductsByIDs';

// export namespace DigitaleHelden {
//   export namespace api {
//     export namespace Product {
//       export const getAllActive = getAllActiveProducts;
//       export const getAll = getAllProducts;
//       export const getDisabledIDs = getDisabledProductIDs;
//       export const getByID = getProductByID;
//       export const getByName = getProductByName;
//       export const getAllByContextID = getProductsByContextID;
//       export const getByIDs = getProductsByIDs;
//     }
//     export namespace Contact {
//       export const getByID = getContactByID;
//     }
//     export namespace ContactCompanyRelation {
//       export const getAllByContactIDCoql = getAllContactCompanyRelationsByContactCOQL;
//     }
//     export namespace GroupContactRelation {
//       export const getAllByContactIDCoql = getAllGroupContactRelationsByContactIDCOQL;
//     }
//     export namespace Order {
//       export const submitMentoring = submitMentoringOrder;
//       export const createMentoring = createMentoringOrder;
//     }
//   }
// }
