import type { Maybe, ValueEvent } from '@adornis/base/utilTypes';
import { css } from '@adornis/chemistry/directives/css';
import { FormField } from '@adornis/formfield/form-field';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { Link } from '../../db/Link';
import { LASPromptChoosePage } from '../prompts/page/las-prompt-choose-page';

@customElement('d-link-editor')
export class DLinkEditor extends FormField<Link> {
  override render() {
    if (!this.value.value) return html` <d-text> Keine Link-Entity gefunden </d-text> `;

    return html`
      <d-flex space="sm" ${css({ marginTop: this.spacing.sm })}>
        <d-button
          @click=${async () => {
            if (!this.value.value) return;

            const link = this.value.value?.link ?? '';
            const selectedPageID = link.startsWith(':page-') ? link.split('-')[1] : null;
            const pageID = await LASPromptChoosePage.showPopup<Maybe<string>>({
              props: {
                selectedPageID,
              },
            });

            if (!pageID) return (this.value.value.link = '');
            this.value.value.link = `:page-${pageID}`;
            this.dispatchEvent(new CustomEvent('value-picked', { detail: { value: this.value.value } }));
            this.requestUpdate();
          }}
        >
          Mit Seite verknüpfen
        </d-button>
        <d-input
          placeholder="Link"
          .value=${this.value.value.link}
          @value-picked=${(e: ValueEvent<string>) => {
            if (!this.value.value) return;
            this.value.value.link = e.detail.value;
            this.dispatchEvent(new CustomEvent('value-picked', { detail: { value: this.value.value } }));
            this.requestUpdate();
          }}
        ></d-input>

        <d-input
          placeholder="Target"
          .value=${this.value.value.target}
          @value-picked=${(e: ValueEvent<string>) => {
            if (!this.value.value) return;
            if (e.detail.value !== '_blank' && e.detail.value !== '_self') return;
            this.value.value.target = e.detail.value;
            this.dispatchEvent(new CustomEvent('value-picked', { detail: { value: this.value.value } }));
            this.requestUpdate();
          }}
        ></d-input>
      </d-flex>
    `;
  }
}
