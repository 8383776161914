import { registerQuery } from '@adornis/baseql/metadata/register';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { makeZohoAPIRequest } from '@adornis/digitale-helden-shared/server/zoho/api';
import {
  CONTACT_COMPANY_RELATION_BIMAP,
  CONTACT_COMPANY_RELATION_ZOHO_FIELDS,
  ContactCompanyRelation,
} from '../../../db/Relations/ContactCompanyRelation';

//* Resolver
const getAllContactCompanyRelationsByContactCOQLResolver = (contactID: string) => {
  return async (gqlFields: BaseQLSelectionSet<ContactCompanyRelation>) => {
    const endpoint = `coql`;
    const fields = ContactCompanyRelation.gqlFieldsZoho(gqlFields, CONTACT_COMPANY_RELATION_BIMAP).join(',');
    const query = `SELECT ${fields} FROM ${ContactCompanyRelation.ZOHO_MODULE} WHERE ${CONTACT_COMPANY_RELATION_ZOHO_FIELDS.CONTACT} = '${contactID}'`;

    const result = await makeZohoAPIRequest({
      method: 'post',
      endpoint,
      data: { select_query: query },
      zohoModule: ContactCompanyRelation.ZOHO_MODULE,
      isRawRequest: true,
    });

    if (!result?.data?.[0]) return [];
    const deserializedRelation = result.data.map(data =>
      ContactCompanyRelation.deserializeZoho(data),
    ) as ContactCompanyRelation[];
    return deserializedRelation;
  };
};

//* Query
export const getAllContactCompanyRelationsByContactCOQL = registerQuery({
  type: () => [ContactCompanyRelation],
  operationName: 'getAllContactCompanyRelationsByContactCOQL',
  resolve: getAllContactCompanyRelationsByContactCOQLResolver,
  params: [{ type: () => String, name: 'contactID' }],
});
