import { registerQuery } from '@adornis/baseql/metadata/register';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { CONTACT_BIMAP, CONTACT_ZOHO_FIELDS, Contact } from '@adornis/digitale-helden-shared/db/Contact';
import { makeZohoCOQLRequest } from '@adornis/digitale-helden-shared/server/zoho/api';

export const getContactByIDCOQL = registerQuery({
  type: () => Contact,
  operationName: 'getContactByIDCOQL',
  resolve: (id: string) => {
    return async (gqlFields: BaseQLSelectionSet<Contact>) => {
      if (!id) return null;

      const result = await makeZohoCOQLRequest({
        gqlFields,
        filter: `${CONTACT_ZOHO_FIELDS.ID} = '${id}'`,
        moduleBiMap: CONTACT_BIMAP,
        moduleName: Contact.ZOHO_MODULE,
      });

      if (!result?.data?.[0]) return null;
      const data = result.data[0];
      const deserialized = Contact.deserializeZoho(data);
      return deserialized;
    };
  },
  params: [{ name: 'id', type: () => String }],
});
