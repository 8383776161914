import { Arg, Entity, Field, Mutation, Query } from '@adornis/baseql/decorators';
import { type BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { getAllZohoRecords, makeZohoAPIRequest, makeZohoCOQLRequest, upsertZohoRecord } from '../../server/zoho/api';
import { type ZohoRecord } from '../../server/zoho/types';
import { BiMap } from '../BiMap';
import { ZohoModule } from '../enumns/zoho';
import { ContactAcademyRole, ContactPosition, type ContactCompanyPermission, type ContactDepartment } from '../enums';
import { ZohoEntity } from '../zoho-entity';

export enum CONTACT_COMPANY_RELATION_ZOHO_FIELDS {
  ID = 'id',
  CONTACT = 'KontaktId',
  COMPANY = 'FirmaId',
  DEPARTMENT = 'Abteilung',
  COMPANY_PERMISSION = 'Firmen_Permission',
  POSITION = 'Firmen_Position',
  UNTERSTELLT_UNTER = 'Unterstellt_unter',
}

export const CONTACT_COMPANY_RELATION_BIMAP = new BiMap<string, CONTACT_COMPANY_RELATION_ZOHO_FIELDS>([
  ['id', CONTACT_COMPANY_RELATION_ZOHO_FIELDS.ID],
  ['contactId', CONTACT_COMPANY_RELATION_ZOHO_FIELDS.CONTACT],
  ['companyId', CONTACT_COMPANY_RELATION_ZOHO_FIELDS.COMPANY],
  ['permissions', CONTACT_COMPANY_RELATION_ZOHO_FIELDS.COMPANY_PERMISSION],
  ['positions', CONTACT_COMPANY_RELATION_ZOHO_FIELDS.POSITION],
  ['department', CONTACT_COMPANY_RELATION_ZOHO_FIELDS.DEPARTMENT],
  ['subordinateUnderId', CONTACT_COMPANY_RELATION_ZOHO_FIELDS.UNTERSTELLT_UNTER],
]);

export const CONTACT_COMPANY_RELATION_FOREIGN_KEYS = [
  CONTACT_COMPANY_RELATION_ZOHO_FIELDS.CONTACT,
  CONTACT_COMPANY_RELATION_ZOHO_FIELDS.COMPANY,
];

@Entity()
export class ContactCompanyRelation extends ZohoEntity {
  static override _class = 'ContactCompanyRelation';
  static override ZOHO_MODULE = ZohoModule.CONTACT_COMPANY_RELATIONS;
  static override ZOHO_FIELDS = Array.from(CONTACT_COMPANY_RELATION_BIMAP.values).join(',');

  @Field(type => String) id!: string;

  // contact
  @Field(type => String) contactId?: string;
  @Field(type => String) contactName?: string;

  @Field(type => String) companyId?: string;
  @Field(type => [String]) permissions?: ContactCompanyPermission[];
  @Field(type => [String]) positions?: ContactPosition[];
  @Field(type => String) otherPosition?: string;
  @Field(type => String) department?: ContactDepartment;
  @Field(type => String) subordinateUnderId!: string;

  get toFilteredJSON() {
    const fields = {};
    const keys = Array.from(CONTACT_COMPANY_RELATION_BIMAP.keys);
    keys.forEach(key => {
      if (key && this.toJSON()[key]) {
        fields[key] = this.toJSON()[key];
      }
    });
    return fields;
  }

  override serializeZoho = (isNew: boolean = false) => {
    const fields = {};
    const keys = Array.from(CONTACT_COMPANY_RELATION_BIMAP.keys);
    keys.forEach(key => {
      const keyZoho = CONTACT_COMPANY_RELATION_BIMAP.get(key);
      if (keyZoho && (this[key] || typeof this[key] === 'boolean')) {
        if (CONTACT_COMPANY_RELATION_FOREIGN_KEYS.includes(keyZoho)) {
          fields[keyZoho] = {
            id: this[key],
          };
        } else {
          fields[keyZoho] = this[key] ?? null;
        }
      }
    });

    const data: ZohoRecord<any> = {
      data: [fields],
      trigger: ['workflow'],
    };
    return JSON.stringify(data).replace(/"id":[ ]?"([0-9]+)"/g, '"id":$1');
  };

  static override deserializeZoho = (rawData: any) => {
    const fields = {};
    const keys = Array.from(CONTACT_COMPANY_RELATION_BIMAP.reverseKeys);
    keys.forEach(key => {
      const keyLAS = CONTACT_COMPANY_RELATION_BIMAP.reverseGet(key);
      if (keyLAS) {
        if (CONTACT_COMPANY_RELATION_FOREIGN_KEYS.includes(key)) {
          fields[keyLAS] = rawData[key]?.id ?? null;

          if (key === CONTACT_COMPANY_RELATION_ZOHO_FIELDS.CONTACT) {
            fields.contactName = rawData[key]?.name ?? null;
          }
        } else {
          fields[keyLAS] = rawData[key] ?? null;
        }
      }
    });
    return new ContactCompanyRelation({
      ...fields,
    });
  };

  @Query(type => [ContactCompanyRelation])
  static getContactCompanyRelationsByUserAndPermission(
    @Arg('contactId', type => String) contactId: string,
    @Arg('permission', type => String) permission: ContactCompanyPermission,
  ) {
    return async (gqlFields: BaseQLSelectionSet<ContactCompanyRelation>) => {
      const result = await makeZohoCOQLRequest({
        moduleName: this.ZOHO_MODULE,
        moduleBiMap: CONTACT_COMPANY_RELATION_BIMAP,
        gqlFields,
        filter: `(${CONTACT_COMPANY_RELATION_ZOHO_FIELDS.CONTACT} = '${contactId}') AND (${CONTACT_COMPANY_RELATION_ZOHO_FIELDS.COMPANY_PERMISSION} = '${permission}')`,
      });

      if (!result?.data) return [];

      const deserialized = result.data.map(data => this.deserializeZoho(data)) as ContactCompanyRelation[];
      return deserialized;
    };
  }

  @Query(type => String)
  static deleteContactCompanyRelationById(@Arg('id', type => String) id: string) {
    return async () => {
      const endpoint = `${this.ZOHO_MODULE}/${id}`;
      await makeZohoAPIRequest({ method: 'delete', endpoint, zohoModule: this.ZOHO_MODULE });
    };
  }

  @Query(type => [ContactCompanyRelation])
  static getContactCompanyRelationsByCompany(@Arg('companyId', type => String) companyId: string) {
    return async (gqlFields: BaseQLSelectionSet<ContactCompanyRelation>) => {
      const result = await makeZohoCOQLRequest({
        filter: `${CONTACT_COMPANY_RELATION_ZOHO_FIELDS.COMPANY} = '${companyId}'`,
        gqlFields,
        moduleBiMap: CONTACT_COMPANY_RELATION_BIMAP,
        moduleName: ContactCompanyRelation.ZOHO_MODULE,
      });
      if (!result?.data) return [];
      const deserialized = result.data.map(data => this.deserializeZoho(data)) as ContactCompanyRelation[];
      return deserialized;
    };
  }

  @Query(type => String)
  static deleteContactCompanyRelationsByIDs(@Arg('ids', type => [String]) ids: string[]) {
    return async () => {
      if (ids.length === 0) return;
      const endpoint = `${this.ZOHO_MODULE}?ids=${ids.join(',')}`;
      await makeZohoAPIRequest({ method: 'delete', endpoint, zohoModule: this.ZOHO_MODULE });
    };
  }

  @Query(type => ContactCompanyRelation)
  static getContactCompanyRelation(
    @Arg('contactId', type => String) contactId: string,
    @Arg('companyId', type => String) companyId: string,
  ) {
    return async (gqlFields: BaseQLSelectionSet<ContactCompanyRelation>) => {
      const result = await makeZohoCOQLRequest({
        gqlFields,
        filter: `${CONTACT_COMPANY_RELATION_ZOHO_FIELDS.CONTACT} = '${contactId}' AND ${CONTACT_COMPANY_RELATION_ZOHO_FIELDS.COMPANY} = '${companyId}'`,
        moduleBiMap: CONTACT_COMPANY_RELATION_BIMAP,
        moduleName: ContactCompanyRelation.ZOHO_MODULE,
      });
      if (!result?.data?.[0]) return null;
      const resultData = result.data[0];
      const deserializedRelation = this.deserializeZoho(resultData);
      return deserializedRelation;
    };
  }

  @Mutation(type => ContactCompanyRelation)
  public static upsertContactCompanyRelation(
    @Arg('entity', type => ContactCompanyRelation) entity: ContactCompanyRelation,
  ) {
    return async (gqlFields: BaseQLSelectionSet<ContactCompanyRelation>) => {
      const positions = new Set<string>(entity.positions);

      if (positions.has(ContactAcademyRole.SCHULLEITER)) {
        positions.delete(ContactAcademyRole.SCHULLEITER);
        positions.add(ContactPosition.MANAGEMENT);
      }

      entity.positions = Array.from(positions.values()) as ContactPosition[];

      if (!entity.id) {
        const existingRelation = await ContactCompanyRelation.getContactCompanyRelation(
          entity.contactId!,
          entity.companyId!,
        )(ContactCompanyRelation.allFields);
        if (existingRelation) {
          entity = new ContactCompanyRelation({
            ...existingRelation.toFilteredJSON,
            ...entity.toFilteredJSON,
            permissions: Array.from(
              new Set([...(existingRelation.permissions ?? []), ...(entity.permissions ?? [])]).values(),
            ),
            positions: Array.from(
              new Set([...(existingRelation.positions ?? []), ...(entity.positions ?? [])]).values(),
            ),
          });
        }
      }

      const id = await upsertZohoRecord(this.ZOHO_MODULE, entity);
      entity.id = id;
      return entity;
    };
  }

  @Query(type => [ContactCompanyRelation], { requestHandlerMeta: { queryCache: { cachePolicy: 'bypass' } } })
  public static getAllContactCompanyRelations() {
    return async (gqlFields: BaseQLSelectionSet<ContactCompanyRelation>) => {
      const rawData = await getAllZohoRecords(this.ZOHO_MODULE, { fields: this.ZOHO_FIELDS }, true);
      const contacts = rawData.data.map(data => this.deserializeZoho(data));
      return contacts;
    };
  }
}
