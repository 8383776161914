/* eslint-disable max-lines */
import { css } from '@adornis/chemistry/directives/css';
import { html } from 'lit';
import { leadSignupFormularTranslations } from '../_forms/_lead-signup-form/db/translations';
import { supportFormularTranslations } from '../_forms/_support-form/db/translations';
import { generalFormularTranslations } from '../_forms/db/translations';
import {
  AcademyRoles,
  GroupContactRole,
  MediaType,
  OrderAttendenceStatus,
  ProductPaymentModality,
  Salutation,
} from './enums';

export enum TranslationRootItem {
  SUPPORT_FORMULAR = 'SUPPORT_FORMULAR',
  GENERAL_FORMULAR = 'GERNAL_FORMULAR',
  LEAD_SIGNUP_FORMULAR = 'LEAD_SIGNUP_FORMULAR',
}

export const translations = {
  [TranslationRootItem.SUPPORT_FORMULAR]: supportFormularTranslations,
  [TranslationRootItem.GENERAL_FORMULAR]: generalFormularTranslations,
  [TranslationRootItem.LEAD_SIGNUP_FORMULAR]: leadSignupFormularTranslations,

  field: {
    margin: ['Margin', '#english'],
    padding: ['Padding', '#english'],
    icon: ['Icon', '#english'],
    color: ['Farbe', '#english'],
    size: ['Größe', '#english'],
    isBold: ['Fett?', '#english'],
    openLinkInNewTab: ['In neuem Fenster öffnen?', '#english'],
    invert: ['Invertieren?', '#english'],
    link: ['Link', '#english'],
    text: ['Text', '#english'],
  },

  // general
  academy: ['Campus', '#english'],
  add: ['Hinzufügen', '#english'],
  create: ['Erstellen', '#english'],
  apply: ['Anwenden', '#english'],
  send: ['Senden', '#english'],
  register: ['Registrieren', '#english'],
  request: ['Anfordern', '#english'],
  cancel: ['Abbrechen', '#english'],
  confirm_cancel: ['Willst du wirklich abbrechen?', '#english'],
  forgot_password: ['Passwort vergessen?', '#english'],
  wrong_password: ['Passwort falsch', '#english'],
  no_permission_page: ['Keine Berechtigung für diese Seite!', '#english'],
  no_description: ['Keine Beschreibung angegeben', '#english'],
  back: ['Zurück', '#english'],
  next: ['Weiter', '#english'],
  dropdown_search_selection: {
    no_search: ['Bitte hier tippen um die Auswahl zu durchsuchen...', '#english'],
    empty_results: ['Die Suche hat keinen Treffer ergeben', '#english'],
  },

  // sidebar
  sidebar: {
    dashboard: ['Dashboard', '#english'],
    dashboard_tooltip: ['Dashboard', '#english'],
    'explore-products': ['Entdecken', '#english'],
    'explore-products-tooltip': ['Entdecken', '#english'],
    'my-learning-contents': ['Meine Lerninhalte', '#english'],
    'my-learning-contents-tooltip': ['Meine Lerninhalte', '#english'],
    settings: ['Einstellungen', 'Settings'],
    settings_tooltip: ['Einstellungen', 'Settings'],
    institution: ['Institution', '#english'],
    institution_tooltip: ['Institution', '#english'],
    profile: ['Profil', '#english'],
    profile_tooltip: ['Profil', '#english'],
    team: ['Team', 'Team'],
    groups: ['Gruppen', '#english'],
    groups_tooltip: ['Gruppen', '#english'],
    user_administration: ['Administration', '#english'],
    user_administration_tooltip: ['Administration', '#english'],
    support: ['Support-Bereich', '#english'],
    support_tooltip: ['Support-Bereich', '#english'],
    stop_impersonation: ['Stop. Impers.', '#english'],
    stop_impersonation_tooltip: ['Stop. Impers.', '#english'],
    logout: ['Logout', '#english'],
    logout_tooltip: ['Logout', '#english'],
    buildify: ['Buildify', 'Buildify'],
    buildify_tooltip: ['Buildify', 'Buildify'],
    bottom: [
      html`
        <d-flex space="sm">
          <d-text small> Wir danken unseren Förderer:innen. </d-text>
          <d-text small> Der Campus ist ein Angebot der </d-text>
          <d-text small>
            <d-text small bold highlight ${css({ display: 'inline-block' })}>Digitalen Helden</d-text>.
          </d-text>
        </d-flex>
      `,
      '#english',
    ],
    cookies: ['Cookies', '#english'],
    imprint: ['Impressum', '#english'],
    data_privacy: ['Datenschutz', '#english'],
  },

  // page headings
  profile_overview: ['Dein Profil im Überblick', '#english'],
  'my-learning-contents-heading': ['Meine Lerninhalte', '#english'],
  cookies_overview: ['Deine Cookies', '#english'],
  catalog_overview: ['Alle Produkte im Überblick', '#english'],
  institution_overview: ['Institutionen verwalten', '#english'],
  groups_overview: ['Gruppen', '#english'],
  group_overview: ['Gruppenansicht', '#english'],
  support_overview: ['Kontaktformular', '#english'],
  dashboard_overview: ['Dashboard', '#english'],
  admin_overview: ['Admin', '#english'],
  course_overview: ['Deine Kurse', '#english'],
  products_overview: ['Entdecken', '#english'],

  // page subheading
  dashboard_subheading: ['Dein persönliches Dashboard', '#english'],
  admin_subheading: ['Adminverwaltung', '#english'],
  support_subheading: ['Sende uns eine Nachricht mit deinem Anliegen.', '#english'],
  profile_subheading: ['Dein persönliches Profil', '#english'],
  'my-learning-contents-subheading': ['Alle meine Lerninhalte im Überblick', '#english'],
  institution_subheading: ['Deine Institution:en im Überblick', '#english'],
  course_subheading: ['', '#english'],
  groups_subheading: ['Deine Gruppen im Überblick', '#english'],
  group_subheading: ['Schüler:innen, Pädagog:innen und Gruppenbesitzer:innen', '#english'],
  products_subheading: ['Alle Produkte im Überblick', '#english'],
  cookies_subheading: ['', '#english'],

  // courses
  courses: ['Lerninhalte', '#english'],
  loading_courses: ['Lade Lerninhalte...', '#english'],
  no_courses: ['Keine Lerninhalte', '#english'],

  // products
  product_is_not_available: ['Dieses Produkt ist nicht verfügbar', '#english'],

  // profile data
  first_name: ['Vorname', '#english'],
  last_name: ['Nachname', '#english'],
  contact: ['Kontakt', '#english'],
  category: ['Kategorie', '#english'],
  e_mail: ['E-Mail', '#english'],
  your_email: ['Deine E-Mail-Adresse', '#english'],
  e_mail_repeat: ['Wiederholung deiner E-Mail-Adresse', '#english'],
  phone: ['Telefon-Nr.', '#english'],
  mobile: ['Mobil', '#english'],
  title: ['Titel', '#english'],
  contact_name: ['Kontakt-Name', '#english'],
  department: ['Abteilung/Bereich', '#english'],
  company_name: ['Firmenname', '#english'],
  contact_category: ['Kontakt-Kategorie', '#english'],
  contact_status: ['Kontakt-Status', '#english'],
  position: ['Position', '#english'],
  internal_departments: ['Interne Bereiche', '#english'],
  academy_role: ['Ich bin', '#english'],
  academy_role_other: ['Sonstiges', '#english'],

  account_existing: ['Akademie-Account vorhanden', '#english'],
  save: ['Speichern', '#english'],
  save_success: ['Speichern erfolgreich!', '#english'],
  change_password: ['Passwort ändern', '#english'],
  miscellaneous: ['Sonstiges', '#english'],
  sample_name: ['Max Mustermann', '#english'],
  sample_address: ['Musterstraße 22a, 78258 Ort', '#english'],

  // groups
  groups_title: ['Gruppen verwalten', '#english'],
  your_groups: ['Deine Gruppen', '#english'],
  group_roles: { gruppenbesitzer: ['Gruppenbesitzer:in', '#english'], teilnehmer: ['Teilnehmer:in'] },
  create_group: ['Neue Gruppe anlegen', '#english'],
  group_name: ['Gruppenname', '#english'],
  edit_group_name: ['Gruppenname bearbeiten', '#english'],

  // group functions
  [AcademyRoles.ELTERNTEIL]: ['Elternteil', '#english'],
  [AcademyRoles.SCHULLEITER]: ['Schulleiter:in', '#english'],
  [AcademyRoles.SOZIALARBEITER]: ['Pädagog:in', '#english'],
  [AcademyRoles.STUDENT]: ['Student:in', '#english'],
  [AcademyRoles.SCHUELER]: ['Schüler:in', '#english'],
  [AcademyRoles.SONSTIGES]: ['Sonstiges', '#english'],

  // group roles
  role: ['Rolle', '#english'],
  [GroupContactRole.GROUP_ADMIN]: ['Gruppenbesitzer:in', '#english'],
  [GroupContactRole.PARTICIPANT]: ['Teilnehmer:in', '#english'],
  [`${GroupContactRole.GROUP_ADMIN}_plural`]: ['Gruppenbesitzer:innen', '#english'],
  [`${GroupContactRole.PARTICIPANT}_plural`]: ['Teilnehmer:innen', '#english'],
  no_participants: ['Es wurden noch keine Teilnehmer:innen hinzugefügt.', '#english'],
  no_group_admins: ['Es wurden noch keine Gruppenbesitzer:innen hinzugefügt.', '#english'],

  // salutations
  salutation: ['Anrede', '#english'],
  [Salutation.DIVERS]: ['Divers', '#english'],
  [Salutation.MISTER]: ['Herr', '#english'],
  [Salutation.MADAM]: ['Frau', '#english'],

  // media types
  belong_to: ['Sie gehören zu', '#english'],
  [MediaType.FUNK_FERNSEHEN]: ['Funk/Fernsehen', '#english'],
  [MediaType.FÖRDERER_IN]: ['Förderer:in', '#english'],
  [MediaType.PARTNER_IN]: ['Partner:in', '#english'],
  [MediaType.POLITIK]: ['Politik', '#english'],
  [MediaType.PRESSE]: ['Presse', '#english'],
  [MediaType.SPENDER_IN]: ['Spender:in', '#english'],
  // [MediaType.SONSTIGES]: ['Sonstiges', '#english'],

  testing: ['Testing', '#english'],
  style_guide: ['Style Guide', '#english'],
  confirm_logout: ['Möchtest du dich wirklich abmelden?', '#english'],
  logout: ['Abmelden', '#english'],
  custom_style_components: ['Custom Style Components', '#english'],
  luctus_accumsan: [
    'Luctus accumsan tortor posuere ac ut consequat semper viverra nam libero justo laoreet sit amet cursus sit amet dictum sit.',
    '#english',
  ],
  luctus_accumsan_short: ['Luctus accumsan tortor posuere.', '#english'],
  word_highlighting: [
    'Wörter können natürlich je nach belieben noch gehighlighted werden. (Komponente 1 und 2)',
    '#english',
  ],
  h1_title: ['H1 title', '#english'],
  h2_subtitle: ['H2 subtitle', '#english'],
  school: ['Schule', '#english'],
  address: ['Adresse', '#english'],
  nothing_yet: ['nothing here yet..', '#english'],
  data_overview: ['Daten Übersicht', '#english'],
  schools: ['Schulen', '#english'],
  view: ['Ansehen', '#english'],
  users: ['Nutzer:in', '#english'],
  no_schools: ['Keine Schulen', '#english'],
  details: ['Details', '#english'],
  new_password: ['Neues Passwort', '#english'],
  password: ['Passwort', '#english'],
  repeat_password: ['Passwort wiederholen', '#english'],
  cancel_confirmation: ['Wollen Sie wirklich abbrechen?', '#english'],
  incomplete_form: ['Es müssen alle Felder ausgefüllt sein', '#english'],
  different_passwords: ['Die Passwörter sind unterschiedlich', '#english'],
  password_changed: ['Passwort geändert', '#english'],
  save_error: ['Beim Speichern ist etwas schiefgelaufen', '#english'],
  signup_now: ['Jetzt anmelden', '#english'],
  i_am: ['Ich bin', '#english'],
  data_protection_seen: ['Ich habe die Datenschutzerklärung zur Kenntnis genommen.', '#english'],
  e_mail_accept_header: ['Ich möchte zukünftig per E-Mail erhalten:', '#english'],
  e_mail_hero_accept: [
    html`
      Die <d-text bold ${css({ display: 'inline-block' })}> Heldenpost </d-text> – Ihre
      <d-text bold ${css({ display: 'inline-block' })}> monatliche E-Mail </d-text> zum Thema Medienbildung
    `,
    '#english',
  ],
  e_mail_webinar_accept: [
    html` <d-text bold ${css({ display: 'inline-block' })}> Einladungen </d-text> zu unseren
      <d-text bold ${css({ display: 'inline-block' })}> Webinaren </d-text> für Eltern und Lehrkräfte`,
    '#english',
  ],
  have_issue: ['Haben Sie ein konkretes Anliegen an uns?', '#english'],
  company: ['Ihre Schule/Institution', '#english'],
  request_media_package: ['Pressepaket anfordern', '#english'],

  // profile
  name: ['Name', '#english'],
  edit: ['Bearbeiten', '#english'],
  general: ['Allgemein', '#english'],
  personal_details: ['Persönliche Details', '#english'],
  address_data: ['Adressdaten', '#english'],
  notifications: ['Benachrichtigungen', '#english'],
  is_webinar: ['Webinar-Verteiler', '#english'],
  webinar_on: ['Sie erhalten Informationen zu unseren regelmäßig stattfindenden Webinaren.', '#english'],
  webinar_off: ['Sie erhalten keine Informationen mehr zu unseren regelmäßig stattfindenden Webinaren.', '#english'],
  is_newsletter: ['Heldenpost', '#english'],
  newsletter_on: ['Sie erhalten eine monatliche E-Mail zum Thema Medienbildung.', '#english'],
  newsletter_off: ['Sie erhalten keine monatliche E-Mail mehr zum Thema Medienbildung.', '#english'],
  is_email: ['E-Mail-Verteiler', '#english'],
  email_on: ['Sie erhalten aktuelle Infos der Digitalen Helden.', '#english'],
  email_off: ['Sie erhalten keine aktuellen Infos der Digitalen Helden mehr.', '#english'],
  function: ['Funktion', '#english'],
  function_other: ['Sonstige Funktion', '#english'],
  street: ['Straße', '#english'],
  zip: ['Postleitzahl', '#english'],
  city: ['Stadt', '#english'],
  federal_state: ['Bundesland', '#english'],
  country: ['Land', '#english'],
  action: ['Aktion', '#english'],
  product: ['Produkt', '#english'],
  email_repeat_error: ['Die E-Mail-Adressen müssen übereinstimmen.', '#english'],
  validation_non_optional: ['Dies ist ein Pflichtfeld.', '#english'],
  validation_email: ['Bitte gib eine gültige E-Mail an.', '#english'],
  salutation_required: ['Anrede ist ein Pflichtfeld!', '#english'],
  enter_all_non_optional: ['Geben Sie all Pflichtfelder an.', '#english'],
  fax: ['Fax', '#english'],
  department_number: ['Dienststellennummer', '#english'],
  department_needs_positive_integer: ['Dienststellennummer muss eine postive Ganzzahl sein', '#english'],
  department_max_digits: ['Dienststellennummer darf maximal 9 Zeichen beinhalten', '#english'],

  // institution
  institution_title: ['Institution/Schule', '#english'],
  schoolDistrict: ['Schulamtsbezirk', '#english'],
  schoolType: ['Schulform', '#english'],
  institution_create: ['Institution erstellen', '#english'],

  // Placeholders
  settings: {
    name: ['Einstellungen', 'Settings'],

    profile: {
      // personal details
      salutation: ['Anrede*', '#english'],
      firstName: ['Vorname*', '#english'],
      lastName: ['Nachname*', '#english'],
      email: ['E-Mail*', '#english'],
      repeatEmail: ['Wiederholung Ihrer E-Mail-Adresse*', '#english'],
      academyRole: ['Funktion*', '#english'],
      otherAcademyRole: ['Sonstige Funktion*', '#english'],
      // adress
      street: ['Straße, Hausnummer*', '#english'],
      zip: ['Postleitzahl*', '#english'],
      city: ['Stadt*', '#english'],
      federalState: ['Bundesland*', '#english'],
      country: ['Land*', '#english'],
    },

    safety: {
      title: ['Passwort ändern', '#english'],

      oldPassword: ['Altes Passwort*', '#english'],
      newPassword: ['Neues Passwort*', '#english'],
      generatePasswordTooltip: ['Sicheres Passwort generieren', '#english'],
      repeatNewPassword: ['Neues Passwort bestätigen*', '#english'],
    },

    institution: {
      address: {
        title: ['Adressdaten', '#english'],

        name: ['Name der Institution', '#english'],
        phone: ['Telefon-Nr.', '#english'],
        fax: ['Fax', '#english'],
        departmentNumber: ['Dienststellennummer', '#english'],
        street: ['Straße, Haunummer', '#english'],
        homepage: ['Homepage', '#english'],
        city: ['Stadt', '#english'],
        zip: ['Postleitzahl', '#english'],
        federalState: ['Bundesland', '#english'],
        country: ['Land', '#english'],
      },
      users: {},
    },
  },
  ph_first_name: ['-Bitte Vornamen angeben-', '#english'],
  ph_last_name: ['-Bitte Nachnamen angeben-', '#english'],
  ph_email: ['-Bitte E-Mail-Adresse angeben-', '#english'],
  ph_street: ['-Bitte Straße angeben-', '#english'],
  ph_zip: ['-Bitte Postleitzahl angeben-', '#english'],
  ph_city: ['-Bitte Stadt angeben-', '#english'],
  ph_federal_state: ['-Bitte Bundesland angeben-', '#english'],
  ph_federal_state_de: ['-Bitte Bundesland auswählen-', '#english'],
  ph_salutation: ['-Bitte Anrede auswählen-', '#english'],
  ph_function: ['-Bitte Funktion auswählen-', '#english'],
  ph_country: ['-Bitte Land auswählen-', '#english'],

  digimember: {
    vimeo_data: [
      html`
        Um das Video ansehen zu können, müssen Sie die
        <a href="https://vimeo.com/privacy" target="_blank"> Datenschutzerklärung </a> von Vimeo akzeptieren.
      ` as unknown as string,
      '#english',
    ],
    youtube_data: [
      html`
        Um das Video ansehen zu können, müssen Sie die
        <a href="https://policies.google.com/privacy" target="_blank"> Datenschutzerklärung </a> von YouTube
        akzeptieren.
      ` as unknown as string,
      '#english',
    ],
    accept: ['Akzeptieren'],
  },

  // legal
  data_privacy_accept: ['Ich habe die Datenschutzerklärung zur Kenntniss genommen.', '#english'],
  declaration_of_consent: [
    html`
      Ich habe die
      <d-link
        href="https://s3.digitale-helden.de/akademie/public/2023/07/Datenschutzrechtliche-Einwilligungserklaerung-Mentorenprogramm.pdf"
        target="_blank"
        @click=${e => e.stopPropagation()}
      >
        <d-text bold ${css({ display: 'inline-block' })}> Einverständniserkärung </d-text>
      </d-link>
      eingeholt.
    `,
    '#english',
  ],
  accepts_data_privacy: [
    html`
      Ich habe die
      <d-link target="_blank" href="https://digitale-helden.de/startseite/datenschutz/">
        <d-text bold ${css({ display: 'inline-block' })}>Datenschutzerklärung</d-text>
      </d-link>
      zur Kenntnis genommen.
    `,
    '#english',
  ],

  imprint: ['Impressum', '#english'],
  data_privacy: ['Datenschutz', '#english'],

  // formulars

  // loading states
  loading: {
    create_participant: ['Teilnehmer:in wird erstellt...', '#english'],
    create_group: ['Gruppe wird erstellt...', '#english'],
    sending_mail: ['E-Mail wird verschickt...', '#english'],
    buying_product: ['Produkt wird erworben...', '#english'],
    is_processed: ['Wird bearbeitet...', '#english'],
    subscribing_media_package: ['Pressepaket wird abonniert...', '#english'],
    buying_mentoring: ['Mentorenprogramm wird erworben...', '#english'],
    subscribing_newsletter: ['Newsletter-Verteiler wird abonniert...', '#english'],
    subscribing: ['wird abonniert..', '#english'],
    subscribing_webinar: ['Webinar-Verteiler wird abonniert...', '#english'],
    loading_group: ['Gruppendaten werden geladen...', '#english'],
  },

  // success states
  success: {
    added: ['erfolgreich hinzugefügt', '#english'],
    created_group: ['Gruppe wurde erfolgreich angelegt', '#english'],
    link_sent: ['Link wurde erfolgreich versandt.', '#english'],
    email_sent: ['E-Mail wurde erfolgreich verschickt.', '#english'],
    removed_admin: ['Administrator:in wurde erfolgreich gelöscht', '#english'],
    removed_participant: ['Teilnehmer:in wurde erfolgreich gelöscht', '#english'],
    created_successful: ['Erfolgreich angelegt', '#english'],
    is_added_soon: ['wird in Kürze hinzugefügt', '#english'],
  },

  // error states
  error: {
    error: ['Fehler', '#english'],
    mail_sent: [
      'Beim Versenden der E-Mail ist ein Fehler aufgetreten. Versuchen Sie es bitte später erneut.',
      '#english',
    ],
    save_address_first: ['Speichern Sie bitte erst die Adressdaten ab.', '#english'],
    save_general_first: ['Speichern Sie bitte erst die allgemeinen Daten ab.', '#english'],
    delete: ['Während dem Löschvorgang ist etwas schiefgelaufen. Bitte probieren Sie es später erneut.', '#english'],
    invalid_link: ['Ungültiger Link.', '#english'],
    invalid_token: ['Ungültiger Token.', '#english'],
    token_already_used: ['Token wurde bereits verwendet.', '#english'],
  },

  // dialog check password
  dialog_check_password: {
    user_already_registered: ['Nutzer bereits im System registriert', '#english'],
    user_already_registered_explanation: [
      'Es existiert bereits ein Nutzer mit dieser E-Mail-Adresse. Um eine bessere Sicherheit zu garantieren, müssen Sie nun Ihr Passwort eingeben. Sie können dieses auch zurücksetzen, sollten Sie sich nicht mehr daran erinnern können.',
      '#english',
    ],
    check_password: ['Passwort überprüfen', '#english'],
  },

  prompt_check_password: {
    user_already_exists: ['Dieser Account existiert bereits. Bitte geben Sie Ihr Passwort ein.', '#english'],
    check: ['Prüfen', '#english'],
    internet_error: ['Es ist ein interner Fehler aufgetreten. Bitte versuchen Sie es später erneut', '#english'],
    password_missing: ['Bitte geben Sie ein Passwort ein!', '#english'],
    attention: ['Achtung!', '#english'],
    wrong_password: ['Falsches Passwort', '#english'],
  },

  // === pages

  // institution
  institution: {
    users: ['Benutzer:innen deiner Institution', '#english'],
    users_explanation: ['Schüler:innen, Pädagog:innen und Gruppenbesitzer:innen', '#english'],
    admins: ['Administrator:innen', '#english'],
    admins_explanation: ['Personen, die diese Institution verwalten können.', '#english'],
    admins_empty: ['Keine Administrator:innen vorhanden', '#english'],
    cannot_self_degrade: ['Du kannst deine Benutzerrrechte nicht selbst ändern.', '#english'],
    degrade_question: ['Möchtest du dieser/m Schuladministrator:in ihre/seine Rechte entziehen?', '#english'],
    degrade_successful: ['Die Rechte der/des Schuladministrator:in wurde erfolgreich aktualisiert.', '#english'],
    degrade: ['Rechte anpassen', '#english'],
    remove_institution_affiliation_question: ['Möchtest du diese Firmenzugehörigkeit wirklich löschen?', '#english'],
    member_plural: ['Teilnehmer:innen', '#english'],
    member_plural_explanation: ['Personen, welche Mitglied deiner Institution sind.', '#english'],
    no_members: ['Keine Teilnehmer:innen vorhanden', '#english'],
    promote_question: ['Möchtest du die Rechte diese:r Teilnehmer:in wirklich hochstufen?', '#english'],
    promote_successful: ['Teilnehmer:in wurde erfolgreich hochgestuft', '#english'],
    promote: ['Befördern', '#english'],
    general_data: ['Allgemeine Daten', '#english'],
  },

  // catalog
  catalog: {
    no_products: ['Aktuell gibt es keine Produkte', '#english'],
  },

  // cookies
  cookies: {
    load_youtube: ['Youtube-Inhalte automatisch laden', '#english'],
    load_vimeo: ['Vimeo-Inhalte automatisch laden', '#english'],
  },

  dashboard: {
    products: ['Deine Produkte im Überblick', '#english'],
    products_explanation: ['Unser Mentorenprogramm, Online-Trainings, Kurse, Hörbücher, Webinare', '#english'],
    settings_section: ['Basis-Einstellungen', '#english'],
    settings_section_explanation: [
      'Einstellungen von Gruppen, Nutzern und Berechtigungen oder auch Passwörtern',
      '#english',
    ],
    institution: ['Institution / Schule anpassen', '#english'],
    institution_explanation: ['Institution', '#english'],
    institution_text: [
      html`
        Die Einstellungen und Daten für deine <d-text bold ${css({ display: 'inline-block' })}>Institution</d-text> oder
        <d-highlight>Schule</d-highlight> inklusive der Adressdaten kannst Du hier vornehmen.
      `,
      '#english',
    ],
    profile: ['Dein Profil anpassen', '#english'],
    profile_explanation: ['Profil', '#english'],
    profile_text: [
      html`
        Deine <d-text bold ${css({ display: 'inline-block' })}>persönlichen</d-text> Einstellungen änderst du in deinem
        Profil-Bereich. Hier kannst du auch deine <d-highlight>Adressdaten</d-highlight> anpassen.
      `,
      '#english',
    ],
    profile_cta_text: ['Persönliches Profil', '#english'],
    groups: ['Einstellungen anpassen', '#english'],
    groups_explanation: ['Nutzer • Gruppen • Rechte', '#english'],
    groups_users: ['Nutzer & Rechte', '#english'],
    groups_name: ['Gruppen', '#english'],
    groups_text: [
      'Neue Nutzer:innen anlegen, Berechtigungen anpassen, eine neue Klasse oder Gruppe anlegen.',
      '#english',
    ],
    welcome: ['Willkommen!', '#english'],
    welcome_explanation: ['Schön, dass Du da bist.', '#english'],
    welcome_greeting: ['Sei gegrüßt', '#english'],
    welcome_text: [
      html`
        Hier auf deinem persönlichen Dashboard gelangst du zu allen relevanten
        <d-text bold ${css({ display: 'inline-block' })}>Inhalten</d-text> und
        <d-text bold ${css({ display: 'inline-block' })}>Einstellungen</d-text> der Akademie. Im Laufe des Jahres ändern
        sich auch ab und an die Inhalte, es wird neue Produkte und Kurse geben, darüber wirst du hier auch informiert.
      `,
      '#english',
    ],
    welcome_text_secondary: [
      html`
        Wenn du <d-text bold ${css({ display: 'inline-block' })}>Fragen</d-text> hast oder
        <d-highlight>Hilfe</d-highlight> benötigst dann sind wir im
        <d-icon ${css({ fontSize: '1rem' })}> hand-heart </d-icon>
        <d-text bold ${css({ display: 'inline-block' })}>Support-Bereich</d-text> für dich da.
      `,
      '#english',
    ],
    new_content_new: ['Aktuelles!', '#english'],
    new_content: ['Anmeldung Mentorenprogramm!', '#english'],
    new_content_text: ['Die Anmeldung zum Mentorenprogramm ist ab sofort möglich', '#english'],
  },

  // groups

  groups: {
    reached_maximum: ['Maximum erreicht', '#english'],
    users: ['Benutzer:innen deiner Gruppe', '#english'],
    users_explanation: ['Gruppenbesitzer*innen und Teilnehmer:innen', '#english'],
    participants: ['Teilnehmer:innen', '#english'],
    participants_explanation: ['Personen, welche Mitglied dieser Gruppe sind.', '#english'],
    owners: ['Gruppenbesitzer:innen', '#english'],
    owners_explanation: ['Personen, welche diese Gruppe bearbeiten können.', '#english'],
    degrade: ['Abstufen', '#english'],
    promote: ['Hochstufen', '#english'],
    error_removing_user: ['Beim Entfernen der Teilnehmer:in von der Gruppe ist etwas schief gelaufen.', '#english'],
    remove_admin_question: [
      'Möchtest du diese Schulansprechpartner:in wirklich aus der Grupper entfernen?',
      '#english',
    ],
    no_groups: ['Du bist aktuell in keiner Gruppe', '#english'],
    remove_admin_success: ['Die Teilnehmer:in wurde erfolgreich von der Gruppe entfernt', '#english'],
    remove_participant_question: ['Möchtest du diese Teilnehmer:in wirklich von der Gruppe entfernen?', '#english'],
    remove_participant_success: ['Die Teilnehmer:in wurde erfolgreich aus der Gruppe entfernt', '#english'],
    change_role_question: ['Möchtest du die Rolle dieses/r Nutzer:in wirklich ändern?', '#english'],
    change_role_loading: ['Rolle wird angepasst...', '#english'],
    change_role_success: ['Rolle wurde erfolgreich geändert', '#english'],

    groupname: ['Gruppenname', '#english'],
    groupowner: ['Gruppenbesitzer:in', '#english'],
    num_participants: ['Anzahl Teilnehmer:innen', '#english'],
    canceled: ['Abgebrochen', '#english'],
  },

  // orders
  orders: {
    'empty-state': ['Du hast noch keine Lerninhalte hinzugefügt', '#english'],
    explore: ['Entdecken', '#english'],
    order_single: ['Meine Produkte', '#english'],
    order_single_explanation: ['Produkte • Funktionen • Status • Anpassungen', '#english'],
    order_group: ['Produkte meiner Institution', '#english'],
    order_group_explanation: ['Produkte • Funktionen • Status • Anpassungen', '#english'],
    order_status: ['Bestellstatus', '#english'],

    mobile_warning: [
      'Bestellungen können nicht in der Mobile-Variante editiert werden. Bitte gehe dafür an einen PC.',
      '#english',
    ],

    product: ['Produkt', '#english'],
    function: ['Funktion', '#english'],
    status: ['Status', '#english'],
    action: ['Aktion', '#english'],

    no_name: ['Keine Angabe', '#english'],
    no_type: ['Kein Typ gegeben', '#english'],
    no_academy_role: ['Keine Angabe', '#english'],
    no_group_contact_role: ['Keine Angabe', '#english'],
    no_single_orders: ['Noch keine Einzelbestellungen getätigt', '#english'],
    no_group_orders: ['Noch keine Gruppenbestellungen getätigt', '#english'],

    activate: ['Aktivieren', '#english'],
    deactivate: ['Deaktivieren', '#english'],

    cancel: ['Abbrechen', '#english'],
    rejoin: ['Wiedereinstieg', '#english'],

    manage_admins: ['Gruppen-Admistrator:innen verwalten', '#english'],
    manage_group: ['Gruppe verwalten', '#english'],
  },

  // admin
  admin: {
    is_admin: ['Ist Administrator', '#english'],
    username: ['Benutzername', '#english'],
    fields_of_admin: ['Felder des Kontakts', '#english'],
    title: ['Administration', '#english'],
    add_user: ['Benutzer:in hinzufügen', '#english'],
    create_user: ['Benutzer:in anlegen', '#english'],
    search_user: ['Durchsuchen', '#english'],
    user: ['Nutzer', '#english'],
    rights: ['Berechtigungen', '#english'],
    orders: ['Bestellverwaltung', '#english'],
    table_email: ['Email', '#english'],
    table_roles: ['Rollen', '#english'],

    send_password_reset: ['Passwort-Vergessen-Link schicken', '#english'],
    send_password_reset_question_before_email: ['Möchtest du wirklich an', '#english'],
    send_password_reset_question_after_email: ['einen Passwort-Vergessen-Link schicken?', '#english'],

    impersonate_question_before_email: ['Möchtest du wirklich', '#english'],
    impersonate_question_after_email: ['impersonaten?', '#english'],
    impersonate_loading: ['Impersonating...', '#english'],
    impersonate_failed: ['Beim Versuch zu Imporsonaten ist etwas schiefgelaufen.', '#english'],
    impersonate: ['Imitieren', '#english'],

    remove_admin_rights: ['Admin-Rechte entziehen', '#english'],
    add_admin_rights: ['Zum Admin ernennen', '#english'],
    remove_super_admin_rights: ['Super-Admin-Rechte entziehen', '#english'],
    add_super_admin_rights: ['Zum Super-Admin ernennen'],

    remove_user_question_before_email: ['Möchtest du wirklich', '#english'],
    remove_user_question_after_email: ['löschen?', '#english'],
    remove_user_loading: ['Löschen...', '#english'],
    remove_user_failed: ['Beim Versuch zu löschen ist etwas schiefgelaufen.', '#english'],
    remove_user_success: ['Nutzer gelöscht', '#english'],
    remove_user: ['Löschen', '#english'],
  },

  // success page
  success_page: {
    heading: ['Super, das hat geklappt!', '#english'],
    sub_heading: ['Vielen Dank für Ihr Interesse an unseren Angeboten.', '#english'],
    text: [
      html`
        Wichtig: Falls Ihre Kontaktdaten noch nicht in unserem System hinterlegt sind, müssen Sie zunächst noch Ihre
        E-Mail-Adresse bestätigen. Öffnen Sie dazu Ihr E-Mail-Postfach und klicken Sie in unserer E-Mail auf den
        enthaltenen Link. Sonst dürfen wir Ihnen die gewünschten Informationen nicht zusenden.
      `,
      '#english',
    ],
  },

  // form step administration
  form_step_administration: {
    school_contact: ['Schulansprechpartner:in', '#english'],
    school_contact_explanation: [
      'Diese Person ist die erste Ansprechpartner:in eurer Schule und erhält Zugang zu eurem Schulaccount.',
      '#english',
    ],
  },

  // form step funding
  form_step_funding: {
    success_title: ['Glückwunsch! Es wurde eine passende Förderung für Ihre Schule gefunden!', '#english'],
    success_text: [
      html`Die Teilnahme am Mentorenprogramm ist für Ihre Schule diesmal
        <d-text bold ${css({ display: 'inline-block' })}>kostenfrei</d-text>.`,
      '#english',
    ],
    funding_rate: ['Förderanteil', '#english'],
    funding_possible: ['Finanzierung möglich!', '#english'],
    funding_possible_explanation: ['Über folgende Finanzierung abwickeln.', '#english'],
    no_funding_possible: ['Keine Förderung verfügbar.', '#english'],
    funding_key_title: ['Du hast einen Förderschlüssel und möchten diesen verwenden? Gib diesen hier ein.', '#english'],
    funding_key: ['Förderschlüssel', '#english'],
    need_funding_key: ['Förderschlüssel muss angegeben werden', '#english'],
    invalid_funding_key: ['Ungültiger Förderschlüssel', '#english'],
    not_usable_funding_key: ['Dieser Förderschlüssel kann für Ihre Bestellung nicht angewandt werden.', '#english'],
  },

  // form step invoice address
  form_step_invoice_address: {
    need_invoice_modality: ['Sie müssen eine Rechnungsmodalität angeben', '#english'],
    invoice_recipient: ['Rechnungsempfänger', '#english'],
    name_institution: ['Name der Institution', '#english'],
    invoice_to_institution: ['Rechnung geht an die Schuladresse', '#english'],
    invoice_flow: ['Rechnungsabwicklung', '#english'],
    invoice_will_go_alternative: ['Die Rechnung wird an die/den alternativen Rechnungsempfänger gesendet.', '#english'],
    invoice_will_go_institution_contact: [
      'Die Rechnung wird an die/den Schulansprechpartner:in und die Schulleitung gesendet.',
      '#english',
    ],
    invoice_should_go_alternative_question: ['Soll diese an eine alternative Rechnungsadresse gehen?', '#english'],
    alternative_invoice_address: ['Alternative Rechnungsadresse', '#english'],
    invoice_address: ['Rechnungsadresse', '#english'],
    will_get_financing: ['Sie werden finanziert!', '#english'],
    invoice_goes_to: ['Rechnung geht an', '#english'],
    will_get_funding: ['Die Rechnung geht an die Förder-Institution.', '#english'],
    invoice_goes_to_funding: ['', '#english'],
  },

  // form step legal
  form_step_legal: {
    title: ['Rechtliches', '#english'],
    is_authorized: [
      'Ich bin berechtigt, die Anmeldung für die von mir ausgewählte Institution/Schule durchzuführen.',
      '#english',
    ],
    accepts_terms: [
      html`
        Ich akzeptiere die
        <d-link
          href="https://s3.digitale-helden.de/akademie/public/2023/07/230613-Teilnahmebedingungen.pdf"
          target="_blank"
          @click=${e => e.stopPropagation()}
        >
          <d-text bold ${css({ display: 'inline-block' })}> Teilnahmebedingungen </d-text> </d-link
        >.
      `,
      '#english',
    ],
    needs_to_accept_terms: ['Bitte stimmen Sie erst allen Bestimmungen zu.', '#english'],
  },

  // form step school
  form_step_school: {
    school_administration: ['Schulleitung', '#english'],
    school_info: ['Angaben zur Schule', '#english'],
    school_data: ['Schuldaten', '#english'],
    enter_federal_state: ['Bitte wählen Sie zuerst ein Bundesland aus.', '#english'],
    checking_school_information: ['Prüfe die Schulinformationen...', '#english'],
    choose_school: ['Schule suchen', '#english'],
    no_school_found: [
      html`
        Finden Sie Ihre Schule nicht?
        <d-link href="mailto:support@campus.de" ${css({ display: 'inline-block', textDecoration: 'none' })}>
          <d-flex space="xs" horizontal crossaxis-center>
            <d-icon> arrow-right </d-icon>
            <d-text bold>Schreiben Sie uns</d-text>
          </d-flex>
        </d-link>
      `,
      '#english',
    ],
    type_of_execution: ['Durchführungsart', '#english'],
    type_of_school: ['Schulform', '#english'],
  },

  // confirm mail
  confirm_mail: {
    title: ['Deine E-Mail-Adresse ist bestätigt.', '#english'],
    text_create_account: [
      html`
        <d-text bold ${css({ display: 'inline-block' })}
          >Ein heldenhaftes <d-highlight>Danke</d-highlight>, das hat geklappt!</d-text
        >
        Du hast dich <d-text bold ${css({ display: 'inline-block' })}>erfolgreich</d-text> zur Digitalen Helden-Akademie
        <d-highlight>angemeldet</d-highlight> und deine
        <d-text bold ${css({ display: 'inline-block' })}>E-Mail-Adresse bestätigt.</d-text>
      `,
      '#english',
    ],
    text: [
      html`
        <d-text bold ${css({ display: 'inline-block' })}
          >Ein heldenhaftes <d-highlight>Danke</d-highlight>, das hat geklappt!</d-text
        >
        Du hast deine
        <d-text bold ${css({ display: 'inline-block' })}>E-Mail-Adresse bestätigt.</d-text>
      `,
      '#english',
    ],
    footer: [
      html`
        Wir wünschen <d-text bold ${css({ display: 'inline-block' })}>viel Spaß</d-text> mit unseren Angeboten!
        <br />Das <d-text bold ${css({ display: 'inline-block' })}>Team</d-text> der Digitalen Helden
      `,
      '#english',
    ],
  },

  // contact form
  contact_form: {
    message: ['Deine Nachricht an uns', '#english'],
    want_mail_for: ['Ich möchte zukünftig per E-Mail erhalten:', '#english'],
    mail_for_heldenpost: ['Die Heldenpost - Ihre monatliche E-Mail zum Thema Medienbildung', '#english'],
    mail_for_webinar: ['Einladungen zu unseren Webinaren für Eltern und Lehrkräfte', '#english'],
  },

  // course form
  courses_form: {
    error_choose_product: ['Bitte wählen Sie ein Produkt aus.', '#english'],
    choose_course: ['Welcher Kurs?', '#english'],
  },

  // media package form
  media_package_form: {
    company: ['Ihr Verlag/Ihre Firma', '#english'],
    concern: ['Haben Sie ein konkretes Anliegen?', '#english'],
    media_package: ['Pressepaket', '#english'],
  },

  // mentoring form
  mentoring_form: {
    administration: ['Administration', '#english'],
    financing: ['Finanzierung', '#english'],
    invoice: ['Rechnung', '#english'],
    legal: ['Rechtliches', '#english'],
    buy: ['Zahlungspflichtig bestellen', '#english'],
  },

  // set password
  set_password: {
    title: ['Gib unten ein neues Passwort ein oder generiere ein neues.', '#english'],
    explanation: [
      'Bitte gebe dein neues Passwort hier ein, um dein aktuelles Passwort zu ändern. Mindestens 8 Zeichen. 1 Großbuchstabe. 1 Kleinbuchstabe. Eine Nummer und ein Sonderzeichen.',
      '#english',
    ],
    generate_password: ['Passwort generieren', '#english'],
    set_password: ['Passwort festlegen', '#english'],
  },

  // product payment modality
  [ProductPaymentModality.SPLITTED_BILL]: ['Zweiteil-Rechnung', '#english'],
  [ProductPaymentModality.TOTAL_BILL]: ['Gesamtrechnung', '#english'],

  // order attendancy status
  [OrderAttendenceStatus.ABGEBROCHEN]: ['Abgebrochen', '#english'],
  [OrderAttendenceStatus.ABGESCHLOSSEN]: ['Abgeschlossen', '#english'],
  [OrderAttendenceStatus.ANGEMELDET]: ['Angemeldet', '#english'],
  [OrderAttendenceStatus.NICHT_TEILGENOMMEN]: ['Nicht teilgenommen', '#english'],
  [OrderAttendenceStatus.TEILGENOMMEN]: ['Teilgenommen', '#english'],
  [OrderAttendenceStatus.WIEDEREINSTIEG]: ['Wiedereinstieg', '#english'],
} as const;

export type LASTranslationDictionary = Record<string, string>;
