import '@adornis/buildify/client/components/x-buildify-permission-selection';
import { H1Extension } from '@adornis/buildify/client/extensions/H1Extension';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import { html } from 'lit';
import { h1Extensions } from '../../../../../client/tiptap-editor/visual-text-extensions';
import './campus-h1-extension';

export const BuildifyCampusH1Extension: BuildifyExtension = {
  class: H1Extension.class,
  toolbar: { icon: 'heading', text: { default: 'H1' } },
  render(instance, { defaultValues, isSubelement }) {
    return html`
      <campus-h1-extension
        .content=${instance}
        .defaultValues=${defaultValues}
        ?sub=${isSubelement}
      ></campus-h1-extension>
    `;
  },
  editor({ contentController, controllerBaseKeyPath }) {
    return html`
      <d-tiptap-buildify
        hide-typo
        .extensions=${h1Extensions}
        ${contentController.field(...controllerBaseKeyPath, 'text')}
      ></d-tiptap-buildify>
    `;
  },
  group: 'Schriften',
};
