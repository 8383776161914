export const CampusRootURL = '/campus';

export enum CampusRoute {
  DASHBOARD = '/',
  EXPLORE = '/explore',
  ORDERS = '/orders',
  CONTENT = '/content',
  SETTINGS = '/settings',
  TEAM = '/team',
  ADMIN = '/admin',
  GROUP = '/group',
  BUILDIFY = '/buildify',
  SUPPORT = '/support',
  COOKIES = '/cookies',
  // TODO: remove this route (digimember)
  COURSE = '/course',
  ACTION = '/action',
}

export enum CampusSettingsRoute {
  PROFILE = '/profile',
  SAFETY = '/safety',
  INSTITUTION = '/institution',
  INVOICES = '/invoices',
}

export enum CampusTeamRoute {
  PRODUCT_INSIGHT = '/product-insight',
  SUPPORT = '/support',
  ACCOUNTING = '/accounting',
  DESIGN = '/design',
  TICKETING = '/ticketing',
}

export enum CampusAdminRoute {
  USERS = '/users',
  PERMISSIONS = '/permissions',
  ORDERS = '/orders',
  FORMULARS = '/formulars',
}

export enum CampusBuildifyRoute {
  FILES = '/files',
  CUSTOM_COMPONENTS = '/custom',
  PAGES = '/pages',
  PDPS = '/pdps',
  MARKETING = '/marketing',
}

// helper functions
export const PathToCampusRoute = (route: CampusRoute): string => {
  return `${CampusRootURL}${route}`;
};

export const PathToCampusSettingsRoute = (route: CampusSettingsRoute): string => {
  return `${PathToCampusRoute(CampusRoute.SETTINGS)}${route}`;
};

export const PathToCampusTeamRoute = (route: CampusTeamRoute): string => {
  return `${PathToCampusRoute(CampusRoute.TEAM)}${route}`;
};

export const PathToCampusAdminRoute = (route: CampusAdminRoute): string => {
  return `${PathToCampusRoute(CampusRoute.ADMIN)}${route}`;
};

export const PathToCampusBuildifyRoute = (route: CampusBuildifyRoute): string => {
  return `${PathToCampusTeamRoute(CampusTeamRoute.DESIGN)}${route}`;
};
