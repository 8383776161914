import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import '@campus/client/theme/components/d-hr';
import { html } from 'lit';
import { DubniumBaseContainerFlex } from '../../../../../db/extensions/flex-container/DubniumBaseContainerFlex';
import { BuildifyCampusContainerExtension } from '../BuildifyCampusContainerExtension';
import './campus-container-flex-extension';

export const BuildifyCampusContainerFlexExtension: BuildifyExtension<DubniumBaseContainerFlex> = {
  class: DubniumBaseContainerFlex,
  toolbar: { icon: 'diagram-cells', text: { default: 'Flex' } },
  render(instance, { defaultValues, isSubelement }) {
    return html`
      <campus-container-flex-extension
        .content=${instance}
        .defaultValues=${defaultValues}
        ?sub=${isSubelement}
      ></campus-container-flex-extension>
    `;
  },
  editor(options) {
    return html`
      <d-flex space="sm">
        ${BuildifyCampusContainerExtension.editor?.(options)}
        <d-input
          ${options.contentController.field(...options.controllerBaseKeyPath, 'flexDirection')}
          placeholder="Flex Direction"
        ></d-input>
        <d-input
          ${options.contentController.field(...options.controllerBaseKeyPath, 'columnGap')}
          placeholder="Column Gap"
        ></d-input>
        <d-input
          ${options.contentController.field(...options.controllerBaseKeyPath, 'rowGap')}
          placeholder="Row Gap"
        ></d-input>
        <d-input
          ${options.contentController.field(...options.controllerBaseKeyPath, 'justifyContent')}
          placeholder="Justify Content"
        ></d-input>
        <d-input
          ${options.contentController.field(...options.controllerBaseKeyPath, 'alignItems')}
          placeholder="Align Items"
        ></d-input>
      </d-flex>
    `;
  },
  group: 'Layout',
};
