import type { ValueEvent } from '@adornis/base/utilTypes';
import { SpacingExtension } from '@adornis/buildify/client/extensions/SpacingExtension';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import type { BaseSpacing } from '@adornis/buildify/db/extensions/BaseSpacing';
import '@campus/client/theme/components/d-button';
import '@campus/client/theme/components/d-checkbox';
import '@campus/client/theme/components/d-input';
import '@campus/client/theme/fonts';
import { html, nothing } from 'lit';

export const BuildifyCampusSpacingExtension: BuildifyExtension<BaseSpacing> = {
  class: SpacingExtension.class,
  toolbar: { icon: 'distribute-spacing-vertical', text: { default: 'Abstand' } },
  render(instance, { defaultValues, isSubelement }) {
    return SpacingExtension.render(instance, { defaultValues, isSubelement });
  },
  editor({ content, contentController, controllerBaseKeyPath, host }) {
    const distanceButton = (content: BaseSpacing, distance: string, text: string) => html`
      <d-button
        mode=${content.value('distance', contentController.size) === distance ? 'action' : 'outline'}
        @click=${() => {
          content.setValue('distance', distance, contentController.size);
          host.requestUpdate();
        }}
      >
        ${text}
      </d-button>
    `;

    return html`
      <d-flex space="sm">
        <d-flex space="xs">
          <d-text small> Vordefinierte Werte </d-text>
          <d-flex horizontal wrap space="xs">
            ${distanceButton(content, '4px', 'Small')} ${distanceButton(content, '8px', 'Medium')}
            ${distanceButton(content, '16px', 'Large')} ${distanceButton(content, '32px', 'Extralarge')}
          </d-flex>
        </d-flex>
        <d-input
          type="string"
          ${contentController.field(...controllerBaseKeyPath, 'distance')}
          placeholder="Breite"
        ></d-input>

        <d-checkbox
          ${contentController.field(...controllerBaseKeyPath, 'hasDividingLine')}
          @value-picked=${(e: ValueEvent<boolean>) => {
            host.requestUpdate();
          }}
          .label=${'Hat Trennlinie'}
        ></d-checkbox>
        ${content.value('hasDividingLine', contentController.size)
          ? html`
              <d-input
                type="string"
                ${contentController.field(...controllerBaseKeyPath, 'dividingLineColor')}
                placeholder="Farbe (Trennlinie)"
              ></d-input>
              <d-input
                type="string"
                ${contentController.field(...controllerBaseKeyPath, 'dividingLineSize')}
                placeholder="Breite (Trennlinie)"
              ></d-input>
            `
          : nothing}
      </d-flex>
    `;
  },
  group: 'Basics',
};
