import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import { html } from 'lit';
import { DubniumBaseFlipcard } from '../../../../db/extensions/DubniumBaseFlipcard';
import { BuildifyCampusImageExtension } from '../image/BuildifyCampusImageExtension';
import './campus-image-flipcard-extension';

export const BuildifyCampusImageFlipcardExtension = ({
  textExtension,
  imageExtension,
}: {
  textExtension: BuildifyExtension;
  imageExtension: BuildifyExtension;
}): BuildifyExtension<DubniumBaseFlipcard> => ({
  class: DubniumBaseFlipcard,
  toolbar: { icon: 'cards-blank', text: { default: 'Bildbeschreibung' } },
  render(instance, { defaultValues, isSubelement }) {
    return html`
      <d-image-flipcard-extension
        .textExtension=${textExtension}
        .imageExtension=${imageExtension}
        .content=${instance}
        .defaultValues=${defaultValues}
        ?sub=${isSubelement}
      ></d-image-flipcard-extension>
    `;
  },
  editor({ content, contentController, controllerBaseKeyPath, host }) {
    return html`
      <d-flex space="sm">
        <d-input ${contentController.field(...controllerBaseKeyPath, 'width')} placeholder="Breite"></d-input>
        <d-input ${contentController.field(...controllerBaseKeyPath, 'height')} placeholder="Höhe"></d-input>
        <d-input
          ${contentController.field(...controllerBaseKeyPath, 'aspectRatio')}
          placeholder="Aspect-Ratio"
        ></d-input>
        <d-input
          ${contentController.field(...controllerBaseKeyPath, 'backgroundColor')}
          placeholder="Hintergrundfarbe"
        ></d-input>
        ${BuildifyCampusImageExtension.editor?.({
          content: content.file,
          contentController,
          controllerBaseKeyPath: [...controllerBaseKeyPath, 'file'],
          host,
        })}
        ${textExtension.editor?.({
          content: content.imageText,
          contentController,
          controllerBaseKeyPath: [...controllerBaseKeyPath, 'imageText'],
          host,
        })}
      </d-flex>
    `;
  },
  group: 'Medien',
});
