import { ExtensionBoilerplate } from '@adornis/buildify/client/extensions/ContentExtensionBoilerplate';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import '../../../../../../_forms/_lead-signup-form/client/las-form-lead-signup';
import type { DubniumBaseLeadSignupFormular } from '../../../../../db/extensions/_formulars/DubniumBaseLeadSignupFormular';

@customElement('campus-lead-signup-formular-extension')
export class CampusLeadSignupFormularExtension extends ExtensionBoilerplate<DubniumBaseLeadSignupFormular> {
  protected override _content(content: DubniumBaseLeadSignupFormular) {
    return html`
      <las-form-lead-signup
        ?webinar=${content.isWebinar}
        ?newsletter=${content.isNewsletter}
        campaigns=${(content.communicationCampaigns ?? []).join(',')}
      ></las-form-lead-signup>
    `;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          width: '100%',
        },
      },
    ];
  }
}
