import { Bold, BoldExtension } from '@adornis/tiptap/client/extensions/Bold';
import type { AdornisTiptapExtension } from '@adornis/tiptap/client/extensions/ExtensionBase';
import { History } from '@adornis/tiptap/client/extensions/History';
import { Italic } from '@adornis/tiptap/client/extensions/Italic';
import { Strike } from '@adornis/tiptap/client/extensions/Strike';
import { TextAlign } from '@adornis/tiptap/client/extensions/TextAlign';
import { Underline } from '@adornis/tiptap/client/extensions/Underline';
import { CharacterCount } from '@tiptap/extension-character-count';

export const DigitaleHeldenTiptapBold: AdornisTiptapExtension = {
  buttons: ({ editor, host }) => {
    const superButtons = Bold.buttons?.({ editor, host }) ?? [];
    for (const button of superButtons) {
      button.icon = 'bold';
    }
    return superButtons;
  },
  extensions: [BoldExtension.configure({ tagName: 'd-bold' })],
};

export const DigitaleHeldenCharCount: AdornisTiptapExtension = {
  extensions: [
    CharacterCount.configure({
      limit: 350,
    }),
  ],
};

export const DigitaleHeldenTiptapItalic: AdornisTiptapExtension = {
  ...Italic,
  buttons: ({ editor, host }) => {
    const superButtons = Italic.buttons?.({ editor, host }) ?? [];
    for (const button of superButtons) {
      button.icon = 'italic';
    }
    return superButtons;
  },
};

export const DigitaleHeldenTiptapUnderline: AdornisTiptapExtension = {
  ...Underline,
  buttons: ({ editor, host }) => {
    const superButtons = Underline.buttons?.({ editor, host }) ?? [];
    for (const button of superButtons) {
      button.icon = 'underline';
    }
    return superButtons;
  },
};

export const DigitaleHeldenTiptapStrike: AdornisTiptapExtension = {
  ...Strike,
  buttons: ({ editor, host }) => {
    const superButtons = Strike.buttons?.({ editor, host }) ?? [];
    for (const button of superButtons) {
      button.icon = 'strikethrough';
    }
    return superButtons;
  },
};

export const DigitaleHeldenTiptapHistory: AdornisTiptapExtension = {
  ...History,
  buttons: ({ editor, host }) => {
    const superButtons = History.buttons?.({ editor, host }) ?? [];
    for (const button of superButtons) {
      if (button.icon === 'redo') button.icon = 'rotate-right';
      if (button.icon === 'undo') button.icon = 'rotate-left';
    }
    return superButtons;
  },
};

export const DigitaleHeldenTiptapTextAlign: AdornisTiptapExtension = {
  ...TextAlign,
  buttons: ({ editor, host }) => {
    const superButtons = TextAlign.buttons?.({ editor, host }) ?? [];
    for (const button of superButtons) {
      switch (button.icon) {
        case 'format_align_left':
          button.icon = 'align-left';
          break;
        case 'format_align_center':
          button.icon = 'align-center';
          break;
        case 'format_align_right':
          button.icon = 'align-right';
          break;
        case 'format_align_justify':
          button.icon = 'align-justify';
          break;
      }
    }
    return superButtons;
  },
};
