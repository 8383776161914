import type { Maybe } from '@adornis/base/utilTypes';
import { ImageExtension } from '@adornis/buildify/client/extensions/ImageExtension';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import { RXController } from '@adornis/chemistry/controllers/RXController';
import { FormField } from '@adornis/formfield/form-field';
import '@campus/client/theme/components/d-checkbox';
import '@campus/client/theme/components/d-dropdown-selection';
import { html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { switchMap } from 'rxjs';
import { LASFile } from '../../../../../db/files/LASFile';
import { DubniumBaseImage } from '../../../../db/extensions/DubniumBaseImage';
import { SupportedFileType } from '../../../components/las-file-explorer';
import { LASPromptChooseFile } from '../../../prompts/las-prompt-choose-file';
import './campus-image-extension';

export const BuildifyCampusImageExtension: BuildifyExtension<DubniumBaseImage> = {
  class: DubniumBaseImage,
  toolbar: ImageExtension.toolbar,
  render(instance, { defaultValues, isSubelement }) {
    return html`
      <campus-image-extension
        .content=${instance}
        .defaultValues=${defaultValues}
        ?sub=${isSubelement}
      ></campus-image-extension>
    `;
  },
  editor({ contentController, controllerBaseKeyPath }) {
    return html`
      <d-flex space="sm">
        <campus-buildify-image-selector
          ${contentController.field(...controllerBaseKeyPath, 'fileID')}
        ></campus-buildify-image-selector>
        <d-input ${contentController.field(...controllerBaseKeyPath, 'width')} placeholder="Breite"></d-input>
        <d-input ${contentController.field(...controllerBaseKeyPath, 'height')} placeholder="Height"></d-input>
        <d-input
          ${contentController.field(...controllerBaseKeyPath, 'borderRadius')}
          placeholder="Border-Radius"
        ></d-input>
        <d-dropdown-selection
          ${contentController.field(...controllerBaseKeyPath, 'viewMode')}
          placeholder="Object-Fit"
          .selectables=${['view', 'contain', 'cover']}
        ></d-dropdown-selection>

        <!-- ausrichtung -->
        <d-dropdown-selection
          clearable
          .selectables=${['center']}
          ${contentController.field(...controllerBaseKeyPath, 'alignHorizontal')}
          placeholder="Ausrichtung horizontal"
        ></d-dropdown-selection>
        <d-dropdown-selection
          clearable
          .selectables=${['center']}
          ${contentController.field(...controllerBaseKeyPath, 'alignVertical')}
          placeholder="Ausrichtung vertikal"
        ></d-dropdown-selection>

        <d-checkbox
          ${contentController.field(...controllerBaseKeyPath, 'viewDownloadButton')}
          .label=${'Download-Button'}
        ></d-checkbox>
      </d-flex>
    `;
  },
  group: 'Medien',
};

@customElement('campus-buildify-image-selector')
export class CampusBuildifyFileSelector extends FormField<string> {
  @state() private readonly _file = new RXController(
    this,
    this.value.observable.pipe(
      switchMap(async fileID => {
        if (!fileID) return null;
        const file = await LASFile.getByID<LASFile>(fileID)({ meta: { fileName: 1 } });
        return file;
      }),
    ),
  );

  override render() {
    return html`
      <d-flex space="sm">
        ${this._file.value
          ? html` <d-text> Datei ausgewählt: <d-text bold inline>${this._file.value.meta?.fileName}</d-text></d-text> `
          : nothing}
        <d-button
          mode="outline"
          @click=${async () => {
            const allowedFileTypes = [
              SupportedFileType.GIF,
              SupportedFileType.JPG,
              SupportedFileType.JPNG,
              SupportedFileType.PNG,
            ];

            const fileID = await LASPromptChooseFile.showPopup<Maybe<string>>({
              props: { selectedFileID: this.value.value, allowedFileTypes },
            });
            if (!fileID) return;
            this.value.next(fileID);
            this.dispatchEvent(new CustomEvent('value-picked', { detail: { value: this.value.value } }));
          }}
        >
          Datei
        </d-button>
      </d-flex>
    `;
  }
}
