import type { Maybe } from '@adornis/base/utilTypes';
import { Int } from '@adornis/baseql/baseqlTypes';
import { Arg, Entity, Field, Subscription } from '@adornis/baseql/decorators';
import { getCollection, getCollectionHandle } from '@adornis/baseql/server/collections';
import { context } from '@adornis/baseql/server/context';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { AdornisFile } from '@adornis/file-utils/db/files';
import { switchMap } from 'rxjs';
import type { Order } from '../Order';
import { checkPermission } from '../helpers';
import { FileFilter } from './FileFilter';

@Entity()
export class LASFile extends AdornisFile {
  static override _class = 'LASFile';

  @Field(type => String)
  folderID!: Maybe<string>;

  @Subscription(type => [LASFile])
  static subscribeFilesPaginatedFiltered(
    @Arg('skip', type => Int) skip: number,
    @Arg('limit', type => Int) limit: number,
    @Arg('filter', type => FileFilter) filter: FileFilter,
  ) {
    return (gqlFields: BaseQLSelectionSet<Order>) => {
      return getCollectionHandle(LASFile._collectionName)
        .watchQuery({})
        .pipe(
          switchMap(async () => {
            await checkPermission({ context, permission: 'Designer.View' });

            const collection = await getCollection<LASFile>(LASFile._class);

            const query = {};

            if ((filter.fileExtensions?.length ?? 0) > 0) {
              query['meta.extension'] = { $in: filter.fileExtensions };
            }

            if (filter.fileName) {
              query['meta.fileName'] = { $regex: `${filter.fileName}`, $options: 'i' };
            }

            const result = await collection.find<LASFile>(query, { skip, limit }).toArray();

            return result;
          }),
        );
    };
  }
}
