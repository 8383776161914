import { registerMutation } from '@adornis/baseql/metadata/register';
import { executeSyncProducts } from '../../../_accounting/server/cronjobProducts';

//* Resolver
const syncProductsResolver = () => {
  return async () => {
    await executeSyncProducts();
  };
};

//* Query
export const syncProducts = registerMutation({
  type: () => String,
  operationName: 'syncProducts',
  resolve: syncProductsResolver,
});
