import { AccordeonExtension } from '@adornis/buildify/client/extensions/AccordeonExtension';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import '../../../../client/theme/components/d-accordeon';

export const BuildifyCampusAccordeonExtension = (textExtension: BuildifyExtension): BuildifyExtension => ({
  ...AccordeonExtension(textExtension),
  toolbar: {
    icon: 'chevron-down',
    text: AccordeonExtension(textExtension).toolbar.text,
  },
});
