import type { Maybe } from '@adornis/base/utilTypes';
import { RXController } from '@adornis/chemistry/controllers/RXController';
import { LocalStorageBehaviorController } from '@adornis/chemistry/controllers/local-storage-behavior-controller';
import { css } from '@adornis/chemistry/directives/css';
import '@adornis/chemistry/elements/components/x-flex';
import '@adornis/chemistry/elements/components/x-link';
import { XSidebar, XSidebarItem } from '@adornis/chemistry/elements/components/x-sidebar';
import { goTo } from '@adornis/router/client/open-href';
import LeftSideGreenSVG from 'client/assets/Left-side-green.svg';
import { html, nothing, type TemplateResult } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs';
import './d-icon';

/**
 * @element d-sidebar
 */

@customElement('d-sidebar')
export class DubniumSidebar extends XSidebar {
  @state() private readonly _minify = new LocalStorageBehaviorController(this, 'minifySidebar', false);

  static sidebar: Maybe<DubniumSidebar>;

  static toggleMinify() {
    this.sidebar?._minify.next(!this.sidebar._minify.value);
    this.sidebar?.dispatchEvent(new CustomEvent('minified-changed'));
  }

  static isMinified() {
    return this.sidebar?._minify.value ?? false;
  }

  override connectedCallback(): void {
    super.connectedCallback();

    if (DubniumSidebar.sidebar) {
      return;
    } else {
      DubniumSidebar.sidebar = this;
    }

    this.size.observable
      .pipe(
        map(() => this.size.mobile),
        distinctUntilChanged(),
        takeUntil(this.disconnected),
      )
      .subscribe(isMobile => {
        if (isMobile) (this.constructor as typeof DubniumSidebar).close();
      });
  }

  override render() {
    if (!this.size.mobile) XSidebar.open();
    if (XSidebar.isOpen())
      return html`
        <x-flex space="md" w100>
          <img src=${LeftSideGreenSVG} ${css({ position: 'absolute', top: '100px', left: '0' })} />
          ${this.size.mobile
            ? html`
                <d-flex horizontal space-between crossaxis-center>
                  ${this._logo()}
                  <d-icon
                    solid
                    ${css({ fontSize: '24px', color: this.colors.tone.secondaryText, cursor: 'pointer' })}
                    @click=${() => {
                      if (XSidebar.isOpen()) XSidebar.close();
                      else XSidebar.open();
                    }}
                  >
                    xmark
                  </d-icon>
                </d-flex>
              `
            : this._logo()}
          <d-flex
            space="lg"
            space-between
            flex
            ${css({
              paddingTop: this.size.mobile ? this.spacing.xl : this.spacing.md,
              width: this.size.mobile ? `auto` : '100%',
              minWidth: '80%',
              alignSelf: 'center',
              overflow: 'hidden',
            })}
          >
            <d-flex ${css({ overflowY: 'auto', overflowX: 'hidden' })}>
              <slot></slot>
            </d-flex>

            <d-flex ${css({ paddingLeft: this.spacing.lg, paddingRight: this.spacing.lg })}>
              <slot name="bottom"></slot>
            </d-flex>
          </d-flex>
        </x-flex>
      `;
    return html``;
  }

  protected override _logo() {
    return html`
      <d-flex
        crossaxis-center
        @click=${() => goTo(this.logoRouteTo)}
        ${css({ paddingLeft: this._minify.value ? '0px' : this.spacing.lg })}
      >
        <slot name="logo"></slot>
      </d-flex>
    `;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          width: this.size.mobile ? '100%' : 'max-content',
          maxWidth: this.size.mobile ? '100%' : 'auto',
          background: '#fff',
          transform: XSidebar.isOpen() ? 'translateX(0)' : 'translateX(-100vw)',
          padding: this.size.mobile ? '32px' : '0px',
          paddingTop: '32px',
          paddingBottom: '32px',
        },
      },
    ];
  }
}

/**
 * @element d-sidebar-item
 */
@customElement('d-sidebar-item')
export class DubniumSidebarItem extends XSidebarItem {
  @property({ attribute: 'minified', type: Boolean }) isMinified = DubniumSidebar.isMinified();

  override render() {
    return html`
      <d-link
        class=${this.active ? 'active-item item' : 'item'}
        href=${this.href ?? '#'}
        @click=${() => XSidebar.close()}
      >
        <d-flex horizontal space-between ${css({ margin: `0 0 0 ${this.isMinified ? '0' : '20px'}` })} w100>
          <d-flex
            horizontal
            flex
            ?center=${!!DubniumSidebar.isMinified()}
            space="md"
            crossaxis-center
            ${css({ borderTop: '1px solid #fff', padding: '.9rem 1rem' })}
          >
            ${this.iconDisplay()}
            ${this.isMinified
              ? nothing
              : html`
                  <d-text
                    ${css({
                      fontFamily: this.active ? 'NettoOT-Black' : 'Netto W01 Regular',
                      fontSize: this.size.mobile ? '20px' : '17px',
                    })}
                  >
                    ${this.text}
                  </d-text>
                `}

            <slot></slot>
          </d-flex>
        </d-flex>
      </d-link>
    `;
  }

  protected override iconDisplay(): TemplateResult<1> {
    return html` <d-icon> ${this.icon ?? 'notdef'} </d-icon> `;
  }

  override styles() {
    return [
      {
        ':host': {
          textDecoration: 'none',
          color: this.active ? this.colors.accent : this.colors.tone.secondaryText,
          fontSize: '1.375rem',
          fontFamily: 'Netto W01 Regular,"Helvetica Neue",Helvetica,Arial,sans-serif;',
          margin: `${this.spacing.xxs} 0`,
        },
        '.item': {
          textDecoration: 'none',
          width: '100%',
        },
        'd-icon': {
          fontSize: '16px',
        },
        '.active-item': {
          position: 'relative',
        },
        '.active-item::before': {
          content: '""',
          height: '110%',
          width: '5.1px',
          top: '50%',
          transform: 'translateY(-50%)',
          background: this.colors.accent,
          borderRadius: this.sizes.borderRadius,
          position: 'absolute',
          right: '0',
        },
      },
    ];
  }
}

/**
 * @element d-sidebar-item-nested
 */
@customElement('d-sidebar-item-nested')
export class DubniumSidebarItemNested extends DubniumSidebarItem {
  @property({ attribute: false }) childItems: Array<{
    href: string;
    icon: string;
    text: string;
    validateActive?: (currentUrl: string, href: string) => boolean;
  }> = [];
  @property({ attribute: 'id' }) localhostIdentifier = new RXController<Maybe<string>>(this, undefined);
  @state() private _isOpen: Maybe<LocalStorageBehaviorController<boolean>>;

  override connectedCallback(): void {
    super.connectedCallback();

    this.localhostIdentifier.observable.pipe(filter(Boolean)).subscribe(id => {
      if (this._isOpen) return;
      this._isOpen = new LocalStorageBehaviorController(this, id, false);
    });
  }

  override render() {
    return html`
      <d-flex space="sm">
        <d-link
          class=${this.active ? 'active-item item' : 'item'}
          href=${this.href ?? '#'}
          @click=${() => {
            this._isOpen.next(true);
            XSidebar.close();
          }}
        >
          <d-flex
            horizontal
            space-between
            ${css({ margin: `0 0 0 ${DubniumSidebar.isMinified() ? '0' : '20px'}` })}
            w100
          >
            <d-flex
              horizontal
              flex
              ?center=${!this.text}
              space="md"
              crossaxis-center
              ${css({ borderTop: '1px solid #fff', padding: '.9rem 1rem' })}
            >
              ${this.iconDisplay()}
              ${this.text
                ? html`
                    <d-text
                      flex
                      ${css({
                        fontFamily: this.active ? 'NettoOT-Black' : 'Netto W01 Regular',
                        fontSize: this.size.mobile ? '20px' : '17px',
                      })}
                    >
                      ${this.text}
                      <d-tooltip> ${this.text} </d-tooltip>
                    </d-text>
                  `
                : nothing}
              <d-icon
                @click=${e => {
                  e.preventDefault();
                  e.stopPropagation();

                  this._isOpen.next(!this._isOpen.value);
                }}
              >
                ${this._isOpen.value ? 'chevron-up' : 'chevron-down'}
              </d-icon>
            </d-flex>
          </d-flex>
        </d-link>
        ${this._isOpen.value
          ? html`
              <d-flex>
                ${repeat(
                  this.childItems,
                  item => html`
                    <d-sidebar-item
                      ?minified=${DubniumSidebar.isMinified()}
                      ${css({ margin: '0' })}
                      .validateActive=${(currentUrl: string, href: string) => currentUrl.startsWith(item.href)}
                      href=${item.href}
                      text=${item.text}
                      icon=${item.icon}
                    >
                      <d-tooltip> ${item.text} </d-tooltip>
                    </d-sidebar-item>
                  `,
                )}
              </d-flex>
            `
          : nothing}
      </d-flex>
    `;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        '.active-item': {
          fontFamily: 'NettoOT-Black',
          color: this.colors.tone.secondaryText,
        },
        '.active-item::before': {
          content: '',
          display: 'none',
        },
      },
    ];
  }
}
