import type { Maybe } from '@adornis/base/utilTypes';
import { Entity, Field } from '@adornis/baseql/decorators';
import { validate } from '@adornis/validation/decorators';
import { ValidationError } from '@adornis/validation/errors/ValidationError';
import { nonOptional } from '@adornis/validation/functions/nonOptional';
import { LASPaymentFormData } from '../../../_accounting/db/Payments/LASPaymentFormData';
import { AcademyRoles } from '../../../db/enums';

@Entity()
export class FormularAutomaticProduct extends LASPaymentFormData {
  static override _class = 'FormularAutomaticProduct';

  @Field(type => [String])
  communicationCampaigns!: string[];

  @validate(nonOptional())
  @Field(type => String)
  academyRole!: string;

  @validate(options => {
    if (options.target.academyRole === AcademyRoles.SONSTIGES && !options.value)
      throw new ValidationError('Geben Sie eine Akademie-Rolle an..', {
        key: options.key,
        translationKey: 'validation_non_optional',
      });
  })
  @Field(type => String)
  otherAcademyRole: Maybe<string>;

  @Field(type => Boolean)
  isWebinar: Maybe<boolean>;

  @Field(type => Boolean)
  isNewsletter: Maybe<boolean>;

  @validate(nonOptional())
  @Field(type => Boolean)
  acceptedConditionsOfParticipation!: boolean;

  @validate(nonOptional())
  @Field(type => Boolean)
  accepetedPrivacy!: boolean;

  @validate(nonOptional())
  @Field(type => Boolean)
  acceptedWithdrawal!: boolean;
}
