import { css } from '@adornis/chemistry/directives/css.js';
import { DesignSystem } from '@adornis/chemistry/elements/theming/design';
import { type Renderable } from '@adornis/chemistry/renderable';
import { XNativeDialog } from '@adornis/dialog/x-native-dialog';
import { html, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import './d-button';

/**
 * @element d-dialog
 */
@customElement('d-dialog')
export class DubniumDialog<T> extends XNativeDialog<T> {
  static override get element_name() {
    return 'd-dialog';
  }

  override styles() {
    return [
      ...super.styles(),
      {
        dialog: {
          borderRadius: this.sizes.borderRadiusSecondary,
          maxWidth: '60vmax',
          padding: '32px 68px',
        },
      },
    ];
  }

  // override getInnerWrapper(options: Required<DialogOptions>) {
  //   const wrapperElem = super.getInnerWrapper(options);
  //   wrapperElem.style.setProperty('background', this.colors.white);
  //   if (!options.draggable) wrapperElem.style.setProperty('border-radius', this.sizes.borderRadiusSecondary);
  //   else {
  //     wrapperElem.style.setProperty('border-top-left-radius', this.sizes.borderRadiusSecondary);
  //     wrapperElem.style.setProperty('border-top-right-radius', this.sizes.borderRadiusSecondary);
  //   }
  //   wrapperElem.style.setProperty('max-width', '60vmax');
  //   wrapperElem.style.setProperty('padding', `32px 68px`);

  //   // wrapperElem.style.setProperty('width', 'min(800px, 95%)');
  //   return wrapperElem;
  // }

  // override getContentWrapper(options: Required<DialogOptions>) {
  //   const wrapperElem = super.getContentWrapper(options);
  //   if (!options.draggable) wrapperElem.style.removeProperty('height');
  //   return wrapperElem;
  // }

  static override confirm(
    heading?: Renderable,
    text?: Renderable,
    {
      highlightDecline = false,
      acceptText = 'Bestätigen',
      declineText = 'Abbrechen',
      maxWidth = '500px',
    }: { highlightDecline?: boolean; acceptText?: string; declineText?: string; maxWidth?: string } = {},
  ) {
    return this.showPopup<boolean>({
      modal: true,
      closeOnEscape: true,
      content: r => {
        const enterListener = (e: KeyboardEvent) => {
          if (e.key === 'Enter') resolve(true);
        };
        document.addEventListener('keydown', enterListener);
        const resolve = (v: boolean) => {
          document.removeEventListener('keydown', enterListener);
          r(v);
        };
        return html`
          <d-flex
            ${css({
              padding: `${DesignSystem.currentTheme.getValue().spacing.sm} ${
                DesignSystem.currentTheme.getValue().spacing.lg
              }`,
              maxWidth,
              gap: '24px',
            })}
          >
            ${heading ?? nothing} ${text ?? nothing}
            <d-flex horizontal center space="md">
              <d-button
                tone="neutral"
                mode="${highlightDecline ? 'action' : 'outline'}"
                @click="${() => resolve(false)}"
              >
                ${declineText}
              </d-button>
              <d-button mode="${highlightDecline ? 'outline' : 'action'}" @click="${() => resolve(true)}">
                ${acceptText}
              </d-button>
            </d-flex>
          </d-flex>
        `;
      },
    }).catch(() => false);
  }
}
