import { BaseError } from '@adornis/validation/errors/BaseError';

export class OrderGroupContactRelationStatusAlreadyDoneError extends BaseError {
  static override _class = 'OrderGroupContactRelationStatusAlreadyDoneError';

  public constructor() {
    super('OrderGroupContactRelationStatusAlreadyDoneError', {
      translationKey: 'order-group-contact-relation-status-already-done-error',
    });
  }
}
