import { logger } from '@adornis/base/logging';
import type { Maybe } from '@adornis/base/utilTypes';
import { goTo } from '@adornis/router/client/open-href';
import { DubniumPage } from '../_buildify/db/DubniumPage';
import { CampusRoute, PathToCampusRoute } from '../_routing/db/enums';
import { colors } from '../client/theme/theme';
import { ProductType } from '../db/enums';

export const pathToContent = async (productID: string): Promise<string> => {
  try {
    const path = await DubniumPage.getPathToProduct(productID)();
    return path;
  } catch (err) {
    return `${PathToCampusRoute(CampusRoute.COURSE)}/${productID}`;
  }
};

export const navigateToContent = async (productID: string): Promise<void> => {
  goTo(await pathToContent(productID));
};

export function productTypeIcon(type: ProductType): string {
  switch (type) {
    case ProductType.LIVE_WEBINAR:
    case ProductType.WEBINAR:
      return 'presentation-screen';
    case ProductType.WEBINAR_ARCHIV:
      return 'box-archive';
    case ProductType.AUDIO:
      return 'podcast';
    case ProductType.DOWNLOAD_INFO:
      return 'download';
    case ProductType.LEARNING_MATERIALS:
      return 'files';
    case ProductType.ONLINE_COURSE:
      return 'screen-users';
    default:
      return 'rocket';
  }
}

type InfoListItem = {
  icon: string;
  text: string;
};

/**
 * @arg rawString - expects a string in the form of "<some-icon>;<some-description>\n<another-icon>;<more-text>"
 * @returns an empty array if no or empty string is given
 * @throws if it's not able to parse the data into the expected shape
 */
export function parseInfoListItems(rawString: Maybe<string>): InfoListItem[] {
  if (!rawString) return [];
  const infoList: InfoListItem[] = [];
  const infoLines = rawString.split('\n');
  for (const a of infoLines) {
    const splitInfos = a.split(';');
    const icon = splitInfos[0];
    const text = splitInfos[1];
    if (splitInfos.length !== 2 || !icon || !text) {
      const errMsg = 'Something went wrong parsing the infolist string.';
      logger.error({ rawString, infoLines, splitInfos, icon, text }, errMsg);
      throw new Error(errMsg);
    }
    infoList.push({ icon, text });
  }
  return infoList;
}

type ThemeItem = {
  // TODO refactor to be of enum type once settled on the values
  type: string;
  text: string;
};

/**
 * @arg rawString - expects a string in the form of "<some-text>;<type-string>\n<more-text>;<another-type>"
 * @returns an array of ThemeItems. If input is falsy it will return an empty array
 * @throws if the input can't be parsed properly
 */
export function parsePDPThemeItems(rawString: Maybe<string>): ThemeItem[] {
  if (!rawString) return [];
  const themeItems: ThemeItem[] = [];
  const rawLines = rawString.split('\n');
  for (const line of rawLines) {
    const splits = line.split(';');
    const text = splits[0];
    const type = splits[1];
    if (splits.length !== 2 || !type || !text) {
      const errMsg = 'Something went wrong parsing the pdp-themes string.';
      logger.error({ rawString, rawLines, splits, text, type }, errMsg);
      throw new Error(errMsg);
    }
    themeItems.push({ text, type });
  }
  return themeItems;
}

// TODO these colors probably need to be moved to design system
export function getPDPThemeColor(type: string): { background: string; font: string } {
  switch (type) {
    case 'gelb':
      return {
        background: '#FFEFE1',
        font: '#FF7600',
      };
    case 'blau':
      return {
        background: '#DDF7FF',
        font: '#00A2FF',
      };
    case 'rot':
      return {
        background: '#FFE6F3',
        font: '#FE00B9',
      };
    case 'lila':
      return {
        background: '#ECE7FF',
        font: '#923FCF',
      };
    default:
      return { background: colors.primary, font: colors.neutralSnow };
  }
}
