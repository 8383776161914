/* eslint-disable no-irregular-whitespace */
import { html } from 'lit';
import { emailTemplate } from './e-mail-templates';

export const doubleOptInTempate = (options: {
  accessLink: string;
  firstName: string;
  lastName: string;
  createAccount: boolean;
  redirectURL?: string;
}) => {
  const url = new URL(options.accessLink);
  if (options.redirectURL) url.searchParams.append('redirect', options.redirectURL);

  return emailTemplate(
    {
      content: html`
        <p style="line-height: 18px; padding-top: 10px;">
          Hallo <strong>${options.firstName} ${options.lastName}</strong>,<br />
          vielen Dank für deine Anmeldung.<br />
        </p>

        ${options.createAccount
          ? html`
              <p style="line-height: 18px; padding-top: 10px;">
                <strong>Herzlich willkommen</strong> im Campus der Digitalen Helden. Um deinen Account
                <strong>zu aktivieren</strong> und dein Passwort festzulegen,
                <a href="${options.accessLink}" target="_blank"><strong style="color:#afca0b">klicke hier</strong></a>
                oder rufe den Button auf.
              </p>
            `
          : html`
              <p style="line-height: 18px; padding-top: 10px;">
                Damit wir dir zukünftig Informationen zu unseren <strong>Angeboten,</strong>
                <strong><span style="color:#afca0b">Webinaren</span></strong> und <strong>Aktionen</strong> zuschicken
                dürfen, <a href="${options.accessLink}" style="color:#afca0b"></a><strong>bestätige</strong></a> bitte noch deine E-Mail-Adresse.
              </p>
            `}

        <p style="line-height: 18px; padding-top: 8px;">
          <a href="${url.toString()}" target="_blank" style="color:#FFFFFF;">
            <span class="button">${options.createAccount ? 'Account aktivieren' : 'E-Mail-Adresse bestätigen'}</span>
          </a>
        </p>
        <p style="line-height: 18px; padding-top: 10px;">
          Mit deinem Klick bestätigst du, dass du unsere
          <strong
            ><a style="color:#afca0b;" href="https://digitale-helden.de/datenschutz/">Datenschutzerklärung</a></strong
          >
          zur Kenntnis genommen hast.<br />Vielen Dank.
        </p>
        <p style="line-height: 18px; padding-top: 10px;">
          Herzliche Grüße<br />
          Dein Team Digitale Helden
        </p>
      `,
    },
    {
      title: html` <p style="line-height: 18px; padding-top: 10px;">Technischer Hinweis</p> `,
      content: html`
        <p style="line-height: 18px; padding-top: 10px;">
          Solltest du Probleme beim Aufrufen des Buttons oder des Links haben, kannst du diesen auch hier kopieren und
          manuell in deinen Browser einfügen:
        </p>
        <div style="padding: 10px; margin-top: 10px; border-radius: 5px; background-color: #f9f9f9;">
          <p style="line-height: 18px">${options.accessLink}</p>
        </div>
      `,
    },
  );
};
