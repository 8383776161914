import { ChemistryLitElement } from '@adornis/chemistry/chemistry-lit-element';
import { customElement, property } from 'lit/decorators.js';

/**
 * @element d-spacer
 */
@customElement('d-spacer')
export class DubniumSpacer extends ChemistryLitElement {
  @property({ attribute: true, reflect: true }) size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'sm';

  override styles() {
    return [
      ...super.styles(),
      {
        ...Object.fromEntries(
          Object.keys(this.spacing).map(size => [
            `:host([size=${size}])`,
            { padding: `${this.spacing[size] ?? '0'} 0` },
          ]),
        ),
      },
    ];
  }
}
