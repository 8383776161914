import type { Maybe } from '@adornis/base/utilTypes';
import { Entity, Field } from '@adornis/baseql/decorators';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity';

@Entity()
export class OrderManagementFilter extends AdornisEntity {
  static override _class = 'OrderManagementFilter';

  @Field(type => String) orderType: Maybe<'group' | 'single'>;
  @Field(type => String) productName: Maybe<string>;
  @Field(type => String) orderPaymentDate: Maybe<'asc' | 'desc'>;
}
