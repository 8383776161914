import '@adornis/digitale-helden-shared/db/product/queries';
import './product/mutations';
import './product/queries';

// product helpers
import '@adornis/digitale-helden-shared/db/product/helpers/activeProductCriteria';
import '@adornis/digitale-helden-shared/db/product/helpers/activeProductCriteriaSQL';

// orders
import './order/mutations';
import './order/queries';

// contacts
import './contact/mutations';
import './contact/queries';

// contact-company-relations
import './contact-company-relation/queries';

// group-contact-relations
import './group-contact-relations/queries';

// payments
import './Intent/Intent';

// contact-product-relation
import './contact-product-relation';

// lead
import './lead/mutations';
import './lead/queries';

// product
import './product/mutations';
import './product/queries';

// user
import './user/mutations';
import './user/queries';
