import { Entity, Field } from '@adornis/baseql/decorators';
import { MongoEntity } from '@adornis/baseql/entities/mongoEntity';
import { Contact } from '@adornis/digitale-helden-shared/db/Contact';
import { Lead } from '@adornis/digitale-helden-shared/db/Lead';
import { validate } from '@adornis/validation/decorators';
import { ValidationError } from '@adornis/validation/errors/ValidationError';
import { nonOptional } from '@adornis/validation/functions/nonOptional';
import { DateTime } from 'luxon';

export enum TokenAction {
  DOI = 'doi',
  SOI = 'soi',
  CHANGE_PASSWORD = 'change-password',
}

@Entity()
export class ActionTokenData extends MongoEntity {
  static override _class = 'ActionTokenData';
  static override _collectionName = 'actionTokenDatas';

  @validate(nonOptional())
  @Field(type => String)
  action!: TokenAction;

  @validate(options => {
    if (!options.target.lead && !options.target.contact && !options.value)
      throw new ValidationError('Pflichtfeld.', { key: options.key });
  })
  @Field(type => String)
  email!: string;

  @validate(options => {
    if (!options.target.contact && !options.target.email && !options.value)
      throw new ValidationError('Geben Sie einen Lead oder Kontakt an', { key: options.key });
  })
  @Field(type => Lead)
  lead!: Lead;

  @validate(options => {
    if (!options.target.lead && !options.target.email && !options.value)
      throw new ValidationError('Geben Sie einen Lead oder Kontakt an', { key: options.key });
  })
  @Field(type => Contact)
  contact!: Contact;

  @validate(options => {
    if ([TokenAction.DOI, TokenAction.SOI].includes(options.target.action) && options.value === undefined)
      throw new ValidationError('Geben Sie an, ob ein Akademie-Account erzeugt werden soll.');
  })
  @Field(type => Boolean, { default: v => v ?? false })
  createAccount!: boolean;

  @Field(type => Boolean, { default: v => v ?? false }) accountCreated!: boolean;

  @validate(nonOptional())
  @Field(type => DateTime, { default: v => v ?? DateTime.now() })
  createdAt!: DateTime;

  @Field(type => DateTime)
  usedAt!: DateTime;

  @Field(type => Boolean, { default: v => v ?? false })
  used!: boolean;
}
