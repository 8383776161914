import type { Maybe } from '@adornis/base/utilTypes';
import { Entity, Field } from '@adornis/baseql/decorators';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity';

@Entity()
export class SignupNewFundingData extends AdornisEntity {
  static override _class = 'SignupNewFundingData';

  @Field(type => String) validatedFundingID?: Maybe<string>;
  @Field(type => String) fundingKey?: Maybe<string>;
  @Field(type => String) leitwegID?: Maybe<string>;
}
