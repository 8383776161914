import { registerQuery } from '@adornis/baseql/metadata/register';
import { Product } from '../../../db/Product';
import { getAllProducts } from './getAllProducts';

//* Resolver
const getDisabledProductIDsResolver = () => {
  return async () => {
    const allProducts = await getAllProducts()(Product.allFields);
    const filteredProducts = allProducts.filter(p => p.dontVisualize).map(p => p.id);
    return filteredProducts;
  };
};

//* Query
export const getDisabledProductIDs = registerQuery({
  type: () => [String],
  operationName: 'getDisabledProductIDs',
  resolve: getDisabledProductIDsResolver,
  params: [],
});
