import type { AdornisTiptapExtension } from '@adornis/tiptap/client/extensions/ExtensionBase';
import { Extension } from '@tiptap/core';
import { DOMParser } from 'prosemirror-model';
import { LASPromptTiptapExtensionInsertHTML } from './las-prompt-tiptap-extension-inserthtml';

const HTMLExtension = Extension.create({
  name: 'insertHTMLExtension',

  addCommands() {
    return {
      insertHTML:
        value =>
        async ({ state, dispatch }) => {
          const { schema, selection } = state;

          const element = document.createElement('div');
          element.innerHTML = value.trim();
          const slice = DOMParser.fromSchema(schema).parseSlice(element);
          const transaction = state.tr.insert(selection.anchor, slice.content);
          dispatch(transaction);
        },
    };
  },
});

export const InsertHTMLExtension: AdornisTiptapExtension = {
  buttons: ({ editor }) => {
    const result = [
      {
        name: 'insertHTML',
        icon: 'code',
        action: async () => {
          const htmlCode = await LASPromptTiptapExtensionInsertHTML.showPopup();
          if (!htmlCode) return;
          // @ts-expect-error
          editor.commands.insertHTML(htmlCode);
        },
      },
    ];
    return result;
  },
  extensions: [HTMLExtension],
};
