import type { Maybe } from '@adornis/base/utilTypes';
import { Entity, Field } from '@adornis/baseql/decorators';
import { BaseContainerGrid } from '@adornis/buildify/db/extensions/BaseContainerGrid';
import { validate } from '@adornis/validation/decorators';
import { ValidationError } from '@adornis/validation/errors/ValidationError';
import { nonOptional } from '@adornis/validation/functions/nonOptional';
import { Link } from '../Link';

@Entity()
export class DubniumBaseContainerGrid extends BaseContainerGrid {
  static override _class = 'DubniumBaseContainerGrid';

  @Field(type => Boolean)
  hasLineLeft: Maybe<boolean>;

  @validate(options => {
    if (!options.target.hasLineLeft) return;
    if (!options.value) throw new ValidationError('validate', { key: options.key });
  })
  @Field(type => String)
  lineLeftWidth: Maybe<string>;

  @validate(options => {
    if (!options.target.hasLineLeft) return;
    if (!options.value) throw new ValidationError('validate', { key: options.key });
  })
  @Field(type => String)
  lineLeftColor: Maybe<string>;

  @validate(nonOptional())
  @Field(type => Link, { default: v => v ?? new Link({}) })
  link!: Link;
}
