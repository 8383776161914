import { Size } from '@adornis/buildify/client/globals/enums';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import { DubniumBaseContainerFlexWithBackground } from '../../../../../db/extensions/flex-container/DubniumBaseContainerFlexWithBackground';
import { BuildifyCampusContainerFlexExtension } from './BuildifyCampusContainerFlexExtension';

export const BuildifyCampusContainerFlexBackgroundExtension: BuildifyExtension = {
  class: DubniumBaseContainerFlexWithBackground,
  toolbar: { icon: 'diagram-cells', text: { default: 'Flex mit Hintergrund' } },
  render(instance, { defaultValues, isSubelement }) {
    return BuildifyCampusContainerFlexExtension.render(instance, {
      defaultValues: {
        ...(defaultValues ? defaultValues : {}),
        padding: {
          [Size.DESKTOP]: '32px',
          [Size.TABLET]: '24px',
          [Size.MOBILE]: '20px',
        },
        backgroundColor: {
          global: '#f7f7f7',
        },
      },
      isSubelement,
    });
  },
  editor: BuildifyCampusContainerFlexExtension.editor,
  group: 'Layout',
};
