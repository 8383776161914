import { Entity, Field } from '@adornis/baseql/decorators';
import { BaseIconText } from '@adornis/buildify/db/extensions/BaseIconText';
import { validate } from '@adornis/validation/decorators';
import { nonOptional } from '@adornis/validation/functions/nonOptional';
import { DubniumBaseIcon } from './DubniumBaseIcon';

@Entity()
export class DubniumBaseIconText extends BaseIconText {
  static override _class = 'DubniumBaseIconText';

  @validate(nonOptional())
  @Field(type => DubniumBaseIcon, { default: v => v ?? new DubniumBaseIcon({}) })
  override icon!: DubniumBaseIcon;
}
