import { XBuildifySizePicker } from '@adornis/buildify/client/components/x-buildify-size-picker';
import { customElement } from 'lit/decorators.js';

@customElement('d-buildify-size-picker')
export class DBuildifySizePicker extends XBuildifySizePicker {
  protected override get configuration() {
    return {
      icons: {
        global: 'globe',
        desktop: 'desktop',
        tablet: 'tablet',
        mobile: 'mobile-notch',
      },
    };
  }
}
