export enum UserRoles {
  SUPER_ADMIN = 'LAS Super-Admin',
  ADMIN = 'LAS Admin',
  INSTITUTION_ADMIN = 'Administrator:in (Institution)',
  GROUP_ADMIN = 'Gruppen-Administrator:in',
  CONTACT_PERSON = 'Ansprechpartner:in (Institution)',
  PARTICIPANT = 'Teilnehmer:in',
  USER = 'Nutzer',
  HELDEN_TEAM = 'Helden Team',
  SUPPORT = 'Support',
  ACCOUNTING = 'Accounting',
  DESIGNER = 'Designer',
}

export const defaultPermissions = ['Users.ChangePassword', 'Users.Impersonate', 'Users.ChangeRoles'];
export const BuildifyForceViewPermission = 'Buildify.ForceView';

export type DefaultPermission = typeof defaultPermissions;

// team
export enum TeamPermission {
  VIEW = 'Team.View',
}

export const TEAM_PERMISSIONS = Object.values(TeamPermission);
// ----

// support
export enum SupportPermission {
  VIEW = 'Support.View',
  EDIT = 'Support.Edit',
}

export const SUPPORT_PERMISSIONS = Object.values(SupportPermission);
// ----

// accounting
export enum AccountingPermission {
  VIEW = 'Accounting.View',
  EDIT = 'Accounting.Edit',
}

export const ACCOUNTING_PERMISSIONS = Object.values(AccountingPermission);
// ----

// buildify
export enum DesignerPermission {
  VIEW = 'Designer.View',
  EDIT = 'Designer.Edit',
}

export const DESIGNER_PERMISSIONS = Object.values(DesignerPermission);
// ----

// admin
export enum SuperAdminOnlyPermission {
  VIEW = 'Admin.View',
  EDIT = 'Admin.Edit',
  VIEW_USERLIST = 'Admin.ViewUserlist',
  CREATE_EDIT_USERS = 'Admin.CreateAndEditUsers',
  EDIT_PERMISSIONS = 'Admin.EditUserPermissions',
}

export const SUPER_ADMIN_ONLY_PERMISSIONS = Object.values(SuperAdminOnlyPermission);
// ----

export const permissions = [
  // stammdaten-user
  'User.Stammdaten.View',
  'User.Stammdaten.WriteViaForm',
  'User.Stammdaten.EditRecordViaInterface',
  'User.Stammdaten.DeleteRecord',
  // stammdaten-institution
  'Institution.Stammdaten.View',
  'Institution.Stammdaten.WriteViaForm',
  'Institution.Stammdaten.EditRecordViaInterface',
  'Institution.Stammdaten.DeleteRecord',
  // password reset
  'PasswordReset.View',
  'PasswordReset.TriggerEmail',
  // marketing
  'Marketing.View',
  'Marketing.UpdateEmailStatus',
  'Marketing.SubscribeUnsubscribeMailinglist',
  // akademie
  'Akademie.ViewOrder',
  'Akademie.DeactivateAndReactivate',
  'Akademie.ViewContent',
  // group
  'Group.View',
  'Group.WriteRecord',
  'Group.DeleteRecord',
  'Group.EditPermission',

  // designer
  ...DESIGNER_PERMISSIONS,

  // team
  ...TEAM_PERMISSIONS,

  // support
  ...SUPPORT_PERMISSIONS,

  // accounting
  ...ACCOUNTING_PERMISSIONS,

  // super-admin
  ...SUPER_ADMIN_ONLY_PERMISSIONS,

  ...defaultPermissions,

  BuildifyForceViewPermission,
] as const;

const basicPermissions = [
  'User.Stammdaten.View',
  'User.Stammdaten.WriteViaForm',
  'User.Stammdaten.EditRecordViaInterface',
  'User.Stammdaten.DeleteRecord',
  'PasswordReset.View',
  'PasswordReset.TriggerEmail',
  'Marketing.View',
  'Marketing.UpdateEmailStatus',
  'Marketing.SubscribeUnsubscribeMailinglist',
  'Akademie.ViewContent',
  'Akademie.ViewOrder',
  'Akademie.DeactivateAndReactivate',
] as Permission[];

export type Permission = (typeof permissions)[number] | DefaultPermission;

export const defaultRoles: Array<{ name: string; permissions: Permission[] }> = [
  { name: UserRoles.USER, permissions: [...basicPermissions, 'Users.ChangePassword'] },
  {
    name: UserRoles.GROUP_ADMIN,
    permissions: ['Group.View', 'Group.EditPermission', 'Group.WriteRecord', 'Group.DeleteRecord'],
  },
  { name: UserRoles.PARTICIPANT, permissions: [] },
  {
    name: UserRoles.INSTITUTION_ADMIN,
    permissions: [
      'Group.View',
      'Group.EditPermission',
      'Group.WriteRecord',
      'Group.DeleteRecord',
      'Institution.Stammdaten.DeleteRecord',
      'Institution.Stammdaten.EditRecordViaInterface',
      'Institution.Stammdaten.WriteViaForm',
      'Institution.Stammdaten.View',
    ],
  },
  {
    name: UserRoles.CONTACT_PERSON,
    permissions: [
      'Group.View',
      'Group.EditPermission',
      'Group.WriteRecord',
      'Group.DeleteRecord',
      'Institution.Stammdaten.DeleteRecord',
      'Institution.Stammdaten.EditRecordViaInterface',
      'Institution.Stammdaten.WriteViaForm',
      'Institution.Stammdaten.View',
    ],
  },
  { name: UserRoles.HELDEN_TEAM, permissions: [...TEAM_PERMISSIONS, BuildifyForceViewPermission] },
  { name: UserRoles.SUPPORT, permissions: [...SUPPORT_PERMISSIONS, ...TEAM_PERMISSIONS] },
  { name: UserRoles.ACCOUNTING, permissions: [...ACCOUNTING_PERMISSIONS, ...TEAM_PERMISSIONS] },
  {
    name: UserRoles.DESIGNER,
    permissions: [...DESIGNER_PERMISSIONS, ...TEAM_PERMISSIONS, BuildifyForceViewPermission],
  },
  {
    name: UserRoles.ADMIN,
    permissions: [...permissions].filter(permission => ![...SUPER_ADMIN_ONLY_PERMISSIONS].includes(permission)),
  },
  { name: UserRoles.SUPER_ADMIN, permissions: [...permissions] },
];
