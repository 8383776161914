import { registerMutation } from '@adornis/baseql/metadata/register';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { Lead } from '@adornis/digitale-helden-shared/db/Lead';
import { insertZohoRecord } from '@adornis/digitale-helden-shared/server/zoho/api';

export const insertLead = registerMutation({
  type: () => Lead,
  operationName: 'insertLead',
  resolve: (entity: Lead) => {
    return async (gqlFields: BaseQLSelectionSet<Lead>) => {
      entity.signInDate = new Date();
      const leadId = await insertZohoRecord(Lead.ZOHO_MODULE, entity);
      entity.id = leadId;
      return entity;
    };
  },
  params: [{ name: 'entity', type: () => Lead }],
});
