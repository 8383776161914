import { type Maybe, type ValueEvent } from '@adornis/base/utilTypes';
import { css } from '@adornis/chemistry/directives/css';
import { XSnackbar } from '@adornis/chemistry/elements/components/x-snackbar';
import '@adornis/cms/client/generated-form.js';
import type { DialogOptions } from '@adornis/dialog/x-plain-dialog';
import { TranslationController } from '@adornis/translation-core/client/translation-controller';
import { html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { Contact } from '../../db/Contact';
import { LASUser } from '../../db/las-user';
import type { LASTranslationDictionary } from '../../db/translation';
import '../components/las-card';
import { DubniumDialog } from '../theme/components/d-dialog';

@customElement('las-prompt-check-password')
export class LASPromptCheckPassword extends DubniumDialog<Maybe<string>> {
  static override get element_name() {
    return 'las-prompt-check-password';
  }

  @property({ attribute: false }) readonly lasUser?: LASUser;

  private _password = '';
  @state() private _error: undefined | string = undefined;

  @state() translation = new TranslationController<LASTranslationDictionary>(this);

  override content() {
    return html`
      <las-card top-right="accent" icon="lock" ${css({ borderRadius: '10px', width: '100%' })}>
        <d-flex space="lg" ${css({ paddingBottom: this.spacing.lg })}>
          <d-flex space="xs">
            <d-h4 ${css({ color: '#333333' })}
              >${this.translation.translate('prompt_check_password', 'attention')}</d-h4
            >
            <d-text ${css({ color: '#bbbbb4' })}
              >${this.translation.translate('prompt_check_password', 'user_already_exists')}</d-text
            >
          </d-flex>
          <d-flex space="sm">
            <d-input
              placeholder=${this.translation.translate('password')}
              placeholder-mode="floating"
              type="password"
              .error=${this._error}
              @value-changed=${(e: ValueEvent<string>) => {
                this._error = undefined;
                this._password = e.detail.value;
              }}
            ></d-input>
          </d-flex>

          <d-text>
            <d-link
              ${css({ display: 'inline-block' })}
              @click=${async () => {
                if (!this.lasUser) return;
                await DubniumDialog.waitFor(Contact.sendForgotPasswordMail(this.lasUser.email, false)());
              }}
            >
              <d-text bold> Passwort vergessen? </d-text>
            </d-link>
            <d-text ${css({ display: 'inline-block' })}>
              Keine Sorge. Wir schicken Ihnen einen Link mit dem Sie dieses ändern können.
            </d-text>
          </d-text>

          <d-flex space-between horizontal wrap>
            <d-button mode="outline" @click=${() => this.cancel()}> ${this.translation.translate('cancel')} </d-button>
            <d-button
              @click=${async () => {
                if (!this._password)
                  return XSnackbar.show(this.translation.translate('prompt_check_password', 'password_missing'));
                if (!this.lasUser) {
                  await XSnackbar.show(this.translation.translate('prompt_check_password', 'internet_error'));
                  return this.close(null);
                }

                const isPasswordCheck = await LASUser.checkPassword(this.lasUser._id, this._password)();
                if (isPasswordCheck) return this.close(this._password);

                this._error = this.translation.translate('prompt_check_password', 'wrong_password');
              }}
            >
              ${this.translation.translate('prompt_check_password', 'check')}
            </d-button>
          </d-flex>
        </d-flex>
      </las-card>
    `;
  }

  override async cancel(returnVal?: any) {
    await this.transitionOut();
    return this.close(null);
  }

  override getInnerWrapper(options: Required<DialogOptions>): HTMLDivElement {
    const wrapper = super.getInnerWrapper(options);
    // this.innerWrapper = wrapper;
    wrapper.style.setProperty('padding', '0');
    wrapper.style.setProperty('background', 'transparent');
    wrapper.style.setProperty('width', 'min(800px, 95%)');
    return wrapper;
  }
}
