import type { Maybe } from '@adornis/base/utilTypes';
import { Entity, Field } from '@adornis/baseql/decorators';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity';
import { validate } from '@adornis/validation/decorators';
import { ValidationError } from '@adornis/validation/errors/ValidationError';
import { nonOptional } from '@adornis/validation/functions/nonOptional';
import { IntentInfo } from '../../_api/Intent/Intent';
import type { ProductPaymentModality } from '../../db/enums';
import { PaymentMethod } from '../_new-mentoring-form/db/SignupNewMentoringForm';
import { SignupAlternateBillData } from './SignupAlternateBillData';

export const paymentModalityValidation = ({ key, value, target }) => {
  if (target.paymentMethod !== PaymentMethod.ON_ACCOUNT) return;
  if (!value)
    throw new ValidationError('payment modality is required.', { key, translationKey: 'validation_non_optional' });
};

@Entity()
export class SignupNewPaymentData extends AdornisEntity {
  static override _class = 'SignupNewPaymentData';

  @validate(nonOptional())
  @Field(type => String)
  paymentMethod!: PaymentMethod;

  @validate(paymentModalityValidation)
  @Field(type => String)
  paymentModality!: ProductPaymentModality;

  @Field(type => SignupAlternateBillData)
  alternativeAddress?: Maybe<SignupAlternateBillData>;

  @Field(type => IntentInfo)
  intentInfo?: Maybe<IntentInfo>;

  elements?: Maybe<any>;
  paymentElement?: Maybe<HTMLElement>;
}
