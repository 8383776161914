import { type AdornisContext } from '@adornis/baseql/server/context';
import { LASUser } from '@adornis/digitale-helden-shared/db/las-user';
import { UserRoles } from '@adornis/digitale-helden-shared/db/permissions';
import { ANY_CONTEXT } from '@adornis/users/db/a-roles';
import { CurrentUserInfo } from '@adornis/users/db/currentUserInfo';
import { AdornisRoleToContextsWrapper } from '@adornis/users/db/roleToContextsWrapper';

export const checkRole = async ({ context, role }: { context: AdornisContext; role: UserRoles }) => {
  if (context.serverContext) throw new Error('user not found in permission check helper');
  const user = await LASUser.getByID<LASUser>(context.userID)(LASUser.allFields);
  if (user?.hasRole(role)) return;
  throw new Error(`you do not have the role '${role}' for userID ${context.userID}`);
};

export const checkPermission = async ({
  context,
  permission,
  permissionContext = ANY_CONTEXT,
  allowServerContext = false,
}: {
  context: AdornisContext;
  permission: string;
  permissionContext?: string;
  allowServerContext?: boolean;
}) => {
  if (allowServerContext && context.serverContext) return;
  if (context.serverContext) throw new Error('user not found in permission check helper');
  const user = await LASUser.getByID<LASUser>(context.userID)(LASUser.allFields);
  if (user?.roles.find(role => role.name === UserRoles.SUPER_ADMIN)) return;
  if (user?.hasPermission(permission, permissionContext)) return;
  throw new Error(`you do not have the permission '${permission}' for userID ${context.userID}`);
};

export const checkPermissionOrProductOwning = async ({
  context,
  permission,
  productID,
}: {
  context: AdornisContext;
  permission: string;
  productID: string;
}) => {
  try {
    await checkPermission({ context, permission });
  } catch (err) {
    // wenn keine direkte buildify viewing permissions, check ob er anspruch auf das Produkt hat
    const user = await CurrentUserInfo.getMyself<LASUser>()({
      _id: 1,
      roles: AdornisRoleToContextsWrapper.allFields,
    });
    if (!user) throw new Error('user not given in context');
    const hasPermissionForProduct = user.roles
      .find(role => role.name === UserRoles.PARTICIPANT)
      ?.contexts.includes(productID);
    if (!hasPermissionForProduct) throw new Error('no permission for product');
  }
};
