import { EditContentConsumer } from '@adornis/buildify/client/globals/consumer';
import { ChemistryLitElement } from '@adornis/chemistry/chemistry-lit-element';
import { css } from '@adornis/chemistry/directives/css';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import './d-buildify-editor';

@customElement('d-buildify-sidebar')
export class DBuildifySidebar extends ChemistryLitElement {
  private _consumedEditContent = EditContentConsumer(this);

  override render() {
    return html`
      <d-flex
        ${css({
          background: '#333',
          position: 'relative',
          height: '100%',
          overflow: 'auto',
        })}
        padding="sm"
      >
        ${this._consumedEditContent.value ? this.__editor() : this.__bar()}
      </d-flex>
    `;
  }

  protected __editor() {
    return html` <d-buildify-editor></d-buildify-editor> `;
  }
  protected __bar() {
    return html` <d-buildify-bar></d-buildify-bar> `;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          overflow: 'hidden',
        },
      },
    ];
  }
}
