import { XCalendar } from '@adornis/calendar/calendar';
import { customElement } from 'lit/decorators.js';

/**
 * @element d-calendar
 */
@customElement('d-calendar')
export class DubniumCalendar extends XCalendar {
  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          '--fc-button-text-color': this.colors.white,
        },
      },
    ];
  }
}
