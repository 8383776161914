import { logger } from '@adornis/base/logging';
import { registerMutation } from '@adornis/baseql/metadata/register';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { getContactByEmailCOQL } from '@adornis/digitale-helden-shared/api/Contact/getContactByEmail';
import { getLeadByEmail } from '@adornis/digitale-helden-shared/api/Lead/getLeadByEmail';
import { Contact } from '@adornis/digitale-helden-shared/db/Contact';
import { Lead } from '@adornis/digitale-helden-shared/db/Lead';
import { UpsertContactOptions } from '@adornis/digitale-helden-shared/db/UpsertContactOptions';
import { getDifficultyIndex } from '@adornis/digitale-helden-shared/db/product/enums';
import { upsertZohoRecord } from '@adornis/digitale-helden-shared/server/zoho/api';
import { convertLeadToContact } from '../../../server/zoho/operations';

export const upsertContact = registerMutation({
  type: () => Contact,
  operationName: 'upsertContact',
  resolve: (instance: Contact, options?: UpsertContactOptions) => {
    return async (gqlFields: BaseQLSelectionSet<Contact>) => {
      // options check methods
      const checkStatus = (oldStatus, newStatus): string => {
        return getDifficultyIndex(newStatus) < getDifficultyIndex(oldStatus) ? oldStatus : newStatus;
      };
      const checkAcademyRoles = (oldRoles: string[], newRoles: string[]): string[] => {
        const allAcademyRoles = new Set<string>([...oldRoles, ...newRoles]);
        return Array.from(allAcademyRoles.values());
      };
      const checkOtherAcademyRole = (oldOthers: string, newOthers: string): string => {
        const otherAcademyRoles = new Set<string>([...oldOthers.split(','), ...newOthers.split(',')]);
        return Array.from(otherAcademyRoles.values())
          .filter(value => !!value)
          .join(',');
      };
      const checkCommunicationCampaigns = (oldCampaigns: string[], newCampaigns: string[]) => {
        const campaigns = new Set<string>([...oldCampaigns, ...newCampaigns]);
        return Array.from(campaigns.values());
      };

      // check if a lead exists, and add data to contact
      const existingLead = await getLeadByEmail(instance.email)(Lead.allFields);
      if (existingLead) {
        // reihenfolge des Spreads ist wichtig
        instance = new Contact({
          ...existingLead.toFilteredJSON,
          id: null,
          ...instance.toFilteredJSON,
          status: checkStatus(existingLead.status, instance.status),
          academyRoles: checkAcademyRoles(existingLead.academyRoles ?? [], instance.academyRoles ?? []),
          otherAcademyRole: checkOtherAcademyRole(existingLead.otherAcademyRole ?? '', instance.otherAcademyRole ?? ''),
        });
      }

      const existingContact = await getContactByEmailCOQL(instance.email)(Contact.allFields);
      // check options and configure data as needed
      const optionsCheck = async () => {
        if (
          options &&
          (options.checkStatus ||
            options.checkAcademyRole ||
            options.checkOtherAcademyRole ||
            options.checkCommunicationCampaigns) &&
          !existingLead
        ) {
          if (!options) return;

          if (!existingContact) return;

          if (options.checkStatus) {
            instance.status = checkStatus(existingContact.status, instance.status);
          }

          if (options.checkAcademyRole) {
            instance.academyRoles = checkAcademyRoles(existingContact.academyRoles ?? [], instance.academyRoles ?? []);
          }

          if (options.checkOtherAcademyRole) {
            instance.otherAcademyRole = checkOtherAcademyRole(
              existingContact.otherAcademyRole ?? '',
              instance.otherAcademyRole ?? '',
            );
          }

          if (options.checkCommunicationCampaigns) {
            instance.communicationCampaigns = checkCommunicationCampaigns(
              existingContact.communicationCampaigns ?? [],
              instance.communicationCampaigns ?? [],
            );
          }
        }
      };
      await optionsCheck();

      if (!existingContact && !existingLead) instance.signInDate = new Date();

      instance.id = await upsertZohoRecord(Contact.ZOHO_MODULE, instance);
      if (existingLead) {
        if (!existingLead.id) {
          const msg = 'Existing Lead had no id while registering contact.';
          logger.error({ existingLead }, msg);
          throw new Error(msg);
        }
        await convertLeadToContact(existingLead.id, instance.id);
      }
      return instance;
    };
  },
  params: [
    { name: 'instance', type: () => Contact },
    { name: 'options', type: () => UpsertContactOptions },
  ],
});
