import type { Maybe } from '@adornis/base/utilTypes';
import { constructValue } from '@adornis/baseql/entities/adornisEntity';
import { registerMutation } from '@adornis/baseql/metadata/register';
import { getRawCollection } from '@adornis/baseql/server/collections';
import { context } from '@adornis/baseql/server/context';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { Contact } from '@adornis/digitale-helden-shared/db/Contact';
import { LASUser } from '@adornis/digitale-helden-shared/db/las-user';
import { Product } from '@adornis/digitale-helden-shared/db/product/Product';
import { ProductPaymentModality } from '@adornis/digitale-helden-shared/db/product/enums';
import { getProductByID } from '@adornis/digitale-helden-shared/db/product/queries/getProductByID';
import { CurrentUserInfo } from '@adornis/users/db/currentUserInfo';
import { DateTime } from 'luxon';
import { LASPayment } from '../../../_accounting/db/Payments/LASPayment';
import { FormularAutomaticProduct } from '../../../_forms/_automatic-product-form/db/FormularAutomaticProduct';
import { Order } from '../../../db/Order';
import { OrderAttendenceStatus, OrderStatus } from '../../../db/enums';
import { UpsertContactOptions } from '@adornis/digitale-helden-shared/db/UpsertContactOptions';
import { ContactNotFoundError } from '../../../errors/ContactNotFoundError';
import { ExistingOrderForPaymentIDError } from '../../../errors/ExistingOrderForPaymentIDError';
import { InsufficientPermissionError } from '../../../errors/InsufficientPermissionError';
import { OrderCreationError } from '../../../errors/OrderCreationError';
import { PaymentInvalidError } from '../../../errors/PaymentInvalidError';
import { PaymentNotFoundError } from '../../../errors/PaymentNotFoundError';
import { PaymentNotPaidError } from '../../../errors/PaymentNotPaidError';
import { ProductNotFoundError } from '../../../errors/ProductNotFoundError';
import { UserNotFoundError } from '../../../errors/UserNotFoundError';
import { upsertContact } from '../../contact/mutations/upsertContact';
import { getContactByIDCOQL } from '../../contact/queries/getContactByIDCOQL';
import { upsertOrder } from './upsertOrder';

/**
 * Get payment and create order \n
 * Productinformation & User is read out of the payment
 *
 * @param {string} paymentID paymentID that should result in a new order
 * @return {Order} Order that has been created
 *
 * possible errors:
 * @throws {PaymentNotFoundError} The given paymentID doesn't exist.
 * @throws {InsufficientPermissionError} Client-Context has no permission to perform this action.
 * @throws {PaymentNotPaidError} Given payment is not payed yet.
 * @throws {ExistingOrderForPaymentIDError} A order with this paymentID reference already exists
 * @throws {PaymentInvalidError} invalid payment (for example: no userID on payment, no products)
 * @throws {UserNotFoundError} invalid userID given by payment>userReference>userID
 * @throws {ContactNotFoundError} zohoID results in no valid contact user>zohoID
 * @throws {ProductNotFoundError} payment product reference couldn't be resolved
 * @throws {OrderCreationError} error by upserting the order
 */
//* Resolver
const createOrderByPaymentResolver = (paymentID: string) => {
  return async (gqlFields: BaseQLSelectionSet<Order>) => {
    const paymentRawCollection = await getRawCollection<LASPayment>(LASPayment._collectionName);
    let payment = await paymentRawCollection.findOne<LASPayment>({ _id: paymentID });
    payment = constructValue(payment);

    if (!(payment?.formData instanceof FormularAutomaticProduct)) throw new Error('invalid payment was given in');
    if (!payment) throw new PaymentNotFoundError();
    if (!payment.userReference.userID) throw new PaymentInvalidError();
    if (payment.status !== 'paid') throw new PaymentNotPaidError();

    const existingOrderForPaymentID = await Order.getOrderByPaymentIDCOQL(paymentID)({ id: 1, paymentID: 1 });
    if (existingOrderForPaymentID) throw new ExistingOrderForPaymentIDError();

    if (!context.serverContext) {
      const currentUser = await CurrentUserInfo.getMyself<LASUser>()({ _id: 1 });
      if (!currentUser || currentUser._id !== payment.userReference.userID) throw new InsufficientPermissionError();
    }

    const userRawCollection = await getRawCollection<LASUser>(LASUser._collectionName);
    const user = await userRawCollection.findOne<LASUser>({ _id: payment.userReference.userID });
    if (!user) throw new UserNotFoundError();

    const contact = await getContactByIDCOQL(user.zohoID)(Contact.allFields);
    if (!contact) throw new ContactNotFoundError();

    if (payment.formData instanceof FormularAutomaticProduct) {
      const data = payment.formData;
      if (data.isWebinar) contact.isWebinar = true;
      if (data.isNewsletter) contact.isNewsletter = true;

      contact.communicationCampaigns = data.communicationCampaigns;
      try {
        await upsertContact(contact, new UpsertContactOptions({ checkCommunicationCampaigns: true }))({});
      } catch (err) {}
    }

    const productID = payment.products.at(0)?.product.reference;
    if (!productID) throw new PaymentInvalidError();

    const product = await getProductByID(productID)(Product.allFields);
    if (!product) throw new ProductNotFoundError();

    let expiryDate: Maybe<Date> = null;
    if (product.durationInDays && product.durationInDays !== 0) {
      expiryDate = DateTime.now().plus({ days: product.durationInDays }).toJSDate();
    }

    const order = new Order({
      buyerContactId: contact.id,
      status: OrderStatus.ACTIVE,
      paymentDate: DateTime.now(),
      attendanceStatus: OrderAttendenceStatus.ANGEMELDET,
      productId: product.id,
      productConfiguration: product,
      paymentModality: ProductPaymentModality.TOTAL_BILL,
      paymentID,
      expiryDate,
      ...(payment.formData._class === FormularAutomaticProduct._class
        ? {
            academyRole: payment.formData.academyRole,
            otherAcademyRole: payment.formData.otherAcademyRole,
          }
        : {}),
    });

    try {
      return await upsertOrder(order)(Order.allFields);
    } catch (err) {
      throw new OrderCreationError();
    }
  };
};

//* Query
export const createOrderByPayment = registerMutation({
  type: () => Order,
  operationName: 'createOrderByPayment',
  resolve: createOrderByPaymentResolver,
  params: [{ type: () => String, name: 'paymentID' }],
});
