import type { Maybe } from '@adornis/base/utilTypes';
import { Int } from '@adornis/baseql/baseqlTypes';
import { Arg, Entity, Field, Mutation, Query } from '@adornis/baseql/decorators';
import { getCollection, getRawCollection } from '@adornis/baseql/server/collections';
import { context } from '@adornis/baseql/server/context';
import { selectionSet, type BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { CustomContent } from '@adornis/buildify/db/CustomContent';
import { Page } from '@adornis/buildify/db/Page';
import { CurrentUserInfo } from '@adornis/users/db/currentUserInfo';
import { AdornisRoleToContextsWrapper } from '@adornis/users/db/roleToContextsWrapper';
import { validate } from '@adornis/validation/decorators';
import { nonOptional } from '@adornis/validation/functions/nonOptional';
import { Versioned } from '@adornis/versioned-entity/db/versioned-mongo-entity';
import type { UpdateResult } from 'mongodb';
import { CampusRoute, PathToCampusRoute } from '../../_routing/db/enums';
import { checkPermission, checkPermissionOrProductOwning } from '../../db/helpers';
import { BuildifyForceViewPermission, LASUser, UserRoles } from '../../db/las-user';
import { PathAlreadyTakenError } from '../../errors/PathAlreadyTakenError';
import { DubniumPagePublished } from './DubniumPagePublished';

@Versioned({ versionCollection: 'page-versions' })
@Entity()
export class DubniumPage extends Page {
  static override _class = 'DubniumPage';
  static override _collectionName = 'pages';

  static override get allFields() {
    return selectionSet(() => this, 5);
  }

  @validate(nonOptional({ allowFalsy: true }))
  @Field(type => Int, { default: v => v ?? 0 })
  version!: number;

  @validate(nonOptional())
  @Field(type => String)
  override name!: string;

  @validate(nonOptional())
  @Field(type => String)
  shortName!: string;

  @validate(nonOptional())
  @Field(type => [String], { default: v => v ?? [] })
  productIDs!: string[];

  @Field(type => String) parentPageID: Maybe<string>;
  @Field(type => String) documentType: Maybe<DocumentType>;

  static override async transformBeforeUpdate(
    oldEntity: {
      _id?: string | undefined;
      _class?: string | undefined;
    },
    newEntity: DubniumPage,
  ) {
    await super.transformBeforeUpdate(oldEntity, newEntity);
    newEntity.version = newEntity.version + 1;
  }

  static override async afterUpdate(
    oldEntity: {
      _id?: string | undefined;
      _class?: string | undefined;
    },
    newEntity: DubniumPage,
    result: UpdateResult,
  ) {
    await super.afterUpdate(oldEntity, newEntity, result);
  }

  @Mutation(type => String)
  static checkIfPathAlreadyTaken(@Arg('instance', type => DubniumPage) instance: DubniumPage) {
    return async (): Promise<void> => {
      if (instance.parentPageID) {
        // hier wirds komplizierter
        const parent = await this.getRootPageOfSubpage(instance.parentPageID)(DubniumPage.allFields);
        const children = await this.getPagesByProductPage(parent._id, true)({ _id: 1, path: 1 });
        for (const child of children) {
          if (child.path?.toLowerCase() === instance.path?.toLowerCase()) throw new PathAlreadyTakenError();
        }
      } else {
        const pageCollection = await getCollection(DubniumPage._class);
        const results = await pageCollection.find<DubniumPage>({ parentPageID: null }).toArray();
        for (const result of results) {
          if (result.path?.toLowerCase() === instance.path?.toLowerCase()) throw new PathAlreadyTakenError();
        }
      }
    };
  }

  @Mutation(type => String)
  static saveRawCustomContent(@Arg('instance', type => CustomContent) instance: CustomContent) {
    return async (): Promise<string> => {
      await checkPermission({ context, permission: 'Designer.Edit' });

      const collection = await getRawCollection<CustomContent>(CustomContent._collectionName);
      const result = await collection.replaceOne({ _id: instance._id }, instance.toJSON());
      return result.upsertedId;
    };
  }

  @Query(type => String)
  static saveRawPage(@Arg('instance', type => DubniumPage) instance: DubniumPage) {
    return async (): Promise<string> => {
      await checkPermission({ context, permission: 'Designer.Edit' });

      const collection = await getRawCollection<DubniumPage>(DubniumPage._collectionName);
      const result = await collection.replaceOne({ _id: instance._id }, instance.toJSON());
      return result.upsertedId;
    };
  }

  @Mutation(type => String)
  static removePage(@Arg('pageID', type => String) pageID: string) {
    return async (): Promise<void> => {
      await checkPermission({ context, permission: 'Designer.Edit' });

      const collection = await getCollection<DubniumPage>(DubniumPage._class);
      await collection.deleteOne({ _id: pageID });

      const children = await this.getDirectChildrenOfPage(pageID)({ _id: 1 });
      for (const child of children) {
        await this.removePage(child._id)();
      }
    };
  }

  @Query(type => [DubniumPage])
  static getAllPages() {
    return async (gqlFields: BaseQLSelectionSet<DubniumPage>): Promise<DubniumPage[]> => {
      await checkPermission({ context, permission: 'Designer.View' });

      const collection = await getCollection(DubniumPage._class);
      const result = await collection.find<DubniumPage>({}).toArray();
      return result;
    };
  }

  @Query(type => [DubniumPage])
  static getDirectChildrenOfPage(@Arg('pageID', type => String) pageID: string) {
    return async (gqlFields: BaseQLSelectionSet<DubniumPage>): Promise<DubniumPage[]> => {
      await checkPermission({ context, permission: 'Designer.View' });

      const collection = await getCollection(DubniumPage._class);
      const result = await collection.find<DubniumPage>({ parentPageID: pageID }).toArray();
      return result;
    };
  }

  @Query(type => DubniumPage)
  static deletePageByID(@Arg('id', type => String) id: string) {
    return async (): Promise<void> => {
      await checkPermission({ context, permission: 'Designer.Edit' });

      const collection = await getCollection(DubniumPage._class);
      await collection.deleteOne({ _id: id });
    };
  }

  @Query(type => DubniumPage)
  static getActivePageByPath(@Arg('path', type => String) path: string) {
    return async (gqlFields: BaseQLSelectionSet<DubniumPage>): Promise<DubniumPage> => {
      const collection = await getCollection(DubniumPage._class);
      const result = await collection.findOne<DubniumPage>({ path });
      if (!result) return null;
      const user = await CurrentUserInfo.getMyself<LASUser>()({
        _id: 1,
        email: 1,
        roles: AdornisRoleToContextsWrapper.allFields,
      });
      if (user?.hasRole(UserRoles.DESIGNER) || user?.hasRole(UserRoles.SUPER_ADMIN)) return result;

      const publishment = await DubniumPagePublished.getPublishmentByPage(result._id)({ _id: 1 });
      if (!publishment) return null;
      return result;
    };
  }

  @Query(type => DubniumPage)
  static getPageByPath(
    @Arg('path', type => String) path: string,
    @Arg('isProduct', type => Boolean) isProduct: boolean = false,
  ) {
    return async (gqlFields: BaseQLSelectionSet<DubniumPage>): Promise<DubniumPage> => {
      const collection = await getCollection(DubniumPage._class);
      const query = { path };
      if (isProduct) query['productIDs'] = { $nin: ['', null] };
      const result = await collection.findOne<DubniumPage>(query);
      return result;
    };
  }

  @Query(type => DubniumPage)
  static getRootPageOfSubpage(@Arg('pageID', type => String) pageID: string) {
    return async (gqlFields: BaseQLSelectionSet<DubniumPage>): Promise<DubniumPage> => {
      const collection = await getCollection(DubniumPage._class);

      const page = await collection.findOne<DubniumPage>({ _id: pageID });
      if (!page) throw new Error(`404: page for id '${pageID}' not found.`);
      // if no parentID is given, then we know that this page is on root level
      if (!page.parentPageID) return page;
      // if we are not on root level, go recursivly deeper
      return this.getRootPageOfSubpage(page.parentPageID)(gqlFields);
    };
  }

  @Query(type => String)
  static getPathToProduct(@Arg('productID', type => String) productID: string) {
    return async (): Promise<string> => {
      const collection = await getCollection(DubniumPage._class);
      const page = await collection.findOne<DubniumPage>({ productIDs: productID });
      if (!page) throw new Error('Dieses Produkt hat noch keine Content-Page definiert');
      const publishment = await DubniumPagePublished.getPublishmentByPage(page._id)({ _id: 1 });
      if (!publishment) throw new Error('page not published yet');
      return `${PathToCampusRoute(CampusRoute.CONTENT)}${page.path}`;
    };
  }

  @Query(type => [DubniumPage])
  static getPagesByProductPage(@Arg('productPageID', type => String) productPageID: string) {
    return async (gqlFields: BaseQLSelectionSet<DubniumPage>): Promise<DubniumPage[]> => {
      if (context.serverContext) return [];
      const user = await CurrentUserInfo.getMyself<LASUser>()({
        _id: 1,
        roles: AdornisRoleToContextsWrapper.allFields,
      });
      const collection = await getRawCollection<DubniumPage>(DubniumPage._collectionName);
      const productPage = await collection.findOne<DubniumPage>({ _id: productPageID });

      if (!productPage || !productPage.productIDs || productPage.productIDs.length === 0)
        throw new Error('product page not found');

      let hasPermission = false;
      for (const productID of productPage.productIDs) {
        try {
          await checkPermissionOrProductOwning({ context, permission: BuildifyForceViewPermission, productID });
          hasPermission = true;
        } catch {}
      }

      if (!hasPermission) throw new Error('no permission for this page');

      const hasForcedPermission = user && user.hasPermission(BuildifyForceViewPermission);

      const result = await collection
        .aggregate<DubniumPage>([
          {
            $graphLookup: {
              from: DubniumPage._collectionName,
              startWith: '$_id',
              connectFromField: '_id',
              connectToField: 'parentPageID',
              as: 'children',
            },
          },
          {
            $match: {
              _id: productPageID,
            },
          },
          {
            $unwind: '$children',
          },
          // ==================================== START preview check ================================
          // wenn preview, dann muss nicht geprüft werden ob der Content bereits veröffentlicht wurde
          ...(hasForcedPermission
            ? []
            : [
                {
                  $lookup: {
                    from: DubniumPagePublished._collectionName,
                    foreignField: 'pageID',
                    localField: 'children._id',
                    as: 'children.publishment',
                  },
                },
                {
                  $unwind: '$children.publishment',
                },
                {
                  $match: {
                    'children.publishment': { $ne: null },
                  },
                },
              ]),
          // ------------------------------------- ENDE preview check --------------------------------
          {
            $replaceRoot: {
              newRoot: '$children',
            },
          },
        ])
        .toArray();

      return result;
    };
  }

  @Query(type => DubniumPage)
  static getProductPageByURL(@Arg('path', type => String) path: string) {
    return async (gqlFields: BaseQLSelectionSet<DubniumPage>): Promise<DubniumPage> => {
      const user = await CurrentUserInfo.getMyself<LASUser>()({
        _id: 1,
        roles: AdornisRoleToContextsWrapper.allFields,
      });
      if (!user) throw new Error("no user found in context by requesting 'getProductPageByURL'");

      // Die Preview zeigt jeglichen Content, auch den unveröffentlichten.
      // Deshalb ist wichtig, dass nur Super-Admins darauf zugreifen können.
      const hasForcedPermission = user.hasPermission(BuildifyForceViewPermission);

      const collection = await getCollection(DubniumPage._class);
      const page = await collection.findOne<DubniumPage>({
        path,
        productIDs: {
          $exists: true,
          $ne: [],
        },
      });

      if (!page) throw new Error('no page found');

      // wenn es sich um die Preview handelt, sollen auch unveröffentlichte Seiten gezeigt werden.
      // Deshalb wird sie hier schon zurückgegeben
      if (hasForcedPermission) return page;

      // prüfen, ob die Seite bereits veröffentlicht wurde.
      // Wenn nicht -> Error
      const publishment = await DubniumPagePublished.getPublishmentByPage(page._id)({ _id: 1 });
      if (!publishment) throw new Error('page not published yet');
      if (!page.productIDs) throw new Error('how did you get here');

      if (
        user.roles.findIndex(
          role => role.name === UserRoles.PARTICIPANT && page.productIDs?.some(id => role.contexts.includes(id)),
        ) === -1 &&
        user.roles.findIndex(role => role.name === UserRoles.SUPER_ADMIN) === -1
      ) {
        throw new Error('no permission for this product page');
      }

      return page;
    };
  }

  @Query(type => DubniumPage)
  static getPageByProductPage(
    @Arg('productPageID', type => String) productPageID: string,
    @Arg('path', type => String) path: string,
  ) {
    return async (gqlFields: BaseQLSelectionSet<DubniumPage>): Promise<Maybe<DubniumPage>> => {
      if (context.serverContext) return null;
      const user = await CurrentUserInfo.getMyself<LASUser>()({
        _id: 1,
        roles: AdornisRoleToContextsWrapper.allFields,
      });
      const hasForcedPermission = user && user.hasPermission(BuildifyForceViewPermission);
      const collection = await getRawCollection(DubniumPage._collectionName);
      const result = await collection
        .aggregate<DubniumPage>([
          {
            $graphLookup: {
              from: DubniumPage._collectionName,
              startWith: '$_id',
              connectFromField: '_id',
              connectToField: 'parentPageID',
              as: 'children',
            },
          },
          {
            $match: {
              _id: productPageID,
            },
          },
          {
            $unwind: '$children',
          },
          // ==================================== START preview check ================================
          // wenn preview, dann muss nicht geprüft werden ob der Content bereits veröffentlicht wurde
          ...(hasForcedPermission
            ? [
                {
                  $match: { 'children.path': path },
                },
              ]
            : [
                {
                  $lookup: {
                    from: DubniumPagePublished._collectionName,
                    foreignField: 'pageID',
                    localField: 'children._id',
                    as: 'children.publishment',
                  },
                },
                {
                  $unwind: '$children.publishment',
                },
                {
                  $match: {
                    'children.path': path,
                    'children.publishment': { $ne: null },
                  },
                },
              ]),
          // ------------------------------------- ENDE preview check --------------------------------
          {
            $replaceRoot: {
              newRoot: '$children',
            },
          },
        ])
        .toArray();

      if (result.length > 1)
        throw new Error(`multiple possible matches found for productID ${productPageID} and subpage path ${path}`);

      return result[0];
    };
  }
}
