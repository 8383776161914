import type { Styles } from '@adornis/ass/style';
import type { Maybe } from '@adornis/base/utilTypes';
import {
  ContainerGridExtension,
  XContainerGridExtension,
} from '@adornis/buildify/client/extensions/ContainerGridExtension';
import { Size } from '@adornis/buildify/client/globals/enums';
import { __renderBase } from '@adornis/buildify/client/globals/methods';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import '@adornis/buildify/client/x-buildify-dropzone';
import type { Base } from '@adornis/buildify/db/Base';
import { BaseContainer } from '@adornis/buildify/db/extensions/BaseContainer';
import { css } from '@adornis/chemistry/directives/css';
import type { Renderable } from '@adornis/chemistry/renderable';
import { goTo } from '@adornis/router/client/open-href';
import { DubniumBaseContainerGrid } from 'db/buildify/container/DubniumBaseContainerGrid';
import { html, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import { customContainerEditor } from '../helpers';

export const BuildifyCampusContainerGridExtension: BuildifyExtension<DubniumBaseContainerGrid> = {
  class: DubniumBaseContainerGrid,
  toolbar: { icon: 'grid', text: { default: 'Grid' } },
  render(instance, { defaultValues, isSubelement, dropzoneBefore, dropzoneAfter }) {
    return html`
      <campus-container-grid-extension
        .content=${instance}
        .defaultValues=${defaultValues}
        ?sub=${isSubelement}
        .hasDropzoneBefore=${dropzoneBefore}
        .hasDropzoneAfter=${dropzoneAfter}
      ></campus-container-grid-extension>
    `;
  },
  editor(options) {
    return html`
      <d-flex space="sm">
        <!-- default -->
        ${ContainerGridExtension.editor?.(options)}
        <!-- custom -->
        ${customContainerEditor(options)}
      </d-flex>
    `;
  },
  group: 'Layout',
};

@customElement('campus-container-grid-extension')
export class CampusContainerGridExtension extends XContainerGridExtension {
  override connectedCallback(): void {
    super.connectedCallback();

    this.addEventListener('click', () => {
      const content = this.content.value as Maybe<DubniumBaseContainerGrid>;
      if (!content) return;
      const link = content?.link;
      if (!link || !link.resolvedLink) return;
      if (this.isEditMode) return;
      goTo(link.resolvedLink, { target: link.target || '_self' });
    });
  }

  protected override _content(content: DubniumBaseContainerGrid): any {
    return html` ${super._content(content)} ${this.__leftLine()} `;
  }

  protected override renderChild({
    extensions,
    content,
  }: {
    extensions: BuildifyExtension[];
    content: Base;
  }): Renderable | undefined {
    const isContainer = content instanceof BaseContainer;
    const defaultValues = isContainer ? { margin: { global: '0' } } : {};
    return __renderBase(extensions, content, { defaultValues });
  }

  protected __leftLine() {
    if (!this.getValue('hasLineLeft')) return nothing;
    return html`
      <d-flex
        ${css({
          background: this.defaultValue('lineLeftColor', { global: this.colors.accent }),
          position: 'absolute',
          top: '1px',
          left: '0',
          height: 'calc(100% - 2px)',
          width: this.defaultValue('lineLeftWidth', { global: '3px' }),
          borderRadius: this.sizes.borderRadius,
          zIndex: '20',
        })}
      ></d-flex>
    `;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          rowGap: this.defaultValue('rowGap', { global: '20px', [Size.MOBILE]: '16px' }),
          columnGap: this.defaultValue('columnGap', { global: '24px', [Size.MOBILE]: '20px' }),
        },
      },
    ] as Styles[];
  }
}
