import type { Maybe, ValueEvent } from '@adornis/base/utilTypes';
import { css } from '@adornis/chemistry/directives/css.js';
import type { DialogOptions } from '@adornis/dialog/x-plain-dialog';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { DubniumDialog } from '../../../../client/theme/components/d-dialog';
import '../../components/las-page-explorer';

@customElement('las-prompt-choose-page')
export class LASPromptChoosePage extends DubniumDialog<Maybe<string>> {
  @property({ type: String }) selectedPageID: Maybe<string>;

  override content() {
    return html`
      <las-page-explorer
        selectable
        .selectedPageID=${this.selectedPageID}
        @page-changed=${(e: ValueEvent<Maybe<string>>) => {
          this.selectedPageID = e.detail.value;
        }}
      ></las-page-explorer>
      <d-flex horizontal crossaxis-center space-between ${css({ marginTop: this.spacing.md })}>
        <d-button mode="outline" @click=${() => this.cancel()}> Abbrechen </d-button>
        <d-button ?disabled=${!this.selectedPageID} @click=${() => this.close(this.selectedPageID)}>
          Speichern
        </d-button>
      </d-flex>
    `;
  }

  override async cancel() {
    return super.close(this.selectedPageID);
  }

  override getInnerWrapper(options: Required<DialogOptions>): HTMLDivElement {
    const wrapper = super.getInnerWrapper(options);
    // this.innerWrapper = wrapper;
    wrapper.style.setProperty('width', 'min(800px, 95%)');
    return wrapper;
  }

  static override get element_name() {
    return 'las-prompt-choose-page';
  }
}
