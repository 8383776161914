import { registerQuery } from '@adornis/baseql/metadata/register';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { PRODUCT_BIMAP, PRODUCT_ZOHO_FIELDS, Product } from '../../../db/Product';
import { ZohoModule } from '../../../db/enumns/zoho';
import { ProductNotActiveError } from '../../../errors/ProductNotActiveError';
import { makeZohoAPIRequest } from '../../../server/zoho/api';
import { activeProductCriteriaSQL } from '../helpers/activeProductCriteriaSQL';

//* Resolver
const getProductByNameResolver = (name: string, activeCheck: boolean = false) => {
  return async (gqlFields: BaseQLSelectionSet<Product>) => {
    const endpoint = `coql`;

    const selectionField = Product.gqlFieldsZoho(gqlFields, PRODUCT_BIMAP);

    const query = `SELECT ${selectionField.join(',')} FROM Products WHERE (${PRODUCT_ZOHO_FIELDS.NAME} = '${name}' ${
      activeCheck ? `AND ${activeProductCriteriaSQL()}` : ''
    } )`;

    const result = await makeZohoAPIRequest({
      method: 'post',
      endpoint,
      data: { select_query: query },
      zohoModule: ZohoModule.PRODUCTS,
      isRawRequest: true,
    });
    if (!result?.data?.[0]) {
      if (activeCheck) throw new ProductNotActiveError();
      return null;
    }

    const resultData = result.data[0];
    const deserializedContact = Product.deserializeZoho(resultData);
    return deserializedContact;
  };
};

//* Query
export const getProductByName = registerQuery({
  type: () => Product,
  operationName: 'getProductByName',
  resolve: getProductByNameResolver,
  params: [
    { type: () => String, name: 'name' },
    { type: () => Boolean, name: 'activeCheck' },
  ],
});
