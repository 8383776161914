import { Size } from '@adornis/buildify/client/globals/enums';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import { DubniumBaseGridContainerThree } from 'db/buildify/container/DubniumBaseGridContainerThree';
import { BuildifyCampusContainerGridExtension } from './BuildifyCampusContainerGridExtension';

export const BuildifyCampusContainerGridThreeExtension: BuildifyExtension<DubniumBaseGridContainerThree> = {
  class: DubniumBaseGridContainerThree,
  toolbar: { icon: 'grid', text: { default: 'Grid 3' } },
  render(instance, { defaultValues, isSubelement, dropzoneBefore, dropzoneAfter }) {
    return BuildifyCampusContainerGridExtension.render(instance, {
      defaultValues: {
        ...(defaultValues ? defaultValues : {}),
        columns: { global: 3, [Size.TABLET]: 2, [Size.MOBILE]: 1 },
        rows: { [Size.DESKTOP]: 1, [Size.TABLET]: 2, [Size.MOBILE]: 3 },
      },
      isSubelement,
      dropzoneBefore,
      dropzoneAfter,
    });
  },
  editor: BuildifyCampusContainerGridExtension.editor,
  group: 'Layout',
};
