import type { Maybe } from '@adornis/base/utilTypes';

/**
 * Validates the existence of a value and throws an error if it's null or undefined.
 * @template T
 * @param {Maybe<T> | T} val - The value to be checked.
 * @param {string} [errorMessage] - Optional custom error message to throw if the value is null or undefined.
 * @throws {Error} Throws an error if the value is null or undefined.
 * @returns {T} Returns the validated value if it's not null or undefined.
 */
export function assertExisting<T>(val: Maybe<T> | T, errorMessage?: string) {
  if (val === null || val === undefined) {
    throw new Error(errorMessage ?? 'value was null or undefined');
  }
  return val;
}

/**
 * Returns the only element of the array and throws if there are not exactly one.
 * @template T
 * @param {T[]} array - The value to be checked.
 * @param {string} [errorMessage] - Optional custom error message to throw if the value is null or undefined.
 * @throws {Error} Throws an error if the value is null or undefined.
 * @returns {T} Returns the only value.
 */
export function assertOneAndReturn<T>(array: T[], errorMessage?: string): T {
  if (array.length !== 1 || !array[0]) throw new Error(errorMessage ?? 'array does not have exactly one entry');
  return array[0];
}

/**
 * Returns the only element of the array and throws if there are not exactly one.
 * @template T
 * @param {T[]} array - The value to be checked.
 * @param {string} [errorMessage] - Optional custom error message to throw if the value is null or undefined.
 * @throws {Error} Throws an error if the value is null or undefined.
 * @returns {T} Returns the only value.
 */
export function assertOneOrZeroAndReturn<T>(array: T[], errorMessage?: string): Maybe<T> {
  if (array.length !== 1 && array.length !== 0)
    throw new Error(errorMessage ?? 'array does not have one or no entries');
  return array[0];
}
