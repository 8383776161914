import type { Maybe } from '@adornis/base/utilTypes';
import { Arg, Entity, Field, Query } from '@adornis/baseql/decorators';
import { MongoEntity } from '@adornis/baseql/entities/mongoEntity';
import { getCollection } from '@adornis/baseql/server/collections';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { validate } from '@adornis/validation/decorators';
import { nonOptional } from '@adornis/validation/functions/nonOptional';
import type { Product } from '../../db/Product';
import { FormularType } from './enums';

export const getFormularType = ({
  product,
  definition,
}: {
  product: Product;
  definition?: Maybe<FormularDefinition>;
}) => {
  if (definition?.type) return definition.type;
  return FormularType.AUTOMATIC;
};

@Entity()
export class FormularDefinition extends MongoEntity {
  static override _class = 'FormularDefinition';
  static override _collectionName = 'formular-definition';

  @validate(nonOptional())
  @Field(type => String)
  productID!: string;

  @validate(nonOptional())
  @Field(type => String)
  type!: FormularType;

  @validate(nonOptional({ allowFalsy: true }))
  @Field(type => Boolean, { default: v => v ?? false })
  isWebinar!: boolean;

  @validate(nonOptional({ allowFalsy: true }))
  @Field(type => Boolean, { default: v => v ?? false })
  isNewsletter!: boolean;

  @validate(nonOptional({ allowFalsy: true }))
  @Field(type => Boolean, { default: v => v ?? false })
  hideAttendencyRequired!: boolean;

  @validate(nonOptional({ allowFalsy: true }))
  @Field(type => Boolean, { default: v => v ?? false })
  hidePrivacy!: boolean;

  @Field(type => [String])
  communicationCampaigns?: Maybe<string[]>;

  @Field(type => String)
  redirectUrl?: Maybe<string>;

  @Query(type => FormularDefinition)
  static getFormularDefintionByProductID(@Arg('productID', type => String) productID: string) {
    return async (gqlFields: BaseQLSelectionSet<FormularDefinition>) => {
      const collection = await getCollection<FormularDefinition>(FormularDefinition._class);
      const result = await collection.findOne<FormularDefinition>({ productID });
      return result;
    };
  }
}
