import type { Maybe } from '@adornis/base/utilTypes';
import { Int } from '@adornis/baseql/baseqlTypes';
import { Entity, Field } from '@adornis/baseql/decorators';
import { DubniumBaseContainer } from '../DubniumBaseContainer';

@Entity()
export class DubniumBaseContainerGrid extends DubniumBaseContainer {
  static override _class = 'DubniumBaseContainerGrid';

  @Field(type => Int)
  columns: Maybe<number>;

  @Field(type => Int)
  rows: Maybe<number>;

  @Field(type => String)
  gridRowGap: Maybe<string>;

  @Field(type => String)
  gridColumnGap: Maybe<string>;
}
