import type { Maybe } from '@adornis/base/utilTypes';
import { Entity, Field } from '@adornis/baseql/decorators';
import { DubniumBaseContainer } from '../DubniumBaseContainer';

@Entity()
export class DubniumBaseContainerFlex extends DubniumBaseContainer {
  static override _class = 'DubniumBaseContainerFlex';

  @Field(type => String)
  flexDirection: Maybe<string>;

  @Field(type => String)
  rowGap: Maybe<string>;

  @Field(type => String)
  columnGap: Maybe<string>;

  @Field(type => String)
  justifyContent: Maybe<string>;

  @Field(type => String)
  alignItems: Maybe<string>;
}
