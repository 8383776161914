import { ChemistryLitElement } from '@adornis/chemistry/chemistry-lit-element';
import { css } from '@adornis/chemistry/directives/css.js';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import '../../../client/components/las-shimmer';

@customElement('las-form-support-loading')
export class LASFormSupportLoading extends ChemistryLitElement {
  override render() {
    return html`
      <d-flex space="md" ${css({ position: 'relative' })}>
        <d-flex horizontal space="md" ${css({ justifyContent: 'space-evenly', marginBottom: this.spacing.lg })}>
          <las-shimmer height="56px"></las-shimmer>
        </d-flex>

        <d-flex>
          <d-flex horizontal crossaxis-center space="xxs">
            <las-shimmer-letter></las-shimmer-letter>
            <las-shimmer-letter></las-shimmer-letter>
            <las-shimmer-letter></las-shimmer-letter>
            <las-shimmer-letter></las-shimmer-letter>
            &nbsp;
            <las-shimmer-letter></las-shimmer-letter>
            <las-shimmer-letter></las-shimmer-letter>
            <las-shimmer-letter></las-shimmer-letter>
            <las-shimmer-letter></las-shimmer-letter>
            <las-shimmer-letter></las-shimmer-letter>
            <las-shimmer-letter></las-shimmer-letter>
            <las-shimmer-letter></las-shimmer-letter>
            <las-shimmer-letter></las-shimmer-letter>
            <las-shimmer-letter></las-shimmer-letter>
            &nbsp;
            <las-shimmer-letter></las-shimmer-letter>
            <las-shimmer-letter></las-shimmer-letter>
            <las-shimmer-letter></las-shimmer-letter>
          </d-flex>
          <las-shimmer height="46px"></las-shimmer>
        </d-flex>

        <d-flex horizontal end>
          <las-shimmer height="44px" width="200px"></las-shimmer>
        </d-flex>
      </d-flex>
    `;
  }
}
