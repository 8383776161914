import type { Maybe, ValueEvent } from '@adornis/base/utilTypes';
import { XBuildifyGlobalSettingsFormField } from '@adornis/buildify/client/components/x-buildify-global-settings-formfield';
import { ExtensionsConsumer } from '@adornis/buildify/client/globals/consumer';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import { RenderableConfigFormController } from '@adornis/buildify/client/RenderableConfigFormController';
import { ChemistryLitElement } from '@adornis/chemistry/chemistry-lit-element';
import { XStack } from '@adornis/chemistry/elements/components/x-stack';
import { html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { BuildifyGlobalSettingTagsDefinition } from '../../../db/buildify/DigitaleHeldenBuildifyGlobalSettings';
import { DubniumBaseTag } from '../../../db/buildify/DubniumBaseTag';

@customElement('d-buildify-global-settings-tag-templates')
export class DBuildifyGlobalSettingsTagTemplates extends XBuildifyGlobalSettingsFormField<
  Maybe<BuildifyGlobalSettingTagsDefinition[]>
> {
  protected readonly _consumedExtensions = ExtensionsConsumer(this);

  override render() {
    console.log('kommen wir hier rein? 1');
    const extensions = this._consumedExtensions.value;
    if (!extensions) return nothing;
    const tagExtension = extensions.find(ext => ext.class._class === DubniumBaseTag._class);
    if (!tagExtension) return;

    console.log('kommen wir hier rein?');

    return html`
      <d-flex space="md">
        ${repeat(
          this.value.value ?? [],
          tagDefinition => tagDefinition._id,
          (tagDefinition, index) => html`
            <d-flex space="md" horizontal crossaxis-center>
              <x-button
                flex
                @click=${e => {
                  XStack.addItem(e.target, {
                    heading: `${index + 1}. Tags`,
                    render: () =>
                      html`
                        <d-buildify-tag-editor
                          .tagDefinition=${tagDefinition}
                          .tagExtension=${tagExtension}
                        ></d-buildify-tag-editor>
                      `,
                  });
                }}
              >
                ${index + 1}. Tags
              </x-button>
              <d-icon-button
                icon="trash"
                background=${this.colors.tone.warning}
                @click=${e => {
                  const currentArray = this.value.value ?? [];
                  currentArray.splice(index, 1);
                  this.value.next(currentArray);
                  this.dispatchEvent(new CustomEvent('value-picked', { detail: { value: this.value.value } }));
                }}
              ></d-icon-button>
            </d-flex>
          `,
        )}
        <d-button
          mode="outline"
          @click=${() => {
            const currentArray = this.value.value ?? [];
            currentArray.push(new BuildifyGlobalSettingTagsDefinition({ tag: new DubniumBaseTag({}) }));
            this.value.next(currentArray);
            this.dispatchEvent(new CustomEvent('value-picked', { detail: { value: this.value.value } }));
          }}
        >
          Neues Tag hinzufügen
        </d-button>
      </d-flex>
    `;
  }
}

@customElement('d-buildify-tag-editor')
export class DBuildifyTagEditor extends ChemistryLitElement {
  @property({ attribute: false }) tagDefinition!: BuildifyGlobalSettingTagsDefinition;
  @property({ attribute: false }) tagExtension!: BuildifyExtension;

  override render() {
    if (!this.tagDefinition || !this.tagExtension) return nothing;

    return html`
      <d-flex space="sm">
        <d-input
          placeholder="Bezeichnung"
          .value=${this.tagDefinition.name}
          @value-picked=${(e: ValueEvent<string>) => {
            this.tagDefinition.name = e.detail.value;
          }}
        ></d-input>
        ${this.tagExtension.editor?.(
          {
            host: this,
            content: this.tagDefinition.tag,
            contentController: new RenderableConfigFormController(this, this.tagDefinition.tag, undefined),
            controllerBaseKeyPath: [],
          },
          true,
        )}
      </d-flex>
    `;
  }
}
