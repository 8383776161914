import type { Styles } from '@adornis/ass/style';
import { ChemistryLitElement } from '@adornis/chemistry/chemistry-lit-element';
import type { AdornisTiptapExtension } from '@adornis/tiptap/client/extensions/ExtensionBase';
import { mergeAttributes } from '@tiptap/core';
import { Superscript } from '@tiptap/extension-superscript';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('d-sup')
export class DSuperscript extends ChemistryLitElement {
  override render() {
    return html`<sup><slot></slot></sup>`;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          fontSize: 'inherit',
          lineHeight: 'inherit',
          fontFamily: 'inherit',
        },
        sup: {
          position: 'relative',
          top: '-5px',
          verticalAlign: 'unset',
        },
      },
    ] as Styles[];
  }
}

const CustomSuperscript = Superscript.extend({
  renderHTML({ HTMLAttributes }) {
    return ['d-sup', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
  addAttributes() {
    return {};
  },
});

export const SuperscriptExtension: AdornisTiptapExtension = {
  buttons: ({ editor }) => {
    const result = [
      {
        name: 'insertHTML',
        icon: 'superscript',
        action: async () => {
          editor.commands.toggleSuperscript();
        },
      },
    ];
    return result;
  },
  extensions: [CustomSuperscript],
};
