import { darken } from '@adornis/chemistry/elements/theming/color-shades';
import { Color, ColorScheme } from '@adornis/chemistry/elements/theming/coloring-system';
import { DesignSystem } from '@adornis/chemistry/elements/theming/design';
import { type SpacingType } from '@adornis/chemistry/elements/theming/spacings';

export const colors = {
  primary: '#AFCA0B',
  neutral: '#D9D8D7',
  warning: '#ffc427',
  neutralBase: '#F0F0F0',
  neutralAlabster: '#F7F7F7',
  neutralSnow: '#F3F3F3',
  emergency: '#E21E36',
  emergencyLight: '#e84b5e',
  emergencyGrey: '#E7EBED',
  dark_grey: '#757575',
  dark_green: '#95ac0c',
  secondary_text: '#bbbbb4',
} as const;

export const primaryShades = {
  brightness10: '#f8faed',
  brightness20: '#f1f5db',
  brightness30: '#e9efc6',
  brightness40: '#e2eab1',
  brightness50: '#dae49b',
  brightness60: '#d3df84',
  brightness70: '#cada6b',
  brightness80: '#c2d450',
  brightness90: '#b9cf35',
  brightness110: '#95ac0c',
  brightness120: '#95ad0d',
  brightness130: '#879d0c',
  brightness140: '#798e0b',
  brightness150: '#6a7d0a',
} as const;

export const buttonArrowTones = {
  success: colors.primary,
  error: darken(colors.warning, 1),
  warning: darken(colors.warning, 1),
  subtle: primaryShades.brightness120,
  neutral: colors.neutralBase,
  whitePrimary: Color.black,
} as const;

export const buttonBackgroundTones = {
  success: primaryShades.brightness80,
  error: colors.warning,
  warning: colors.warning,
  subtle: primaryShades.brightness150,
  whitePrimary: Color.white,
  test: colors.neutral,
} as const;

export const customDesignSizes = {
  maxPageWidth: '75rem',
  borderRadiusSecondary: '4px',
};

const defaultDesign = DesignSystem.currentTheme.getValue();
DesignSystem.prefix = 'd';
DesignSystem.setTheme('dubnium', {
  defaults: {
    // @ts-expect-error placeholder-mode type
    placeholderMode: 'static-floating',
  },
  baseFontSize: 16,
  baseFontSizePrint: 16,
  borders: defaultDesign.borders,
  colors: new ColorScheme({
    accent: colors.primary,
    primary: colors.primary,
    neutral: colors.neutral,
    secondary: colors.neutral,

    tone: {
      neutral: colors.neutral,
      neutralBase: colors.neutralBase,
      neutralAlabster: colors.neutralAlabster,
      neutralSnow: colors.neutralSnow,

      placeholder: Color.make(colors.neutral).shade(500),

      emergency: colors.emergency,
      emergencyLight: colors.emergencyLight,
      emergencyGrey: colors.emergencyGrey,

      success: colors.primary,
      successFaded: primaryShades.brightness80,
      error: colors.warning,
      warning: colors.warning,
      subtle: primaryShades.brightness140,
      whitePrimary: '#fff',
      whiteInput: '#fafafa',

      primaryFaded: primaryShades.brightness80,
      secondaryFaded: primaryShades.brightness80,

      // accent color shades
      primaryShade10: primaryShades.brightness10,
      primaryShade20: primaryShades.brightness20,
      primaryShade30: primaryShades.brightness30,
      primaryShade40: primaryShades.brightness40,
      primaryShade50: primaryShades.brightness50,
      primaryShade60: primaryShades.brightness60,
      primaryShade70: primaryShades.brightness70,
      primaryShade80: primaryShades.brightness80,
      primaryShade90: primaryShades.brightness90,
      primaryShade110: primaryShades.brightness110,
      primaryShade120: primaryShades.brightness120,
      primaryShade130: primaryShades.brightness130,
      primaryShade140: primaryShades.brightness140,
      primaryShade150: primaryShades.brightness150,

      // grey tones
      darkGrey: colors.dark_grey,
      darkGreen: colors.dark_green,
      secondaryText: colors.secondary_text,

      black: '#333333',
      lightblack: '#3f3f3f',
      darkblack: '#0d0d0d',
      tooltipBackground: '#333',

      infoText: '#008482',
      infoBackground: '#cce6e6',
    },
  }),
  shadows: defaultDesign.shadows,
  sizes: { ...defaultDesign.sizes, ...customDesignSizes },
  fontFamily: 'Netto W01 Regular',
  fontFamilyHeading: 'NettoOT-Black',
  spacing: { ...defaultDesign.spacing, md: '16px' as SpacingType },
});
DesignSystem.useTheme('dubnium');
