import type { Styles } from '@adornis/ass/style';
import { DesignSystem } from '@adornis/chemistry/elements/theming/design';
import { setGlobalStyle } from '@adornis/chemistry/elements/theming/global-style';
import { fontReset } from '@adornis/fonts/font-resets';
import { XText } from '@adornis/fonts/fonts';
import { customElement } from 'lit/decorators.js';
import { colors } from '../configuration';
import '../fonts';

setGlobalStyle(() => ({
  '::selection': {
    color: '#fff',
    background: DesignSystem.currentTheme.getValue().colors.accent,
  },
  '.tippy-arrow': {
    color: `${colors.darkGrey} !important`,
  },
  'body, :host': { fontFamily: 'Netto W01 Regular', fontSize: `${1.0625 * 16}px`, lineHeight: '1.4' }, // default font for whole page
  '@media print, screen and (min-width: 21.5625em)': {
    body: {
      fontSize: '1.0625rem',
    },
  },
  body: {
    fontSize: '1.0625rem',
  },
  '@media print, screen and (min-width: 40em)': {
    body: {
      fontSize: '1.25rem',
    },
  },
  '@media print, screen and (min-width: 64em)': {
    body: {
      fontSize: '1.25rem',
    },
  },
  '@media screen and (min-width: 75em)': {
    body: {
      fontSize: '1.25rem',
    },
  },
  '@media screen and (min-width: 90em)': {
    body: {
      fontSize: '1.375rem',
    },
  },
}));

// addFontData('Netto W01 Regular', {
//   googleFont: false,
//   capHeightRatio: 0.68,
//   descenderHeightRatio: 0.125,
// });

// addFontData('NettoOT-Black', {
//   googleFont: false,
//   capHeightRatio: 0.73,
//   descenderHeightRatio: 0.085,
// });

@customElement('d-text')
export class DText extends XText {
  override styles() {
    return [
      fontReset('Netto W01 Regular', 1.0625, 400, 1.4, 16),
      {
        fontFamily: 'Netto W01 Regular',
        fontSize: '16px',

        '&[paragraph]': { marginBlock: '1em' },

        '&[inline]': { display: 'inline-block' },
        '&[grey]': { color: this.colors.tone.secondaryText },
        '&[neutral]': { color: this.colors.neutral },
        '&[black]': { color: this.colors.tone.tooltipBackground },
        '&[white]': { color: '#fff' },
        '&[italic]': { fontStyle: 'italic' },
        '&[tone=subtle]': { color: this.colors.tone.subtle },
        '&[tone=success]': { color: this.colors.tone.success },
        '&[tone=warning]': { color: this.colors.tone.warning },
        '&[tone=error]': { color: this.colors.tone.error },
        '&[tone=placeholder]': { color: this.colors.tone.placeholder },
        '&[center]': { textAlign: 'center' },
        '&[tiny]': {
          fontSize: '10px',
          lineHeight: '13px',
        },
        '&[small]': {
          fontSize: '14px',
          lineHeight: '22px',
        },
        '&[large]': { fontSize: '20px' },
        '&[bold]': {
          fontFamily: 'NettoOT-Black !important',
        },
        '&[highlight]': { color: this.colors.accent },
      },
      {
        b: {
          fontFamily: 'NettoOT-Black !important',
        },
      },
    ] as Styles[];
  }
}
