import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import { html } from 'lit';
import { DubniumBaseSupportFormular } from '../../../../../db/extensions/_formulars/DubniumBaseSupportFormular';
import './campus-support-formular-extension';

export const BuildifyCampusSupportFormularExtension: BuildifyExtension = {
  class: DubniumBaseSupportFormular,
  toolbar: { icon: 'headset', text: { default: 'Supportformular' } },
  render(instance, { defaultValues, isSubelement }) {
    return html`
      <campus-support-formular-extension
        .content=${instance}
        .defaultValues=${defaultValues}
        ?sub=${isSubelement}
      ></campus-support-formular-extension>
    `;
  },
  editor({}) {
    return html` Hier kann nichts zusätzlich verändert werden. `;
  },
  group: 'Formulare',
};
