import type { Maybe } from '@adornis/base/utilTypes';
import { Entity, Field } from '@adornis/baseql/decorators';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity';
import { Salutation } from '@adornis/digitale-helden-shared/db/enums';
import { validate } from '@adornis/validation/decorators';
import { nonOptional } from '@adornis/validation/functions/nonOptional';

@Entity()
export class LASPaymentRecipient extends AdornisEntity {
  static override _class = 'LASPaymentRecipient';

  // name etc.

  @validate(nonOptional())
  @Field(type => String)
  salutation!: Salutation;

  @validate(nonOptional())
  @Field(type => String)
  firstName!: string;

  @validate(nonOptional())
  @Field(type => String)
  lastName!: string;

  @validate(nonOptional())
  @Field(type => String)
  email!: string;

  // adress

  @validate(nonOptional())
  @Field(type => String)
  street!: string;

  @validate(nonOptional())
  @Field(type => String)
  zip!: string;

  @validate(nonOptional())
  @Field(type => String)
  city!: string;

  @validate(nonOptional())
  @Field(type => String)
  state!: string;

  @validate(nonOptional())
  @Field(type => String)
  country!: string;

  // company
  @Field(type => String)
  companyName?: Maybe<string>;
}
