import { IconTextExtension } from '@adornis/buildify/client/extensions/IconTextExtension';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import { DubniumBaseIconText } from '../../../../db/extensions/DubniumBaseIconText';

export const BuildifyCampusIconTextExtension = ({
  textExtension,
  iconExtension,
}: {
  textExtension: BuildifyExtension;
  iconExtension: BuildifyExtension;
}): BuildifyExtension<DubniumBaseIconText> => ({
  class: DubniumBaseIconText,
  toolbar: { icon: 'spell-check', text: { default: 'Icon mit Text' } },
  render: IconTextExtension({ textExtension, iconExtension }).render,
  editor: IconTextExtension({ textExtension, iconExtension }).editor,
  group: 'Basics',
});
