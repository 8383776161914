import { registerQuery } from '@adornis/baseql/metadata/register';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { Order } from '../../../db/Order';
import { Product } from '../../../db/Product';
import { getProductsByIDs } from './getProductsByIDs';

//* Resolver
const getProductsForUserResolver = (zohoContactID: string) => {
  return async (gqlFields: BaseQLSelectionSet<Product>) => {
    const orders = await Order.getAllOrdersByContactId(zohoContactID)({
      id: 1,
      productId: 1,
      productConfiguration: gqlFields,
    });

    if (!orders.length) return [];

    let productIDs = Array.from(new Set(orders.map(order => order.productId)));
    productIDs = productIDs.filter(id => !!id);

    const products = await getProductsByIDs(productIDs, true)(gqlFields);
    return products;
  };
};

//* Query
export const getProductsForUser = registerQuery({
  type: () => [Product],
  operationName: 'getProductsForUser',
  resolve: getProductsForUserResolver,
  params: [{ type: () => String, name: 'zohoContactID' }],
});
