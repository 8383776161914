import type { Styles } from '@adornis/ass/style';
import { fontReset } from '@adornis/fonts/font-resets';
import { XH3 } from '@adornis/fonts/fonts';
import { customElement } from 'lit/decorators.js';

@customElement('d-h3')
export class DH3 extends XH3 {
  override styles() {
    return [
      fontReset('NettoOT-Black', 1.5, 400, 1.16, 16),
      {
        fontSize: '20px',
        '&[inline]': { display: 'inline-block' },
      },
    ] as Styles[];
  }
}
