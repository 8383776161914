import { registerMutation } from '@adornis/baseql/metadata/register';
import { getRawCollection } from '@adornis/baseql/server/collections';
import { context } from '@adornis/baseql/server/context';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { LASUser } from '@adornis/digitale-helden-shared/db/las-user';
import { Product } from '@adornis/digitale-helden-shared/db/product/Product';
import { upsertZohoRecord } from '@adornis/digitale-helden-shared/server/zoho/api';
import { Order } from '../../../db/Order';
import { OrderStatus } from '../../../db/enums';
import { ensureDigitaleHeldenRoles } from '../../../server/ensure/ensureDigitaleHeldenRoles';

//* Resolver
const upsertOrderResolver = (entity: Order) => {
  return async (gqlFields: BaseQLSelectionSet<Order>) => {
    if (entity.id) {
      const order = new Order({ ...entity.toFilteredJSON });
      await upsertZohoRecord(Order.ZOHO_MODULE, order);
      return order;
    }

    const existingOrder = await Order.getActiveOrderByBuyer(entity.buyerContactId, entity.productId)(Order.allFields);

    if (existingOrder) {
      const productConfiguration = new Product({
        ...existingOrder.productConfiguration.toFilteredJSON,
        ...entity.productConfiguration.toFilteredJSON,
      });

      entity = new Order({
        ...existingOrder.toFilteredJSON,
        ...entity.toFilteredJSON,
        status: OrderStatus.ACTIVE,
        productConfiguration,
      });
    }

    const zohoID = await upsertZohoRecord(Order.ZOHO_MODULE, entity);

    if (!context.serverContext && entity.buyerContactId) {
      const rawUserCollection = await getRawCollection<LASUser>(LASUser._collectionName);
      const user = await rawUserCollection.findOne<LASUser>({ zohoID: entity.buyerContactId });
      if (user) {
        await ensureDigitaleHeldenRoles(user._id);
      }
    }

    entity.id = zohoID;

    return entity;
  };
};

//* Query
export const upsertOrder = registerMutation({
  type: () => Order,
  operationName: 'upsertOrder',
  resolve: upsertOrderResolver,
  params: [{ type: () => Order, name: 'entity' }],
});
