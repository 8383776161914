import { Entity, Field } from '@adornis/baseql/decorators';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity';

@Entity()
export class UpsertContactOptions extends AdornisEntity {
  static override _class = 'UpsertContactOptions';

  @Field(type => Boolean, { default: v => v ?? false }) checkStatus!: boolean;
  @Field(type => Boolean, { default: v => v ?? false }) checkAcademyRole!: boolean;
  @Field(type => Boolean, { default: v => v ?? false }) checkOtherAcademyRole!: boolean;
  @Field(type => Boolean, { default: v => v ?? false }) checkCommunicationCampaigns!: boolean;
}
