import { registerMutation } from '@adornis/baseql/metadata/register';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { Contact } from '@adornis/digitale-helden-shared/db/Contact';
import { ContactEmailStatus } from '@adornis/digitale-helden-shared/db/enums';
import { UpsertContactOptions } from '@adornis/digitale-helden-shared/db/UpsertContactOptions';
import { getContactEmailStatusByEmail } from '../queries/getContactEmailStatusByEmail';
import { sendContactSOIMail } from './sendContactSOIMail';
import { upsertContact } from './upsertContact';

export const upsertContactAndCheckSOI = registerMutation({
  type: () => Contact,
  operationName: 'upsertContactAndCheckSOI',
  resolve: (instance: Contact, createAccount: boolean, options?: UpsertContactOptions) => {
    return async (gqlFields: BaseQLSelectionSet<Contact>) => {
      const emailStatus = await getContactEmailStatusByEmail(instance.email)();

      instance.emailStatus = emailStatus;
      instance = await upsertContact(instance, options)(Contact.allFields);

      if (!instance.emailStatus || instance.emailStatus === ContactEmailStatus.OPT_IN_PENDING) {
        await sendContactSOIMail(instance, createAccount)();
        instance.emailStatus = ContactEmailStatus.OPT_IN_PENDING;
      }

      instance = await upsertContact(instance, options)(Contact.allFields);
      return instance;
    };
  },
  params: [
    { name: 'instance', type: () => Contact },
    { name: 'createAccount', type: () => Boolean },
    { name: 'options', type: () => UpsertContactOptions },
  ],
});
