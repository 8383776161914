import { type Styles } from '@adornis/ass/style';
import { css } from '@adornis/chemistry/directives/css';
import { XCheckbox } from '@adornis/forms/x-checkbox';
import { html, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import './fonts';

/**
 * @element d-checkbox
 */
@customElement('d-checkbox')
export class DubniumCheckbox extends XCheckbox {
  override styles() {
    const overrideStyles: Styles = {
      ':host([white]) d-text': { color: '#fff' },
      ':host': {
        userSelect: 'none',
      },
      ':host([error]) div.item label': {
        border: `1px solid ${this.colors.tone.darkGreen + ' !important'}`,
        background: this.colors.tone.neutralAlabster,
      },
      '#circle': {
        position: 'relative',
        width: '3.5rem',
        height: '1.75rem',
        fontSize: '.75rem',
        borderRadius: 'this.sizes.borderRadiusSecondary',
        transition: 'backgroundColor .12s, color .12s',
        background: this.value.value ? this.colors.accent : this.colors.neutral.shade(300),
        color: this.value.value ? this.colors.on.accent : 'unset',
      },
      ".toggle-rect-color input[type='checkbox']": {
        display: 'none',
      },
      ".toggle-rect-color input[type='checkbox'] + label": {
        display: 'block',
        position: 'relative',
        width: '3.5rem',
        height: '1.75rem',
        background: this.colors.neutral.shade(100),
        borderRadius: 'calc(1.75rem / 2)',
        cursor: 'pointer',
        userSelect: 'none',
      },
      ".toggle-rect-color input[type='checkbox'] + label:before": {
        content: "''",
        display: 'block',
        width: '1.35rem',
        height: '1.35rem',
        borderRadius: '50%',
        background: '#fff',
        position: 'absolute',
        left: '0.2rem',
        top: '0.2rem',
        transition: 'all 0.2s ease-in-out',
      },
      ".toggle-rect-color input[type='checkbox']:checked + label": {
        background: this.colors.accent,
      },
      ".toggle-rect-color input[type='checkbox']:checked + label:before": {
        left: '1.95rem',
      },
    };
    return [...super.styles(), overrideStyles];
  }

  override render() {
    return html`
      <d-flex
        tabindex="0"
        horizontal
        space="sm"
        crossaxis-center
        ${css({ cursor: this.disabled ? 'initial' : 'pointer' })}
        @keyup=${(e: KeyboardEvent) => {
          if (e.key === 'Enter') this._toggle();
        }}
      >
        ${this._circle()}
        ${this.label
          ? html`
              <d-text
                ${css({
                  color: this.error ? (this.error === '' ? '' : this.colors.tone.darkGreen) : '',
                })}
              >
                ${this.label}
              </d-text>
            `
          : nothing}
      </d-flex>
    `;
  }

  protected override _circle() {
    const isSelected = this.value.value;
    return html`
      <div
        class="item"
        @click=${e => {
          if (this.disabled) return;

          e.preventDefault();
          e.stopPropagation();

          this._toggle();
        }}
      >
        <div class="toggle-rect-color">
          <input type="checkbox" id="rect3" name="check" ?checked=${isSelected} />
          <label for="rect3"></label>
        </div>
      </div>
    `;
  }
}
