import type { Maybe } from '@adornis/base/utilTypes';
import { Entity, Field } from '@adornis/baseql/decorators';
import { BaseButton } from '@adornis/buildify/db/extensions/BaseButton';
import { validate } from '@adornis/validation/decorators';
import { nonOptional } from '@adornis/validation/functions/nonOptional';
import { Link } from '../Link';

@Entity()
export class DubniumBaseButton extends BaseButton {
  static override _class = 'DubniumBaseButton';

  @Field(type => Boolean) isDigitalEmergency: Maybe<boolean>;

  @Field(type => Boolean) invert: Maybe<boolean>;

  @validate(nonOptional())
  @Field(type => Link, { default: v => v ?? new Link({}) })
  link!: Link;

  @Field(type => String) alignHorizontal: Maybe<string>;
  @Field(type => String) alignVertical: Maybe<string>;
}
