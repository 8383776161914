import type { Maybe } from '@adornis/base/utilTypes';
import { Entity, Field } from '@adornis/baseql/decorators';
import { BiMap } from '@adornis/digitale-helden-shared/db/zoho/BiMap';
import type { ZohoRecord } from '@adornis/digitale-helden-shared/db/zoho/types';
import { ZohoModule } from '@adornis/digitale-helden-shared/db/zoho/zoho';
import { ZohoEntity } from '@adornis/digitale-helden-shared/db/zoho/zoho-entity';

export enum CONTACT_PRODUCT_RELATION_ZOHO_FIELDS {
  ID = 'id',
  CONTACT = 'Kontakt',
  PRODUCT = 'Produkt',
  NOTATIONS = 'Bezeichnungen',
}

export const CONTACT_PRODUCT_RELATION_BIMAP = new BiMap<string, CONTACT_PRODUCT_RELATION_ZOHO_FIELDS>([
  ['id', CONTACT_PRODUCT_RELATION_ZOHO_FIELDS.ID],
  ['productID', CONTACT_PRODUCT_RELATION_ZOHO_FIELDS.PRODUCT],
  ['contactID', CONTACT_PRODUCT_RELATION_ZOHO_FIELDS.CONTACT],
  ['notations', CONTACT_PRODUCT_RELATION_ZOHO_FIELDS.NOTATIONS],
]);

export const CONTACT_PRODUCT_RELATION_FOREIGN_KEYS = [
  CONTACT_PRODUCT_RELATION_ZOHO_FIELDS.CONTACT,
  CONTACT_PRODUCT_RELATION_ZOHO_FIELDS.PRODUCT,
];

/**
 * @entity ContactProductRelation
 */
@Entity()
export class ContactProductRelation extends ZohoEntity {
  static override _class = 'ContactProductRelation';
  static override ZOHO_MODULE = ZohoModule.CONTACT_PRODUCT_RELATIONS;
  static override ZOHO_FIELDS = Array.from(CONTACT_PRODUCT_RELATION_BIMAP.values).join(',');

  static override resolveID(entity: any): string {
    return entity.id;
  }

  @Field(type => String) id!: string;
  @Field(type => String) productID!: string;
  @Field(type => String) contactID!: string;
  @Field(type => [String]) notations: Maybe<string[]>;

  override serializeZoho = (isNew: boolean = false) => {
    const fields = {};
    const keys = Array.from(CONTACT_PRODUCT_RELATION_BIMAP.keys);
    keys.forEach(key => {
      const keyZoho = CONTACT_PRODUCT_RELATION_BIMAP.get(key);
      if (!keyZoho || !this[key]) return;
      if (CONTACT_PRODUCT_RELATION_FOREIGN_KEYS.includes(keyZoho)) {
        fields[keyZoho] = {
          id: this[key],
        };
      } else {
        fields[keyZoho] = this[key];
      }
    });

    const data: ZohoRecord<any> = {
      data: [fields],
      trigger: ['workflow'],
    };
    return JSON.stringify(data).replace(/"id":[ ]?"([0-9]+)"/g, '"id":$1');
  };

  static override deserializeZoho = (rawData: any) => {
    const fields = {};
    const keys = Array.from(CONTACT_PRODUCT_RELATION_BIMAP.reverseKeys);
    keys.forEach(key => {
      const keyLAS = CONTACT_PRODUCT_RELATION_BIMAP.reverseGet(key);
      if (!keyLAS) return;
      if (CONTACT_PRODUCT_RELATION_FOREIGN_KEYS.includes(key)) {
        fields[keyLAS] = rawData[key]?.id ?? null;
      } else {
        fields[keyLAS] = rawData[key] ?? null;
      }
    });
    return new ContactProductRelation({
      ...fields,
    });
  };
}
