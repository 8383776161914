import { Entity, Field } from '@adornis/baseql/decorators';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity';
import { validate } from '@adornis/validation/decorators';
import { nonOptional } from '@adornis/validation/functions/nonOptional';

@Entity()
export class SignupLegalData extends AdornisEntity {
  static override _class = 'SignupLegalData';

  @validate(nonOptional())
  @Field(type => Boolean, { default: v => v ?? false })
  acceptedAllowedTo!: boolean;

  @validate(nonOptional())
  @Field(type => Boolean, { default: v => v ?? false })
  acceptedConditionsOfParticipation!: boolean;

  @validate(nonOptional())
  @Field(type => Boolean, { default: v => v ?? false })
  acceptedPrivacy!: boolean;

  @validate(nonOptional())
  @Field(type => Boolean, { default: v => v ?? false })
  acceptedWithdrawal!: boolean;
}
