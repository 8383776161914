import { setGlobalStyle } from '@adornis/chemistry/elements/theming/global-style';

setGlobalStyle(() => ({
  '@keyframes shake': {
    '0%, 100%': {
      transform: 'translateX(0)',
    },
    '10%, 30%, 50%, 70%, 90%': {
      transform: 'rotate(.5deg)',
    },
    '20%, 40%, 60%, 80%': {
      transform: 'rotate(-.5deg)',
    },
  },
  '.shake': {
    animation: 'shake .5s ease-in-out alternate',
  },
}));
