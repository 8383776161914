import type { Styles } from '@adornis/ass/style';
import { SizeConsumer } from '@adornis/buildify/client/globals/consumer';
import { Size } from '@adornis/buildify/client/globals/enums';
import type { BuildifyDocumentDefinition } from '@adornis/buildify/client/globals/types';
import { ChemistryLitElement } from '@adornis/chemistry/chemistry-lit-element';
import { xComponents } from '@adornis/chemistry/elements/x-components';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';

export const DubniumDocumentContentExtension: BuildifyDocumentDefinition = {
  render(content) {
    return html` <d-document-content-extension> ${content} </d-document-content-extension> `;
  },
};

@customElement('d-document-content-extension')
export class DDocumentExtension extends ChemistryLitElement {
  private _consumedSize = SizeConsumer(this);

  override render(): any {
    return xComponents(html`
      <d-flex document>
        <slot></slot>
      </d-flex>
    `);
  }

  public get padding() {
    const size = this._consumedSize.value;

    switch (size) {
      case Size.DESKTOP:
        return '48px';
      case Size.TABLET:
        return '32px';
      default:
        return '24px';
    }
  }

  override styles() {
    return [
      ...super.styles(),
      {
        '[document]': {
          padding: this.padding,
        },
      },
    ] as Styles[];
  }
}
