import { ID } from '@adornis/baseql/baseqlTypes';
import { Arg, Entity, Field, Mutation } from '@adornis/baseql/decorators';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import type { BiMap } from './BiMap';
import { type ZohoModule } from './enumns/zoho';

export const convertBaseQLSelectionSetToZohoFieldSelection = <T extends AdornisEntity>(
  gqlFields: BaseQLSelectionSet<T>,
  zohoFieldsBiMap: BiMap<string, string>,
) => {
  const fields: string[] = [];
  for (const groupField of Array.from(zohoFieldsBiMap.keys)) {
    const zohoField = zohoFieldsBiMap.get(groupField);
    if (gqlFields[groupField] && zohoField) fields.push(zohoField);
  }
  return fields.join(',');
};

@Entity()
export abstract class ZohoEntity extends AdornisEntity {
  static override _class = 'ZohoEntity';
  static ZOHO_FIELDS: string;
  static ZOHO_MODULE: ZohoModule;

  get zohoFields() {
    if (!(this.constructor as typeof ZohoEntity).ZOHO_FIELDS) throw new Error('Property ZOHO_FIELDS needs to be set!');
    return { fields: (this.constructor as typeof ZohoEntity).ZOHO_FIELDS };
  }

  get zohoModule() {
    return (this.constructor as typeof ZohoEntity).ZOHO_MODULE;
  }

  serializeZoho = () => {
    return JSON.stringify({
      data: [this.toJSON()],
    });
  };

  static override resolveID(entity: ZohoEntity): string {
    return entity.id;
  }

  @Field(type => ID)
  zohoID!: string;

  static deserializeZoho(rawData: object) {
    throw new Error('Implement in subclass!');
  }

  static gqlFieldsZoho = (gqlFields: BaseQLSelectionSet<any> | any, bimap: BiMap<string, any>): string[] => {
    return Object.keys(gqlFields)
      .map(key => {
        if (gqlFields[key] === 0) return null;
        const field = bimap.get(key);
        if (!field) return null;
        return field;
      })
      .filter(e => !!e);
  };

  @Mutation(type => String)
  public static removeRecord(@Arg('zohoID', type => String) zohoID: string) {
    return async () => {
      const { deleteZohoRecord: removeZohoRecord } = await import('../server/zoho/api');
      return await removeZohoRecord(this.ZOHO_MODULE, zohoID);
    };
  }
}
