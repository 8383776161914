import { AcademyRoles, ContactCategory } from '@adornis/digitale-helden-shared/db/enums';

export enum MediaType {
  FUNK_FERNSEHEN = 'Funk/Fernsehen',
  FÖRDERER_IN = 'Förderer*in',
  PARTNER_IN = 'Partner*in',
  POLITIK = 'Politik',
  PRESSE = 'Presse',
  SPENDER_IN = 'Spender*in',
  SONSTIGES = 'Sonstiges',
}

export const outsideGermany = 'Außerhalb von Deutschland';

export enum ProfileTab {
  DETAILS = 'Details',
  NOTIFICATIONS = 'Benachrichtigungen',
}

export enum InstitutionTab {
  DETAILS = 'Details',
  USERS = 'Benutzer',
}

export enum OrderStatus {
  ACTIVE = 'Aktiviert',
  DEACTIVE = 'Deaktiviert',
}

export enum OrderAttendenceStatus {
  ANGEMELDET = 'Angemeldet',
  TEILGENOMMEN = 'Teilgenommen',
  NICHT_TEILGENOMMEN = 'Nicht teilgenommen',
  WIEDEREINSTIEG = 'Wiedereinstieg',
  ABGEBROCHEN = 'Abgebrochen',
  ABGESCHLOSSEN = 'Abgeschlossen',
}

export enum Titel {
  PROF = 'Prof.',
  DR = 'Dr.',
  PROF_DR = 'Prof. Dr.',
}

export enum GroupStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum GroupContactRole {
  PARTICIPANT = 'Teilnehmer:in',
  GROUP_ADMIN = 'Gruppen-Administrator:in',
}

export enum EmbedFormContactCategory {
  APPLICANT = ContactCategory.APPLICANT,
  CO_FINANCE = ContactCategory.CO_FINANCE,
  DH_TEAM = ContactCategory.DH_TEAM,
  SERVICE_PROVIDER = ContactCategory.SERVICE_PROVIDER,
  FUNDING = ContactCategory.FUNDING,
  PARTNER = ContactCategory.PARTNER,
  POLITICS = ContactCategory.POLITICS,
  PRESS = ContactCategory.PRESS,
  SOCIAL_MEDIA = ContactCategory.SOCIAL_MEDIA,
  DONOR = ContactCategory.DONOR,
  SONSTIGES = 'Sonstiges',
}

export enum FederalState {
  BADEN_WÜRTTEMBERG = 'Baden-Württemberg',
  BAYERN = 'Bayern',
  BERLIN = 'Berlin',
  BRANDENBURG = 'Brandenburg',
  BREMEN = 'Bremen',
  HAMBURG = 'Hamburg',
  HESSEN = 'Hessen',
  MECKLENBURG_VORPOMMERN = 'Mecklenburg-Vorpommern',
  NIEDERSACHSEN = 'Niedersachsen',
  NORDRHEIN_WESTFALEN = 'Nordrhein-Westfalen',
  RHEINLAND_PFALZ = 'Rheinland-Pfalz',
  SAARLAND = 'Saarland',
  SACHSEN = 'Sachsen',
  SACHSEN_ANHALT = 'Sachsen-Anhalt',
  SCHLESWIG_HOLSTEIN = 'Schleswig-Holstein',
  THÜRINGEN = 'Thüringen',
}

// export enum AcademyRoles {
//   SECRETARIAT = 'Sekretariat',
//   PEDAGOGOU = 'Pädagog:in',
//   ELTERNTEIL = 'Elternteil',
//   ELTERNVERTRETUNG = 'Elternvertretung',
//   SCHULLEITER = 'Schulleiter:in',
//   SOZIALARBEITER = 'Sozialarbeiter:in',
//   SCHUELER = 'Schüler:in',
//   STUDENT = 'Student:in',
//   SONSTIGES = 'Sonstiges',
// }

export enum ContactRegisterAcademyRoles {
  ELTERNTEIL = AcademyRoles.ELTERNTEIL,
  SCHULLEITER = AcademyRoles.SCHULLEITER,
  STUDENT = AcademyRoles.STUDENT,
  SCHUELER = AcademyRoles.SCHUELER,
  SONSTIGES = AcademyRoles.SONSTIGES,
}

export enum MentoringContactAcademyRole {
  SCHULLEITER = AcademyRoles.SCHULLEITER,
  PEDAGOGOU = AcademyRoles.PEDAGOGOU,
  ELTERNTEIL = AcademyRoles.ELTERNTEIL,
  STUDENT = AcademyRoles.STUDENT,
  SONSTIGES = AcademyRoles.SONSTIGES,
}

export enum ContactCompanyPermission {
  ADMIN = 'Firmen-Admin',
  MEMBER = 'Firmen-Zugehörige:r',
}

export enum ContactDepartment {
  IT = 'IT',
  MARKETING = 'Marketing',
  EMPLOYEES = 'Personal',
  DISTRIBUTION = 'Vertrieb',
  SUPPORT = 'Support',
  FINANCE = 'Finanzen',
}

export enum ContactPosition {
  MANAGEMENT = 'Geschäftsleitung/Schulleitung',
  ANSPRECHPARTNERIN = '(Schul-)Ansprechpartner:in',
  BEREICHSLEITUNG = 'Bereichsleitung/Teamleitung',
  PROJEKTLEITUNG = 'Projektleitung',
  HEAD_OF_DEPARTMENT = 'Bereichsleitung/Teamleitung',
  HEAD_OF_PROJECT = 'Projektleitung',
  EMPLOYEE = 'Mitarbeiter:in',
  APPRENTICE = 'Auszubildende:r/Praktikant:in',
  PEDAGOGUE = AcademyRoles.PEDAGOGOU,
  ELTERNTEIL = AcademyRoles.ELTERNTEIL,
  SCHUELER = AcademyRoles.SCHUELER,
  STUDENT = AcademyRoles.STUDENT,
  SONSTIGES = AcademyRoles.SONSTIGES,
}

export enum CompanyType {
  SERVICE_PROVIDER = 'Dienstleister',
  SPONSOR = 'Förder/in',
  HERO_SCHOOL = 'Helden-Schule',
  SUPPLIER = 'Lieferant/in',
  NETWORK = 'Netzwerk',
  PARTNER = 'Partner/in',
  POLITICS = 'Politik',
  PRESS = 'Presse/Medien',
  SCHOOL = 'Schule',
  RESELLER = 'Reseller',
  SOCIAL_MEDIA = 'Social Media',
  RADIO_TELEVISION = 'Funk/Fernsehen',
  SOCIAL_ORGANISATION = 'Soziale Organisation',
  CO_FINANCE = 'Co-Finanzierer:in',
}

export enum SchoolType {
  GRUNDSCHULE = 'Grundschule',
  HAUPTSCHULE = 'Hauptschule',
  REALSCHULE = 'Realschule',
  GYMNASIUM_OHNE_OBERSTUFE = 'Gymnasium ohne gymnasiale Oberstufe',
  GYMNASIUM_MIT_OBERSTUFE = 'Gymnasium mit gymnasialer Oberstufe',
}

export enum State {
  BW = 'Baden-Württemberg',
  BY = 'Bayern',
  BE = 'Berlin',
  BB = 'Brandenburg',
  HB = 'Bremen',
  HH = 'Hamburg',
  HE = 'Hessen',
  MV = 'Mecklenburg-Vorpommern',
  NI = 'Niedersachsen',
  NRW = 'Nordrhein-Westfalen',
  RP = 'Rheinland-Pfalz',
  SL = 'Saarland',
  SN = 'Sachsen',
  ST = 'Sachsen-Anhalt',
  SH = 'Schleswig Holstein',
  TH = 'Thüringen',
}
