import { ContainerSectionExtension } from '@adornis/buildify/client/extensions/ContainerSectionExtension';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import type { BaseContainerSection } from '@adornis/buildify/db/extensions/BaseSection';

export const BuildifyCampusSectionExtension: BuildifyExtension<BaseContainerSection> = {
  ...ContainerSectionExtension,
  toolbar: {
    icon: 'pipe-section',
    text: ContainerSectionExtension.toolbar.text,
  },
};
