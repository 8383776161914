import type { ValueEvent } from '@adornis/base/utilTypes';
import { SpacingExtension } from '@adornis/buildify/client/extensions/SpacingExtension';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types';
import type { BaseSpacing } from '@adornis/buildify/db/extensions/BaseSpacing';
import { html, nothing } from 'lit';
import '../../../d-button';
import '../../../d-input';
import '../../../fonts';

export const BuildifyCampusSpacingExtension: BuildifyExtension<BaseSpacing> = {
  class: SpacingExtension.class,
  toolbar: { icon: 'distribute-spacing-vertical', text: { default: 'Abstand' } },
  render(instance, { defaultValues, isSubelement, dropzoneBefore, dropzoneAfter }) {
    return SpacingExtension.render(instance, { defaultValues, isSubelement, dropzoneBefore, dropzoneAfter });
  },
  editor: SpacingExtension.editor,
  group: 'Basics',
};
