import { registerQuery } from '@adornis/baseql/metadata/register';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { PRODUCT_BIMAP, Product } from '../../../db/Product';
import { getAllZohoRecords } from '../../../server/zoho/api';

//* Resolver
const getAllProductsResolver = () => {
  return async (gqlFields: BaseQLSelectionSet<Product>) => {
    const zohoSelectionSet = Product.gqlFieldsZoho(gqlFields, PRODUCT_BIMAP).join(',');
    const rawProducts = await getAllZohoRecords(Product.ZOHO_MODULE, { fields: zohoSelectionSet });
    const products = rawProducts.data.map(product => Product.deserializeZoho(product));
    return products;
  };
};

//* Query
export const getAllProducts = registerQuery({
  type: () => [Product],
  operationName: 'getAllProducts',
  resolve: getAllProductsResolver,
  params: [],
});
